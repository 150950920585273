import { Injectable } from '@angular/core';
import * as mzCommon from 'mz-common';
import { FrfValueGetterClassInterface } from '../../@res/@abstract/@interface/common.inteface';
import { FrfSharedStorageService } from '../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfValueGetterPayloadOptions } from './@res/@abstract/@interface/common.interface';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterPayloadOptionsService
  implements FrfValueGetterClassInterface {
  constructor(private frfSharedStorage: FrfSharedStorageService) {}

  /**
   *
   * */
  public get(
    getter: FrfValueGetterPayloadOptions,
    frfElement: any,
    frf: any
  ): any {
    const frfObject = getter.id
      ? this.frfSharedStorage.frfValueGetter.getFrfElementById(
          getter.id,
          frf,
          null,
          frfElement
        )
      : frfElement;

    if (frfObject) {
      return mzCommon.getValueFromObjectByPath(
        'body.payload.options',
        frfObject
      );
    }

    return null;
  }
}
