import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';

import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';
import { CntPubSubMessageService } from '@cnt-nx-workspace/feature/cnt-pub-sub-message';

export abstract class DbAbstractClass<Content extends {} = {}> {
  public db: PouchDB.Database<Content>;

  /**
   * */
  public dbName: string;

  /**
   * */
  public idKey: string;

  /**
   * */
  public cntPubSubMessageService: CntPubSubMessageService;

  constructor(
    dbName: string,
    idKey: string,
    cntPubSubMessageService: CntPubSubMessageService
  ) {
    this.init(dbName, idKey);
    this.cntPubSubMessageService = cntPubSubMessageService;
  }

  /**
   * */
  private init(dbName: string, db) {
    PouchDB.plugin(PouchDBFind);
    this.db = new PouchDB<any>((this.dbName = dbName));
  }

  public createIndex() {
    return fromPromise(
      this.db.createIndex({
        index: {
          fields: ['n', 't'],
          ddoc: 'nindex2'
        }
      })
    );
  }

  /**
   * */
  public add(doc: Content, db = this.db): Observable<PouchDB.Core.Response> {
    return fromPromise(
      db.put(<any>{
        ...doc,
        _id: doc[this.idKey]
      })
    );
  }

  /**
   * */
  public find(
    request: PouchDB.Find.FindRequest<Content>,
    db = this.db
  ): Observable<Content[]> {
    return <any>(
      fromPromise(db.find(request)).pipe(map(data => data && data.docs))
    );
  }

  /**
   * */
  public changes(
    options?: PouchDB.Core.ChangesOptions,
    db = this.db
  ): Observable<any> {
    return new Observable(observer => {
      const changes = db
        .changes({
          since: 'now',
          live: true,
          include_docs: true,
          ...(options || {})
        })
        .on('change', function(
          change: PouchDB.Core.ChangesResponseChange<any>
        ) {
          // handle change
          observer.next(change.doc);
        })
        .on('complete', function(info) {
          // changes() was canceled
          observer.complete();
        })
        .on('error', function(err) {
          console.log(err);
          observer.complete();
        });

      return () => {
        // whenever you want to cancel
        changes.cancel();
      };
    });
  }
}
