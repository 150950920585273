<!--<nb-layout>-->
<!--  <nb-layout-column class="colored-column-basic">-->
<!--<mz-modal-window>-->
<div
  class="content"
  mz-screen-detector
  [ngClass]="{
    _hide: mainSharedService.loader
  }"
>
  <router-outlet></router-outlet>
</div>
<!--</mz-modal-window>-->

<!-- loader-->
<div
  class="_loader-block"
  [ngClass]="{
    _hide: !mainSharedService.loader
  }"
>
  <img
    class="_loader"
    alt="Загрузка"
    src="assets/images/gif/loader/loader-dot-2.gif"
  />
</div>
<!--<mz-camera></mz-camera>-->

<!--<div class="block">-->
<!--  <div class="header">-->
<!--    <airtafa-input-->
<!--      placeholder="Срок"-->
<!--      type="number"-->
<!--      min="3"-->
<!--      max="24"-->
<!--    ></airtafa-input>-->
<!--    <airtafa-input placeholder="Цена" type="number"></airtafa-input>-->
<!--    <airtafa-input placeholder="Первый взнос" type="number"></airtafa-input>-->
<!--    <airtafa-input placeholder="Поручители" type="number"></airtafa-input>-->
<!--  </div>-->
<!--</div>-->
<!--  </nb-layout-column>-->
<!--</nb-layout>-->
