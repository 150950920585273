import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../../environments/environment';
import { MzOutclickModule } from 'mz-outclick';
import { MzModalWindowModule } from 'mz-modal-window';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ConnectAuthModule } from '@cnt-nx-workspace/feature/auth';
import { MzScreenDetectorModule } from 'mz-screen-detector';
import { MzStorageModule } from 'mz-storage';
import { MzDictionaryInputTypeEnum, MzDictionaryModule } from 'mz-dictionary';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { SumSubComponent } from '../../@view/lk/@sub/@view/sum-sub/sum-sub.component';
import { BaseAppHeaderComponent } from './@sub/@view/base-app-header/base-app-header.component';
import { BaseAppLeftMenuComponent } from './@sub/@view/base-app-left-menu/base-app-left-menu.component';
import { ConnectSumsubModule } from 'mz-connect-sumsub';
import { MzLongClickModule } from 'mz-long-click';
import { MdatePipe } from './@sub/@pipe/mdate.pipe';
import * as moment from 'moment';
import { CIconComponent } from './@sub/@view/c-icon/c-icon.component';
import { FileDropDirective } from './@sub/@directrive/file-drop/file-drop.directive';
import { MzCountdownPipe } from './@sub/@pipe/mz-countdown/mz-countdown.pipe';
import { CntTableComponent } from './@sub/@view/cnt-table/cnt-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CntTableFlexModule } from './@sub/@view/cnt-table-flex/cnt-table-flex.module';
import { RouterModule } from '@angular/router';
import { PaginatorComponent } from './@sub/@view/paginator/paginator.component';
import { PaginatorFirestoreComponent } from './@sub/@view/paginator-firestore/paginator-firestore.component';
import { FirestoreTimePipe } from './@sub/@pipe/firestore-time/firestore-time.pipe';
import { PaginatorSimpleGraphqlComponent } from './@sub/@view/paginator-simple-graphql/paginator-simple-graphql.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LifecycleDirective } from '@cnt-nx-workspace/function/shared/base';
import { MzOnlineModule } from '@cnt-nx-workspace/mz/ng-online';
import { NbContextMenuModule } from '@nebular/theme';

@NgModule({
  imports: [
    MzOnlineModule,

    RouterModule,
    CommonModule,
    MatSelectModule,
    MzLongClickModule,
    ConnectSumsubModule,
    MatSidenavModule,
    MatTabsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSnackBarModule,
    DragDropModule,
    //  screen dictionary
    CntTableFlexModule,
    MzScreenDetectorModule.forRoot(
      {
        small: 0,
        medium: 768,
        large: 1024,
      },
      150,
      ['body']
    ),
    // dictionary module,
    MzDictionaryModule.forRoot({
      type: MzDictionaryInputTypeEnum.graphQl,
      url: environment.domain.graphqlDomain,
    }),
    // storage
    MzStorageModule.forRoot({
      storageKey: 'ConnectStorage',
      group: 'base',
    }),
    // auth - module
    ConnectAuthModule,
    // modal window service
    MzModalWindowModule,
    MzOutclickModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //  firebase
    AngularFireModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
  ],
  exports: [
    MzOnlineModule,

    MatSelectModule,
    RouterModule,
    MatSidenavModule,
    MzLongClickModule,
    MatTabsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSnackBarModule,
    DragDropModule,
    CntTableFlexModule,
    MzModalWindowModule,
    ConnectSumsubModule,
    MzScreenDetectorModule,
    MzDictionaryModule,
    MzStorageModule,
    CommonModule,
    ConnectAuthModule,
    MzOutclickModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    ScrollingModule,

    SumSubComponent,
    BaseAppHeaderComponent,
    BaseAppLeftMenuComponent,
    CIconComponent,
    CntTableComponent,
    PaginatorComponent,
    PaginatorFirestoreComponent,
    PaginatorSimpleGraphqlComponent,

    FileDropDirective,

    MdatePipe,
    FirestoreTimePipe,
    MzCountdownPipe,
  ],
  declarations: [
    SumSubComponent,
    BaseAppHeaderComponent,
    BaseAppLeftMenuComponent,
    CIconComponent,
    CntTableComponent,
    PaginatorComponent,
    PaginatorFirestoreComponent,
    PaginatorSimpleGraphqlComponent,
    FileDropDirective,
    MdatePipe,
    FirestoreTimePipe,
    MzCountdownPipe,
    LifecycleDirective,
  ],
  entryComponents: [SumSubComponent],
  providers: [
    /*
     * firebase options
     * */
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
  ],
})
export class SharedModule {
  constructor() {
    moment.locale('ru');
  }
}
