import { Injectable } from '@angular/core';
import * as mzCommon from 'mz-common';
import { FrfValueGetterClassInterface } from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueGetterElementStatus } from './@res/@abstract/@interface/common.interface';
import { FrfSharedStorageService } from '../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterElementStatusService
  implements FrfValueGetterClassInterface {
  constructor(private frfSharedStorage: FrfSharedStorageService) {}

  /**
   *
   * */
  public get(
    getter: FrfValueGetterElementStatus,
    frfElement: any,
    frf: any
  ): boolean {
    const frfObject = getter.id
        ? this.frfSharedStorage.frfValueGetter.getFrfElementById(
            getter.id,
            frf,
            null,
            frfElement
          )
        : frfElement,
      basePathToStatus = 'body.status.value';

    if (frfObject) {
      return (
        mzCommon.getValueFromObjectByPath(basePathToStatus, frfObject) || false
      );
    }

    return false;
  }
}
