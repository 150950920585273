import { ChannelNamePubSubEnum } from '@cnt-multi-shared/@shared/pubnub/@res/@enum/common.enum';

export const systemPubnubPostfixFor = '-pnpres';

/**
 * */
export function pubnub_getChannelGroupNameWithPresenceGroup(
  uid: string,
  addPostfix = false
): string {
  return `cg_pr_${uid}${addPostfix ? systemPubnubPostfixFor : ''}`;
}
/**
 * */
export function pubnub_getChannelGroupNameWithPresenceGroupByUn(
  uid: number,
  addPostfix = false
): string {
  return `cg_un_pr_${uid}${addPostfix ? systemPubnubPostfixFor : ''}`;
}

/**
 * */
export function pubnub_getChannelGroupName(uid: string): string {
  return `cg_${uid}`;
}

/**
 * */
export function pubnub_getChannelGroupNameByUn(un: number): string {
  return `cg_un_${un}`;
}

/**
 * */
export function pubnub_getPrivateChannel(uid: string): string {
  return `uid_${uid}`;
}

/**
 * */
export function pubnub_getPrivateChannelByUn(un: number): string {
  return `un_${un}`;
}


/**
 * */
export function pubnub_getChannelsForUsers(uuid: string, un: number): string[] {
  return [
      ChannelNamePubSubEnum.public,
      pubnub_getPrivateChannel(uuid),
      pubnub_getPrivateChannelByUn(un)
  ];
}

/**
 * */
export function pubnub_getChannelsGroupForUser(uid: string, un: number): string[] {
  return [
    pubnub_getChannelGroupNameWithPresenceGroup(uid, true),
    pubnub_getChannelGroupNameWithPresenceGroupByUn(un, true),
    pubnub_getChannelGroupNameWithPresenceGroup(uid, false),
    pubnub_getChannelGroupNameWithPresenceGroupByUn(un, false),
    pubnub_getChannelGroupName(uid),
    pubnub_getChannelGroupNameByUn(un)
  ];
}
