import { Injectable } from '@angular/core';
import { FrfApiSetFieldValueInterface } from './@res/@abstract/@interface/common.interface';
import { FrfValueCheckerEnum } from '../../../../../../../../../../../../../../../../../../../../../../@service/@group:value-services/frf-value-checker/@res/@abstract/@enum/common.enum';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../../../../../../../../../../../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfApiSetFieldValueService
  implements FrfApiSetFieldValueInterface {
  constructor(
    private frfValueSetter: FrfValueSetterService,
    public common: FreeformCommonService
  ) {}

  /**
   * set field value
   * */
  public setFieldValue(
    formElement: any,
    value: any,
    triggersList: string[] = ['onChange', 'onSetValue'],
    isDependentElementsStart: boolean = false,
    updateWatchChanges: boolean = true,
    updateStatus: boolean = true
  ): void {
    this.frfValueSetter.setFieldValue(
      formElement,
      value,
      triggersList,
      isDependentElementsStart,
      undefined,
      FrfValueCheckerEnum.isAny,
      undefined,
      updateWatchChanges,
      updateStatus
    );
  }

  /**
   * update field status
   * */
  public updateFieldStatus(formElement: any, status: boolean): void {
    this.frfValueSetter.updateStatus(formElement, status, this.common.freeform);
  }
}
