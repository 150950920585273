export namespace FrfCustomFieldNamespace {
  /**
   *
   * */
  export const idPrefix = 'frf-custom:';

  /**
   *
   * */
  export function isCustomComponentType(idWithPrefix: string): boolean {
    if (typeof idWithPrefix !== 'string') return false;

    return idWithPrefix.startsWith(idPrefix);
  }
}
