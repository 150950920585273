import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';
import request, { GraphQLClient } from 'graphql-request';
import { Variables } from 'graphql-request/dist/src/types';

/*
 * Для graphql запросов по кастомным ссылкам
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule,
})
export class GraphqlService {
  constructor() {}

  /**
   * simple graphql query request
   * */
  public simpleQuery(url: string, query: string, name?: string) {
    return Observable.create((observer: Observer<any>) => {
      request(url, query)
        .then((result: any) => {
          const data = result && result && name ? result[name] : result;

          observer.next(data);
          observer.complete();
        })
        .catch((err) => {
          observer.error(err);
          observer.complete();
        });
    });
  }

  /**
   * graphql query request with support headers
   * */
  public query(
    url: string,
    query: string,
    name?: string,
    headers?: any,
    variables?: Variables
  ): Observable<any> {
    const graphQLClient = new GraphQLClient(url, {
      headers,
    });

    return new Observable((observer: Observer<any>) => {
      graphQLClient
        .request(query, variables)
        .then((result: any) => {
          const data = result && result && (name ? result[name] : result);

          observer.next(data);
          observer.complete();
        })
        .catch((err) => {
          observer.error(err);
          observer.complete();
        });
    });
  }
}
