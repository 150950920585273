import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction } from 'rxjs';
import {
  auditTime,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  skip,
  take,
  throttleTime,
  timeout
} from 'rxjs/operators';
import { FrfMainLazyServiceModule } from '../../../../../frf-main-lazy-service.module';
import { FrfTriggerActionBaseInterface } from '../frf-trigger-action/@res/@abstract/@interface/common.interface';
import { FreeformCommonService } from '../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import {
  FrfTriggerActionPipeByFuncInputParamsEnum,
  FrfTriggerActionPipeEnum
} from './@res/@abstract/@enum/common.enum';
import {
  FrfTriggerAndActionPipeInterface,
  FrfTriggerInterface
} from '../../@res/@abstract/@interface/common.interface';
import { FrfTriggerAndActionFilterPipeInterface } from './@sub/frf-trigger-and-action-filter-by-value-pipe/@res/@abstract/@interface/common.interface';
import { FrfTriggerAndActionFilterByValuePipeService } from './@sub/frf-trigger-and-action-filter-by-value-pipe/frf-trigger-and-action-filter-by-value-pipe.service';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerAndActionPipeService {
  constructor(
    private common: FreeformCommonService,
    private frfTriggerAndActionFilterPipe: FrfTriggerAndActionFilterByValuePipeService
  ) {}

  /**
   * add pipes to action
   * */
  public add<T>(
    action: FrfTriggerActionBaseInterface | FrfTriggerInterface,
    frfObject: any,
    scope: any
  ): MonoTypeOperatorFunction<T> {
    return input$ => {
      if (
        action &&
        action.options$ &&
        action.options$.pipe &&
        Array.isArray(action.options$.pipe) &&
        action.options$.pipe.length
      ) {
        /*
         * if exists add pipes
         * */
        const pipes = this.common.sortByWeight<
          FrfTriggerAndActionPipeInterface
        >(action.options$.pipe);

        for (const pipe of pipes) {
          if (typeof pipe.active === 'boolean' && !pipe) continue;

          switch (pipe.key) {
            case FrfTriggerActionPipeEnum.auditTime:
              if (typeof pipe.val === 'number') {
                input$ = input$.pipe(auditTime(pipe.val));
              }
              break;

            case FrfTriggerActionPipeEnum.skip:
              if (typeof pipe.val === 'number') {
                input$ = input$.pipe(skip(pipe.val));
              }
              break;

            case FrfTriggerActionPipeEnum.debounceTime:
              if (typeof pipe.val === 'number') {
                input$ = input$.pipe(debounceTime(pipe.val));
              }
              break;

            case FrfTriggerActionPipeEnum.filterByValue:
              if (pipe.val) {
                input$ = input$.pipe(
                  filter(result =>
                    this.frfTriggerAndActionFilterPipe.pipe(
                      <FrfTriggerAndActionFilterPipeInterface>pipe,
                      frfObject,
                      scope
                    )
                  )
                );
              }
              break;

            /* TODO later add pass api classees to user func */
            /* TODO later add pass custom arguments */
            case FrfTriggerActionPipeEnum.mapByFunc:
              if (pipe.val) {
                input$ = input$.pipe(
                  map((result: any) => {
                    try {
                      return new Function(
                        FrfTriggerActionPipeByFuncInputParamsEnum.value,
                        FrfTriggerActionPipeByFuncInputParamsEnum.frfObject,
                        FrfTriggerActionPipeByFuncInputParamsEnum.action,
                        pipe.val
                      )(result, frfObject, action);
                    } catch (e) {
                      console.warn(
                        'mapByFunc - error',
                        pipe.val,
                        action,
                        frfObject,
                        e
                      );
                    }

                    return undefined;
                  })
                );
              }
              break;

            /* TODO later add pass api classees to user func */
            /* TODO later add pass custom arguments */
            case FrfTriggerActionPipeEnum.filterByFunc:
              if (pipe.val) {
                input$ = input$.pipe(
                  filter((result: any) => {
                    try {
                      return new Function(
                        FrfTriggerActionPipeByFuncInputParamsEnum.value,
                        FrfTriggerActionPipeByFuncInputParamsEnum.frfObject,
                        FrfTriggerActionPipeByFuncInputParamsEnum.action,
                        pipe.val
                      )(result, frfObject, action);
                    } catch (e) {
                      console.warn(
                        'filterByFunc - error',
                        pipe.val,
                        action,
                        frfObject,
                        e
                      );
                    }

                    return undefined;
                  })
                );
              }
              break;

            case FrfTriggerActionPipeEnum.timeout:
              if (typeof pipe.val === 'number') {
                input$ = input$.pipe(timeout(pipe.val));
              }
              break;

            case FrfTriggerActionPipeEnum.delay:
              if (typeof pipe.val === 'number') {
                input$ = input$.pipe(delay(pipe.val));
              }
              break;

            case FrfTriggerActionPipeEnum.throttleTime:
              if (typeof pipe.val === 'number') {
                input$ = input$.pipe(throttleTime(pipe.val));
              }
              break;

            case FrfTriggerActionPipeEnum.first:
              input$ = input$.pipe(first());
              break;

            case FrfTriggerActionPipeEnum.toNumber:
              input$ = input$.pipe(map(item => <any>parseInt(item + '', 10)));
              break;

            case FrfTriggerActionPipeEnum.distinctUntilChanged:
              input$ = input$.pipe(distinctUntilChanged());
              break;

            case FrfTriggerActionPipeEnum.take:
              if (typeof pipe.val === 'number') {
                input$ = input$.pipe(take(pipe.val));
              }
              break;
          }
        }
      }

      return input$;
    };
  }
}
