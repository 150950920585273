import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MzOnlineDirective } from './@sub/@directive/mz-online/mz-online.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MzOnlineDirective],
  exports: [MzOnlineDirective],
})
export class MzOnlineModule {}
