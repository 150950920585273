<div class="menu-control-block">
  <div class="search-icon control-icon" (click)="clickToSearchHandler()">
    <i class="fas fa-search"></i>
  </div>
  <div class="menu-icon control-icon">
    <i class="fas fa-bars" [matMenuTriggerFor]="menu2"></i>
    <!--    <i class="fas fa-bars" [nbContextMenu]="items"></i>-->
  </div>
</div>

<mat-menu #menu2="matMenu" [xPosition]="'after'" [yPosition]="'below'">
  <ng-template [ifScreenType]="'small'" (mzScreenChange)="updateView()">
    <button
      mat-menu-item
      *ngFor="let category of categories"
      (click)="clickToMenu.emit(category)"
    >
      {{ category.name }}
    </button>
  </ng-template>
  <button mat-menu-item *ngFor="let menu of menus" (click)="changeMenu(menu)">
    {{ menu.name }}
  </button>
</mat-menu>
