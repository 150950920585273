export enum FrfTriggerActionPipeEnum {
  throttleTime = 'throttle-time',
  debounceTime = 'debounce-time',
  timeout = 'timeout',
  delay = 'delay',
  distinctUntilChanged = 'distinct-until-changed',
  auditTime = 'audit-time',
  take = 'take',
  skip = 'skip',
  first = 'first',
  filterByValue = 'filter-by-value',
  mapByFunc = 'map-by-func',
  filterByFunc = 'filter-by-func',
  toNumber = 'to-number'
}

export enum FrfTriggerActionPipeByFuncInputParamsEnum {
  value = 'value',
  frfObject = 'frfObject',
  action = 'action'
}
