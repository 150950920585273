export enum FrfTriggerActionEnum {
  setPayloadOptions = 'set-payload-options',

  /**
   * @deprecated
   * */
  setFieldValueOld = 'setFieldValue',
  setFieldValue = 'set-field-value',

  /**
   * @deprecated
   * */
  setFileSourceOld = 'setFileSource',
  setFileSource = 'set-file-source',

  /**
   * set element data
   * */
  setElementData = 'set-element-data',

  /**
   * show toast
   * */
  showToast = 'show-toast',

  /**
   * set temp data - use only before reload
   * */
  setTempData = 'set-temp-data',

  /**
   * http request actions
   * */
  requestByGraphQl = 'request-by-graphql',
  requestByPost = 'request-by-post',
  requestByGet = 'request-by-get',
  requestBy = 'request-by',

  /* graphql to our cnt endpoint */
  requestByCntGraphQl = 'request-by-cnt-graphql',

  setLoadingState = 'set-loading-state',

  /**
   * set values from array
   * */
  forArray = 'for-array',

  /**
   * copy element
   * */
  copyElement = 'copy-element',

  /**
   * set state of element (use in pre submit / submit buttons)
   * */
  setElementState = 'set-element-state',

  /**
   * set payload
   * */
  setPayload = 'set-payload',

  /**
   * invoke method
   * */
  invokeMethod = 'invoke-method',

  /**
   * block content of full pages
   * */
  blocker = 'blocker',
  elementBlocker = 'element-blocker',

  setCustomMenu = 'set-custom-menu'
}

export enum FrfTriggerActionSequenceTypeEnum {
  concurrency = 'concurrency',
  parallel = 'parallel'
}
