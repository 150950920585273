import { ElementRef, Injectable } from '@angular/core';
import { MzDynamicJoin } from 'mz-dynamic-join';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { CntFlexyViewApiService } from '../cnt-flexy-view-api/cnt-flexy-view-api.service';
import { Apollo, gql } from 'apollo-angular-boost';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import {
  CntFlexyViewDepsForOwnerItemInterface,
  CntFlexyViewDepsItemType,
  CntFlexyViewForOwnerItemInterface,
} from './@res/@interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class CntFlexyViewService {
  /**
   * base source href
   * */
  private readonly baseSourceHref = 'https://cdn.ramman.net/flexy-view/';

  /**
   *
   * */
  private readonly prefix = 'cnt-flexy-view';
  /**
   *
   * */
  private readonly noneValue = '-';

  /**
   *
   * */
  private mzDynamicJoin = new MzDynamicJoin();

  /**
   *
   * */
  private inView$: Subject<{ hash: string; state: boolean }> = new Subject();

  constructor(
    private cntFlexyViewApiService: CntFlexyViewApiService,
    private apollo: Apollo,
    private connectAuthService: ConnectAuthService
  ) {}

  /**
   * TODO move to category
   * */
  public changeInViewDetection(hash: string, state: boolean) {
    this.inView$.next({
      hash,
      state,
    });
  }

  /**
   * TODO move to category
   * */
  public getChangeInViewDetection$(): Observable<{
    hash: string;
    state: boolean;
  }> {
    return this.inView$.pipe(
      distinctUntilChanged((a, b) => {
        a = a || <any>{};
        b = b || <any>{};

        return a.state === b.state && a.hash === b.hash;
      })
    );
  }

  /**
   *
   * */
  private getBaseUrlToComponent(
    user: string,
    name: string,
    version: number,
    external = ''
  ): string {
    return this.baseSourceHref + `${user}/${name}/${version}/${external}`;
  }

  /**
   * TODO later delete or fix
   * */
  private checkComponent(
    name: string,
    version: number,
    owner: string
  ): boolean {
    return true;
  }

  /**
   *
   * */
  public getComponentNameOfFlexyViewName(
    name: string,
    version: number,
    user: string
  ): string {
    return `${this.prefix}-${user}-${name}-${(version + '').replace(
      /[\.]+/g,
      '_'
    )}`;
  }

  /**
   *
   * */
  public getSelector(name: string, version: number, user: string): string {
    return (
      'cntFlexyView' + this.getComponentNameOfFlexyViewName(name, version, user)
    ).replace(/[\.\-]/g, '');
  }

  /**
   *
   * */
  private getLinkToCss(
    owner: string,
    componentName: string,
    version: number,
    deps: CntFlexyViewDepsForOwnerItemInterface
  ): string {
    return this.getLink(
      'main.css',
      deps.mainCss,
      owner,
      componentName,
      version,
      deps
    );
  }

  /**
   *
   * */
  private getLink(
    link: string,
    main: string,
    owner: string,
    componentName: string,
    version: number,
    deps: CntFlexyViewDepsForOwnerItemInterface
  ): string {
    const local = main ? (main.indexOf('://') === -1 ? main : null) : link;

    if (local) {
      return local !== this.noneValue
        ? this.getBaseUrlToComponent(owner, componentName, version, local)
        : null;
    }

    return main;
  }

  /**
   *
   * */
  private getLinkToJs(
    owner: string,
    componentName: string,
    version: number,
    deps: CntFlexyViewDepsForOwnerItemInterface
  ): string {
    return this.getLink(
      'main.js',
      deps.mainJs,
      owner,
      componentName,
      version,
      deps
    );
  }

  /**
   *
   * */
  private getLinkToAssets(
    owner: string,
    componentName: string,
    version: number
  ): string {
    return this.getBaseUrlToComponent(owner, componentName, version, 'assets/');
  }

  /**
   * add style and script
   * */
  private joinComponentResources(
    owner: string,
    componentName: string,
    version: number,
    deps: CntFlexyViewDepsForOwnerItemInterface
  ): Observable<boolean> {
    const flexyViewId = this.getComponentNameOfFlexyViewName(
        componentName,
        version,
        owner
      ),
      linkToCss = this.getLinkToCss(owner, componentName, version, deps),
      linkToJs = this.getLinkToJs(owner, componentName, version, deps),
      flows$: Observable<any>[] = [
        linkToJs
          ? from(this.mzDynamicJoin.addScriptByUrlIfNotExist(linkToJs))
          : of(true),
        linkToJs
          ? from(this.mzDynamicJoin.addStyleByUrlIfNotExist(linkToCss))
          : of(true),
      ];

    this.addFromDeps(flexyViewId, deps, flows$);

    return forkJoin(...flows$);
  }

  /**
   *
   * */
  private safeExtractJson(val: any): any {
    let obj;

    try {
      obj = JSON.parse(val);
    } catch (err) {
      obj = val;
    }

    return obj;
  }

  /**
   * add from deps
   * */
  private addFromDeps(
    flexyViewId: string,
    deps: CntFlexyViewDepsForOwnerItemInterface,
    flows$: Observable<any>[]
  ): void {
    if (deps) {
      /* add js */
      if (deps.js && deps.js.length) {
        deps.js.forEach((val) => {
          let flow: Observable<any>;

          val = this.safeExtractJson(val);

          switch (typeof val) {
            case 'string':
              flow = from(this.mzDynamicJoin.addScriptByUrlIfNotExist(val));
              break;

            case 'object':
              if (val?.val && val?.attr) {
                flow = from(
                  this.mzDynamicJoin.addScriptByUrlIfNotExist(
                    val.val,
                    undefined,
                    val.attr
                  )
                );
              }
              break;
          }
          flows$.push(flow);
        });
      }

      /* add js */
      if (deps.css && deps.css.length) {
        deps.css.forEach((val, id) => {
          val = this.safeExtractJson(val);

          let flow: Observable<any>;
          switch (typeof val) {
            case 'string':
              flow = from(this.mzDynamicJoin.addStyleByUrlIfNotExist(val));
              break;

            case 'object':
              if (val?.val && val?.attr) {
                flow = from(
                  this.mzDynamicJoin.addStyleByUrlIfNotExist(
                    val.val,
                    undefined,
                    val.attr
                  )
                );
              }
              break;
          }
          flows$.push(flow);
        });
      }

      /* add css code */
      if (deps.cssCode && deps.cssCode.length) {
        deps.cssCode.forEach((code) => {
          flows$.push(
            from(this.mzDynamicJoin.addStyleByCode(code, flexyViewId))
          );
        });
      }

      /* add js code */
      if (deps.jsCode && deps.jsCode.length) {
        deps.jsCode.forEach((code) => {
          flows$.push(
            from(this.mzDynamicJoin.addScriptByCode(code, flexyViewId))
          );
        });
      }
    }
  }

  /**
   *
   * */
  private joinComponentToDomSimple(
    owner: string,
    name: string,
    component: string,
    version: number,
    parentElement: ElementRef<HTMLElement>,
    flexyViewId: string,
    payload: any,
    changeValue: (value: any) => void,
    elementRef: (element: any) => void
  ): void {
    const customElement = document.createElement(flexyViewId),
      pathToAssets = this.getLinkToAssets(owner, name, version),
      cntApi = this.cntFlexyViewApiService;

    // @ts-ignore
    customElement.cntApi = cntApi;
    // @ts-ignore
    customElement.pathToAssets = pathToAssets;
    // @ts-ignore
    customElement.payload = payload && JSON.parse(payload);

    // @ts-ignore
    this.cntFlexyViewApiService.modal.init({
      pathToAssets: pathToAssets,
      cntApi: this.cntFlexyViewApiService,
    });

    customElement.addEventListener(
      'changeValue',
      (status: CustomEvent<boolean>) => {
        if (typeof changeValue === 'function') changeValue(status.detail);
      }
    );

    if (typeof elementRef === 'function') {
      elementRef(customElement);
    }

    parentElement.nativeElement.appendChild(customElement);
  }

  /**
   *
   * */
  private joinComponentToDom(
    owner: string,
    name: string,
    component: string,
    version: number,
    parentElement: ElementRef<HTMLElement>,
    flexyViewId: string,
    payload: any,
    changeValue: (value: any) => void,
    elementRef: (element: any) => void
  ) {
    customElements
      .whenDefined(flexyViewId)
      .then(() => {
        this.joinComponentToDomSimple(
          owner,
          name,
          component,
          version,
          parentElement,
          flexyViewId,
          payload,
          changeValue,
          elementRef
        );
      })
      .catch((err) => {
        console.warn('joinComponentToDom - err', flexyViewId, err);
      });
  }

  /**
   * download component, deps of component, and add to dom
   * */
  public initComponent(
    parentElement: ElementRef<HTMLElement>,
    owner: string,
    name: string,
    component: string,
    version: number,
    payload: any,
    deps: CntFlexyViewDepsForOwnerItemInterface,
    changeValue: (value: any) => void,
    elementRef: (element: any) => void
  ): Observable<boolean> {
    const flexyViewId = this.getComponentNameOfFlexyViewName(
      component,
      version,
      owner
    );

    if (!this.checkComponent(name, version, owner)) {
      console.error(`cnt-flexy-view - can not find component <${flexyViewId}>`);
      return of(false);
    }

    return this.joinComponentResources(owner, name, version, deps).pipe(
      tap((result) => {
        if (!result) {
          console.error(
            `cnt-flexy-view - can not get styles or scripts for <${flexyViewId}>`
          );
        } else {
          this.joinComponentToDom(
            owner,
            name,
            component,
            version,
            parentElement,
            flexyViewId,
            payload,
            changeValue,
            elementRef
          );
        }
      })
    );
  }

  /**
   * get categories for owner
   * */
  public getForOwner(
    owner: string,
    page?: string
  ): Observable<CntFlexyViewForOwnerItemInterface[]> {
    const query = gql`
      query flexyViewGetView(
        $uid: String
        $page: String
        $owner: String!
        $token: String
      ) {
        flexyViewGetView(uid: $uid, page: $page, owner: $owner, token: $token) {
          status
          data {
            name
            weight
            version
            component
            payload
            options {
              backgroundColor
              paddingTop
              paddingBottom
              fullWidth
              css
            }
            creator
            hash
            viewName
            deps {
              jsCode
              cssCode
              js
              css
              mainJs
              mainCss
            }
          }
          error {
            code
            text
          }
        }
      }
    `;

    return this.apollo
      .watchQuery({
        query: query,
        variables: {
          uid: this.connectAuthService.uid,
          token: this.connectAuthService.token,
          owner: owner,
          page: page,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        catchError(() => of(null)),
        map((result: any) => {
          return result && result.data && result.data.flexyViewGetView;
        }),
        map(
          (result: { data: CntFlexyViewForOwnerItemInterface[] }) =>
            result && result.data
        )
      );
  }

  private getOnlyUrls(links?: CntFlexyViewDepsItemType[]): string[] {
    return links
      ? links.map((item) => {
          switch (typeof item) {
            case 'object':
              return item && item.val;
            default:
              return item;
          }
        })
      : [];
  }

  /**
   *
   * */
  public clearComponent(
    owner: string,
    viewName: string,
    version: number,
    deps: CntFlexyViewDepsForOwnerItemInterface
  ): Observable<boolean[][]> {
    const linkToCss = this.getLinkToCss(owner, viewName, version, deps),
      linkToJs = this.getLinkToJs(owner, viewName, version, deps),
      flexyViewId = this.getComponentNameOfFlexyViewName(
        viewName,
        version,
        owner
      );

    /* safe add css */
    const styleUrls = this.getOnlyUrls(deps.css) || [];
    if (linkToCss && linkToCss !== this.noneValue) {
      styleUrls.push(linkToCss);
    }

    /* safe add js */
    const scriptUrls = this.getOnlyUrls(deps.js) || [];
    if (linkToJs && linkToJs !== this.noneValue) {
      scriptUrls.push(linkToJs);
    }

    return from(
      this.mzDynamicJoin.clearAddedExternalData(
        styleUrls,
        scriptUrls,
        flexyViewId
      )
    );
  }
}
