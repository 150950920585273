import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
// import {ConnectAuthService} from "../../../../../../../../../../libs/connect/auth/src/lib/@sub/@service/connect-auth.service";

@Injectable({
  providedIn: 'root'
})
export class MainGuardService implements CanActivate {
  constructor(
    private connectAuthService: ConnectAuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return Observable.create((observer: Observer<boolean>) => {
      observer.next(true);
      observer.complete();
      // this.connectAuthService.getUserId().subscribe(
      //     (uid: string) => {
      //         console.log(
      //           'canActivate - route',
      //           route.params,
      //           route.data
      //         );
      //         if ( route.url.toString() === 'sign' ) {
      //             // if loging -> get access if we not signed
      //            if ( !uid ) {
      //              observer.next(true);
      //              observer.complete();
      //              return;
      //            } else {
      //              // if we auther -> go to lk
      //              this.router.navigate(['/lk']);
      //            }
      //         } else {
      //           // if we are not want to get login page -> get access if we signed
      //           if ( uid ) {
      //             observer.next(true);
      //             observer.complete();
      //             return;
      //           } else {
      //             // if we authed -> go to lk
      //             this.router.navigate(['/sign']);
      //           }
      //         }
      //
      //         // get
      //         observer.next(true);
      //         observer.complete();
      //     }
      // )
    }).toPromise();
  }
}
