export enum FrfEmitStateEnum {
  fieldChanges,
  onSubmit
}

export enum PathToFieldValuesForEmitEnum {
  'all' = '*',
  value = 'value'
}

/**
 * type of change close/open
 * */
export enum FrfFormActionTypeEnum {
  close = 'close',
  open = 'open'
}
