import { Injectable } from '@angular/core';
import { CameraServiceInterface } from '../../../../@shared/@interface/camera-service/camera-service.interface';
import { Observable, Subject } from 'rxjs';
import { MzCameraService } from '../mz-camera/@sub/@service/mz-camera/mz-camera.service';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CameraService implements CameraServiceInterface {
  public cameraState$: Subject<boolean> = new Subject();

  constructor(private mzCamera: MzCameraService) {}

  /**
   * take jpeg photo
   * */
  public takeJpegPhoto(): Observable<{ status: boolean; data: Blob[] }> {
    this.openCamera();

    return this.mzCamera.close$.pipe(
      tap(() => {
        this.closeCamera();
      }),
      map(result => {
        return {
          status: !!result.data,
          data: result.data.map(image => image.blob)
        };
      }),
      take(1)
    );
  }

  /**
   * open camera (open by route)
   * */
  private openCamera(): void {
    console.log('openCamera');
    this.cameraState$.next(true);
  }

  /**
   * close camera (close route)
   * */
  private closeCamera(): void {
    console.log('closeCamera');
    this.cameraState$.next(false);
  }
}
