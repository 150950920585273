import { Injectable } from '@angular/core';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';

/**
 *
 *
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfElementSetterService {
  public getGeneratedBlockForFreeformObject(frfElement: any) {
    // if isset not we create this block
    if (typeof frfElement['body']['gen'] !== 'object') {
      frfElement['body']['gen'] = {};
    }

    return frfElement['body']['gen'];
  }
}
