import { Injectable } from '@angular/core';
import {
  FrfValueGetterClassInterface,
  FrfValueGetterServiceInterface
} from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueCheckerService } from '../../../frf-value-checker/frf-value-checker.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfValueGetterByMaskDataInterface } from './@res/@abstract/@interface/common.interface';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterByMaskService
  implements FrfValueGetterClassInterface {
  constructor(private frfValueChecker: FrfValueCheckerService) {}

  /**
   *
   * */
  public get(
    mask: string,
    data: FrfValueGetterByMaskDataInterface,
    valueGetter: FrfValueGetterServiceInterface,
    frfElement: any,
    frf: any,
    startFrfElement: any,
    scope: any
  ): string {
    const keys = this.getKeysFromMask(mask);
    let result = mask;

    for (const key of keys) {
      result = this.replaceByKey(
        result,
        key,
        data,
        valueGetter,
        frfElement,
        frf,
        startFrfElement,
        scope
      );
    }

    return result;
  }

  /**
   * get keys from mask
   * */
  private getKeysFromMask(mask: string): string[] {
    const regex = /\{\{[^\{\}]+\}\}/g,
      result = mask.match(regex),
      delBracketsRegex = /[\}\{]+/g;

    return result
      ? result.map(data => {
          return data.replace(delBracketsRegex, '');
        })
      : [];
  }

  /**
   * replace by key
   * */
  private replaceByKey(
    mask: string,
    key: string,
    data: FrfValueGetterByMaskDataInterface,
    valueGetter: FrfValueGetterServiceInterface,
    frfElement: any,
    frf: any,
    startFrfElement: any,
    scope: any
  ): string {
    const value = <string>(
        this.getByKey(
          key,
          data,
          valueGetter,
          frfElement,
          frf,
          startFrfElement,
          scope
        )
      ),
      regexString = `\{\{${key}\}\}`,
      regex = new RegExp(regexString, 'g');

    return mask.replace(regex, value);
  }

  /**
   *
   * */
  private getByKey(
    key: string,
    data: FrfValueGetterByMaskDataInterface,
    valueGetter: FrfValueGetterServiceInterface,
    frfElement: any,
    frf: any,
    startFrfElement: any,
    scope: any
  ): string | number {
    if (key && data && data[key]) {
      const currentData = data[key],
        value = valueGetter.getValue(
          currentData.value,
          frfElement,
          frf,
          startFrfElement,
          scope
        );

      if (
        currentData.check &&
        !this.frfValueChecker.check(value, currentData.check)
      ) {
        return typeof currentData.default !== 'undefined'
          ? currentData.default
          : '';
      }

      return value;
    }

    return '';
  }
}
