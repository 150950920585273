import { Injectable } from '@angular/core';
import { FrfTriggerAndActionFilterPipeInterface } from './@res/@abstract/@interface/common.interface';
import { FreeformCommonService } from '../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfTriggerAndActionPipeClassInterface } from '../../@res/@abstract/@interface/common.interface';
import { FrfMainLazyServiceModule } from '../../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerAndActionFilterByValuePipeService
  implements FrfTriggerAndActionPipeClassInterface {
  constructor(
    private frfSharedStorage: FrfSharedStorageService,
    private frfCommon: FreeformCommonService
  ) {}

  /**
   * pipe
   * */
  public pipe(
    pipe: FrfTriggerAndActionFilterPipeInterface,
    frfObject: any,
    scope: any
  ) {
    let val = pipe.val;

    if (typeof val === 'object' && val) {
      val = Object.keys(val).map(key =>
        !Array.isArray(val[key]) ? [val[key]] : val[key]
      );
    }

    for (const valueAnd of val) {
      const result = this.compareByAnd(valueAnd, frfObject, scope);

      if (result) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   * */
  private compareByAnd(values: any[], frfObject: any, scope: any) {
    if (typeof values === 'object' && values && !Array.isArray(values)) {
      values = Object.keys(values).map(key =>
        !Array.isArray(values[key]) ? [values[key]] : values[key]
      );
    }

    if (!Array.isArray(values)) {
      values = [values];
    }

    for (const value of values) {
      const val = this.frfSharedStorage.frfValueGetter.getValue(
        value,
        frfObject,
        this.frfCommon.freeform,
        frfObject,
        scope
      );

      if (!val) {
        return false;
      }
    }

    return true;
  }
}
