import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppBrowserModule } from './app/app.browser.module';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.error(err));
});

/* stencil js - source */
import * as cntAirtafaStencil from '@cnt-nx-workspace/design/stencil/airtafa-loader';
import * as cntChat from '@cnt-nx-workspace/design/stencil/cnt/chat-loader';
import * as cntChartsStencil from '@cnt-nx-workspace/design/stencil/cnt/charts-loader';
// import * as cntChat from 's-cnt-chat/loader';

/* stencil js - add loaders */
cntAirtafaStencil.applyPolyfills().then(() => {
  cntAirtafaStencil.defineCustomElements();
});
cntChartsStencil.applyPolyfills().then(() => {
  cntChartsStencil.defineCustomElements();
});
cntChat.applyPolyfills().then(() => {
  cntChat.defineCustomElements();
});
