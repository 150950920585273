import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[mzColumnDef]'
})
export class MzColumnDefDirective {
  @Input() mzColumnDef: string;

  constructor(
    /** @docs-private */
    public template: TemplateRef<any>
  ) {}
}
