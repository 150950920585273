import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CntPubSubMessageInterface } from './@res/@abstract/@interface/common.interface';
import {
  CntPubSubMessageOperationTypeEnum,
  CntPubSubMessageTypeEnum,
} from './@res/@abstract/@enum/common.enum';
import * as PubNub from 'pubnub';
import { MessageEvent } from 'pubnub';
import {
  pubnub_getChannelGroupName,
  pubnub_getChannelGroupNameByUn,
  pubnub_getChannelGroupNameWithPresenceGroup,
  pubnub_getChannelGroupNameWithPresenceGroupByUn,
  pubnub_getChannelsForUsers,
} from '@cnt-multi-shared/@shared/pubnub/@res/@function/common.function';
import { ChannelNamePubSubEnum } from '@cnt-multi-shared/@shared/pubnub/@res/@enum/common.enum';

@Injectable({
  providedIn: 'root',
})
export class CntPubSubMessageService {
  /**
   * */
  public messages$: Subject<CntPubSubMessageInterface> = new Subject<
    CntPubSubMessageInterface
  >();

  /**
   *
   * */
  public uuid: string;
  /**
   *
   * */
  public un: number;

  /**
   *
   * */
  public token: string;

  /**
   *
   * */
  public connected = false;

  /**
   *
   * */
  public environment: any;

  /**
   *
   * */
  public get publishKey(): string {
    return this.environment.pubnub.publishKey;
  }

  /**
   *
   * */
  public get subscribeKey(): string {
    return this.environment.pubnub.subscribeKey;
  }

  /**
   *
   * */
  private pubnub: PubNub;

  /**
   * auth
   * */
  public auth(uuid: string, token: string, un: number) {
    const config = {
      subscribeKey: this.subscribeKey,
      uuid: this.uuid = uuid,
      authKey: this.token = token,
    };
    this.un = un;
    this.pubnub = new PubNub(config);
  }

  /**
   * init
   * */
  public init(uuid: string, token: string, un: number, environment: any) {
    this.environment = environment;
    this.auth(uuid, token, un);
    this.subscribe();
  }

  /**
   * unsubscribe
   * */
  public unsubscribe() {
    if (!this.pubnub) {
      return;
    }
    this.pubnub.unsubscribeAll();
    this.connected = false;
  }

  /**
   * */
  private sendMessage(event: MessageEvent) {
    const msg = <CntPubSubMessageInterface>event.message;

    switch (msg.type) {
      case CntPubSubMessageTypeEnum.sharepayBankPurchase:
        break;
    }

    this.messages$.next({
      ...(event.message || {}),
      channel: event.channel,
      public: event.channel === ChannelNamePubSubEnum.public,
    });
  }

  /**
   * subscribe
   * */
  private subscribe() {
    console.log('pn', 'subscribe 1');
    if (!this.pubnub) {
      return null;
    }

    if (!this.token || !this.uuid || !this.un || this.connected) {
      return;
    }
    console.log('pn', 'subscribe 2');

    // this.unsubscribe();

    this.pubnub.addListener({
      message: (event) => {
        console.log(
          'pn',
          '[MESSAGE: received]',
          event.message.entry + ': ' + event.message.update,
          event
        );
        this.sendMessage(event);
      },
      presence: (event) => {
        console.log(
          'pn',
          '[PRESENCE: ' + event.action + ']',
          'uuid: ' + event.uuid + ', channel: ' + event.channel,
          event
        );
      },
      status: (event) => {
        console.log(
          'pn',
          '[STATUS: ' + event.category + ']',
          'connected to channels: ' + event.affectedChannels,
          JSON.stringify(event)
        );
      },
    });

    const timetoken = this.generateTimeToken(new Date()),
      withPresence = {
        channelGroups: [
          pubnub_getChannelGroupNameWithPresenceGroup(this.uuid, false),
          pubnub_getChannelGroupNameWithPresenceGroupByUn(this.un, false),
        ],
        withPresence: true,
        timetoken: timetoken,
      };

    const withoutPresence = {
      channels: pubnub_getChannelsForUsers(this.uuid, this.un),
      channelGroups: [
        pubnub_getChannelGroupName(this.uuid),
        pubnub_getChannelGroupNameByUn(this.un),
      ],
      withPresence: false,
      timetoken: timetoken,
    };

    console.log('pn', 'subscribe 3', withoutPresence, withPresence);
    /* subscribe */
    this.pubnub.subscribe(withoutPresence);
    this.pubnub.subscribe(withPresence);

    this.connected = true;
  }

  /**
   * */
  private generateTimeToken(date: Date) {
    return date.getTime() * 10000;
  }

  /**
   *
   * */
  public getSharepayBankPurchase(
    operation?: CntPubSubMessageOperationTypeEnum
  ) {
    return this.messages$.pipe(
      filter(
        (result) =>
          result &&
          result.type === CntPubSubMessageTypeEnum.sharepayBankPurchase &&
          (!operation || operation === result.operation)
      )
    );
  }

  /**
   * @deprecated
   * */
  // public initPrivate(uid: string): Observable<CntPubSubMessageInterface[]> {
  //   return of([]);
  //   // return this.firestore
  //   //   .collection(`pub-sub-private/${uid}/messages`, ref => {
  //   //     return ref
  //   //       .where('time', '>', new Date(new Date().getTime() - 1000))
  //   //       .orderBy('time');
  //   //   })
  //   //   .stateChanges()
  //   //   .pipe(
  //   //     map(doc =>
  //   //       doc.map(item => {
  //   //         const data = item.payload.doc.data() as CntPubSubMessageInterface;
  //   //         const id = item.payload.doc.id;
  //   //         return {
  //   //           ...data,
  //   //           docId: id
  //   //         };
  //   //       })
  //   //     ),
  //   //     catchError(error => {
  //   //       console.warn(
  //   //         'initPubSubPrivateMessage',
  //   //         `/pub-sub/@private/${uid}`,
  //   //         error
  //   //       );
  //   //
  //   //       return of(null);
  //   //     }),
  //   //     tap((data: CntPubSubMessageInterface[]) => {
  //   //       if (data) {
  //   //         for (const item of data) {
  //   //           this.messages$.next({
  //   //             ...item,
  //   //             public: false
  //   //           });
  //   //         }
  //   //       }
  //   //     })
  //   //   );
  // }

  /**
   * @deprecated
   * */
  // public initPublic(): Observable<CntPubSubMessageInterface[]> {
  //   return of([]);
  //   //   return this.firestore
  //   //     .collection(`/pub-sub`, ref => {
  //   //       return ref
  //   //         .where('time', '>', new Date(Date.now() - 10000))
  //   //         .orderBy('time');
  //   //     })
  //   //     .stateChanges()
  //   //     .pipe(
  //   //       map(doc =>
  //   //         doc.map(item => {
  //   //           const data = item.payload.doc.data() as CntPubSubMessageInterface;
  //   //           const id = item.payload.doc.id;
  //   //
  //   //           return {
  //   //             ...data,
  //   //             docId: id
  //   //           };
  //   //         })
  //   //       ),
  //   //       tap((data: CntPubSubMessageInterface[]) => {
  //   //         if (data) {
  //   //           for (const item of data) {
  //   //             this.messages$.next({
  //   //               ...item,
  //   //               public: true
  //   //             });
  //   //           }
  //   //         }
  //   //       })
  //   //     );
  // }
}
