export enum FrfElementTypeEnum {
  field = 'field',
  row = 'row',
  group = 'group',
  page = 'page',
  collection = 'collection'
}

export enum FrfElementObjectTypeEnum {
  object = 'object',
  model = 'model'
}

export enum FrfElementFolderTypeEnum {
  page = 'pages',
  row = 'rows',
  field = 'fields',
  group = 'groups',
  collection = 'fields'
}
