import { Injectable } from '@angular/core';
import * as mzCommon from 'mz-common';
import * as _ from 'lodash';
import { FrfSubmitPathEnum } from './@res/@abstract/@enum/common.enum';
import { FrfSubmitStateButtonInterface } from './@res/@abstract/@interface/common.interface';
import { frfValueGetterPreSubmitGetBaseTempPath } from './@res/@function/common.function';
import { FrfValueSetterTempDataStorageTypeEnum } from '../@group:value-services/frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FrfTriggerNameEnum } from '../frf-trigger/@res/@abstract/@enum/common.enum';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';
import { FrfValueSetterTempDataService } from '../@group:value-services/frf-value-setter/@sub/frf-value-setter-temp-data/frf-value-setter-temp-data.service';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfSubmitService {
  private readonly pathToFormState = FrfSubmitPathEnum.pathToFormState;
  private readonly pathToElementState = FrfSubmitPathEnum.pathToElementState;

  constructor(private frfValueSetterTempData: FrfValueSetterTempDataService) {}

  /**
   *
   * */
  public isElementHasPreSubmitButtons(
    frfElement: any,
    isLeftSide: boolean
  ): boolean {
    return !!this.getElementPreSubmitButton(frfElement, isLeftSide);
  }

  /**
   *
   * */
  public showSubmitOrNextBtn(
    frfElement: any,
    btn: FrfSubmitStateButtonInterface,
    preSubmitButton: any
  ) {
    if (!btn) {
      return null;
    }

    if (!preSubmitButton && (!btn.state || !btn.state.length)) {
      return true;
    } else if (btn.state && btn.state.length) {
      return this.needShowThisSubmitButton(
        btn,
        this.getFrfElementState(frfElement)
      );
    }

    return false;
  }

  /**
   *
   * */
  public getElementPreSubmitButton(
    frfElement: any,
    isLeftSide: boolean
  ): FrfSubmitStateButtonInterface | null {
    const path = isLeftSide
        ? FrfSubmitPathEnum.pathToPreSubmitLeftSide
        : FrfSubmitPathEnum.pathToPreSubmitRightSide,
      preButtons = <FrfSubmitStateButtonInterface[]>(
        mzCommon.getValueFromObjectByPath(path, frfElement)
      );

    const states = this.getFrfElementState(frfElement);

    if (preButtons && Array.isArray(preButtons)) {
      return (
        preButtons.find(btn => this.needShowThisSubmitButton(btn, states)) ||
        null
      );
    }

    return null;
  }

  /**
   *
   * */
  public getFormPreSubmitButton(
    frf: any
  ): FrfSubmitStateButtonInterface | null {
    const preButtons = <FrfSubmitStateButtonInterface[]>(
      mzCommon.getValueFromObjectByPath('options.submit.pre', frf)
    );

    const states = this.getFrfElementState(frf);

    if (preButtons && Array.isArray(preButtons)) {
      return (
        preButtons.find(btn => this.needShowThisSubmitButton(btn, states)) ||
        null
      );
    }

    return null;
  }

  /**
   *
   * */
  private needShowThisSubmitButton(
    button: FrfSubmitStateButtonInterface,
    states: string[]
  ): boolean {
    if (
      button &&
      (typeof button.active !== 'boolean' || button.active === true) &&
      /* has element states*/
      Array.isArray(states) &&
      states.length &&
      /* has frf states */
      button.state &&
      Array.isArray(button.state) &&
      button.state.length
    ) {
      return _.difference(button.state, states).length === 0;
    }

    return false;
  }

  /**
   *
   * */
  private getFrfElementState(frfElement: any): string[] {
    return (
      mzCommon.getValueFromObjectByPath('body.submit.state', frfElement) || []
    );
  }

  /**
   *
   * */
  public getFrfElementNextButton(
    frfElement: any
  ): FrfSubmitStateButtonInterface {
    const state = this.getFrfElementState(frfElement),
      ready = !state.length || state.indexOf('@ready') !== -1,
      button = mzCommon.getValueFromObjectByPath(
        'body.submit.next',
        frfElement
      ) || {
        state: [],
        key: 'default-next',
        value: 'Далее',
        title: '',
        active: true
      };

    return ready ? button : null;
  }

  /**
   *
   * */
  public getFrfElementSubmitButton(
    frfElement: any
  ): FrfSubmitStateButtonInterface {
    const state = this.getFrfElementState(frfElement),
      ready = !state.length || state.indexOf('@ready') !== -1,
      button = mzCommon.getValueFromObjectByPath(
        'body.submit.main',
        frfElement
      ) || {
        state: [],
        key: 'default-submit',
        value: 'Закрыть',
        title: 'Нажмите для закрытия формы ',
        active: true
      };

    return ready ? button : null;
  }

  /**
   *
   * */
  public getFrfElementPreviousButton(
    frfElement: any
  ): FrfSubmitStateButtonInterface {
    const state = this.getFrfElementState(frfElement),
      ready = !state.length || state.indexOf('@ready') !== -1,
      button = mzCommon.getValueFromObjectByPath(
        'body.submit.previous',
        frfElement
      ) || {
        state: [],
        key: 'default-submit',
        value: 'Назад',
        title: 'Нажмите для перехода назад ',
        active: true
      };

    return ready ? button : null;
  }

  /**
   * get state of form
   * */
  public getFrfFormState(frf: any): string[] {
    return mzCommon.getValueFromObjectByPath(this.pathToFormState, frf) || [];
  }

  /**
   *
   * */
  public saveLastClickedPreSubmitBtnToElement(
    button: FrfSubmitStateButtonInterface,
    frf: any,
    frfElement: any
  ) {
    if (typeof button.active === 'boolean' && !button.active) {
      return;
    }

    const fullPath = frfValueGetterPreSubmitGetBaseTempPath();

    this.frfValueSetterTempData.set(
      frfElement,
      fullPath,
      button,
      FrfValueSetterTempDataStorageTypeEnum.system,
      [FrfTriggerNameEnum.onClickToPreSubmitButton],
      false
    );
  }
}
