import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'firestoreTime',
  pure: true
})
export class FirestoreTimePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const data = value && value.toDate();
    return data ? moment(data).format((args && args[0]) || 'LT') : '';
  }
}
