import { Injectable } from '@angular/core';
import { FrfTriggerActionSetLoadingStateInterface } from '../../../@res/@abstract/@interface/common.interface';
import { FrfValueSetterTempDataStorageTypeEnum } from '../../../../../../@group:value-services/frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FreeformCommonService } from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfValueGetterService } from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';
import { FrfTriggerActionSetLoadingStateGetTempPathFunction } from '../../../../../../@group:value-services/frf-value-setter/@sub/frf-value-setter-payload/@res/@function/common.function';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionSetLoadingStateService {
  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfValueGetterService: FrfValueGetterService
  ) {}

  /**
   * set loading state
   * */
  public async run(
    action: FrfTriggerActionSetLoadingStateInterface,
    startElement: any,
    scope: any
  ) {
    const element = action.id
      ? this.frfValueGetterService.getFrfElementById(
          action.id,
          this.common.freeform,
          null,
          startElement
        )
      : startElement;

    this.frfSharedStorage.frfValueSetter.setTempDataInElement(
      element,
      FrfTriggerActionSetLoadingStateGetTempPathFunction(),
      action.state,
      FrfValueSetterTempDataStorageTypeEnum.system
    );

    return true;
  }
}
