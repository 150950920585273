import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FreeformFieldValidatorLibrary } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform-field-validators.library';

// window['UPLOADCARE_LOCALE'] = 'ru'; // set a preferred locale if needed

@Component({
  selector: 'frf-field-textredactor',
  templateUrl: './freeform-field-textredactor.component.html',
  styleUrls: ['./freeform-field-textredactor.component.scss']
})
export class FreeformFieldTextredactorComponent implements OnInit {
  // CKEDITOR = window['CKEDITOR'];

  private ckeditorConfig = {
    // uiColor       : '#99000',
    extraPlugins: 'uploadcare',
    uploadcare: {
      publicKey: '75cdcf4baad946c1a02c', // set your public API key here
      multiple: true, // allow multi-file uploads
      locale: 'ru'
      // crop: '1:1,4:3', // set crop options when handling images
      /* feel free to add more “object key” options here */
    }
  };

  // reqired
  @Input('fieldid') fieldid;
  @Input('objid') objid;
  @Input('freeform') freeform;
  @Input('disabled') disabled;

  // optional
  @Input('type') type = 'text';

  // this field from freeform object
  public field;
  // this field we add mask
  public mask;

  // create angular reactive form
  public form = new FormGroup({
    field: new FormControl('')
  });

  constructor(private common: FreeformCommonService) {}

  ngOnInit() {
    this.field = this.freeform.fields[this.fieldid].objects[this.objid];
    this.type = this.field.type || this.type;

    // add required validator
    new FreeformFieldValidatorLibrary(
      this.freeform,
      this.field,
      this.form
    ).run();
  }

  change(iNvalue) {
    this.common.for_dependentStartByObject(this.field);
  }

  onKeyPress(iNevent) {
    const event = iNevent,
      symbol = event.key,
      keyCode = event.keyCode;

    // dont block this symbols (enter or backspace or delete () and so on...)
    if (
      keyCode === 8 || // enter
      keyCode === 9 || // tab
      keyCode === 13 || //backspase
      keyCode === 110 || // delete
      keyCode === 39 || // >
      keyCode === 37 // <
    )
      return true;
    //**LATER add key up work only for need fields
    if (
      this.field.body.rules.resolvedSymbols &&
      Array.isArray(this.field.body.rules.resolvedSymbols)
    ) {
      const result = this.common.checkSymbolForResolved(
        symbol,
        this.field.body.rules.resolvedSymbols
      );
      if (!result) {
        //dellete last symbol if this symbola not resolved
        // this.field.body['value'] = this.field.body.value.slice(0, -1);
        // console.log('onKeyPress - false');
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  /**
   *
   * */
  public onContentDom(data) {}

  /**
   *
   * */
  public onFileUploadRequest(data) {}

  /**
   *
   * */
  public onFocus(data) {}

  /**
   *
   * */
  public onReady(data) {}

  /**
   *
   * */
  public onEditorChange(data) {}

  /**
   *
   * */
  public onChange(data) {}

  /**
   *
   * */
  public onBlur(data) {}
}
