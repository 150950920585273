import { FrfRequestTypeEnum } from '../../../../../../../../@shared/frf-request/@res/@abstract/@enum/common.enum';
import {
  FrfTriggerActionRequestBasePathToTempData,
  FrfTriggerActionRequestTempDataDefaultKey
} from '../@const/common.cons';

export function frfTriggerActionRequestGetFullPathToTempDataFunction(
  type: FrfRequestTypeEnum,
  key?: string
) {
  const basePath = FrfTriggerActionRequestBasePathToTempData;

  /* cntGraphql -> to Graphql > for save as graphQl */
  type =
    type === FrfRequestTypeEnum.cntGraphQl ? FrfRequestTypeEnum.graphql : type;

  key = key || FrfTriggerActionRequestTempDataDefaultKey;

  return `${basePath}.${type}.${key}`;
}
