import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LkService } from '../../../../../@res/@module/shared/@sub/@service/lk/lk.service';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'connect-web-base-lk',
  templateUrl: './lk.component.html',
  styleUrls: ['./lk.component.scss']
})
export class LkComponent extends AbstractUnsubscribeViewControl
  implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private lk: LkService
  ) {
    super();
    this.lk.lkActivatedRoute = this.activatedRoute;
  }

  ngOnInit() {}
}
