import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FrfFieldButtonTypeEnum } from './@res/@abstract/@enum/common.enum';
import { FreeformFieldValidatorLibrary } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform-field-validators.library';

@Component({
  selector: 'frf-field-button',
  templateUrl: './freeform-field-button.component.html',
  styleUrls: ['./freeform-field-button.component.scss']
})
export class FreeformFieldButtonComponent implements OnInit {
  // reqired
  @Input('fieldid') fieldid;
  @Input('objid') objid;
  @Input('freeform') frf;
  @Input('disabled') disabled;

  // this field from freeform object
  public field;

  // create angular reactive form
  public form = new FormGroup({
    field: new FormControl('', [])
  });

  public type: FrfFieldButtonTypeEnum;
  public color: string;

  /**
   * enum use in template
   * */
  public frfFieldButtonTypeEnum = FrfFieldButtonTypeEnum;

  constructor() {}

  ngOnInit() {
    this.field = this.frf.fields[this.fieldid].objects[this.objid];

    this.color = this.field.body.payload.color || ''; //"primary";
    this.type = this.field.body.payload.type || FrfFieldButtonTypeEnum.basic;

    const field = this.field;
    // add required validator
    new FreeformFieldValidatorLibrary(this.frf, this.field, this.form).run();
  }
}
