import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CntFlexyViewDepsForOwnerItemInterface } from '../../@service/cnt-flexy-view/@res/@interface/common.interface';
import { CntFlexyViewService } from '../../@service/cnt-flexy-view/cnt-flexy-view.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FlexyViewComponentOptionsMysqlItem } from '@cnt-multi-shared/@shared/@library/@group:flexy-view/component/@res/@interface/common.interface';
import * as InView from 'inview';
import { CntFlexyViewApiService } from '../../@service/cnt-flexy-view-api/cnt-flexy-view-api.service';

@Component({
  selector: 'cnt-flexy-view-self',
  templateUrl: './cnt-flexy-view.component.html',
  styleUrls: ['./cnt-flexy-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CntFlexyViewApiService]
})
export class CntFlexyViewComponent implements OnInit, OnDestroy {
  @Input() hash: string;
  @Input() backColor: string = 'transparent';
  @Input() version!: number;
  @Input() viewName!: string;
  @Input() component!: string;
  @Input() owner: string;
  @Input() payload: any;
  @Input() options?: FlexyViewComponentOptionsMysqlItem;
  @Input() deps: CntFlexyViewDepsForOwnerItemInterface;
  @Input() theme: any;

  @ViewChild('flexyView', { static: true })
  flexyView: ElementRef;

  private viewDestroy$ = new Subject();

  /**
   * selector for detect in view
   * */
  public selector: string = '';

  /**
   *
   * */
  public loaded = false;

  /**
   *
   * */
  private inView$: Subject<boolean> = new Subject();
  private inViewForDestroy: any;

  constructor(
    private cntFlexyView: CntFlexyViewService,
    private el: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadComponent();
  }

  ngOnDestroy(): void {
    this.clearFromFlexyViewResources();
    this.viewDestroy$.next(true);
    this.viewDestroy$.unsubscribe();
  }

  /*;
   *
   * */
  private initSelector() {
    this.selector = this.cntFlexyView.getSelector(
      this.viewName,
      this.version,
      this.owner
    );
    console.log('initSelector - selector', this.selector);
  }

  /**
   *
   * */
  private clearFromFlexyViewResources() {
    this.cntFlexyView
      .clearComponent(this.owner, this.viewName, this.version, this.deps)
      .subscribe();
  }

  /**
   *
   * */
  private loadComponent() {
    this.cntFlexyView
      .initComponent(
        this.flexyView,
        this.owner,
        this.viewName,
        this.component,
        this.version,
        this.payload,
        this.deps,
        (value: string) => {},
        (element: HTMLElement) => {}
      )
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(result => {
        // console.log('loadComponent 2', {
        //   result,
        //   flexyView: this.flexyView,
        //   owner: this.owner,
        //   viewName: this.viewName,
        //   version: this.version,
        //   payload: this.payload,
        //   deps: this.deps
        // });
        this.loaded = true;
        this.cdRef.markForCheck();
      });
  }

  private destroyInViewController() {
    if (this.inViewForDestroy) {
      this.inViewForDestroy.destroy();
    }
  }

  /**
   *
   * */
  private initInViewController() {
    if (!this.hash) {
      return;
    }

    this.initSelector();

    this.inView$
      .pipe(
        distinctUntilChanged(),
        debounceTime(150),
        takeUntil(this.viewDestroy$)
      )
      .subscribe(state => {
        this.cntFlexyView.changeInViewDetection(this.hash, state);
      });

    const inView$ = this.inView$;

    // TODO #temServeDisable
    InView(this.el.nativeElement, function(isInView, data) {
      if (!this.inViewForDestroy) {
        this.inViewForDestroy = this;
      }
      if (isInView) {
        // if (data.elementOffsetTopInViewHeight < data.inViewHeight / 2) {
        // } else {
        // }
        inView$.next(true);
      } else {
        inView$.next(false);
        // if (
        //   data.windowScrollTop - (data.elementOffsetTop - data.inViewHeight) >
        //   data.inViewHeight
        // ) {
        //   console.log("not in view (scroll up)");
        // } else {
        //   console.log("not in view (scroll down)");
        // }
      }
    });
  }
}
