<form [formGroup]="form">
  <!--<mat-form-field>-->

  <!--(change)="change(field, thisField.value)"  [ngModel]="field.body.value"-->
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="frfFieldButtonTypeEnum.raised">
      <button
        mat-raised-button
        [color]="color"
        freeformObject
        [title]="field.body.payload?.title"
        [freeformType]="'field'"
        [freeformBaseId]="fieldid"
        [freeformObjId]="objid"
        [disabled]="
          field['clientGeneration'] ||
          disabled ||
          field.body.status.disabled ||
          !field.options.access.write
        "
      >
        {{ field.body.value || field.body.payload?.text }}
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="frfFieldButtonTypeEnum.fab">
      <button
        mat-fab
        [color]="color"
        freeformObject
        [title]="field.body.payload?.title"
        [freeformType]="'field'"
        [freeformBaseId]="fieldid"
        [freeformObjId]="objid"
        [disabled]="
          field['clientGeneration'] ||
          disabled ||
          field.body.status.disabled ||
          !field.options.access.write
        "
      >
        {{ field.body.value || field.body.payload?.text }}
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="frfFieldButtonTypeEnum.miniFab">
      <button
        mat-mini-fab
        [color]="color"
        freeformObject
        [title]="field.body.payload?.title"
        [freeformType]="'field'"
        [freeformBaseId]="fieldid"
        [freeformObjId]="objid"
        [disabled]="
          field['clientGeneration'] ||
          disabled ||
          field.body.status.disabled ||
          !field.options.access.write
        "
      >
        {{ field.body.value || field.body.payload?.text }}
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="frfFieldButtonTypeEnum.stroked">
      <button
        mat-stroked-button
        [color]="color"
        freeformObject
        [title]="field.body.payload?.title"
        [freeformType]="'field'"
        [freeformBaseId]="fieldid"
        [freeformObjId]="objid"
        [disabled]="
          field['clientGeneration'] ||
          disabled ||
          field.body.status.disabled ||
          !field.options.access.write
        "
      >
        {{ field.body.value || field.body.payload?.text }}
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="frfFieldButtonTypeEnum.flat">
      <button
        mat-flat-button
        [color]="color"
        freeformObject
        [title]="field.body.payload?.title"
        [freeformType]="'field'"
        [freeformBaseId]="fieldid"
        [freeformObjId]="objid"
        [disabled]="
          field['clientGeneration'] ||
          disabled ||
          field.body.status.disabled ||
          !field.options.access.write
        "
      >
        {{ field.body.value || field.body.payload?.text }}
      </button>
    </ng-container>

    <!--      <ng-container *ngSwitchCase="'basic'">-->
    <ng-container *ngSwitchDefault>
      <button
        mat-button
        [color]="color"
        freeformObject
        [title]="field.body.payload?.title"
        [freeformType]="'field'"
        [freeformBaseId]="fieldid"
        [freeformObjId]="objid"
        [disabled]="
          field['clientGeneration'] ||
          disabled ||
          field.body.status.disabled ||
          !field.options.access.write
        "
      >
        {{ field.body.value || field.body.payload?.text }}
      </button>
    </ng-container>
  </ng-container>

  <!--</mat-form-field>-->
</form>

<!-- TODO later delete field.body.payload?.text and stay only field.body.value-->
