import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {
  private openedLogin: string;

  /**
   * public opened login
   * */
  public openedLogin$: BehaviorSubject<string | null> = new BehaviorSubject<
    string
  >(null);

  constructor(private router: Router) {}

  /**
   * */
  public saveOpenedLogin(login: string) {
    this.openedLogin$.next((this.openedLogin = login));
  }

  /**
   *
   * */
  public getOpenedLogin() {
    return this.openedLogin;
  }

  /**
   *
   * */
  public openSignPage() {
    this.router.navigate(['/sign']);
  }

  /**
   *
   * */
  public openByLogin(login: string) {
    this.router.navigate([`${login}`]);
  }

  /**
   *
   * */
  public openAppChat() {
    this.openApp('chat');
  }

  /**
   *
   * */
  public openApp(app: string) {
    this.openedLogin$.pipe(take(1)).subscribe(login => {
      this.router.navigate([`${this.getLogin(login)}/${app}`]);
    });
  }

  /**
   *
   * */
  public openAppVisit() {
    this.openApp('visit');
  }

  /**
   *
   * */
  private getLogin(login?: string): string {
    return login ? login : 'connect';
  }
}
