import { Component, OnInit } from '@angular/core';
import { CntPubSubMessageService } from '@cnt-nx-workspace/feature/cnt-pub-sub-message';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';
import { PlatformSpecificService } from './@res/@service/platform-specific/platform-specific.service';
import { MainSharedService } from './@res/@module/shared/@sub/@service/main-shared/main-shared.service';
import { ChatDbService } from './@res/@module/shared/@sub/@service/db/@sub/chat-db/chat-db.service';
import { LkService } from './@res/@module/shared/@sub/@service/lk/lk.service';
import { MzOnlineService } from '@cnt-nx-workspace/mz/ng-online';
import { skip, takeUntil } from 'rxjs/operators';

//API
// import { Plugins, StatusBarStyle } from '@capacitor/core';

// const { StatusBar } = Plugins;

@Component({
  selector: 'connect-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AbstractUnsubscribeViewControl
  implements OnInit {
  constructor(
    public mainSharedService: MainSharedService,
    public cntPubSubMessage: CntPubSubMessageService,
    public chatDbService: ChatDbService,
    public lk: LkService,
    public mzOnlineService: MzOnlineService,
    public platformSpecificService: PlatformSpecificService
  ) {
    super();
  }

  ngOnInit() {
    this.initOnlineStateController();
    this.chatDbService.changes().subscribe((data) => {
      console.log('chatDbService - data', data);
    });

    this.chatDbService.createIndex().subscribe((r) => {
      console.log('chatDbService find - 1', r);
      this.chatDbService
        .find({
          selector: { n: { $gte: null }, t: 't' },
          fields: ['_id', 't', 'n'],
          // sort: ['n'],
          use_index: 'nindex2',
        })
        .subscribe((result) => {
          console.log('chatDbService find - [2]', result);
        });
    });
  }

  /**
   * */
  private initOnlineStateController() {
    this.mzOnlineService.flow$
      .pipe(skip(1), takeUntil(this.viewDestroy$))
      .subscribe(($event) => {
        const onlineStateTitle =
          ($event ? 'Есть' : 'Нет') + ' интернет соединение';

        this.lk.toast(
          $event ? 'success' : 'danger',
          onlineStateTitle,
          undefined,
          undefined,
          3000
        );
      });
  }
}
