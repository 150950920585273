import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  FrfTriggerActionRequestBaseInterface,
  FrfTriggerActionRequestByCntGraphQlInterface,
  FrfTriggerActionRequestByGetInterface,
  FrfTriggerActionRequestByGraphQlInterface,
  FrfTriggerActionRequestByInterface,
  FrfTriggerActionRequestByPostInterface
} from '../../../@res/@abstract/@interface/common.interface';
import { frfTriggerActionRequestGetFullPathToTempDataFunction } from './@res/@function/common.function';
import { FrfRequestTypeEnum } from '../../../../../../@shared/frf-request/@res/@abstract/@enum/common.enum';
import { FrfValueSetterTempDataStorageTypeEnum } from '../../../../../../@group:value-services/frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FrfSharedStorageService } from '../../../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfRequestService } from '../../../../../../@shared/frf-request/frf-request.service';
import { FrfValueGetterService } from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';
import { FrfRequestTypeInterface } from '../../../../../../@shared/frf-request/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionEnum } from '../../../@res/@abstract/@enum/common.enum';
import { environment } from '../../../../../../../../../../../../../../apps/main/cnt-main/src/environments/environment';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionRequestService {
  constructor(
    private frfRequestService: FrfRequestService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfValueGetterService: FrfValueGetterService
  ) {}

  /**
   *
   * */
  public requestByGraphQl(
    action: FrfTriggerActionRequestByGraphQlInterface,
    frfElement: any,
    frf: any,
    scope: any
  ) {
    return this.request(
      FrfRequestTypeEnum.graphql,
      action,
      frfElement,
      frf,
      scope
    );
  }

  /**
   *
   * */
  public requestByCntGraphQl(
    action: FrfTriggerActionRequestByCntGraphQlInterface,
    frfElement: any,
    frf: any,
    scope: any
  ) {
    return this.request(
      FrfRequestTypeEnum.cntGraphQl,
      action,
      frfElement,
      frf,
      scope
    );
  }

  /**
   *
   * */
  public requestByPost(
    action: FrfTriggerActionRequestByPostInterface,
    frfElement: any,
    frf: any,
    scope: any
  ) {
    return this.request(
      FrfRequestTypeEnum.post,
      action,
      frfElement,
      frf,
      scope
    );
  }

  /**
   *
   * */
  public requestBy(
    action: FrfTriggerActionRequestByInterface,
    frfElement: any,
    frf: any,
    scope: any
  ) {
    return this.request(
      action.request.requestType,
      action,
      frfElement,
      frf,
      scope
    );
  }

  /**
   *
   * */
  public requestByGet(
    action: FrfTriggerActionRequestByGetInterface,
    frfElement: any,
    frf: any,
    scope
  ) {
    return this.request(FrfRequestTypeEnum.get, action, frfElement, frf, scope);
  }

  /**
   *
   * */
  private async request(
    type: FrfRequestTypeEnum,
    action: FrfTriggerActionRequestBaseInterface,
    frfElement: any,
    frf: any,
    scope: any
  ) {
    try {
      const data = _.cloneDeep(action.request);

      if (typeof type === 'string') {
        data.requestType = type;
      }

      if (
        data.requestType === FrfRequestTypeEnum.graphql ||
        data.requestType === FrfRequestTypeEnum.cntGraphQl
      ) {
        data.graphqlName = await this.frfValueGetterService
          .getValueWithPipe(
            data.graphqlName,
            frfElement,
            frf,
            frfElement,
            scope
          )
          .toPromise();

        data.graphqlQuery = await this.frfValueGetterService
          .getValueWithPipe(
            data.graphqlQuery,
            frfElement,
            frf,
            frfElement,
            scope
          )
          .toPromise();

        /*
         * if has keys pass to data
         * */
        if (
          data.graphqlVariables &&
          Object.keys(data.graphqlVariables).length
        ) {
          const variablesKeys = Object.keys(data.graphqlVariables);

          for (const varKey of Object.keys(variablesKeys)) {
            const test = data.graphqlVariables[variablesKeys[varKey]];
            data.graphqlVariables[
              variablesKeys[varKey]
            ] = await this.frfValueGetterService
              .getValueWithPipe(
                data.graphqlVariables[variablesKeys[varKey]],
                frfElement,
                frf,
                frfElement,
                scope
              )
              .toPromise();
          }
        }
      }

      data.url = await this.getRightUrl(type, data, frfElement, frf, scope);

      data.requestType = await this.frfValueGetterService
        .getValueWithPipe(data.requestType, frfElement, frf, frfElement, scope)
        .toPromise();

      return this.frfRequestService
        .doByRequestInput(data, frfElement, frf, scope)
        .pipe(
          tap(async result => {
            const key = action.keyForTempElementStorage
              ? await this.frfValueGetterService
                  .getValueWithPipe(
                    action.keyForTempElementStorage,
                    frfElement,
                    frf,
                    frfElement,
                    scope
                  )
                  .toPromise()
              : null;

            this.frfSharedStorage.frfValueSetter.setTempDataInElement(
              frfElement,
              frfTriggerActionRequestGetFullPathToTempDataFunction(type, key),
              result,
              FrfValueSetterTempDataStorageTypeEnum.system
            );
          }),
          take(1)
        )
        .toPromise();
    } catch (e) {
      console.warn(
        'FrfTriggerActionRequestService - requestBy',
        'has not right format action',
        type,
        e,
        action
      );

      return of(null).toPromise();
    }
  }

  /**
   *
   * */
  private async getRightUrl(
    type: FrfRequestTypeEnum,
    data: FrfRequestTypeInterface,
    frfElement: any,
    frf: any,
    scope: any
  ): Promise<string> {
    return type === FrfRequestTypeEnum.cntGraphQl
      ? environment.domain.graphqlDomain
      : await this.frfValueGetterService
          .getValueWithPipe(data.url, frfElement, frf, frfElement, scope)
          .toPromise();
  }
}
