import { Injectable } from '@angular/core';
// import { FreeformObjectInterface } from '../../../../@multi-shared/connect-multi-shared/@shared/freeform/@doc/@form/freeform-object.interface';
import * as _ from 'lodash';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';
import { FrfRootService } from '../frf-root/frf-root.service';
import { FreeformObjectInterface } from '@cnt-multi-shared/@shared/freeform/@doc/@form/freeform-object.interface';

/**
 * for with input-params (from back and from front)
 * это параметры котоые могу переданы с фронта (из сервиса) и из бэка (POST/GET params)
 * TODO add later back input params support
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfInputParamsService {
  constructor(private frfRoot: FrfRootService) {}

  /**
   * get params from front
   * */
  private getInputParamsFromFront(formId: string = 'base') {
    return this.frfRoot.inputParams && this.frfRoot.inputParams[formId];
  }

  /**
   * add input params from front if exist
   * */
  public addInputParamsFromFrontIfExist(frf: FreeformObjectInterface) {
    const params = this.getInputParamsFromFront();

    if (params && !frf.inputParams) {
      /*
       * TODO add mergine if exist
       * */
      frf.inputParams = _.cloneDeep(params);
    }
  }
}
