<div class="select">
  <mat-select [value]="currentSize" (valueChange)="updateSize($event)">
    <mat-option *ngFor="let page of allSizes" [value]="page">{{
      page
    }}</mat-option>
  </mat-select>
</div>
<div class="current-page">
  <span class="name">Страница: </span>
  <span class="number">{{ currentPage }}</span>
</div>
<div class="switchers">
  <div
    class="left disabled c-icon-arrow-to-right"
    [ngClass]="{ disabled: block || !hasPreviousPage || currentPage <= 1 }"
    (click)="
      !block &&
        (hasPreviousPage || currentPage > 1) &&
        updatePage(currentPage - 1)
    "
  ></div>
  <div
    class="right c-icon-arrow-to-right"
    [ngClass]="{ disabled: block || !hasNextPage }"
    (click)="!block && hasNextPage && updatePage(currentPage + 1)"
  ></div>
</div>
