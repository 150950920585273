import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FrfCustomFieldWrapperService } from './@sub/@view/frf-custom-field-wrapper/@sub/@service/frf-custom-field-wrapper/frf-custom-field-wrapper.service';

@Component({
  selector: 'frf-field-simple',
  templateUrl: './freeform-field-simple.component.html',
  styleUrls: ['./freeform-field-simple.component.scss']
})
export class FreeformFieldSimpleComponent implements OnInit {
  @Input('fieldid') fieldid;
  @Input('objid') objid;
  @Input('freeform') freeform;
  @Input('disabled') disabled;

  /**
   *
   * */
  @Output() changeStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public frfCustomFieldWrapper: FrfCustomFieldWrapperService) {}

  ngOnInit() {}
}
