import { Injectable } from '@angular/core';
import { filter, share } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfElementInvokerInterface } from './@res/@abstract/@interface/common.interface';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfElementInvokerMethodService {
  /**
   *
   * */
  private readonly listen$: Subject<FrfElementInvokerInterface> = new Subject();

  constructor() {}

  /**
   * следить за изменения поля
   * */
  public listenElementInvoker$(modelId: string, objid: string) {
    return this.listen$.pipe(
      filter(result => {
        return result && result.modelId === modelId && result.objId === objid;
      }),
      share()
    );
  }

  /**
   *
   * */
  public invokeElementMethod(
    modelId: string,
    objId: string,
    methodName: string,
    params?: any[]
  ) {
    this.listen$.next({
      modelId: modelId,
      objId: objId,
      method: {
        name: methodName,
        params: Array.isArray(params) ? params : []
      }
    });
  }
}
