export enum MzCacheType {
  inObject,
  inLocalStorage
}

export enum MzCacheState {
  notReady,
  ready
}

export enum MzCacheTypeEnum {
  observable,
  promise,
  simple
}
