export enum PlatformsEnum {
  web = 'web',
  android = 'android',
  ios = 'ios'
}

export enum SignedTypeEnum {
  signed = 'signed',
  notSigned = 'not-signed'
}
