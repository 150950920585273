/**
 * list of messengers
 * */
export enum CntApiMessengerEnum {
  cnt = 'connect',
  telegram = 'telegram',
  whatsapp = 'whatsapp',
  viber = 'viber',
  vk = 'vk'
}

export enum CntApiMessengerSendMessageEndpointEnum {
  cnt = 'connect',
  telegram = 'https://telegram.me/',
  whatsapp = 'https://api.whatsapp.com/send',
  viber = 'viber://pa'
  // vk = 'vk'
}
