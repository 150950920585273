import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent extends AbstractUnsubscribeViewControl
  implements OnInit {
  @Input() queryParamsPrefix = 'pnr_';
  @Input() hasNextPage: boolean;
  @Input() block = false;
  @Input() hasPreviousPage: boolean;
  @Input() extraQueryStringParams: { [key: string]: any } = {};
  @Input() activeListenUrlChange = false;
  @Input() initialEmit = false;
  @Output() pagesChange: EventEmitter<{
    currentPage: number;
    currentSize: number;
    offset: number;
  }> = new EventEmitter();

  @Input() currentPage = 1;
  @Input() currentSize = 25;
  @Input() allSizes = [25, 50, 100, 300, 500];

  public defaultSize = 3;

  private readonly pageKey = 'page';
  private readonly sizeKey = 'size';

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    if (this.activeListenUrlChange) {
      this.activatedRoute.queryParams
        .pipe(takeUntil(this.viewDestroy$))
        .subscribe((params) => {
          if (params) {
            if (params[this.queryParamsPrefix + this.pageKey]) {
              this.currentPage = Math.abs(
                parseInt(params[this.queryParamsPrefix + this.pageKey], 10) || 1
              );
            }
            if (params[this.queryParamsPrefix + this.sizeKey]) {
              this.currentSize =
                Math.abs(
                  parseInt(params[this.queryParamsPrefix + this.sizeKey], 10)
                ) || this.defaultSize;
            }

            this.cdRef.markForCheck();

            if (this.initialEmit) {
              this.emit();
            }
          }
        });
    } else {
      if (this.initialEmit) {
        this.emit();
      }
    }
  }

  /**
   * update template and emit value
   * */
  public emit() {
    this.pagesChange.emit({
      currentPage: this.currentPage,
      currentSize: this.currentSize,
      offset: (this.currentPage - 1) * this.currentSize,
    });
  }

  /**
   * open new page
   * */
  public updatePage(number) {
    this.currentPage = number;
    this.updateUrl();
    this.emit();
  }

  /**
   * open new page
   * */
  public updateSize(size) {
    this.currentSize = size;
    this.currentPage = 1;
    this.updateUrl();
    this.emit();
  }

  /**
   * update url
   * */
  private updateUrl() {
    if (this.activeListenUrlChange) {
      this.router.navigate([], {
        queryParamsHandling: 'merge',
        queryParams: {
          ...this.extraQueryStringParams,
          [this.queryParamsPrefix + this.pageKey]: this.currentPage,
          [this.queryParamsPrefix + this.sizeKey]: this.currentSize,
        },
        replaceUrl: true,
      });
    }
  }
}
