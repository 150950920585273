import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { CntTableFlexAutoWidthService } from '../../@service/cnt-table-flex-auto-width/cnt-table-flex-auto-width.service';
import { Subject, timer } from 'rxjs';

@Directive({
  selector: '[cntTableFlexAutoWidth]'
})
export class CntTableFlexAutoWidthDirective implements OnInit, OnDestroy {
  /*
   * ключи таблицы
   * */
  @Input() cntTableFlexAutoWidth: string;
  @Input() idx: number;
  @Input() group: string = 'main';
  @Input() alive: boolean = true;

  private update$: Subject<void> = new Subject();

  constructor(
    private elRef: ElementRef<HTMLElement>,
    private autoWidth: CntTableFlexAutoWidthService
  ) {}

  ngOnInit(): void {
    this.autoWidth.pushElement(
      this.group,
      this.cntTableFlexAutoWidth,
      this.elRef.nativeElement,
      this.idx
    );
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.autoWidth.delElement(
      this.group,
      this.cntTableFlexAutoWidth,
      this.elRef.nativeElement
    );
  }
}
