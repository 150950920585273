import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainGuardService } from './@sub/@guard/main-guard/main-guard.service';
import { AppsIdEnum } from '@cnt-nx-workspace/feature/app-controller';
import { AppZoneComponent } from '../../../@route-module/app-zone/app-zone.component';
import { BaseGuardService } from './@sub/@guard/base-guard/base-guard.service';
import { MainInfoComponent } from './@sub/@module/main-info/@sub/@view/main-info/main-info.component';
// import {ChatAppModule} from ".module";

const routesArray: Routes = [
  {
    path: 'sign',
    canActivate: [BaseGuardService],
    loadChildren: () =>
      import('../../../@route-module/sign/sign.module').then(m => m.SignModule)
  },
  {
    path: ':user',
    canActivate: [BaseGuardService],
    component: AppZoneComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: [
      /* Visit page */
      {
        path: '',
        component: MainInfoComponent
      },
      /* freeform */
      {
        path: 'frf/:user/:model',
        canActivate: [BaseGuardService],
        loadChildren: () =>
          import('../../../@route-module/frf/frf-app.module').then(
            m => m.FrfAppModule
          ),
        outlet: 'freeform'
      },
      {
        path: AppsIdEnum.chat,
        canActivate: [MainGuardService],
        loadChildren: () =>
          import('../../../@route-module/schat-app/schat-app.module').then(
            m => m.SchatAppModule
          )
      },
      {
        path: 'admin',
        canActivate: [MainGuardService],
        loadChildren: () =>
          import(
            '../../../@route-module/admin-app/@sub/@module/admin-user/admin-user.module'
          ).then(m => m.AdminUserModule)
      },
      {
        path: AppsIdEnum.sharepay,
        canActivate: [MainGuardService],
        loadChildren: () =>
          import(
            '../../../@route-module/sharepay-app/sharepay-app.module'
          ).then(m => m.SharepayAppModule)
      },
      // {
      //   path: AppsIdEnum.onepay,
      //   canActivate: [MainGuardService],
      //   loadChildren: () =>
      //     import('../onepay-app/onepay-app.module').then(m => m.OnepayAppModule)
      // },
      // {
      //   path: AppsIdEnum.market,
      //   canActivate: [MainGuardService],
      //   loadChildren: () =>
      //     import('../market-app/market-app.module').then(m => m.MarketAppModule)
      // },
      // {
      //   path: AppsIdEnum.edocument,
      //   canActivate: [MainGuardService],
      //   loadChildren: () =>
      //     import('../edocument-app/edocument-app.module').then(
      //       m => m.EdocumentAppModule
      //     )
      // },
      {
        path: '**',
        canActivate: [MainGuardService],
        redirectTo: ``
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/connect'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routesArray, {
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class MainRoutingModule {}
