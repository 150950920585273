<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="cIconFontTypeEnum.folder">
    <span class="c-icon-folder">
      <span class="path1"></span>
      <span class="path2"></span>
      <span class="path3"></span>
      <span class="path4"></span>
      <span class="path5"></span>
      <span class="path6"></span>
      <span class="path7"></span>
      <span class="path8"></span>
      <span class="path9"></span>
    </span>
  </ng-container>
</ng-container>
