export const enum AppsIdEnum {
  main = 'main',
  chat = 'chat',
  edocument = 'edocument',
  sharepay = 'sharepay',
  onepay = 'onepay',
  market = 'market'
}

export enum ConnectAppControllerActionsEnum {
  appSwitchApp = 'switch-app',
  setMainUser = 'setMainUser'
}

export enum ConnectTestEnum {}
