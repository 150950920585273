import { Component, Inject, OnInit } from '@angular/core';
import { UserMessengersFirestoreInterface } from '../../../../../../../../../../shared/@sub/@service/user-firestore/@res/@type/common.type';
import { UserMessengersItemInterface } from './@res/@interface/common.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CntMessengersIconSrcEnum,
  CntMessengersNameEnum,
  CntMessengersService,
  CntMessengerTypeEnum
} from '@cnt-nx-workspace/feature/cnt-messengers';

@Component({
  selector: 'choose-messenger-modal-window',
  templateUrl: './choose-messenger-modal-window.component.html',
  styleUrls: ['./choose-messenger-modal-window.component.scss']
})
export class ChooseMessengerModalWindowComponent implements OnInit {
  /**
   *
   * */
  public messengersArray: UserMessengersItemInterface[] = [];

  constructor(
    public dialogRef: MatDialogRef<ChooseMessengerModalWindowComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { messengers: UserMessengersFirestoreInterface },
    public cntMessengers: CntMessengersService
  ) {}

  /**
   *
   * */
  ngOnInit() {
    this.initMessengers();
  }

  /**
   *
   * */
  private initMessengers() {
    const messengers = this.data && this.data.messengers;
    if (messengers.instagram) {
      this.messengersArray.push({
        icon: CntMessengersIconSrcEnum.instagram,
        name: CntMessengersNameEnum.instagram,
        id: messengers.instagram,
        type: CntMessengerTypeEnum.instagram
      });
    }

    if (messengers.whatsapp) {
      this.messengersArray.push({
        icon: CntMessengersIconSrcEnum.whatsapp,
        name: CntMessengersNameEnum.whatsapp,
        id: messengers.whatsapp,
        type: CntMessengerTypeEnum.whatsapp
      });
    }

    if (messengers.telegram) {
      this.messengersArray.push({
        icon: CntMessengersIconSrcEnum.telegram,
        name: CntMessengersNameEnum.telegram,
        id: messengers.telegram,
        type: CntMessengerTypeEnum.telegram
      });
    }
  }

  /**
   *
   * */
  public open(id: string, type: CntMessengerTypeEnum) {
    this.cntMessengers.open(id, type);
  }
}
