import { Injectable } from '@angular/core';
import { FrfTriggerActionElementBlockerInterface } from './@res/@abstract/@interface/common.interface';
import { FrfValueSetterBlockerService } from '../../../../../../@group:value-services/frf-value-setter/@sub/frf-value-setter-blocker/frf-value-setter-blocker.service';
import { FreeformCommonService } from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfValueGetterService } from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';

/**
 * TODO create when need
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionElementBlockerService {
  constructor(
    private setter: FrfValueSetterBlockerService,
    private common: FreeformCommonService,
    private frfValueGetterService: FrfValueGetterService
  ) {
    window['blockElement'] = (modelid, id, data) => {
      this.setter.blockElement(modelid, id, data);
    };
  }

  /**
   * set blocker
   * */
  public async run(
    action: FrfTriggerActionElementBlockerInterface,
    startElement: any,
    scope: any
  ) {
    const element = action.id
      ? this.frfValueGetterService.getFrfElementById(
          action.id,
          this.common.freeform,
          null,
          startElement
        )
      : startElement;

    if (action && action.data && element) {
      this.setter.blockElement(element.modelid, element.id, action.data);
    }
  }
}
