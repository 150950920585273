import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import * as showdown from 'showdown';
import { MatDialog } from '@angular/material/dialog';
import { ChooseMessengerModalWindowComponent } from '../choose-messenger-modal-window/choose-messenger-modal-window.component';
import { SumSubComponent } from '../../../../../../../../../../../@view/lk/@sub/@view/sum-sub/sum-sub.component';
import { MzModalWindowService } from 'mz-modal-window';
import { UserMessengersFirestoreInterface } from '../../../../../../../../../../shared/@sub/@service/user-firestore/@res/@type/common.type';

@Component({
  selector: 'main-info-content',
  templateUrl: './main-info-content.component.html',
  styleUrls: ['./main-info-content.component.scss']
})
export class MainInfoContentComponent implements OnInit, OnChanges {
  /**
   *
   * */
  @Input('description')
  set _description(data) {
    this.description = this.toHtml(data);
  }

  /**
   * messenegers
   * */
  @Input() messengers: UserMessengersFirestoreInterface;

  /**
   *
   * */
  @Input() name!: string;
  /**
   *
   * */
  @Input() phone!: string;

  /**
   *
   * */
  @Input() icon!: string;

  /**
   *
   * */
  @Input() uid!: string;

  /**
   *
   * */
  public description = '';

  /**
   *
   * */
  public defaultStringForIcon: string;

  constructor(
    public dialog: MatDialog,
    public mzModalWindowService: MzModalWindowService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.defaultStringForIcon = this.getNameDefaultIcon();
    console.log('MainInfoContentComponent', {
      name: this.defaultStringForIcon,
      defaultStringForIcon: this.defaultStringForIcon
    });
  }

  /*&
   *
   * */
  private toHtml(text): string {
    return new showdown.Converter().makeHtml(text);
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(ChooseMessengerModalWindowComponent, {
      width: '368px',
      data: { messengers: this.messengers }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
  /*
   * show panel
   * */
  public showPanel() {
    const dialogRef = this.dialog.open(SumSubComponent, {
      data: { name: '' }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  /**
   *
   * */
  public getNameDefaultIcon() {
    return (
      this.name &&
      this.name
        .split(' ')
        .map(world => world && world[0])
        .join('')
        .toUpperCase()
    );
  }
}
