import { Injectable } from '@angular/core';
import { FrfValueSetterTempDataStorageTypeEnum } from '../../../frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { frfValueGetterCustomMenuGetBasePath } from './@res/@function/common.function';
import { FrfValueGetterTempDataService } from '../frf-value-getter-temp-data/frf-value-getter-temp-data.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterCustomMenuService {
  constructor(private frfValueGetterTempData: FrfValueGetterTempDataService) {}

  /**
   * get element by path
   * */
  public getClickedMenu(key: string, frfElement: any, path?: string) {
    const basePath = frfValueGetterCustomMenuGetBasePath(key),
      fullPath = basePath + ((path && '.' + path) || '');

    return this.frfValueGetterTempData.get(
      fullPath,
      frfElement,
      FrfValueSetterTempDataStorageTypeEnum.system
    );
  }
}
