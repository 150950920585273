import { Injectable } from '@angular/core';
import { CntMessengerTypeEnum } from './@res/@enum/common.enum';

@Injectable({
  providedIn: 'root'
})
export class CntMessengersService {
  constructor() {}

  /**
   *
   * */
  public open(id: string, type: CntMessengerTypeEnum, target = '_blank'): void {
    let url;

    switch (type) {
      case CntMessengerTypeEnum.instagram:
        url = `https://www.instagram.com/${id}`;
        break;

      case CntMessengerTypeEnum.whatsapp:
        url = `https://wa.me/${id}`;
        break;

      case CntMessengerTypeEnum.telegram:
        url = `http://t.me/${id}`;
        break;
    }

    if (url) {
      window.open(url, target);
    }
  }
}
