import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[mzHeaderDef]'
})
export class MzHeaderDefDirective {
  @Input() mzHeaderDef: string;

  constructor(
    /** @docs-private */
    public template: TemplateRef<any>
  ) {}
}
