import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  MzCameraDataInterface,
  MzCameraEndImageInterface
} from '../../../@res/@abstract/@interface/common.interface';
import { MzCameraService } from '../../@service/mz-camera/mz-camera.service';

@Component({
  selector: 'mz-camera',
  templateUrl: './mz-camera.component.html',
  styleUrls: ['./mz-camera.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MzCameraComponent implements OnInit, OnDestroy {
  @Input() maxImages: number = Infinity;
  @Input() id: string = 'default';
  @Output() close: EventEmitter<MzCameraEndImageInterface> = new EventEmitter();

  @ViewChild('video', { static: true }) videoElement: ElementRef<
    HTMLVideoElement
  >;
  @ViewChild('canvas', { static: true }) canvasElement: ElementRef<
    HTMLCanvasElement
  >;

  public microphones: { label: string; id: string }[] = [];
  public videos: { label: string; id: string }[] = [];
  public images: MzCameraDataInterface[] = [];
  public videoDeviceId: string;
  public microphoneDeviceId: string;

  /**
   * opened image
   * */
  public openImage: MzCameraDataInterface;

  constructor(
    private cdRef: ChangeDetectorRef,
    private mzCameraService: MzCameraService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.stopStream();
  }

  /**
   *
   * */
  private init() {
    navigator.mediaDevices
      .enumerateDevices()
      .then(this.gotDevices)
      .then(this.getStream)
      .catch(this.handleError);
  }

  /**
   * got devices
   * */
  private gotDevices = deviceInfos => {
    const microphones = [],
      videos = [];

    if (deviceInfos) {
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];

        if (deviceInfo.kind === 'audioinput') {
          microphones.push(
            {
              label: deviceInfo.label || `Microphone #${i + 1}`,
              id: deviceInfo.deviceId
            }
            // [
            //     deviceInfo.deviceId,
            //     deviceInfo.label
            // ]
          );
        } else if (deviceInfo.kind === 'videoinput') {
          videos.push(
            {
              label: deviceInfo.label || `Video #${i + 1}`,
              id: deviceInfo.deviceId
            }
            // [
            //     deviceInfo.deviceId,
            //     deviceInfo.label
            // ]
          );
        } else {
          // Found another kind of device: deviceInfo
        }
      }

      this.videoDeviceId = videos[0].deviceId;
      this.microphoneDeviceId = microphones[0].deviceId;

      console.log('microphones, videos - ', microphones, videos);
      this.microphones = microphones;
      this.videos = videos;
      this.cdRef.detectChanges();
    }
  };

  private stopStream() {
    const w: any = window;

    if (w.stream) {
      w.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  private getStream = () => {
    this.stopStream();

    const constraints = {
      video: {
        // width: {
        //   min: 1280
        // },
        // height: {
        //   min: 720
        // },
        deviceId: { exact: this.videoDeviceId }
      }
      // audio: {
      //   // deviceId: {exact: this.microphoneDeviceId}
      // }
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(this.gotStream)
      .catch(this.handleError);
  };

  /**
   * got stream
   * */
  private gotStream = stream => {
    const w: any = window;

    w.stream = stream; // make stream available to console

    this.videoElement.nativeElement.srcObject = stream;
  };

  /**
   * handle error
   * */
  private handleError(error) {
    console.error('Error: ', error);
  }

  /**
   *
   * */
  public takeSnapshot() {
    if (!(this.maxImages > this.images.length)) return;

    const canvas = this.canvasElement.nativeElement,
      video = this.videoElement.nativeElement;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);

    canvas.toBlob(result => {
      this.images.push({
        blob: result,
        src: canvas.toDataURL('image/jpeg')
      });

      this.cdRef.detectChanges();
    });
  }

  /**
   *
   * */
  public changeVideo(deviceId: string) {
    this.videoDeviceId = deviceId;
    this.getStream();
  }

  /**
   *
   * */
  public changeAudio(deviceId: string) {
    this.microphoneDeviceId = deviceId;
    this.getStream();
  }

  /**
   *
   * */
  public deleteIcon(image: any) {
    this.images = this.images.filter(img => image !== img);

    this.openImage = null;

    this.cdRef.detectChanges();
  }

  /**
   *
   * */
  public openPage(image: any) {
    this.openImage = image;
    this.cdRef.detectChanges();
  }

  /**
   *
   * */
  public closeModal() {
    this.openImage = null;
    this.cdRef.detectChanges();
  }

  /**
   *
   * */
  public cancel() {
    const data = {
      id: this.id,
      data: [],
      status: false
    };

    this.close.emit(data);

    this.mzCameraService.close$.next(data);
  }

  /**
   *
   * */
  public success() {
    const data = {
      id: this.id,
      data: this.images,
      status: true
    };

    this.close.emit(data);

    this.mzCameraService.close$.next(data);
  }
}
