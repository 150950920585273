import { Injectable } from '@angular/core';
import * as mzCommon from 'mz-common';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfTriggerService } from '../../../../frf-trigger/frf-trigger.service';
import { FreeformCommonService } from '../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfValueBasePathToTempDataInElement } from '../../../@res/@abstract/@const/common.const';
import { FrfValueSetterTempDataStorageTypeEnum } from './@res/@abstract/@enum/common.enum';
import { FrfValueSetterInterface } from '../../../@res/@abstract/@interface/common.interface';
import { FrfValueGetterService } from '../../../frf-value-getter/frf-value-getter.service';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueSetterTempDataService implements FrfValueSetterInterface {
  constructor(
    private trigger: FrfTriggerService,
    private common: FreeformCommonService
  ) {}

  /**
   * сохраняет временные данные (доступные только до закрытие/перезагрузки формы) к элементу
   * */
  public async set(
    element: any,
    path: string,
    value: any,
    storageType: FrfValueSetterTempDataStorageTypeEnum,
    triggersList = ['onSetTempData'],
    dependetStart: boolean = false
  ): Promise<void> {
    const tempDataBasePath = FrfValueBasePathToTempDataInElement,
      fullPath = `${tempDataBasePath}.${storageType}.${path}`;

    mzCommon.addValueToObjectByPath(element, fullPath, value, false);

    if (
      typeof triggersList === 'object' &&
      Array.isArray(triggersList) &&
      triggersList.length > 0
    ) {
      // we have triggers for start
      this.trigger.run(element, triggersList, {});
    }

    if (dependetStart) {
      // start analyse dependents if need
      this.common.for_dependentStartByObject(element);
    }
  }
}
