<ng-container [ngSwitch]="isLeftSide">
  <!-- if is left side > show previous and presubmit buttons -->
  <ng-container *ngSwitchCase="true">
    <ng-container
      *ngIf="pageNumber > 1"
      [ngTemplateOutlet]="previousButtonTemplate"
      [ngTemplateOutletContext]="{ button: previousButton }"
    ></ng-container>

    <ng-container
      *ngIf="status"
      [ngTemplateOutlet]="preSubmitButtonTemplate"
      [ngTemplateOutletContext]="{ button: preSubmitButton }"
    ></ng-container>
  </ng-container>

  <!-- правая сторона -->
  <ng-container *ngSwitchCase="false" [ngSwitch]="isLastPage">
    <ng-container *ngIf="status">
      <!-- если страница последняя -->
      <ng-container *ngSwitchCase="true">
        <!-- если нет pre-submit кнопки > показываем submit button -->
        <ng-container *ngIf="!isLeftSide">
          <ng-container
            *ngIf="
              frfSubmit.showSubmitOrNextBtn(
                frfElement,
                submitButton,
                preSubmitButton
              )
            "
          >
            <ng-container
              [ngTemplateOutlet]="submitButtonTemplate"
              [ngTemplateOutletContext]="{ button: submitButton }"
            >
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- если страница не последняя -->
      <ng-container *ngSwitchDefault>
        <!-- если нет pre-submit кнопки > показываем следующую страницу -->
        <ng-container
          *ngIf="
            frfSubmit.showSubmitOrNextBtn(
              frfElement,
              nextButton,
              preSubmitButton
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="nextButtonTemplate"
            [ngTemplateOutletContext]="{ button: nextButton }"
          >
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- если есть pre-submit кнопки -->
      <ng-container *ngIf="preSubmitButton">
        <ng-container
          [ngTemplateOutlet]="preSubmitButtonTemplate"
          [ngTemplateOutletContext]="{ button: preSubmitButton }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<!-- если этот элеменет завершен -->
<!--<ng-container *ngIf="status" [ngSwitch]="isLastPage">-->

<!--&lt;!&ndash;  &lt;!&ndash; if is left side > show previous and presubmit buttons &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;  <ng-container *ngIf="isLeftSide">&ndash;&gt;-->
<!--&lt;!&ndash;    <ng-container *ngIf="pageNumber > 1"&ndash;&gt;-->
<!--&lt;!&ndash;                  [ngTemplateOutlet]="previousButtonTemplate"&ndash;&gt;-->
<!--&lt;!&ndash;                  [ngTemplateOutletContext]="{button: previousButton}"></ng-container>&ndash;&gt;-->

<!--&lt;!&ndash;    <ng-container [ngTemplateOutlet]="preSubmitButtonTemplate"&ndash;&gt;-->
<!--&lt;!&ndash;                  [ngTemplateOutletContext]="{button: preSubmitButton}"></ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;  </ng-container>&ndash;&gt;-->

<!--  &lt;!&ndash; если страница последняя &ndash;&gt;-->
<!--  <ng-container *ngSwitchCase="true">-->
<!--    &lt;!&ndash; если нет pre-submit кнопки > показываем submit button &ndash;&gt;-->
<!--    <ng-container  *ngIf="!isLeftSide">-->
<!--      <ng-container *ngIf="frfSubmit.showSubmitOrNextBtn(-->
<!--          frfElement,-->
<!--          submitButton,-->
<!--          preSubmitButton-->
<!--        )">-->
<!--        <ng-container [ngTemplateOutlet]="submitButtonTemplate"-->
<!--                      [ngTemplateOutletContext]="{button: submitButton}">-->
<!--        </ng-container>-->
<!--      </ng-container>-->
<!--    </ng-container>-->

<!--    &lt;!&ndash; если есть pre-submit кнопки &ndash;&gt;-->
<!--    <ng-container *ngIf="preSubmitButton">-->
<!--      <ng-container [ngTemplateOutlet]="preSubmitButtonTemplate"-->
<!--                    [ngTemplateOutletContext]="{button: preSubmitButton}"></ng-container>-->

<!--    </ng-container>-->

<!--  </ng-container>-->

<!--  &lt;!&ndash; если страница не последняя &ndash;&gt;-->
<!--  <ng-container *ngSwitchDefault>-->

<!--    &lt;!&ndash; если нет pre-submit кнопки > показываем следующую страницу &ndash;&gt;-->
<!--    <ng-container *ngIf="frfSubmit.showSubmitOrNextBtn(-->
<!--        frfElement,-->
<!--        nextButton,-->
<!--        preSubmitButton-->
<!--      )">-->
<!--      <ng-container [ngTemplateOutlet]="nextButtonTemplate"-->
<!--                    [ngTemplateOutletContext]="{button: nextButton}">-->
<!--      </ng-container>-->
<!--    </ng-container>-->

<!--    &lt;!&ndash; если есть pre-submit кнопки &ndash;&gt;-->
<!--    <ng-container *ngIf="preSubmitButton">-->
<!--      <ng-container [ngTemplateOutlet]="preSubmitButtonTemplate"-->
<!--                    [ngTemplateOutletContext]="{button: preSubmitButtonOfElement}"></ng-container>-->
<!--    </ng-container>-->
<!--  </ng-container>-->
<!--</ng-container>-->

<ng-template #submitButtonTemplate let-button="button">
  <button
    *ngIf="button"
    (click)="submitPage(button)"
    mat-button
    [title]="button?.title || ''"
    class="submitButton"
  >
    {{ button?.value | connectDictionary }}
  </button>
</ng-template>

<ng-template #nextButtonTemplate let-button="button">
  <button
    *ngIf="button"
    (click)="nextPage(button)"
    mat-button
    [title]="button?.title || ''"
    class="nextButton"
  >
    {{ button?.value | connectDictionary }}
  </button>
</ng-template>

<ng-template #previousButtonTemplate let-button="button">
  <button
    *ngIf="button"
    (click)="previousPage(button)"
    mat-button
    [title]="button?.title || ''"
    class="nextButton"
  >
    {{ button?.value | connectDictionary }}
  </button>
</ng-template>

<ng-template #preSubmitButtonTemplate let-button="button">
  <button
    *ngIf="button"
    (click)="preSubmitPage(button)"
    mat-button
    [title]="button?.title || ''"
    class="preSubmitButton"
  >
    {{ button?.value | connectDictionary }}
  </button>
</ng-template>
