import {Injectable} from '@angular/core';
import {FrfFileStorageParams} from "./@res/@abstract/@interface/common.interface";

/**
 * для хранения временных данных для работы форм
 * */
@Injectable({
  providedIn: 'root'
  // providedIn: FrfMainLazyServiceModule
})
export class FrfStorageService {

  /**
   * тут будут храниться изменененый статусы элементов формы (строк, полей, груп, коллекций и т.д.)
   * */
  private frfElementsStatus: Map<any, boolean> = new Map<any, boolean>();


  /**
   * данные google storage
   * */
  private googleStorageParams: FrfFileStorageParams;

  /**
   * */
  public clear() {
    this.frfElementsStatus.clear();
  }

  /**
   *
   * */
  public setElementStatus (
    frfElement: any,
    state: boolean
  )
  {
    this.frfElementsStatus.set(
      frfElement,
      state
    );
  }

  /**
   *
   * */
  public getElementStatus (
    frfElement: any
  ): boolean
  {
    const has = this.frfElementsStatus.has(
      frfElement
    );

    if (!has) {
      this.setElementStatus(
        frfElement,
        !!frfElement?.body?.status?.value
      )
    }

    return this.frfElementsStatus.get(
      frfElement
    );
  }

  /**
   *
   * */
  public setGoogleStorageParams (
    googleStorageParams: FrfFileStorageParams
  ): void
  {
    this.googleStorageParams = googleStorageParams;
  }


  /**
   *
   * */
  public getGoogleStorageParams (): FrfFileStorageParams
  {
    return this.googleStorageParams;
  }
}
