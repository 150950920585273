import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FrfInitialValueService } from '../../../../../../../../../../../../../../@service/frf-initial-value/frf-initial-value.service';
import { FreeformFieldValidatorLibrary } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform-field-validators.library';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { takeUntil } from 'rxjs/operators';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'frf-field-select',
  templateUrl: './freeform-field-select.component.html',
  styleUrls: ['./freeform-field-select.component.scss']
})
export class FreeformFieldSelectComponent extends AbstractUnsubscribeViewControl
  implements OnInit, AfterViewInit {
  @Input() fieldid;
  @Input() objid;
  @Input() frf;
  @Input() disabled;

  /* optional */
  @Input() multi: boolean;

  /* this field from freeform object */
  public field;

  // here we add error text
  public textOfError = '';

  // create angular reactive form
  public form = new FormGroup({
    field: new FormControl('', [])
  });

  public test = 'MALE';

  constructor(
    private common: FreeformCommonService,
    private frfValueSetterService: FrfValueSetterService,
    private frfInitialValueService: FrfInitialValueService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.field = this.frf.fields[this.fieldid].objects[this.objid];

    /**
     * add required validator
     * */
    new FreeformFieldValidatorLibrary(this.frf, this.field, this.form).run();
  }

  ngAfterViewInit(): void {
    this.frfInitialValueService.setIfNeedInitialValueIfItExist(
      this.field,
      this.frf,
      {}
    );

    this.watchFieldChanges();
  }

  /**
   * watch field changes
   * */
  private watchFieldChanges() {
    this.frfValueSetterService
      .watchFieldChanges(this.fieldid, this.objid)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(() => {
        this.cdRef.markForCheck();
      });
  }

  /**
   * on change selected element
   * */
  public change() {
    this.common.for_dependentStartByObject(this.field);
    this.frfValueSetterService.setFieldValue(
      this.field,
      this.field.body.value,
      ['onChange', 'onSelect']
    );
  }
}
