import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'frf-row',
  templateUrl: './freeform-row.component.html',
  styleUrls: ['./freeform-row.component.scss']
})
export class FreeformRowComponent implements OnInit {
  @Input('groupid') groupid;
  @Input('objid') objid;
  @Input('freeform') freeform;
  @Input('disabled') disabled;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  public setThisRowStateToFocus(iNid) {
    if (typeof this.freeform['focused'] !== 'object')
      this.freeform['focused'] = {};
    this.freeform['focused']['row'] = iNid;
  }

  /**
   *
   * */
  public trackBy(idx: number, item: any) {
    return idx;
  }

  /**
   *
   * */
  public updateFieldStatus(status) {}
}
