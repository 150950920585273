/**
 *
 * */
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

export function RnWebElementCreate(
  tagName: string,
  params?: { [key: string]: any},
  options?: {
    innerHTML?: string,
    style?: string
  },
  autoSetListeners = true
) {
  const element = document.createElement(tagName);

  if (options?.innerHTML){
    element.innerHTML = options.innerHTML;
  }
  if (options?.style){
    element.setAttribute("style", options.style)
  }

  if (params) {
    if (autoSetListeners) {
      RnWebElementSetParamsAndListeners(element, params);
    } else {
      RnWebElementSetParams(element, params);
    }
  }

  return element;
}

/**
 *
 * */
export function RnWebElementSimpleCreate(
  tagName: string,
  listeners?: { [key: string]: any},
  params?: { [key: string]: any},
  options?: {
    innerHTML?: string,
    style?: string,
    onInit?: (element: HTMLElement) => void
    onDestroy?: (element: HTMLElement) => void
    destroyCheckInterval?: number
  }
) {
  const element = document.createElement(tagName);

  if (options?.innerHTML){
    element.innerHTML = options.innerHTML;
  }
  if (options?.style){
    element.setAttribute("style", options.style)
  }

  if (params) {
    RnWebElementSetParams(element, params);
  }

  if (listeners) {
    RnWebElementSetListeners(element, listeners);
  }

  /* safe invoke onInit function */
  if (typeof options?.onInit === 'function') {
    options.onInit(element)
  }

  /* safe invoke onDestroy function */
  if (typeof options?.onDestroy === 'function') {
    interval(options?.destroyCheckInterval ?? 50)
      .pipe(
        takeWhile(
          () => !!element?.parentElement
        )
      )
      .subscribe(
        () => {
        },
        () => {},
        () => {
          options?.onDestroy(element)
        }

      )
  }

  return element;
}

/**
 *
 * */
export function RnWebElementSetParams (
  element: HTMLElement,
  params?: { [key: string]: any}
) {
  if (params) {
    Object.keys(params).forEach(
      (key) => {
        element[key] = params[key];
      }
    )
  }
}

/**
 * save listeners of element for later remove
 * */
export class RnWebElementParamsAndListeners  {
  public static storage: Map<HTMLElement, Map<string, Set<any> > > = new Map();

  /**
   *
   * */
  public static add (
    el: HTMLElement,
    event: string,
    listener: (value: any) => any
  )
  {
    if (!this.storage.has(el)) {
      this.storage.set(el, new Map<string, Set<any>>() )
    }

    const values = <any>this.storage.get(el);

    if (!values.has(event)) {
      values.set(event, new Set<any>())
    }

    const allListeners = <any>values.get(event);

    if ( !allListeners.has(listener) ) {
      allListeners.add(listener);
    }
  }

  /**
   *
   * */
  public static get (
    el: HTMLElement,
    event: string
  ): Set<any>
  {
    if (!this.storage.has(el)) {
      this.storage.set(el, new Map<string, Set<any>>() )
    }

    const values = <any>this.storage.get(el);

    if (!values.has(event)) {
      values.set(event, new Set<any>())
    }

    return values.get(event);
  }

  /**
   * clear from all listeners saved in storage
   * */
  public static clear (
    el: HTMLElement
  )
  {
    if (this.storage.has(el)) {
      const values = <any>this.storage.get(el);

      values.forEach(
        (value, key) => {
          if (value && value.size) {
            value.forEach(
              (listener) => {
                el.removeEventListener(
                  key,
                  listener
                )
              }
            )
          }
        }
      );

      this.storage.delete(el);
    }
  }

  /**
   * add listeners and params
   * */
  public static setParamsAndListeners (
    element: HTMLElement,
    params?: { [key: string]: any},
    removeOld = false
  ) {

    if (params) {
      Object.keys(params).forEach(
        (key) => {

          if (typeof  params[key] === 'function') {

            if (removeOld) {
              const values = this.get(element, key);

              if (values && values.size) {
                new Set(values).forEach(
                  (item) => {
                    element.removeEventListener(
                      key,
                      item
                    );

                    values.delete(item);
                  }
                )
              }
            }

            element.addEventListener(
              key,
              params[key]
            );

            this.add(
              element,
              key,
              params[key]
            );
          } else {
            element[key] = params[key];
          }
        }
      )
    }
  }
}

/**
 *
 * */
export function RnWebElementSetParamsAndListeners (
  element: HTMLElement,
  params?: { [key: string]: any},
  reSetAllParams = false
) {
  if (reSetAllParams) {
    const oldComponent = element;
    element = element.cloneNode(true) as any;
    oldComponent.replaceWith(element);
  }

  if (params) {
    Object.keys(params).forEach(
      (key) => {
        if (typeof  params[key] === 'function') {
          element.addEventListener(
            key,
            params[key]
          );
        } else {
          element[key] = params[key];
        }
      }
    )
  }
}

/**
 *
 * */
export function RnWebElementSetListeners (
  element: HTMLElement,
  params?: { [key: string]: (val: any) => void}
  // remove = true
) {
  if (params) {
    Object.keys(params).forEach(
      (key) => {
        element.addEventListener(
          key,
          params[key]
        )
      }
    )
  }
}
