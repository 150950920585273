import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { take } from 'rxjs/operators';
import { NavigateService } from '../../@service/navigate/navigate.service';

@Injectable({
  providedIn: 'root'
})
export class BaseGuardService implements CanActivate {
  constructor(
    private connectAuthService: ConnectAuthService,
    private router: Router,
    private navigateService: NavigateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.connectAuthService
        .getUserId()
        .pipe(take(1))
        .subscribe(uid => {
          /* if are signed already > navigate to main account */
          this.navigateService.saveOpenedLogin(route.params.user);

          if (this.isOnSignPage(route)) {
            if (!uid) {
              observer.next(true);
              observer.complete();
              return;
            } else {
              observer.next(false);
              this.goToUserPage(uid);
              observer.complete();
            }
          } else {
            observer.next(true);
            observer.complete();
          }
        });
    });
  }

  /**
   *
   * */
  private isOnSignPage(route: ActivatedRouteSnapshot) {
    return route.url.toString() === 'sign';
  }

  /**
   *
   * */
  private goToUserPage(login: string) {
    this.navigateService.openByLogin(login);
    // this.router.navigate([`${login}`]);
  }
}
