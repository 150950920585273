<ng-template
  #fullRowTemplate
  let-freeform="freeform"
  let-fields="freeform.fields"
  let-fieldid="fieldid"
  let-objid="objid"
  let-field="field"
  let-rowobjid="rowobjid"
  let-rowid="rowid"
>
  <!--from object-->
  <ng-container *ngFor="let pre of field.pre; trackBy: trackBy">
    <ng-container
      *ngTemplateOutlet="
        fullRowTemplate;
        context: { fieldid: pre.baseid, objid: pre.objid, freeform: freeform }
      "
    ></ng-container>
  </ng-container>

  <ng-container>
    <div
      class="formField_
        col-{{ field.body?.view?.width?.all }}
        col-md-{{
        field.body?.view?.width?.mobile || field.body?.view?.width?.all
      }}
        col-lg-{{
        field.body?.view?.width?.tablet || field.body?.view?.width?.all
      }}
        col-xl-{{
        field.body?.view?.width?.comp || field.body?.view?.width?.all
      }}

        offset-md-{{
        field.body?.view?.offset?.mobile || field.body?.view?.offset?.all
      }}
        offset-lg-{{
        field.body?.view?.offset?.tablet || field.body?.view?.offset?.all
      }}
        offset-xl-{{
        field.body?.view?.offset?.comp || field.body?.view?.offset?.all
      }}
      "
      [ngClass]="{
        hide: field.body?.payload?.view?.hide || field.body?.status?._hide
      }"
    >
      <div class="block" [ngSwitch]="field.body?.type">
        <ng-container *ngSwitchCase="'collection'">
          <frf-field-collection
            [fieldid]="fieldid"
            [objid]="objid"
            [freeform]="freeform"
            [disabled]="disabled"
          ></frf-field-collection>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <!--SIMPLE FIELDS (NOT COLLECTION-->
          <frf-field-simple
            (changeStatus)="changeStatus.emit($event)"
            [fieldid]="fieldid"
            [objid]="objid"
            [freeform]="freeform"
            [disabled]="disabled"
          ></frf-field-simple>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!--from object-->
  <ng-container *ngFor="let post of field.post; trackBy: trackBy">
    <ng-container
      *ngTemplateOutlet="
        fullRowTemplate;
        context: { fieldid: post.baseid, objid: post.objid, freeform: freeform }
      "
    ></ng-container>
  </ng-container>
</ng-template>

<div
  class="row"
  [ngClass]="{
    right:
      (freeform.rows[rowid]?.objects)[objid].body?.view?.fieldDirection ===
      'right'
  }"
  *ngIf="freeform.fields"
>
  <ng-container
    *ngFor="
      let field of (freeform.rows[rowid]?.objects)[objid]?.body?.fields;
      trackBy: trackBy;
      let fieldIndex = index
    "
  >
    <ng-container
      *ngTemplateOutlet="
        fullRowTemplate;
        context: {
          field: freeform.fields[field.baseid].objects[field.objid],
          fieldid: field.baseid,
          objid: field.objid,
          freeform: freeform,
          rowid: rowid,
          rowobjid: objid
        }
      "
    ></ng-container>
  </ng-container>
</div>
