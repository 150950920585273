import { Injectable } from '@angular/core';
import { FrfCustomFieldApiInterface } from './@res/@abstract/@interface/common.interface';
import { FrfMainLazyServiceModule } from '../../../../../../../../../../../../../../../../../../../../frf-main-lazy-service.module';
import { FrfApiSetFieldValueService } from './@sub/@service/frf-api-set-field-value/frf-api-set-field-value.service';
import { FrfCustomFieldApiUploadService } from './@sub/@service/frf-custom-field-api-upload/frf-custom-field-api-upload.service';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfCustomFieldApiLayoutService
  implements FrfCustomFieldApiInterface {
  constructor(
    public fieldValueSetter: FrfApiSetFieldValueService,
    public fieldUpload: FrfCustomFieldApiUploadService
  ) {}
}
