import { NgModule } from '@angular/core';
import { MzCameraComponent } from './@sub/@view/mz-camera/mz-camera.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [MzCameraComponent],
  exports: [MzCameraComponent]
})
export class MzCameraModule {}
