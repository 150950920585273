<div
  class="uploadZoneAndBtn"
  connectFileDrop
  (filesDropped)="getAccessToUploadFile() && handleDrop($event)"
  (filesHovered)="getAccessToUploadFile() && dropzoneState($event)"
  *ngIf="
    !field['clientGeneration'] ||
    !disabled ||
    !field.body.status.disabled ||
    field.options.access.write
  "
>
  <div class="viewedElement" [ngClass]="{ active: dropzoneStateStatus }">
    <div class="text">{{ field.body.name }}</div>
    <div class="block-with-buttons">
      <div
        (click)="takeSnapshot()"
        class="take-picture icons c-icon-photo-camera"
      ></div>

      <div
        *ngIf="getAccessToUploadFile()"
        (click)="inputElement.click()"
        class="add-file icons c-icon-upload-to-cloud"
      ></div>
    </div>
  </div>

  <input
    #inputElement
    [attr.multiple]="multiple"
    type="file"
    class="uploadInputBtn"
    (change)="onChange(uploadInputBtn.files)"
    #uploadInputBtn
  />
</div>

<div class="uploadedImages row">
  <div
    class="uploadedImage col-md-3 col-sm-6"
    (click)="openImageInModalWindow(fielUploadedEl)"
    *ngFor="
      let fielUploadedEl of fieldUploadedList;
      let idx = index;
      trackBy: common.trackByIndex
    "
  >
    <div class="img">
      <img [src]="getImageIcon(fielUploadedEl) | async" />
    </div>
    <div class="delBtn" (click)="delImage(idx)">x</div>
  </div>

  <div
    class="uploadedImage col-md-3 col-sm-6"
    *ngFor="let fielUploadedEl of fieldUploadingLocalList; let idx = index"
  >
    <div class="progressBar" id="f-pb-{{ fielUploadedEl.fileId }}"></div>
    <div class="img">
      <img [src]="fielUploadedEl['icon']" />
    </div>
  </div>
</div>

<div class="frf-modal-window" *ngIf="showedImageInCenterImage">
  <div class="frf-safe-zone">
    <div class="frf-modal-image-block">
      <div class="menu-button c-icon-more" [matMenuTriggerFor]="menu"></div>
      <div class="center-image">
        <img
          alt="Center file image"
          [src]="getImageIcon(showedImageInCenterImage) | async"
        />
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let menu of menus; trackBy: common.trackByIndex"
      (click)="clickToMenu(menu, showedImageInCenterImage)"
    >
      {{ menu.value }}
    </button>
  </mat-menu>
</div>
