import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApolloBoostModule} from 'apollo-angular-boost';
import {ConnectAuthService} from "./@sub/@service/connect-auth.service";
import {MzStorageModule} from "mz-storage";
import {SignTypeEnum} from "./@sub/@service/@sub/@abstract/@enum/common.enum";

@NgModule({
  imports: [
      CommonModule,
      ApolloBoostModule,
      MzStorageModule
  ],
  providers: [
      ConnectAuthService
  ]
})
export class ConnectAuthModule {
  constructor()
  {
  }
}

export {ConnectAuthService} from "./@sub/@service/connect-auth.service";
export {SignTypeEnum} from "./@sub/@service/@sub/@abstract/@enum/common.enum";