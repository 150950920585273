export enum FrfValueGetterTypeEnum {
  /**
   * get input param from freeform object
   * */
  inputParam = 'input-param',

  /*
   * custom value with auto params
   * */
  customValue = 'custom-value',

  /*
   * custom menu clicked
   * */
  clickedCustomMenu = 'clicked-custom-menu',

  /*
   * custom pre submit button
   * */
  clickedPreSubmitButton = 'clicked-pre-submit-button',

  /*
   * get system data (e.g. uid, token, ...)
   * */
  systemData = 'system-data',

  /*
   * if value
   * */
  if = 'if',

  /**
   * @deprecated
   * */
  thisFieldValueOld = 'thisFieldValue',
  /*
   * get value of this element
   * */
  thisFieldValue = 'this-field-value',

  /*
   * getElementByPath
   * */
  byPathToElement = 'by-path-to-element',

  /*
   * byMask
   * получение по маски значений
   * */
  byMask = 'by-mask',

  /*
   * byMask
   * обычно работает с массиво
   * обходим массив элементов и возврашаем масив
   * */
  mapElements = 'map-elements',

  /**
   * @deprecated
   * */
  fromAutocompleteSelectedOld = 'fromAutocompleteSelected',
  fromAutocompleteSelected = 'from-auto-complete-selected',

  /**
   * @deprecated
   * */
  fromAutocompleteResponseOld = 'fromAutocompleteResponse',
  fromAutocompleteResponse = 'from-auto-complete-response',

  /**
   * @deprecated
   * */
  fieldValueOld = 'fieldValue',
  fieldValue = 'field-value',

  /**
   * @deprecated
   * get element user data ( for field-* )
   * */
  getElementDataOld = '$ed',
  getElementData = 'get-element-data',

  /*
   * get data by path from this element
   * */
  thisElement = '@this-element',

  /**
   * get element id by local id
   * */
  getElementByLocalId = '@lid-id',

  /**
   * get element value by local id
   * */
  getElementValueByLocalId = '@lid-value',

  /**
   * get payload options
   * */
  getPayloadOptions = 'get-payload-options',

  /**
   * get params from scope
   * */
  scope = 'scope',

  /**
   * get payload
   * */
  getPayload = 'payload',

  /**
   * get data from element by path and local id
   * */
  getDataFromLocalId = '@lid',
  getThisElementValue = '@self-value',
  getThisElement = '@self',

  /**
   *
   * */
  value = 'value',

  /**
   * element status
   * */
  elementStatus = 'element-status',

  /**
   * date what save in storage before reloading
   * */
  tempData = 'temp-data',
  /*
   * data which save request action
   * */
  tempDataFromRequestAction = 'temp-data-from-request-action',

  /**
   * state of loading (work now only for frf-text-field
   * */
  loadingState = 'loading-state'

  /**
   *
   * */
}
