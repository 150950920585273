<ng-template
  #fullRowTemplate
  let-freeform="freeform"
  let-fields="freeform.fields"
  let-fieldid="fieldid"
  let-objid="objid"
>
  <!--helpers-->
  <ng-container *ngIf="(fields[fieldid]?.objects)[objid]?.body?.helper">
    <div
      class="helper_"
      [matTooltip]="(fields[fieldid]?.objects)[objid]?.body?.helper?.text"
      *ngIf="(fields[fieldid]?.objects)[objid]?.body?.helper?.text"
    >
      <div class="leftBlock_">
        <div class="icon_">
          <i class="fas fa-question-circle"></i>
        </div>
      </div>
    </div>

    <div
      class="success_ alert alert-success fade in alert-dismissible row"
      *ngIf="
        (fields[fieldid]?.objects)[objid]?.body?.helper?.success &&
        (fields[fieldid]?.objects)[objid]?.body?.status?.value === true
      "
    >
      <div class="leftBlock_ ol-1">
        <div class="icon_">
          <i class="fas fa-check-circle"></i>
        </div>
      </div>
      <div class="body_ ol-11">
        {{ (fields[fieldid]?.objects)[objid]?.body?.helper?.success }}
      </div>
    </div>
  </ng-container>

  <ng-container [ngSwitch]="(fields[fieldid]?.objects)[objid]?.body?.type">
    <ng-container *ngSwitchCase="'text'">
      <!--<p>TEXT</p>-->
      <frf-field-text
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [frf]="freeform"
      ></frf-field-text>
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      <!--<p>NUMBER</p>-->
      <frf-field-text
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [frf]="freeform"
        type="number"
      ></frf-field-text>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <!--<p>DATE</p> date-->
      <frf-field-date
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-field-date>
    </ng-container>

    <ng-container *ngSwitchCase="'textredactor'">
      <!--<p>TEXTREDACTORE</p> textredactor -->
      <frf-field-textredactor
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-field-textredactor>
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
      <!--<p>TEXTAREA</p>-->
      <frf-field-textarea
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
        type="text"
      ></frf-field-textarea>
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <!--<p>SELECT</p>-->
      <frf-field-select
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [frf]="freeform"
      ></frf-field-select>
    </ng-container>

    <ng-container *ngSwitchCase="'button'">
      <!--<p>BUTTON</p>-->
      <frf-field-button
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-field-button>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <frf-field-checkbox
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text,
          _hide: (fields[fieldid]?.objects)[objid]?.body?.status?.hide1
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-field-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="'switcher'">
      <!--<p>SWITCHER</p>-->
      <frf-field-switcher
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-field-switcher>
    </ng-container>

    <ng-container *ngSwitchCase="'radio'">
      <!--<p>RADIO</p>-->
      <frf-field-radio
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-field-radio>
    </ng-container>

    <ng-container *ngSwitchCase="'image'">
      <!--<p>FILE UPLOAD</p>-->
      <frf-view-image
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-view-image>
    </ng-container>

    <!-- upload fiile -->
    <ng-container *ngSwitchCase="'upload'">
      <frf-field-upload
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-field-upload>
    </ng-container>

    <!-- camera upload -->
    <ng-container *ngSwitchCase="'camera'">
      <frf-field-camera
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      >
      </frf-field-camera>
    </ng-container>

    <ng-container *ngSwitchCase="'autocomplete'">
      <!--AUTOCOMPLETE-->
      <frf-field-autocomplete
        [ngClass]="{
          withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
        }"
        [disabled]="disabled"
        [fieldid]="fieldid"
        [objid]="objid"
        [freeform]="freeform"
      ></frf-field-autocomplete>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container
        *ngIf="
          frfCustomFieldWrapper.isCustomComponentType(
            (fields[fieldid]?.objects)[objid]?.body?.type
          )
        "
      >
        <frf-custom-field-wrapper
          (changeStatus)="changeStatus.emit($event)"
          [ngClass]="{
            withHelper: (fields[fieldid]?.objects)[objid]?.body?.helper?.text
          }"
          [customIdWithPrefix]="(fields[fieldid]?.objects)[objid]?.body?.type"
          [disabled]="disabled"
          [fieldid]="fieldid"
          [objid]="objid"
          [frf]="freeform"
        >
        </frf-custom-field-wrapper>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-container
  *ngTemplateOutlet="
    fullRowTemplate;
    context: { fieldid: fieldid, objid: objid, freeform: freeform }
  "
></ng-container>
