import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AbstractFirestorePaginatorService } from '../../@service/@abstract/abstract-firestore-paginator/abstract-firestore-paginator.service';
import { CntTableFlexService } from '../cnt-table-flex/@sub/@service/cnt-table-flex/cnt-table-flex.service';
import { BodyFlexTableInterface } from '../cnt-table-flex/@res/@abstract/@interface/common.interface';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'paganator-firestore',
  templateUrl: './paginator-firestore.component.html',
  styleUrls: ['./paginator-firestore.component.scss']
})
export class PaginatorFirestoreComponent extends AbstractUnsubscribeViewControl
  implements OnInit, OnDestroy {
  /**
   *
   * */
  @Input() public firestorePaginator!: AbstractFirestorePaginatorService;

  /**
   *
   * */
  @Input() public flowName!: string;

  /**
   * need destory firestore flow
   * */
  @Input() public needDestroy?: boolean = true;

  /**
   * need destroy firestore flow
   * */
  @Input() public flexTableFormatEmit?: boolean = true;

  /**
   * need destroy firestore flow
   * */
  @Input() public loadDataOnInit?: boolean = true;

  /**
   *
   * */
  @Output() changed: EventEmitter<
    BodyFlexTableInterface[] | any[]
  > = new EventEmitter();

  /**
   * paginator
   * */
  public paginatorShowOptions: {
    isNextPage?: boolean;
    isPreviousPage?: boolean;
  } = {};

  /**
   *
   * */
  // private lastFlowName: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private cntTableFlex: CntTableFlexService
  ) {
    super();
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.needDestroy) {
      /**
       * if need destory firestore flow
       * */
      this.firestorePaginator.destroyFlow(this.flowName);
    }
  }

  /**
   * init data for paginator
   * */
  public initData() {
    /**
     * listen - for show hide page change arrows
     * */
    this.firestorePaginator
      .getOptionsByName$(this.flowName)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(options => {
        if (options) {
          this.paginatorShowOptions = {
            isNextPage: options.hasNextPage,
            isPreviousPage: options.hasPreviousPage
          };

          // this.changed.emit();
          this.cdRef.markForCheck();
        }
      });

    /**
     * listen for updates firestore date
     * */
    this.firestorePaginator
      .getStreamByName$(this.flowName)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(result => {
        let rows: BodyFlexTableInterface[] = [];

        if (result && result.length) {
          if (this.flexTableFormatEmit) {
            rows = [...this.cntTableFlex.getRows(result)];
          } else {
            rows = result;
          }
        }

        this.changed.emit(rows);
        this.cdRef.markForCheck();
      });

    if (this.loadDataOnInit) {
      this.firestorePaginator.load(this.flowName);
    }
  }

  /**
   * when open page
   * */
  public openPage($event: {
    currentPage: number;
    currentSize: number;
    offset: number;
  }) {
    this.firestorePaginator.openPage(
      this.flowName,
      $event.currentPage,
      $event.currentSize
    );
  }
}
