export enum FrfTriggerNameEnum {
  onKeyup = 'onKeyup',
  onClick = 'onClick',
  onChange = 'onChange',
  onClickToCustomMenu = 'onClickToCustomMenu',
  onSetTempData = 'onSetTempData',
  onSetSrc = 'onSetSrc',
  onUpload = 'onUpload',
  onWrite = 'onWrite',
  onInit = 'onInit',
  onSetPayloadOptions = 'onSetPayloadOptions',
  onClickToPreSubmitButton = 'onClickToPreSubmitButton',
  onChangeStatus = 'onChangeStatus'
}
