import { Injectable } from '@angular/core';
import { FrfValueSetterBlockerService } from '../../../../../../@group:value-services/frf-value-setter/@sub/frf-value-setter-blocker/frf-value-setter-blocker.service';
import { FrfTriggerActionBlockerInterface } from './@res/@abstract/@interface/common.interface';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';

/**
 * TODO create when need
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionBlockerService {
  constructor(private setter: FrfValueSetterBlockerService) {}

  /**
   * set blocker
   * */
  public async run(action: FrfTriggerActionBlockerInterface, scope: any) {
    if (action && action.data) {
      this.setter.set(action.data);
    }
  }
}
