import { Injectable } from '@angular/core';
import * as mzCommon from 'mz-common';
import { FrfValueSetterTempDataStorageTypeEnum } from '../../../frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FrfValueBasePathToTempDataInElement } from '../../../@res/@abstract/@const/common.const';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterTempDataService {
  /**
   * get element by path
   * */
  public get(
    path: string,
    frfElement: any,
    storageType: FrfValueSetterTempDataStorageTypeEnum = FrfValueSetterTempDataStorageTypeEnum.user
  ) {
    const tempDataBasePath = FrfValueBasePathToTempDataInElement,
      fullPath = `${tempDataBasePath}.${storageType}.${path}`;

    return mzCommon.getValueFromObjectByPath(fullPath, frfElement);
  }
}
