import { Injectable } from '@angular/core';
import { FrfValueCheckerEnum } from './@res/@abstract/@enum/common.enum';
import { FrfMainLazyServiceModule } from '../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueCheckerService {
  constructor() {}

  /**
   * check value
   * */
  public check(value: any, check?: FrfValueCheckerEnum): boolean {
    switch (check) {
      case FrfValueCheckerEnum.isAny: {
        return true;
      }

      case FrfValueCheckerEnum.isNull: {
        return value === null;
      }

      case FrfValueCheckerEnum.isNotNull: {
        return !(value === null);
      }

      case FrfValueCheckerEnum.isNotString: {
        return !(typeof value === 'string');
      }

      case FrfValueCheckerEnum.isNumber: {
        return typeof parseInt(value, 10) === 'number';
      }

      case FrfValueCheckerEnum.isArray: {
        return value && Array.isArray(value);
      }

      case FrfValueCheckerEnum.isNotArray: {
        return !(value && Array.isArray(value));
      }

      case FrfValueCheckerEnum.isNotNumber: {
        return !(typeof parseInt(value, 10) === 'number');
      }

      case FrfValueCheckerEnum.isString:
        return typeof value === 'string';

      default:
        return true;
    }
  }
}
