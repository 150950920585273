import { Component, OnInit } from '@angular/core';
import { AppZoneFooterComponent } from '../../../../../../../../../../../../@route-module/app-zone/@sub/@view/app-zone-footer/app-zone-footer.component';

@Component({
  selector: 'main-info-first-buttons',
  templateUrl: './main-info-first-buttons.component.html',
  styleUrls: ['./main-info-first-buttons.component.scss']
})
export class MainInfoFirstButtonsComponent extends AppZoneFooterComponent
  implements OnInit {
  /**
   *
   * */
  public ngOnInit() {
    super.ngOnInit();
  }
}
