<div
  class="uploadZoneAndBtn"
  connectFileDrop
  (filesDropped)="handleDrop($event)"
  (filesHovered)="dropzoneState($event)"
  (click)="inputElement.click()"
  *ngIf="
    !field['clientGeneration'] ||
    !disabled ||
    !field.body.status.disabled ||
    field.options.access.write
  "
>
  <div
    class="viewedElement"
    [ngClass]="{
      active: dropzoneStateStatus
    }"
  >
    Загрузка
  </div>
  <i class="footerText_">Переташите файл</i>
  <input
    #inputElement
    [attr.multiple]="multiple"
    type="file"
    class="uploadInputBtn"
    (change)="onChange(uploadInputBtn.files)"
    #uploadInputBtn
  />
</div>

<div class="uploadedImages row">
  <div
    class="uploadedImage col-md-3 col-sm-6"
    *ngFor="
      let fielUploadedEl of fieldUploadedList;
      trackBy: common.trackByIndex;
      let idx = index
    "
  >
    <div class="img">
      <img [src]="getImageIcon(fielUploadedEl) | async" />
    </div>
    <div class="delBtn" (click)="delImage(idx)">x</div>
  </div>

  <div
    class="uploadedImage col-md-3 col-sm-6"
    *ngFor="let fielUploadedEl of fieldUploadingLocalList; let idx = index"
  >
    <div class="progressBar" id="f-pb-{{ fielUploadedEl.fileId }}"></div>
    <div class="img">
      <img [src]="fielUploadedEl['icon']" />
    </div>
  </div>
</div>

<div class="frf-modal-window" *ngIf="showedImageInCenterImage">
  <div class="frf-safe-zone">
    <div class="frf-modal-image-block">
      <div class="menu-button c-icon-more" [matMenuTriggerFor]="menu"></div>
      <div class="center-image">
        <img
          alt="Center file image"
          [src]="getImageIcon(showedImageInCenterImage) | async"
        />
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let menu of menus; trackBy: common.trackByIndex"
      (click)="clickToMenu(menu, showedImageInCenterImage)"
    >
      {{ menu.value }}
    </button>
  </mat-menu>
</div>
