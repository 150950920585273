import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainInfoComponent } from './@sub/@view/main-info/main-info.component';
import { HeaderComponent } from './@sub/@view/main-info/@sub/@view/header/header.component';
import { MainInfoContentComponent } from './@sub/@view/main-info/@sub/@view/main-info-content/main-info-content.component';
import { MainInfoFirstButtonsComponent } from './@sub/@view/main-info/@sub/@view/main-info-first-buttons/main-info-first-buttons.component';
import { ChooseMessengerModalWindowComponent } from './@sub/@view/main-info/@sub/@view/choose-messenger-modal-window/choose-messenger-modal-window.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CntFlexyViewCategoryComponent } from './@sub/@view/main-info/@sub/@view/header/@sub/@view/cnt-flexy-view-category/cnt-flexy-view-category.component';
import { MatMenuModule } from '@angular/material/menu';
import { CntFlexyViewHeaderMenuComponent } from './@sub/@view/main-info/@sub/@view/header/@sub/@view/cnt-flexy-view-header-menu/cnt-flexy-view-header-menu.component';
import { FeatureCntFlexyViewModule } from '@cnt-nx-workspace/feature/cnt-flexy-view';
import { OverlayModule } from '@angular/cdk/overlay';
import { MzScreenDetectorModule } from 'mz-screen-detector';
import { NbContextMenuModule } from '@nebular/theme';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    MatDialogModule,
    FeatureCntFlexyViewModule,
    MatMenuModule,
    MzScreenDetectorModule,
    NbContextMenuModule,
  ],
  exports: [
    MainInfoComponent,
    MainInfoFirstButtonsComponent,
    // ChoosePhotoAnZamkiComponent,
    // FooterComponent,
    HeaderComponent,
    MainInfoContentComponent,
    // PortfilioComponent,
    // SubjectComponent,
    ChooseMessengerModalWindowComponent,
    CntFlexyViewCategoryComponent,
    FeatureCntFlexyViewModule,
    CntFlexyViewHeaderMenuComponent,
  ],
  declarations: [
    MainInfoComponent,
    MainInfoFirstButtonsComponent,
    // ChoosePhotoAnZamkiComponent,
    // FooterComponent,
    HeaderComponent,
    MainInfoContentComponent,
    // PortfilioComponent,
    // SubjectComponent,
    ChooseMessengerModalWindowComponent,
    CntFlexyViewCategoryComponent,
    CntFlexyViewHeaderMenuComponent,
  ],
  entryComponents: [ChooseMessengerModalWindowComponent],
})
export class MainInfoModule {}
