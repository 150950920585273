import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FrfFieldUploadComponent } from '../frf-field-upload/frf-field-upload.component';
import { FrfValueSetterCustomMenuService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/@sub/frf-value-setter-custom-menu/frf-value-setter-custom-menu.service';
import { take } from 'rxjs/operators';
import { FreeformService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/freeform.service';
import { FrfInitialValueService } from '../../../../../../../../../../../../../../@service/frf-initial-value/frf-initial-value.service';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { FrfFileUploadService } from '../frf-field-upload/@sub/@service/frf-file-upload.service';
import { CameraService } from '../../../../../../../../../../../../../../../../../../../../../apps/main/cnt-main/src/app/@res/@module/camera/@sub/@service/camera.service';

@Component({
  selector: 'frf-field-camera',
  templateUrl: './frf-field-camera.component.html',
  styleUrls: ['./frf-field-camera.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrfFieldCameraComponent extends FrfFieldUploadComponent
  implements OnInit {
  constructor(
    private common_: FreeformCommonService,
    private valueService_: FrfValueSetterService,
    private frfFileUploadService_: FrfFileUploadService,
    private sanitizer_: DomSanitizer,
    private freeformService_: FreeformService,
    private cnt_: ConnectAuthService,
    private camera: CameraService,
    private frfValueSetterCustomMenu_: FrfValueSetterCustomMenuService,
    private frfInitialValueService_: FrfInitialValueService,
    private cdRef_: ChangeDetectorRef
  ) {
    super(
      common_,
      valueService_,
      frfFileUploadService_,
      sanitizer_,
      freeformService_,
      cnt_,
      frfValueSetterCustomMenu_,
      frfInitialValueService_,
      cdRef_
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * take snapshot
   * */
  public takeSnapshot() {
    this.camera
      .takeJpegPhoto()
      .pipe(take(1))
      .subscribe(result => {
        if (result && result.status && result.data.length) {
          this.uploadByFileList(
            <any>result.data.map((blob, idx) => {
              return this.blobToFile(blob, `screen-${idx + 1}.jpg`);
            }),
            true
          );
        }
      });
  }

  /**
   * blob to file
   * */
  private blobToFile(blob: Blob, fileName: string): File {
    const b: any = blob;
    /* A Blob() is almost a File() - it's just missing the two properties below which we will add */
    b.lastModifiedDate = new Date();
    b.name = fileName;

    /* Cast to a File() type */
    return <File>blob;
  }

  /**
   *
   * */
  public getAccessToUploadFile(): boolean {
    return (
      this.field &&
      this.field.body &&
      this.field.body.payload &&
      this.field.body.payload.accessToUpload
    );
  }
}
