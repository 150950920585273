export enum CntPubSubMessageTypeEnum {
  sharepayBankPurchase = 'sharepay-bank-purchase',
  chat = 'chat-base',
  chatMessage = 'chat-message'
}

export enum CntPubSubMessageOperationTypeEnum {
  add = 0,
  update = 1,
  delete = 2
}
