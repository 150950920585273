import { CntModuleApiInterface } from './@res/@abstract/@interface/common.interface';

/**
 *
 * */
export class CntModuleApi implements CntModuleApiInterface {
  /**
   *
   * */
  public readonly host: HTMLElement;

  /**
   *
   * */
  private readonly root: HTMLElement;

  constructor(host: any, root: any) {
    this.host = host;
    this.root = root;
  }

  /**
   *
   * */
  public rootHide() {
    this.setStyle(this.root, { display: 'none' });
  }

  /**
   *
   * */
  public setOverflow(el: HTMLElement, add: boolean) {
    this.setStyle(el, { overflow: add ? 'hidden' : null });
  }

  /**
   *
   * */
  public setStyle(el: HTMLElement, params: { [key: string]: any }) {
    if (el) {
      Object.keys(params).forEach(key => {
        el.style[key] = params[key];
      });
    }
  }

  /**
   *
   * */
  public rootShow() {
    this.setStyle(this.root, { display: null });
    // if (this.root) {
    //   // @ts-ignore
    //   this.root.style.display = null;
    // }
  }

  /**
   *
   * */
  public show() {
    this.setStyle(this.host, { display: null });
    // if (this.host) {
    //   // @ts-ignore
    //   this.host.style.display = null;
    // }
  }

  /**
   *
   * */
  public hide() {
    this.setStyle(this.host, { display: 'none' });
    // if (this.host) {
    //   // @ts-ignore
    //   this.root.style.display = 'none';
    // }
  }

  /**
   *
   * */
  public close() {
    if (this.host) {
      this.host.remove();
      this.rootShow();
    }
  }
}
