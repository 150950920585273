import { Injectable } from '@angular/core';
import { FrfValueSetterTempDataStorageTypeEnum } from '../../../../../../@group:value-services/frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FreeformCommonService } from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfTriggerActionSetTempDataInterface } from '../../../@res/@abstract/@interface/common.interface';
import { FrfValueGetterService } from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionSetTempDataService {
  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfValueGetterService: FrfValueGetterService
  ) {}

  /**
   *
   * */
  public async run(
    action: FrfTriggerActionSetTempDataInterface,
    startElement: any,
    scope: any = {}
  ) {
    const element = action.id
      ? this.frfValueGetterService.getFrfElementById(
          action.id,
          this.common.freeform,
          null,
          startElement
        )
      : startElement;

    const value = await this.frfValueGetterService
      .getValueWithPipe(
        action.value,
        element,
        this.common.freeform,
        element,
        scope
      )
      .toPromise();

    this.frfSharedStorage.frfValueSetter.setTempDataInElement(
      element,
      action.path,
      value,
      FrfValueSetterTempDataStorageTypeEnum.user
    );

    return true;
  }
}
