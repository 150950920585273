import { Injectable } from '@angular/core';
import { FreeformCommonService } from '../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfValueSetterInterface } from '../../../@res/@abstract/@interface/common.interface';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueSetterPayloadService implements FrfValueSetterInterface {
  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService
  ) {}

  /**
   * set loading state
   * */
  public set(data: any, path: string, element: any): void {
    const basePath = ['payload', path].filter(item => !!item).join('.');

    this.frfSharedStorage.frfValueSetter.setElementDataByPath(
      '',
      element,
      basePath,
      data,
      [],
      true,
      true,
      true,
      undefined,
      undefined,
      true
    );
  }
}
