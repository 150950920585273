import { Injectable } from '@angular/core';
import { FrfValueGetterServiceInterface } from '../@group:value-services/frf-value-getter/@res/@abstract/@interface/common.inteface';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';
import { FrfValueSetterServiceInterface } from '../@group:value-services/@res/@abstract/@interface/common.interface';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfSharedStorageService {
  /**
   *
   * */
  public frfValueSetter: FrfValueSetterServiceInterface;

  /**
   *
   * */
  public frfValueGetter: FrfValueGetterServiceInterface;

  /**
   *
   * */
  public addFrfValueSetter(
    frfValueSetter: FrfValueSetterServiceInterface
  ): void {
    this.frfValueSetter = frfValueSetter;
  }

  /**
   *
   * */
  public addFrfValueGetter(
    frfValueGetter: FrfValueGetterServiceInterface
  ): void {
    this.frfValueGetter = frfValueGetter;
  }
}
