import { Injectable } from '@angular/core';
import { CntApiInterface } from './@res/@interface/common.interface';
import { CntFlexyViewApiCommonService } from './@sub/@service/cnt-flexy-view-api-common/cnt-flexy-view-api-common.service';
import { CntFlexyViewApiMessengerService } from './@sub/@service/cnt-flexy-view-api-messenger/cnt-flexy-view-api-messenger.service';
import { CntModalApiService } from '../cnt-modal-api/cnt-modal-api.service';
import { CntModuleApiServiceInterface } from '../cnt-modal-api/@res/@class/cnt-module-api/@res/@abstract/@interface/common.interface';

@Injectable()
export class CntFlexyViewApiService implements CntApiInterface {
  /**
   * работа с модальными окнами
   * */
  public readonly modal: CntModuleApiServiceInterface;

  constructor(
    public common: CntFlexyViewApiCommonService,
    public messenger: CntFlexyViewApiMessengerService
  ) {
    this.modal = new CntModalApiService();
  }
}
