import { Injectable } from '@angular/core';
import { FrfValueGetterIfMarkEnum } from './@res/@enum/common.enum';
import { FrfValueGetterClassInterface } from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueCheckerService } from '../../../frf-value-checker/frf-value-checker.service';
import { FreeformCommonService } from '../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfValueGetterIfInterface } from './@res/@interface/common.interface';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterIfService implements FrfValueGetterClassInterface {
  constructor(
    private frfValueChecker: FrfValueCheckerService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfCommon: FreeformCommonService
  ) {}

  /**
   *
   * */
  public get(getter: FrfValueGetterIfInterface, frfElement: any, scope: any) {
    const valueFirst = this.frfSharedStorage.frfValueGetter.getValue(
        getter.valueFirst,
        frfElement,
        this.frfCommon.freeform,
        frfElement,
        scope
      ),
      valueSecond = this.frfSharedStorage.frfValueGetter.getValue(
        getter.valueSecond,
        frfElement,
        this.frfCommon.freeform,
        frfElement,
        scope
      );

    if (getter.compare) {
      const result = this.compare(getter.compare, valueFirst, valueSecond);

      return result;
    } else if (getter.checker) {
      return this.frfValueChecker.check(getter.valueFirst, getter.checker);
    }
  }

  /**
   *
   * */
  private compare(
    compare: FrfValueGetterIfMarkEnum,
    valueFirst: any,
    valueSecond: any
  ): boolean {
    switch (compare) {
      case FrfValueGetterIfMarkEnum.isEqual:
        return valueFirst === valueSecond;

      case FrfValueGetterIfMarkEnum.isNotEqual:
        return valueFirst !== valueSecond;

      case FrfValueGetterIfMarkEnum.isMore:
        return valueFirst > valueSecond;

      case FrfValueGetterIfMarkEnum.isLess:
        return valueFirst < valueSecond;

      case FrfValueGetterIfMarkEnum.isLessOrEqual:
        return valueFirst <= valueSecond;

      case FrfValueGetterIfMarkEnum.isMoreOrEqual:
        return valueFirst >= valueSecond;
    }

    return false;
  }
}
