import { Injectable } from '@angular/core';
import { DbAbstractClass } from '../../@res/@class/db.abstract';
import { CntChatDbInterface } from '@cnt-multi-shared/@shared/chat/@res/@abstract/@interface/common.interface';
import { CntPubSubMessageService } from '@cnt-nx-workspace/feature/cnt-pub-sub-message';

@Injectable({
  providedIn: 'root'
})
export class ChatDbService extends DbAbstractClass<CntChatDbInterface> {
  constructor(public cntPubSubMessageService: CntPubSubMessageService) {
    super('chat', 'n', cntPubSubMessageService);
  }
}
