export enum UserTypeFirestoreEnum {
  simple = 1,
  service = 2,
  app = 3
}

export enum VerificationTypeFirestoreEnum {
  none = 0,
  success = 1,
  error = 2
}

export enum LangFirestoreEnum {
  ru = 'ru'
}

export enum CountryFirestoreEnum {
  russia = 'Russia'
}

export enum UserStatusFirestoreEnum {
  enable = 0,
  disable = 1
}
