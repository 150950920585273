import { Injectable } from '@angular/core';
import {
  FormsActionInterface,
  FrfFieldChangesEmitInterface,
  FrfInitOptions,
  FrfRootValuesInterface
} from './@res/@abstract/@interface/common.interface';
import {
  FrfEmitStateEnum,
  FrfFormActionTypeEnum
} from './@res/@abstract/@enum/common.enum';
import { PathToFieldValuesForEmitType } from './@res/@abstract/@type/common.type';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FrfStorageService } from '../@storage/frf-storage/frf-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FrfRootService {
  /**
   *
   * */
  public readonly formsAction$: Subject<FormsActionInterface> = new Subject();

  /**
   * states for emit
   * */
  public statesForEmit: FrfEmitStateEnum[] = [];

  /**
   * values of field which will emit
   * */
  public pathToFieldValuesForEmit: PathToFieldValuesForEmitType[];

  /**
   * collection of elements with value
   * */
  public readonly values: FrfRootValuesInterface | any = {
    form$: { base: {} },
    fields$: {},
    fieldsWithModel$: {},
    inputParams$: {}
  };

  /**
   * emit when field changes if state and path right
   * */
  public fieldChanges$: Subject<FrfFieldChangesEmitInterface> = new Subject();

  /**
   * emit when form submited
   * */
  public onSubmit$: Subject<FrfRootValuesInterface> = new Subject();

  /**
   * emit when field changes if state and path right
   * */
  public inputParams: { [formId: string]: any } = {};

  /**
   * need for access var in frf-value-getters
   * */
  public cntVar$: {
    sharepayBankOwner?: string;
  } = {};

  constructor(private frfStorageService: FrfStorageService) {}

  /**
   * set states for emit
   * */
  public setStates(...states: FrfEmitStateEnum[]) {
    this.statesForEmit = states;
  }

  /**
   * TODO add later multiform
   * set input params for form id
   * */
  public setInputParams(
    params: { [key: string]: any },
    formId: string = 'base'
  ) {
    this.inputParams[formId] = params;
  }

  /**
   * set states for emit
   * */
  public setPathToFieldValuesForEmit(...path: PathToFieldValuesForEmitType[]) {
    this.pathToFieldValuesForEmit = path;
  }

  /**
   * active emitter
   * */
  public activeEmitter(
    states: FrfEmitStateEnum[],
    path: PathToFieldValuesForEmitType[] = ['value']
  ) {
    this.setStates(...states);
    this.setPathToFieldValuesForEmit(...path);
  }

  /**
   * open frf
   * */
  public openFrf(
    owner: string,
    modelId: string,
    formId: string = null,
    formCode: string = null,
    initialParams: any = null
  ) {
    if (formId) {
      this.setInputParams(
        this.safeCreateParamsWithCode(formCode, initialParams)
        /* TODO need update when we add  multiform support */
        // formId
      );
    }

    this.formsAction$.next({
      type: FrfFormActionTypeEnum.open,
      formId: formId,
      owner: owner,
      modelId: modelId
    });
  }

  /**
   * close frf
   * */
  public closeFrf(owner?: string, modelId?: string, formId: string = null) {
    this.formsAction$.next({
      type: FrfFormActionTypeEnum.close,
      formId: formId,
      owner: owner,
      modelId: modelId
    });
  }

  /**
   * lister frf on submit shotcut with code
   * */
  public listFrfOnSubmit$(
    formCode: string = null,
    forDestroy$: Subject<any> = null
  ): Observable<FrfRootValuesInterface> {
    let flow$: Observable<any> = this.onSubmit$;

    if (forDestroy$) {
      flow$ = flow$.pipe(takeUntil(forDestroy$));
    }

    return flow$.pipe(
      filter(item => {
        if (formCode) {
          return this.formHasCode(formCode, item);
        }

        return true;
      })
    );
  }

  /**
   * get code [if exist] from form
   * */
  public getFormCode(frf: FrfRootValuesInterface): string | null {
    return (
      (frf.inputParams$ &&
        frf.inputParams$.base &&
        frf.inputParams$.base.formCode$) ||
      null
    );
  }

  /**
   * check form code is equal to passed code [if exist] from form
   * */
  public formHasCode(code: string, frf: FrfRootValuesInterface): boolean {
    return this.getFormCode(frf) === code;
  }

  /**
   * list frf open/close actions
   * */
  public listenFormsAction$(
    forDestroy$: Subject<any> = null
  ): Observable<FormsActionInterface> {
    if (forDestroy$) {
      return this.formsAction$.pipe(takeUntil(forDestroy$));
    } else {
      return this.formsAction$;
    }
  }

  /**
   * safe create params with code for add to frf from front code
   * */
  private safeCreateParamsWithCode(
    formCode: string = null,
    initialParams: any = null
  ): any {
    let params: any = {};

    if (initialParams) {
      params = { ...initialParams };
    }

    if (formCode) {
      params.formCode$ = formCode;
    }

    return params;
  }

  public initOptions(params: FrfInitOptions) {
    this.frfStorageService.setGoogleStorageParams(params.fileStorage);
  }
}
