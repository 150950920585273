import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { MainSharedService } from '../../@service/main-shared/main-shared.service';
import {
  BodyFlexTableInterface,
  DisplayedColumnsInterface,
  OptionsFlexTableInterface
} from './@res/@abstract/@interface/common.interface';
import { MzColumnDefDirective } from './@sub/@directive/mz-columng-def/mz-column-def.directive';
import { MzHeaderDefDirective } from './@sub/@directive/mz-header-def/mz-header-def.directive';
import { MzExtraRowViewDirective } from './@sub/@directive/mz-extra-row-view/mz-extra-row-view.directive';
import { CntTableFlexAutoWidthService } from './@sub/@service/cnt-table-flex-auto-width/cnt-table-flex-auto-width.service';
import { take } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'cnt-table-flex',
  templateUrl: './cnt-table-flex.component.html',
  styleUrls: ['./cnt-table-flex.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'cntTableFlex',
  providers: [CntTableFlexAutoWidthService]
})
export class CntTableFlexComponent
  implements
    OnInit,
    AfterContentInit,
    AfterViewInit,
    AfterViewChecked,
    OnChanges,
    OnDestroy {
  @ContentChildren(MzColumnDefDirective) bodyColumns!: QueryList<
    MzColumnDefDirective
  >;
  @ContentChildren(MzHeaderDefDirective) headerColumns!: QueryList<
    MzHeaderDefDirective
  >;
  @ContentChildren(MzExtraRowViewDirective) extraView!: QueryList<
    MzExtraRowViewDirective
  >;

  /**
   *
   * */
  @Input() changeExpandStateMethod: (
    element: any,
    state: boolean,
    expandedElements: any[]
  ) => any[] = (element: any, state: boolean, expandedElements: any[]) => {
    expandedElements = [];

    if (state) {
      expandedElements.push(element);
    } else {
      expandedElements = this.expandedElements.filter(item => item !== element);
    }

    return expandedElements;
  };

  /*
   * опции основыне настройки
   * */
  @Input() options: OptionsFlexTableInterface = {
    headerColumnDraggable: true,
    bodyRowDraggable: true
  };

  @Input() trackBy: (idx: number, val: any) => any = (
    idx: number,
    val: any
  ) => {
    return idx;
  };

  @Input() trackByColumn: (
    idx: number,
    val: DisplayedColumnsInterface
  ) => any = (idx: number, val: any) => {
    return val.key;
  };

  /*
   * not finished table building style
   * */
  @Input() type = 'row'; // row

  /**
   * если нужно добавить полностью новые стили добавляем другой класс
   * */
  @Input() class = 'default';

  /**
   * добавление класса хосту
   * */
  @HostBinding(`class`)
  get getStyle() {
    return this.class;
  }

  /**
   * добавление ид хосту (для установления стилей)
   * */
  @HostBinding(`id`)
  get getId() {
    return this.cntTableFlexAutoWidth.getTableId();
  }

  /**
   * состояние для скрытия/показа колонок и показаа до
   * */
  @Input() state: string[] = ['comp'];

  /**
   * функция даюшая право на ператаскивание строк
   * */
  @Input() dropListRowEnterPredicate: (
    drag: CdkDrag,
    drop: CdkDropList
  ) => boolean = () => true;

  /**
   * функция даюшая право на ператаскивание колонок
   * */
  @Input() dropListColumnEnterPredicate: (
    drag: CdkDrag,
    drop: CdkDropList
  ) => boolean = () => true;

  /**
   * основные данные с настройками
   * */
  @Input() rows: BodyFlexTableInterface[];

  /**
   *
   * */
  @Input() loaderRowsData: {
    row: BodyFlexTableInterface;
    repeat?: number;
    mainDataLoadingMinTime?: number;
    templateDataLoadingMinTime: number;
  };

  /**
   * данные колонок с настройками
   * */
  @Input() columns: DisplayedColumnsInterface[];

  /**
   * ид формы для добавление динамических стилей
   * */
  @Input() id?: string = Math.random() + '';

  /*
   * колонки которые нужно показать
   * */
  public columnsForShow: DisplayedColumnsInterface[];

  /**
   * templates row
   * */
  public rowTemplates: { [key: string]: TemplateRef<any> } = {};

  /**
   * states which has templates
   * */
  public statesWithTemplates: string[] = [];

  /**
   * filtered states with template
   * */
  public filteredStatesWithTemplate: string[] = [];

  /**
   * header column row
   * */
  public headerColumnTemplates: { [key: string]: TemplateRef<any> } = {};

  /**
   * extra view tamplates
   * */
  public extraViewTemplates: { [key: string]: TemplateRef<any> } = {};

  /*
   * symbol for choose all
   * */
  public readonly symbolAll: string = '*';

  /**
   *
   * */
  public loaderRows: BodyFlexTableInterface[] = [];

  /**
   * sho
   * */
  public showMainData = false;

  /**
   * sho
   * */
  public showTemplateData = false;

  /**
   * sho
   * */
  public hideContent = false;

  /**
   * for destroy streams$
   * */
  private alive = true;

  /**
   *
   * */
  public expandedElements: any[] = [];

  /**
   *
   * */
  // private addedStyle$: Subject<void> = new Subject();

  constructor(
    public mainShared: MainSharedService,
    public cdRef: ChangeDetectorRef,
    public zone: NgZone,
    public cntTableFlexAutoWidth: CntTableFlexAutoWidthService
  ) {}

  ngOnInit() {
    this.initDisplayedColumns();

    /**
     * set postfix for add/remove dynamic style
     * */
    this.cntTableFlexAutoWidth.setStylePostFix(this.id || Math.random() + '');

    this.initLoader();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['state'] && !changes['state'].firstChange) ||
      (changes['displayedColumns'] && !changes['displayedColumns'].firstChange)
    ) {
      this.cntTableFlexAutoWidth.correctWidthNeedPass = true;

      this.renderTable();
    }
  }

  ngAfterContentInit(): void {
    /*
     * add rows templates
     * */
    if (this.bodyColumns) {
      this.bodyColumns.forEach(rowDef => {
        this.rowTemplates[rowDef.mzColumnDef] = rowDef.template;
      });
    }

    /*
     * add header of columns
     * */
    if (this.headerColumns) {
      this.headerColumns.forEach(columnDef => {
        this.headerColumnTemplates[columnDef.mzHeaderDef] = columnDef.template;
      });
    }

    /*
     * add templates of extra
     * */
    if (this.extraView) {
      this.extraView.forEach(extraView => {
        this.extraViewTemplates[extraView.mzExtraRowView] = extraView.template;

        /*
         * states with templates
         * */
        this.statesWithTemplates.push(extraView.mzExtraRowView);
      });

      /*
       * update filtered states with template
       * */
      this.filteredStatesWithTemplate = this.statesWithTemplates.filter(
        state => {
          return this.state.indexOf(state) !== -1;
        }
      );
    }
  }

  ngAfterViewChecked(): void {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.alive = false;
    this.cntTableFlexAutoWidth.clearAddedStyles();
  }

  /**
   *
   * */
  public renderTable() {
    /*
     * TODO move value-formater from template to render function
     * TODO add default sortable function
     * TODO move default icons to svg
     * */
    this.cntTableFlexAutoWidth.clearAddedStyles();

    this.initLoader();

    this.initDisplayedColumns();

    /*
     * update filtered states with template
     * */
    this.filteredStatesWithTemplate = this.statesWithTemplates.filter(state => {
      const result = this.state.indexOf(state) !== -1;
      return result;
    });

    this.cdRef.markForCheck();
  }

  /**
   * loader (show template while get data)
   * */
  private initLoader() {
    if (this.loaderRowsData && this.loaderRowsData.row) {
      const repeat = this.loaderRowsData.repeat || 10,
        mainDataLoadingMinTime =
          this.loaderRowsData.mainDataLoadingMinTime || 1000,
        templateDataLoadingMinTime =
          this.loaderRowsData.templateDataLoadingMinTime || 1000;

      this.loaderRows = [];
      this.showMainData = false;
      this.showTemplateData = true;
      this.hideContent = true;

      for (let i = 0; i < repeat; i++) {
        this.loaderRows.push(this.loaderRowsData.row);
      }

      timer(templateDataLoadingMinTime).subscribe(() => {
        this.hideContent = false;
        // this.showMainData = false;
        this.cdRef.markForCheck();
      });

      this.cntTableFlexAutoWidth.addStyleByText$.pipe(take(1)).subscribe(() => {
        timer(mainDataLoadingMinTime).subscribe(() => {
          this.showTemplateData = false;
          this.showMainData = true;
          this.cdRef.markForCheck();
        });
      });
    } else {
      this.showMainData = true;
      this.cdRef.markForCheck();
    }
  }

  /**
   * когда отпустили строку при перетаскивании элемент, добавляем массив
   * */
  public dropBodyRow(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
  }

  /**
   * меняем местами колонки
   * */
  private swapPlaceOfColumns(
    firstKey: string,
    secondKey: string,
    group: string
  ) {
    const previousColumns = this.cntTableFlexAutoWidth.columns[group][
        firstKey
      ].map((column: any, idx: number) => {
        return {
          ...column,
          el: this.cntTableFlexAutoWidth.columns[group][secondKey][idx].el
        };
      }),
      currentColumns = this.cntTableFlexAutoWidth.columns[group][secondKey].map(
        (column: any, idx: number) => {
          return {
            ...column,
            el: this.cntTableFlexAutoWidth.columns[group][firstKey][idx].el
          };
        }
      );

    this.cntTableFlexAutoWidth.columns[group][firstKey] = previousColumns;
    this.cntTableFlexAutoWidth.columns[group][secondKey] = currentColumns;
  }

  /**
   * когда отпустили колонку хедера при перетаскивании элемент, добавляем массив
   * */
  public dropHeader(event: CdkDragDrop<string[]>, group: string) {
    let keys: string[];

    if (event.previousIndex < event.currentIndex) {
      /*
       * to right
       * */
      keys = [this.columnsForShow[event.previousIndex].key];

      for (
        let firstKey = event.previousIndex;
        firstKey < event.currentIndex;
        firstKey++
      ) {
        const nextKey = firstKey + 1; //;this.getRealNextIndex(firstKey + 1);

        keys.push(this.columnsForShow[nextKey].key);

        this.swapPlaceOfColumns(
          this.columnsForShow[firstKey].key,
          this.columnsForShow[nextKey].key,
          group
        );

        moveItemInArray(this.columnsForShow, firstKey, nextKey);
      }
    } else {
      /*
       * to left
       * */
      keys = [this.columnsForShow[event.currentIndex].key];

      for (
        let firstKey = event.currentIndex;
        firstKey < event.previousIndex;
        firstKey++
      ) {
        const nextKey = firstKey + 1;

        keys.push(this.columnsForShow[nextKey].key);

        this.swapPlaceOfColumns(
          this.columnsForShow[firstKey].key,
          this.columnsForShow[nextKey].key,
          group
        );
      }

      moveItemInArray(
        this.columnsForShow,
        event.previousIndex,
        event.currentIndex
      );
    }

    /*
     * востановить изначальные стили
     * */
    this.cntTableFlexAutoWidth.restoreOldWidth(keys);
  }

  /**
   * при начале выделения
   * */
  public onStartDrag($event) {
    this.mainShared.clearSelection();
  }

  /**
   * инициализировать заголовоки
   * создать необходимые ключи
   * */
  private initDisplayedColumns() {
    this.columnsForShow = this.columns
      .filter(column => {
        /*
         * показать если передано нужное состояние или общее состояние
         * */
        column.show =
          column.state.findIndex(state => {
            return state === '*' || this.state.indexOf(state) !== -1;
          }) !== -1;

        return column.show;
      })
      .map(item => {
        /* create base data */
        if (typeof item.sortable !== 'boolean') {
          item.sortable = true;
        }

        if (typeof item.canExpand !== 'boolean') {
          item.canExpand = false;
        }

        if (typeof item.valueFormatter !== 'function') {
          item.valueFormatter = (value, key) => value;
        }

        return item;
      });
  }

  /**
   * обновить таблицу
   * */
  protected updateTable(update = true) {
    // this.initDisplayedColumns();
    //
    // /*
    // * update filtered states with template
    // * */
    // this.filteredStatesWithTemplate = this.statesWithTemplates.filter(
    //     (state) => {
    //       const result = this.state.indexOf(state) !== -1;
    //       return result;
    //     }
    // );
    //
    // if (update) {
    //   this.cdRef.markForCheck();
    // }
    //
    // this.cntTableFlexAutoWidth.correctWidth$.next();
  }

  /**
   * обновить содержимое
   * */
  public updateContent(event) {
    // this.cntTableFlexAutoWidth.correctWidth$.next();
  }

  /**
   * get current state for shot additional data
   * */
  public isCurrentState(state: string): boolean {
    return this.state.indexOf(state) !== -1;
  }

  /**
   * change expand element state
   * */
  public changeExpandElementState(element: any, state: boolean): any {
    this.expandedElements =
      typeof this.changeExpandStateMethod === 'function'
        ? this.changeExpandStateMethod(element, state, this.expandedElements)
        : [];
  }

  /**
   *
   * */
  public getExpandedStateOfElement(element: any) {
    return this.expandedElements.indexOf(element) !== -1;
  }
}
