import { Action } from '@ngrx/store';
// import {
//   AppsIdEnum,
//   ConnectAppControllerActionsEnum
// } from '../../../../../connect-app-controller.module';
import {
  AppsIdEnum,
  ConnectAppControllerActionsEnum
} from '../../../../../@res/@abstract/@enums/app/apps-id.enum';

export class SwitchAppAction implements Action {
  readonly type = ConnectAppControllerActionsEnum.appSwitchApp;
  constructor(public payload: AppsIdEnum) {}
}
