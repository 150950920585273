import { Observable, of, Subject } from 'rxjs';
import { GraphqlService } from '@cnt-nx-workspace/frf/main';
import {
  SimpleGraphqlPaginatorRequestInterface,
  SimpleGraphqlResultInterface,
  SimpleGraphqlStreamInterface,
} from './@res/@abstract/@interface/common.interface';
import { environment } from '../../../../../../../../environments/environment';
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

export abstract class AbstractSimpleGraphqlPaginatorService {
  /**
   *
   * */
  private stream$: Subject<SimpleGraphqlStreamInterface> = new Subject();

  /**
   *
   * */
  private afterTableRender$: Subject<any> = new Subject();

  /**
   *
   * */
  private lastExtraKeys: any;

  /**
   *
   * */
  public abstract readonly requests: {
    [key: string]: SimpleGraphqlPaginatorRequestInterface;
  };

  /*
   * name of graphql name (endpoint)
   * */
  public abstract readonly defaultGraphqlMethodName?: string;

  /*
   *
   * */
  public abstract readonly defaultGraphqlQuery: string;

  /*
   *
   * */
  public abstract readonly defaultGraphqlVariables: any;

  /**
   *
   * */
  public abstract readonly graphqlService: GraphqlService;

  /**
   * last used variables
   * */
  private lastUsedVariables: any;

  /**
   *
   * */
  private lastUsedRequestKey: any;

  /**
   *
   * */
  public gridApi: any;

  /**
   *
   * */
  public gridColumnApi: any;

  /**
   *
   * */
  public agGridOverlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Пожалуйста, подождите, идет загрузка данных</span>';

  /**
   *
   * */
  public agGridOverlayNoRowsTemplate =
    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">Нет данных для показа</span>';

  /**
   * */
  public getLastUsedVariables() {
    return this.lastUsedVariables;
  }

  /**
   * open page in firestore
   * */
  private loadData<T>(
    requestKey?: string,
    pageNumber?: number,
    limit?: number,
    orderKey?: string,
    orderBy?: string,
    initialExtraKeys?: { [key: string]: any }
  ): Observable<SimpleGraphqlResultInterface<T>> {
    requestKey = requestKey || this.lastUsedRequestKey;
    const extraKeys = initialExtraKeys || this.lastExtraKeys;
    this.lastExtraKeys = initialExtraKeys;

    const query = this.getGraphqlQueryByRequestKey(requestKey);

    return <any>(
      this.graphqlService
        .query(
          environment.domain.graphqlDomain,
          query,
          this.defaultGraphqlMethodName,
          undefined,
          this.getGraphqlVariablesByRequestKey(
            requestKey,
            pageNumber,
            limit,
            orderKey,
            orderBy,
            extraKeys
          )
        )
        .pipe(
          map((result) => this.getResultFromResponse(result)),
          tap(() => {
            this.loadingOverlay(false);
          }),
          catchError((error) => {
            console.error('graphql paginatoor error', error);

            return of({
              status: 0,
              isNextPage: false,
              isPreviousPage: false,
              data: null,
              error: null,
            });
          })
        )
    );
  }

  /**
   *
   * */
  public initKey(requestKey: string) {
    this.lastUsedRequestKey = requestKey;
  }

  /**
   *
   * */
  public getGraphqlQueryByRequestKey(key: string): string {
    return (
      (this.requests &&
        this.requests[key] &&
        this.requests[key].graphqlQuery) ||
      this.defaultGraphqlQuery
    );
  }

  /**
   *
   * */
  public getGraphqlVariablesByRequestKey(
    key: string,
    pageNumber?: number,
    limit?: number,
    orderKey?: string,
    orderBy?: string,
    extraKeys?: { [key: string]: any }
  ): any {
    const requestVariables =
        (this.requests &&
          this.requests[key] &&
          this.requests[key].graphqlVariables) ||
        {},
      mainVariables = this.defaultGraphqlVariables || {};

    this.lastUsedVariables = {
      ...mainVariables,
      ...requestVariables,
      ...this.getPageNumberWithLimit(pageNumber, limit, orderKey, orderBy),
      ...(extraKeys || {}),
    };

    // this.lastExtraKeys = extraKeys;

    console.log('getGraphqlVariablesByRequestKey - ', this.lastUsedVariables, {
      mainVariables,
      requestVariables,
      extraKeys,
    });

    return this.lastUsedVariables;
  }

  /**
   * get page number and limit
   * can override
   * */
  public getPageNumberWithLimit(
    pageNumber?: number,
    limit?: number,
    orderKey?: string,
    orderBy?: string
  ) {
    const result: any = {};

    if (typeof limit === 'number') {
      result.limit = limit;
    }

    if (typeof pageNumber === 'number') {
      result.pageNumber = pageNumber;
    }

    if (typeof orderKey === 'string') {
      result.orderKey = orderKey;
    }

    if (typeof orderBy === 'string') {
      result.orderBy = orderBy;
    }

    return result;
  }

  /**
   * get result from response
   * can override
   * */
  public getResultFromResponse(result: {
    status: number;
    isNextPage: boolean;
    isPreviousPage: boolean;
    data: any;
    error: any;
  }): any {
    return {
      data: (result && result.data) || [],
      isNextPage: !!(result && result.isNextPage),
      isPreviousPage: !!(result && result.isPreviousPage),
    };
  }

  /**
   *
   * */
  public reSortRequest(orderKey: string, orderBy?: string) {
    this.updateRequest(undefined, undefined, undefined, orderKey, orderBy);
  }

  /**
   * disable sorting
   * */
  public disableSort() {
    this.updateRequest(undefined, undefined, undefined, null, null);
  }

  /**
   *
   * */
  public updatePage(pageNumber?: number, limit?: number) {
    this.updateRequest(undefined, pageNumber, limit, undefined, undefined);
  }

  /**
   *
   * */
  public updateRequest(
    requestKey?: string,
    pageNumber?: number,
    limit?: number,
    orderKey?: string,
    orderBy?: string,
    extraKeys?: { [key: string]: any }
  ) {
    this.loadingOverlay(true);

    const data = {
      requestKey: requestKey || this.lastUsedRequestKey,
      pageNumber: this.getFromLastVariablesIfNeed('pageNumber', pageNumber),
      limit: this.getFromLastVariablesIfNeed('limit', limit),
      orderKey: this.getFromLastVariablesIfNeed('orderKey', orderKey),
      orderBy: this.getFromLastVariablesIfNeed('orderBy', orderBy),
      extraKeys: extraKeys || this.lastExtraKeys || null,
    };

    console.log('updateRequest - data', data);

    this.stream$.next(data);
  }

  /**
   *
   * */
  public updateRequestByObj(data: {
    requestKey?: string;
    pageNumber?: number;
    limit?: number;
    orderKey?: string;
    orderBy?: string;
    extraKeys?: { [key: string]: any };
  }) {
    this.updateRequest(
      data.requestKey,
      data.pageNumber,
      data.limit,
      data.orderKey,
      data.orderBy,
      data.extraKeys
    );
  }

  /**
   *
   * */
  public cancel() {
    this.updateRequest(undefined, 1, undefined, undefined, undefined, {});
  }

  public clear() {
    this.getGraphqlVariablesByRequestKey(
      undefined,
      1,
      undefined,
      undefined,
      undefined,
      {}
    );
    this.lastExtraKeys = {};
  }

  /**
   *
   * */
  public getFromLastVariablesIfNeed(key: string, value?: any) {
    return value === undefined
      ? this.lastUsedVariables && this.lastUsedVariables[key]
      : value;
  }

  /**
   *
   * */
  public getStream$<T>(): Observable<SimpleGraphqlResultInterface<T>> {
    return <any>(
      this.stream$.pipe(
        mergeMap(
          (result) =>
            this.loadData(
              result.requestKey,
              result.pageNumber,
              result.limit,
              result.orderKey,
              result.orderBy,
              result.extraKeys
            ),
          1
        )
      )
    );
  }

  /**
   *
   * */
  public getAfterTableRender$(
    viewDestroy$: Subject<any>,
    debounce: number
  ): Observable<any> {
    return this.afterTableRender$.pipe(
      takeUntil(viewDestroy$),
      debounceTime(debounce)
    );
  }

  /**
   *
   * */
  public tableCallChanged(): void {
    this.afterTableRender$.next();
  }

  /**
   *
   * */
  public setAgGridApi(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /**
   *
   * */
  public loadingOverlay(show: boolean) {
    if (show) {
      // clear all overlays
      if (typeof this.gridApi?.showLoadingOverlay === 'function') {
        this.gridApi.showLoadingOverlay();
      }
    } else {
      // clear all overlays
      if (typeof this.gridApi?.hideOverlay === 'function') {
        this.gridApi.hideOverlay();
      }
    }
  }
}
