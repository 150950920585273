import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as ProgressBar from 'progressbar.js';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observer, of } from 'rxjs';
import { FrfValueSetterCustomMenuService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/@sub/frf-value-setter-custom-menu/frf-value-setter-custom-menu.service';
import { FrfInitialValueService } from '../../../../../../../../../../../../../../@service/frf-initial-value/frf-initial-value.service';
import { FrfUploadFieldElementListInterface } from './@res/@abstract/@interface/common.interface';
import { FrfValueSetterCustomMenuInterface } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/@sub/frf-value-setter-custom-menu/@res/@abstract/@interface/common.interface';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { FreeformService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/freeform.service';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { FrfValueSetterCustomMenuSystemTypeEnum } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/@sub/frf-value-setter-custom-menu/@res/@abstract/@enum/common.enum';
import { FrfFileUploadService } from './@sub/@service/frf-file-upload.service';
import { takeUntil } from 'rxjs/operators';
import { FreeformCommonNamespace } from '@cnt-multi-shared/@shared/freeform/freeform.common';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'frf-field-upload',
  templateUrl: './frf-field-upload.component.html',
  styleUrls: ['./frf-field-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrfFieldUploadComponent extends AbstractUnsubscribeViewControl
  implements OnInit, AfterViewInit {
  // required
  @Input('fieldid') fieldid;
  @Input('objid') objid;
  @Input('freeform') frf;
  @Input('disabled') disabled;

  //optional
  @Input('multi') multi: boolean = false;

  /**
   * block for show images
   * */
  private viewImages$: { [id: string]: BehaviorSubject<any> } = {};

  /* this field from freeform object */
  public field;

  /* later add multiple */
  public multiple;

  /* create angular reactive form */
  public form = new FormGroup({
    field: new FormControl('')
  });

  /*
   * showed image
   * */
  public showedImageInCenterImage: FrfUploadFieldElementListInterface = null;

  public noneImageIcon;

  public dropzoneStateStatus = false;

  public fieldUploadedList: FrfUploadFieldElementListInterface[] = [];

  public fieldUploadingLocalList = [];
  public fieldUploadingLocalListIcons = {};

  constructor(
    public common: FreeformCommonService,
    private frfValueSetter: FrfValueSetterService,
    private frfFileUploadService: FrfFileUploadService,
    private sanitizer: DomSanitizer,
    private freeformService: FreeformService,
    private cnt: ConnectAuthService,
    private frfValueSetterCustomMenu: FrfValueSetterCustomMenuService,
    private frfInitialValueService: FrfInitialValueService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.field = this.frf.fields[this.fieldid].objects[this.objid];
    this.multi = this.multi || false;

    this.multiple = this.field.body.payload.multiple;
    this.noneImageIcon = this.frfFileUploadService.getNoneIconImagePath();

    /* get images list */
    this.fieldUploadedList = Array.isArray(this.field.body.payload.options)
      ? this.field.body.payload.options
      : [];

    this.initCustomMenus();
    this.syncInitialStatus();
  }

  ngAfterViewInit(): void {
    /*
     * set if need inital value
     * */
    this.frfInitialValueService.setIfNeedInitialValueIfItExist(
      this.field,
      this.frf,
      {}
    );

    this.watchFieldChanges();
  }

  /**
   *
   * */
  private syncInitialStatus() {
    this.setThisFieldStatus(this.fieldUploadedList.length > 0);
  }

  /**
   * watch field changes
   * */
  private watchFieldChanges() {
    this.frfValueSetter
      .watchFieldChanges(this.fieldid, this.objid)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(() => {
        this.initCustomMenus();

        /* update images if passed */
        if (Array.isArray(this.field.body.payload.options)) {
          /* get images list */
          this.fieldUploadedList = this.field.body.payload.options || [];

          // if (this.fieldUploadedList.length )
          this.setThisFieldStatus(this.fieldUploadedList.length > 0);
        }

        this.cdRef.markForCheck();
      });
  }

  /**
   * @deprecated
   * */
  public getImageIconOld(
    uploadedEl: { id: string; url: string; path: string; private: boolean },
    iNfile: File = null
  ) {
    // console.log('getImageIcon - iNuploadedEl', uploadedEl);
    if (this.fieldUploadingLocalListIcons[uploadedEl['id']]) {
      return this.fieldUploadingLocalListIcons[uploadedEl['id']];
    }
    // we have not get image yet
    new Promise(async () => {
      let url;
      if (!iNfile) {
        // it is not blob file
        url = await this.frfFileUploadService.getFileIconByUrl(
          uploadedEl['path'],
          this.field
        );
      } else if (uploadedEl) {
        url = await this.frfFileUploadService.getFileIconByFile(
          iNfile,
          this.field
        );
      }
      this.fieldUploadingLocalListIcons[uploadedEl['id']] = url;
    }).then();

    return this.noneImageIcon;
  }

  /**
   * получить ссылку на изображение с кэшированием для избежания повторных запросов
   * */
  public getImageIcon(
    uploadedEl: FrfUploadFieldElementListInterface,
    file: File = null
  ) {
    const viewImages$: { [id: string]: BehaviorSubject<any> } = this
      .viewImages$;

    if (!viewImages$[uploadedEl.id]) {
      viewImages$[uploadedEl.id] = new BehaviorSubject(this.noneImageIcon);

      of(null).subscribe(async (observer: Observer<any>) => {
        let url;

        if (uploadedEl.thirdPartyUrl) {
          /* if is third  party  url  > simple return url */
          url = uploadedEl.url;
        } else if (!file) {
          /* it is not blob file */
          url = await this.frfFileUploadService.getFileIconByUrl(
            uploadedEl.path,
            this.field
          );
        } else if (uploadedEl) {
          url = await this.frfFileUploadService.getFileIconByFile(
            file,
            this.field
          );
        }

        this.fieldUploadingLocalListIcons[uploadedEl['id']] = url;

        viewImages$[uploadedEl.id].next(url);
      });
    }

    return viewImages$[uploadedEl.id];
  }

  /**
   *
   * */
  public dropzoneState($event: boolean) {
    this.dropzoneStateStatus = $event;
  }

  /**
   *
   * */
  public uploadByFileList(iNfileList: FileList | any, isFileArray = false) {
    if (iNfileList.length > 0) {
      for (let i = 0; i < iNfileList.length; i++) {
        const file = isFileArray ? iNfileList[i] : iNfileList.item(i);

        //generate file id
        const fileId = this.frfFileUploadService.generateFileId(),
          cssid = `f-pb-${fileId}`;

        //get file icon && add blob url to local object (later need for except file download)
        // @ts-ignore
        const icon = this.getImageIcon({ id: fileId }, file);
        // add address to blob (for save not downloaded file)
        this.fieldUploadingLocalListIcons[fileId] = icon;
        //create local block
        this.fieldUploadingLocalList.push({
          fileId: fileId,
          url: this.frfFileUploadService.getLocalUrlByFile(file),
          icon: icon,
          progress: 0
        });
        const indexInTempArray = this.fieldUploadingLocalList.length - 1;

        let bar;
        //**LATER delete timeout <- create html element from code not angular
        setTimeout(() => {
          // console.log('cssid', cssid, document.getElementById(cssid));
          bar = new ProgressBar.Circle(document.getElementById(cssid), {
            color: '#2d77ff',
            trailColor: '#eee',
            trailWidth: 1,
            duration: 1400,
            easing: 'bounce',
            strokeWidth: 18,
            text: {
              autoStyleContainer: false
            },
            from: { color: '#2d77ff', a: 0 },
            to: { color: '#ED6A5A', a: 1 },
            // Set default step function for all animate calls
            step: function(state, circle) {
              circle.path.setAttribute('stroke', state.color);

              const value = Math.round(circle.value() * 100);
              if (value === 0) {
                circle.setText('');
              } else {
                circle.setText(value);
              }
            }
          });

          const pathToFile = FreeformCommonNamespace.getDownloadPathToFieldForFistore(
            this.freeformService.formInfo.uid,
            this.freeformService.formInfo.modelId,
            this.freeformService.formInfo.formId,
            this.objid,
            this.cnt.uid,
            fileId,
            iNfileList[0].name
          );

          this.frfFileUploadService
            .uploadFile(this.field, pathToFile, iNfileList[0], fileId)
            .subscribe(result => {
              if (result['url']) {
                console.log('frfFileUploadService uploadFile - ', result);
                // destroy uploader
                bar.destroy();

                /* delete */
                this.fieldUploadingLocalList = this.fieldUploadingLocalList.filter(
                  file_ => file_.fileId !== fileId
                );

                /* add to ready -> save to db */
                this.fieldUploadedList.push({
                  id: fileId,
                  url: result['url'],
                  private: this.frfFileUploadService.isPrivateStorage(
                    result['path'],
                    this.field
                  ),
                  path: result['path']
                });

                /* add to db */
                this.frfValueSetter.setFieldPayloadOptions(
                  this.field,
                  this.fieldUploadedList
                );

                /* set status true if not already true status */
                if (this.fieldUploadedList.length > 0) {
                  // && !this.field.body.value
                  // We uploaded files -> set value true if not true yet
                  /* TODO public domain add */
                  const lastUploadedUrl = this.frfFileUploadService.getUrlForPrivateOrPublic(
                    result['path'],
                    this.field
                  );

                  /**
                   * set value for this field
                   * */
                  this.setThisFieldStatus(true);
                }
              } else if (result['percent']) {
                bar.animate(result['percent']);
              }
            });
        }, 100);
      }
    }
  }

  /**
   *
   * */
  private setThisFieldStatus(status: boolean) {
    this.frfValueSetter.setFieldValue<boolean>(
      this.field,
      status,
      ['onChange', 'onUpload'],
      false,
      'boolean',
      undefined,
      undefined,
      false
    );
  }

  /**
   *
   * */
  public handleDrop(iNfileList: FileList) {
    this.uploadByFileList(iNfileList);
  }

  /**
   *
   * */
  public onChange(iNfileList: FileList) {
    // upload this image
    this.uploadByFileList(iNfileList);
  }

  /**
   *
   * */
  public delImage(iNindex) {
    this.fieldUploadedList.splice(iNindex, 1);
    this.frfValueSetter.setFieldPayloadOptions(
      this.field,
      this.fieldUploadedList
    );

    if (this.fieldUploadedList.length < 1) {
      // if we deleted all uploaded files -> set value null
      this.frfValueSetter.setFieldValue(this.field, '');
    }
  }

  /**
   *
   * */
  public openImageInModalWindow(
    upload: FrfUploadFieldElementListInterface
  ): void {
    this.showedImageInCenterImage = upload;
  }

  private systemCustomMenus: FrfValueSetterCustomMenuInterface[] = [
    {
      value: 'Закрыть',
      key: FrfValueSetterCustomMenuSystemTypeEnum.closeModal
    }
    // {
    //     value: 'Сканировать паспорт',
    //     key: 'recognize-passport'
    // }
    // {
    //     value: 'Удалить',
    //     key: FrfValueSetterCustomMenuSystemTypeEnum.deleteFile
    // }
  ];

  public menus: FrfValueSetterCustomMenuInterface[] = [];

  /**
   *
   * */
  private saveLastClickToCustomMenu(
    menu: FrfValueSetterCustomMenuInterface
  ): void {
    /**/
    this.frfValueSetterCustomMenu.saveLastClick(this.field, menu);
  }

  /**
   *
   * */
  public clickToMenu(
    menu: FrfValueSetterCustomMenuInterface,
    image: FrfUploadFieldElementListInterface
  ): void {
    switch (menu.key) {
      case FrfValueSetterCustomMenuSystemTypeEnum.closeModal:
        this.showedImageInCenterImage = null;
        break;

      case FrfValueSetterCustomMenuSystemTypeEnum.deleteFile:
        /* TODO later add */
        break;

      default:
        this.showedImageInCenterImage = null;
        this.saveLastClickToCustomMenu({
          ...menu,
          payload: image
        });
        break;
    }
  }

  /**
   *
   * */
  private initCustomMenus(): void {
    this.menus = this.frfValueSetterCustomMenu.getCustomMenuWithSystemMenuByField(
      this.field,
      this.systemCustomMenus
    );
  }
}
