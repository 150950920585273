import {RnWebElementCreate, RnWebElementSimpleCreate} from '@rn-codebase/function/js/full/element/web-element.function';
import {CntModuleApi} from './@res/@class/cnt-module-api/cnt-module-api';
import {
  CntModuleApiInterface,
  CntModuleApiServiceInterface
} from './@res/@class/cnt-module-api/@res/@abstract/@interface/common.interface';
import {CntApiInterface} from "../cnt-flexy-view-api/@res/@interface/common.interface";

export class CntModalApiService implements CntModuleApiServiceInterface {
  /**
   *
   * */
  private readonly cntModuleApiKey = 'cntModuleApi';

  /**
   *
   * */
  private root: HTMLElement;

  /**
   *
   * */
  private pathToAssets: string;

  /**
   *
   * */
  private cntApi: CntApiInterface;

  /**
   *
   * */
  private getRoot(): HTMLElement {
    return this.root || (this.root = document.querySelector('connect-root'));
  }

  /**
   *
   * */
  public init (
    params: {
      pathToAssets: string
      cntApi: CntApiInterface | null
    }
  ): void
  {
    this.cntApi = params.cntApi;
    this.pathToAssets = params.pathToAssets;
  }

  /**
   *
   * */
  public openByHtml(
    html: string,
    options?: {
      noHideRoot?: boolean;
      payload?: any
    }
  ) {
    const root = this.getRoot();
    const params = this.getBaseParams();


    if (options?.payload) {
      params.payload = options.payload;
    }

    const style = 'width: 100%; height: 100%; position: fixed; z-index: 9999;';

    const host = RnWebElementCreate('div', params, {
      style: style,
      innerHTML: html
    });
    const modalApi: CntModuleApiInterface = new CntModuleApi(host, root);

    params[this.cntModuleApiKey] = modalApi;

    if (!options?.noHideRoot) {
      modalApi.rootHide();
    }

    document.body.appendChild(host);
  }

  /**
   *
   * */
  private getBaseParams (params?: any): any {
    return {
      cntApi: this.cntApi,
      pathToAssets: this.pathToAssets,
      ...(params || {})
    }
  }

  /**
   *
   * */
  public openByTagName(
    tagName: string,
    options?: {
      payload?: any;
      params?: {[key: string]: any}
      hostParams?: {[key: string]: any}
      listeners?: {[key: string]: (val: any) => void}
      hostListeners?: {[key: string]: (val: any) => void}
      hideRoot?: boolean;
      noOverflowRoot?: boolean;
      onInit?: (element: HTMLElement) => void
      onDestroy?: (element: HTMLElement) => void
    }
  ): {host: HTMLElement, root: HTMLElement, tag: HTMLElement, modalApi: CntModuleApiInterface} {
    const root = this.getRoot();
    const params = this.getBaseParams(options?.params);

    if (options?.payload) {
      params.payload = options.payload;
    }

    const style = 'width: 100%; height: 100%; position: fixed; z-index: 9999;';

    const host = RnWebElementSimpleCreate('div', options.hostListeners, options.hostParams,{
      style: style,
      onInit: options?.onInit,
      onDestroy: options?.onDestroy
    });

    const tag = RnWebElementSimpleCreate(tagName, options.listeners, params, {
      style: style
    });

    const modalApi: CntModuleApiInterface = new CntModuleApi(host, root);

    params[this.cntModuleApiKey] = modalApi;

    host.appendChild(
      tag
    );

    if (options?.hideRoot) {
      modalApi.rootHide();
    }

    if (!options?.noOverflowRoot) {
      modalApi.setOverflow(
        document.body,
        true
      );
    }

    document.body.appendChild(host);
    return {host, tag, modalApi, root};
  }
}
