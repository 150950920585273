/**
 *
 * */
import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {Apollo, gql} from "apollo-angular-boost";
import {catchError, map} from "rxjs/operators";
import {CntFlexyViewUserInfoInterface} from "@cnt-multi-shared/@shared/@library/@group:flexy-view/@res/@interface/user-info.enum";

@Injectable(
  {
    providedIn: "root"
  }
)
export class MainUserService {

  constructor(
    private apollo: Apollo,
  ) {
  }

  /**
   * get user info
   * */
  public requestGetUserInfo(
    login: string
  ): Observable<CntFlexyViewUserInfoInterface> {
    return this.apollo
      .watchQuery({
        query: gql`
            query flexyViewUserGetInfo (
              $uid: String
              $login: String!
              $token: String
            ) {
              flexyViewUserGetInfo (
                  uid: $uid,
                  login: $login,
                  token: $token
              ) {
                status,
                data {
                    id,
                    un,
                    type,
                    name,
                    title,
                    country,
                    description,
                    favicon,
                    icon,
                    logo,
                    lang,
                    login,
                    phones,
                    messengers {
                      whatsapp,
                      telegram,
                      instagram,
                      facebook,
                      linkedin,
                      viber
                    }
                }
                error {
                  code,
                  text
                }
              }
            }
          `,
        fetchPolicy: "network-only",
        variables: {
          uid: null,
          login: login,
          token: null
        }
      })
      .valueChanges.pipe(
        map(
          (result: any) => result.data?.flexyViewUserGetInfo?.data || null
        ),
        catchError(error => {
          console.warn('requestGetUserInfo - error', error);
          return of(null);
        })
      );
  }

  /**
   * get user info
   * */
  public requestGetUsersInfo(
    ids: string[]
  ): Observable<CntFlexyViewUserInfoInterface[]> {
    return this.apollo
      .watchQuery({
        query: gql`
            query getUsersInfoByIds (
              $uid: String
              $ids: [String!]!
              $token: String
            ) {
              getUsersInfoByIds (
                  uid: $uid,
                  ids: $ids,
                  token: $token
              ) {
                status,
                data {
                    id,
                    un,
                    type,
                    name,
                    title,
                    country,
                    description,
                    favicon,
                    icon,
                    logo,
                    lang,
                    login,
                    phones,
                    messengers {
                      whatsapp,
                      telegram,
                      instagram,
                      facebook,
                      linkedin,
                      viber
                    }
                }
                error {
                  code,
                  text
                }
              }
            }
          `,
        fetchPolicy: "network-only",
        variables: {
          uid: null,
          ids: ids,
          token: null
        }
      })
      .valueChanges.pipe(
        map(
          (result: any) => result.data?.getUsersInfoByIds?.data || null
        ),
        catchError(error => {
          console.warn('requestGetUsersInfo - error', error);
          return of(null);
        })
      );
  }
}
