import { Injectable } from '@angular/core';
import { frfValueGetterPreSubmitGetBaseTempPath } from '../../../../frf-submit/@res/@function/common.function';
import { FrfValueGetterClassInterface } from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueSetterTempDataStorageTypeEnum } from '../../../frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FrfValueGetterTempDataService } from '../frf-value-getter-temp-data/frf-value-getter-temp-data.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterClickedPreSubmitButtonService
  implements FrfValueGetterClassInterface {
  constructor(private frfValueGetterTempData: FrfValueGetterTempDataService) {}

  /**
   * get clicked pre submit button [with path]
   * */
  public get(frfElement: any, path?: string) {
    const basePath = frfValueGetterPreSubmitGetBaseTempPath(),
      fullPath = basePath + ((path && '.' + path) || '');

    return this.frfValueGetterTempData.get(
      fullPath,
      frfElement,
      FrfValueSetterTempDataStorageTypeEnum.system
    );
  }
}
