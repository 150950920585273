import { Injectable } from '@angular/core';
import { FrfTriggerActionSetPayloadInterface } from './@res/@abstract/@interface/common.interface';
import { FreeformCommonService } from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfValueGetterService } from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';
import { FrfValueSetterPayloadService } from '../../../../../../@group:value-services/frf-value-setter/@sub/frf-value-setter-payload/frf-value-setter-payload.service';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionSetPayloadService {
  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfValueGetterService: FrfValueGetterService,
    private frfValueSetterPayload: FrfValueSetterPayloadService
  ) {}

  /**
   *
   * */
  public async run(
    action: FrfTriggerActionSetPayloadInterface,
    startElement: any,
    scope: any
  ): Promise<boolean> {
    const element = action.id
      ? this.frfValueGetterService.getFrfElementById(
          action.id,
          this.common.freeform,
          null,
          startElement
        )
      : startElement;

    if (!element || !action.path || typeof action.value === 'undefined') {
      return false;
    }

    const value = this.frfValueGetterService.getValue(
      action.value,
      element,
      this.common.freeform,
      startElement,
      scope
    );

    this.frfValueSetterPayload.set(value, action.path, element);

    return true;
  }
}
