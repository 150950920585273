import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'base-app-left-menu',
  templateUrl: './base-app-left-menu.component.html',
  styleUrls: ['./base-app-left-menu.component.scss']
})
export class BaseAppLeftMenuComponent implements OnInit {
  @Input() tabs: string[] = [];
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabIndexChange: EventEmitter<number> = new EventEmitter();
  @ContentChildren('tabTemplates') tabTemplates: TemplateRef<ElementRef>[] = [];

  constructor() {}

  ngOnInit() {}

  /*
   * change tab emit
   * */
  public changeTab(index: number) {
    this.selectedTabIndexChange.emit(index);
  }
}
