import { Injectable } from '@angular/core';
import { FrfMainLazyServiceModule } from '../../../../../../../../../../../../../../../../../frf-main-lazy-service.module';
import { Apollo } from 'apollo-angular';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { Observable, Observer } from 'rxjs';
import { FrfFieldUploadSettingInterface } from '../../../../../../../../../../../../../../../../../@doc/@field/freeform-field-upload/frf-field-upload.common.interface';
import { FrfFileUploadStorageTypeEnum } from '../../../../../../../../../../../../../../../../../@doc/@field/freeform-field-upload/frf-field-upload.common.common';
import { AngularFireStorage } from '@angular/fire/storage';
import { DomSanitizer } from '@angular/platform-browser';
import { FirebaseApp } from '@angular/fire';
import { FrfViewImageService } from '../../../freeform-view-image/@sub/@service/frf-view-image.service';
import { OldConnectLibrary } from '../../../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/oldConnectLibrary';
import { FrfFileUploadResultInterface } from './@res/@abstract/@interface/common.interface';
import { FreeformService } from '../../../../../../../../../../../../../../../../../@res/shared/service/freeform/freeform.service';
import { FreeformCommonNamespace } from '@cnt-multi-shared/@shared/freeform/freeform.common';
import {FrfStorageService} from "../../../../../../../../../../../../../../../../@service/@storage/frf-storage/frf-storage.service";

/**
 *
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfFileUploadService {
  /**
   * our link for public files
   * */
  public get publicDomain()
  {
    return this.frfStorageService.getGoogleStorageParams()?.publicDomain
  };

  /**
   * google storage for public files
   * */
  public get publicGoogleStorage()
  {
    return this.frfStorageService.getGoogleStorageParams()?.publicGoogleStorage
  };

  /**
   * google storage for private files
   * */
  public get privateGoogleStorage()
  {
    return this.frfStorageService.getGoogleStorageParams()?.privateGoogleStorage
  };

  /**
   * тип mime типов с расширениями
   * */
  public readonly arrWithMimeTypes = {
    //images
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
    'image/svg': 'svg',
    //audio
    'audio/midi': 'midi',
    'audio/mpeg': 'mpeg',
    'audio/webm': 'webm',
    'audio/ogg': 'ogg',
    'audio/wav': 'wav',
    'audio/mp3': 'mp3',

    // video
    'video/webm': 'webm',
    'video/ogg': 'ogg',
    'video/mp4': 'mp4',

    // text
    'text/plain': 'txt',
    'text/html': 'html',
    'text/css': 'css',
    'text/javascript': 'js',

    // documents
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
  };

  /**
   * типы наших файлов в зависимоссти от расширения
   * */
  public readonly arrWithTypes = {
    image: ['jpg', 'png', 'gif', 'bmp', 'webp', 'svg', 'jpeg'],
    audio: ['mp3', 'wav', 'ogg', 'webm', 'mpeg', 'midi'],
    video: ['webm', 'ogg', 'mp4'],
    text: ['txt', 'html', 'css', 'js'],
    document: ['docx', 'doc', 'xlsx', 'xls', 'csv']
  };

  /**
   * TODO later replace (delete OldConnectLibrary)
   * connect library
   * */
  private connectLibrary = new OldConnectLibrary();

  constructor(
    private apollo: Apollo,
    private connect: ConnectAuthService,
    private fstorage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private firebase: FirebaseApp,
    private frfStorageService: FrfStorageService,
    private frf: FreeformService,
    private cnt: ConnectAuthService,
    private frfViewImageService: FrfViewImageService
  ) {}

  /**
   * определить тип по ссылке к файлу
   * */
  public getFileTypeByUrl(fileUrl, fileExtension = null) {
    //@disc - get file type
    const fExt = fileExtension
      ? fileExtension.toLocaleLowerCase()
      : this.getFileExtensionByFileName(fileUrl);

    for (const groupKey of Object.keys(this.arrWithTypes)) {
      const group = this.arrWithTypes[groupKey];
      if (group.indexOf(fExt) !== -1) {
        // we found this extension in this group -> return right icon
        switch (groupKey) {
          case 'image':
            return 'image';

          case 'video':
            return 'video';

          case 'audio':
            return 'audio';

          case 'text':
            return 'text';

          case 'document':
            return 'document';
        }
      }
    }
    //@
    return 'unknown';
  }

  /**
   * получить настройки
   * */
  public getSettingsByPath(
    path: string,
    field: any
  ): FrfFieldUploadSettingInterface | null {
    const payload = field['body']['payload'],
      settings = payload['settings'],
      fileType = this.getFileTypeByUrl(path);

    //get file type
    if (settings && settings[fileType]) {
      // we have settings for this type
      return settings[fileType];
    } else if (settings && settings['*']) {
      // we have not settings for file type -> get all
      return settings['*'];
    }

    return null;
  }

  /**
   * получить иконку для вывода в зависимости от файла
   * */
  public async getFileIconByUrl(
    iNfileUrl: string,
    iNfield: object,
    iNfileExt: null | string = null,
    iNisFullAdress = false
  ) {
    let fileUrl = iNfileUrl, //(!iNfileExt) ? iNfileUrl : `${iNfileUrl}.${iNfileExt}`,
      field = iNfield,
      fExt = iNfileExt
        ? iNfileExt.toLocaleLowerCase()
        : this.getFileExtensionByFileName(fileUrl),
      fFolder = '/assets/icons/freeform',
      fileIcon = this.getNoneIconImagePath(), //@default icon
      fileType = this.getFileTypeByUrl(fileUrl, fExt),
      settings = this.getSettingsByPath(fileUrl, field);

    // we found this extension in this group -> return right icon
    switch (fileType) {
      case 'image':
        fileIcon = fileUrl;
        break;

      case 'video':
        fileIcon = `${fFolder}/video.svg`;
        break;

      case 'audio':
        fileIcon = `${fFolder}/audio.svg`;
        break;

      case 'document':
        fileIcon = `${fFolder}/doc.svg`;
        break;
    }
    // if we have icon -> return icon -> url to file
    if (fileIcon === fileUrl) {
      // we have image link -> get public or private -> return url
      const accessTypeOfFile = this.getStorageTypeByFileSetting(settings); //settings['file-type'];
      if (accessTypeOfFile === FrfFileUploadStorageTypeEnum.private) {
        // we have private url -> get url via firebase
        return new Promise(resolve => {
          this.frfViewImageService
            .getUrlFromValue({
              path: fileUrl,
              public: false
            })
            .subscribe(url => {
              resolve(
                url && url.indexOf('blob:http') !== -1
                  ? this.sanitizer.bypassSecurityTrustUrl(url)
                  : url
              );
            });
        });
      } else {
        // we have public url -> add 'https://gstorage.ramman.net' to url
        return new Promise(resolve => {
          let url;
          if (iNisFullAdress) {
            // is full url do not change address
            url = fileIcon;
          } else {
            url = `${this.publicDomain}${fileIcon}`;
          }
          // for blob file url need use sanitizer in angular -> SOLVED user sanitizer for all url here
          resolve(this.sanitizer.bypassSecurityTrustUrl(url));
        });
      }
    } else {
      // we have icon link return url
      return new Promise(resolve => {
        resolve(fileIcon);
      });
    }
  }

  /**
   * определить тип хранилища по полю и ссылке
   * */
  public isPrivateStorage(path: string, field: any): boolean {
    // return false;
    const settings = <FrfFieldUploadSettingInterface>(
      (this.getSettingsByPath(path, field) || {})
    );
    // console.log('isPrivateStorage', settings["storage-type"] === FrfFileUploadStorageTypeEnum.private, settings, path, field);
    return settings['storage-type'] === FrfFileUploadStorageTypeEnum.private;
  }

  public getFileExtensionByMimeType(iNfileType: string) {
    return this.arrWithMimeTypes[iNfileType];
  }

  public getFileIconByFile(iNfile: File, iNfield) {
    const file = iNfile,
      field = iNfield,
      fileUrl = URL.createObjectURL(iNfile),
      fileExt = this.getFileExtensionByFile(file);

    return this.getFileIconByUrl(fileUrl, field, fileExt, true);
  }

  public getFileExtensionByFile(iNfile: File) {
    const file = iNfile,
      fName = file.name,
      mType = file['type'];
    return (
      this.getFileExtensionByFileName(fName) ||
      this.getFileExtensionByMimeType(mType)
    );
  }

  public getFileExtensionByFileName(iNfileName: string) {
    const fileName = iNfileName.toLocaleLowerCase(),
      splitArr = fileName.split('.');

    return splitArr[splitArr.length - 1];
  }

  /*
   * определить google storage url для загрузки по типу хранилища
   * */
  public getBucketForPrivateOrPublic(path: string, field: any): string {
    // console.log('getBucketForPrivateOrPublic', path, field);
    const privateStorage = this.isPrivateStorage(path, field);
    if (privateStorage) {
      return this.privateGoogleStorage; //gs://connect-private';
    } else {
      return this.publicGoogleStorage; //'gs://connect-public';
    }
  }

  /**
   *
   * */
  public getDownloadPathToField(
    field: any,
    fileId: string,
    fileName: string
  ): string {
    return FreeformCommonNamespace.getDownloadPathToFieldForFistore(
      this.frf.formInfo.uid,
      this.frf.formInfo.modelId,
      this.frf.formInfo.formId,
      field['id'],
      this.cnt.uid,
      fileId,
      fileName
    );
  }

  /**
   * light upload file
   * */
  public lightUploadFile(
    field: any,
    fileList: FileList
  ): Observable<FrfFileUploadResultInterface> {
    const file = <File>fileList[0],
      fileId = this.generateFileId(),
      path = this.getDownloadPathToField(field, fileId, file.name);

    return this.uploadFile(field, path, file, fileId);
  }

  /**
   * upload data to firestore
   * */
  public uploadFile(
    iNfield: object,
    path: string,
    file: File,
    fileId: string,
    fileExtension: string = null,
    metadata: any = null
  ): Observable<FrfFileUploadResultInterface> {
    const ext = fileExtension || this.getFileExtensionByFile(file),
      field = iNfield,
      result = { path: `${path}`, error: null, url: null },
      bucket = this.getBucketForPrivateOrPublic(path, field);

    let uploadTask;

    if (!metadata) {
      uploadTask = this.firebase
        .storage(bucket)
        .ref(path)
        .put(file);
    } else {
      uploadTask = this.firebase
        .storage(bucket)
        .ref(path)
        .put(file, metadata);
    }

    /**
     * add pause and resume method
     * */
    result['pause'] = () => uploadTask.pause();
    result['fileId'] = fileId;
    result['resume'] = () => uploadTask.resume();
    result['state'] = 'started';

    return Observable.create((observer: Observer<any>) => {
      // Listen for state changes, errors, and completion of the upload.
      // console.log('uploadTask.snapshot.state', uploadTask.snapshot.state);

      observer.next(result);
      uploadTask.on(
        'state_changed', // or 'state_changed'
        snapshot => {
          // Get task percent, including the number of bytes uploaded and the total number of bytes to be uploaded

          /* add percent */
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          result['percent'] = percent / 100;

          switch (snapshot.state) {
            case 'paused': // or 'paused'
              // console.log('Upload is paused');

              result['state'] = 'paused';
              observer.next(result);
              break;

            case 'running':
              result['state'] = 'running';
              observer.next(result);
              break;
          }
        },
        err => {
          console.error('uploadFile - ERROR', err);
          result['state'] = 'error';
          result['err'] = err;
          observer.next(result);
          observer.complete();
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(url => {
            result['url'] = url;
            result['state'] = 'finished';
            observer.next(result);
            observer.complete();
          });
        }
      );
    });
  }

  /**
   * get urlForPrivateOrPublic
   * */
  public getUrlForPrivateOrPublic(
    path: string,
    field: any
  ): { public: boolean; path: string } {
    const privateStorage = this.isPrivateStorage(path, field);

    if (privateStorage) {
      return {
        public: false,
        path: `${path}`
      };
    } else {
      return {
        public: true,
        path: `${this.publicDomain}${path}`
      };
    }
  }

  /**
   * определить тип хранилища по настрайкам
   * для upload компонента
   * */
  public getStorageTypeByFileSetting(
    fileSetting: FrfFieldUploadSettingInterface
  ): FrfFileUploadStorageTypeEnum {
    return fileSetting &&
      fileSetting['storage-type'] === FrfFileUploadStorageTypeEnum.private
      ? FrfFileUploadStorageTypeEnum.private
      : FrfFileUploadStorageTypeEnum.public;
  }

  public getNoneIconImagePath() {
    return '/assets/icons/freeform/attach-file.svg';
  }

  public getLocalUrlByFile(iNfile) {
    return URL.createObjectURL(iNfile);
  }

  public generateFileId() {
    return this.connectLibrary.getUuid();
  }
}
