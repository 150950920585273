import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FreeformComponent } from './@sub/@view/freeform/freeform.component';
import { FreeformPageComponent } from './@sub/@view/freeform/@sub/@view/freeform-page/freeform-page.component';
import { FrfPageSubmitComponent } from './@sub/@view/freeform/@sub/@view/freeform-page/@sub/@view/frf-page-submit/frf-page-submit.component';
import { FreeformObjectDirective } from './@res/shared/service/freeform/directive/freeform-object.directive';
import { FreeformGroupComponent } from './@sub/@view/freeform/@sub/@view/freeform-group/freeform-group.component';
import { FreeformRowComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/freeform-row.component';
import { FreeformFieldComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/freeform-field.component';
import { FreeformFieldSimpleComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/freeform-field-simple.component';
import { FreeformFieldTextComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-text/freeform-field-text.component';
import { FreeformFieldCollectionComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-collection/freeform-field-collection.component';
import { FreeformViewImageComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-view-image/freeform-view-image.component';
import { FrfFieldUploadComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/frf-field-upload/frf-field-upload.component';
import { FreeformFieldTextredactorComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-textredactor/freeform-field-textredactor.component';
import { FreeformFieldTextareaComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-textarea/freeform-field-textarea.component';
import { FreeformFieldSwitcherComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-switcher/freeform-field-switcher.component';
import { FreeformFieldSlidetoggleComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-slidetoggle/freeform-field-slidetoggle.component';
import { FreeformFieldSelectComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-select/freeform-field-select.component';
import { FreeformFieldRadioComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-radio/freeform-field-radio.component';
import { FreeformFieldDateComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-date/freeform-field-date.component';
import { FreeformFieldCheckboxComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-checkbox/freeform-field-checkbox.component';
import { FreeformFieldButtonComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-button/freeform-field-button.component';
import { FreeformFieldAutocompleteComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/freeform-field-autocomplete/freeform-field-autocomplete.component';
import { FrfFieldCameraComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/frf-field-camera/frf-field-camera.component';
import { FrfCustomFieldWrapperComponent } from './@sub/@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/frf-custom-field-wrapper/frf-custom-field-wrapper.component';
import { ConnectDictionaryPipe } from './@res/shared/service/freeform/pipe/connect-dictionary.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrfMainLazyServiceModule } from './frf-main-lazy-service.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { TextMaskModule } from 'angular2-text-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  imports: [
    CommonModule,
    TextMaskModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatButtonModule,
    MatMenuModule,
    MatRadioModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FrfMainLazyServiceModule
  ],
  declarations: [
    FreeformComponent,
    FreeformPageComponent,
    FrfPageSubmitComponent,
    FreeformObjectDirective,
    FreeformGroupComponent,
    FreeformRowComponent,
    FreeformFieldComponent,
    FreeformFieldSimpleComponent,
    FreeformFieldTextComponent,
    FreeformFieldCollectionComponent,
    FreeformViewImageComponent,
    FrfFieldUploadComponent,
    FreeformFieldTextredactorComponent,
    FreeformFieldTextareaComponent,
    FreeformFieldSwitcherComponent,
    FreeformFieldSlidetoggleComponent,
    FreeformFieldSelectComponent,
    FreeformFieldRadioComponent,
    FreeformFieldDateComponent,
    FreeformFieldCheckboxComponent,
    FreeformFieldButtonComponent,
    FreeformFieldAutocompleteComponent,
    FrfFieldCameraComponent,
    FrfCustomFieldWrapperComponent,
    ConnectDictionaryPipe
  ],
  exports: [
    FreeformComponent,
    FreeformPageComponent,
    FrfPageSubmitComponent,
    FreeformGroupComponent,
    FreeformRowComponent,
    FreeformFieldComponent,
    FreeformFieldSimpleComponent,
    FreeformFieldCollectionComponent,
    FreeformViewImageComponent,
    FreeformFieldTextComponent,
    FrfFieldUploadComponent,
    FreeformFieldTextredactorComponent,
    FreeformFieldTextareaComponent,
    FreeformFieldSwitcherComponent,
    FreeformFieldSlidetoggleComponent,
    FreeformFieldSelectComponent,
    FreeformFieldRadioComponent,
    FreeformFieldDateComponent,
    FreeformFieldCheckboxComponent,
    FreeformFieldButtonComponent,
    FreeformFieldAutocompleteComponent,
    FrfFieldCameraComponent,
    FrfCustomFieldWrapperComponent
  ],
  providers: [
    // FrfRootService,
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ru' },
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class FrfMainModule {}
