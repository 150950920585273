<main>
  <!--  <ng-template #templatePortalContent-->
  <!--    >Hello, this is a template portal</ng-template-->
  <!--  >-->
  <!--  <div-->
  <!--    id="test-block"-->
  <!--    (click)="testSHow()"-->
  <!--    style="height: 50px; width: 50px; margin-top: 100px; background: red; color: white;"-->
  <!--  >-->
  <!--    TEST BUTTON-->
  <!--  </div>-->

  <div class="container">
    <div class="inner_container">
      <app-header [logo]="user?.logo" [categories]="categories"> </app-header>

      <main-info-content
        [name]="user?.name"
        [icon]="user?.icon"
        [messengers]="user?.messengers"
        [phone]="user?.phones && (user?.phones)[0]"
        [uid]="user?.id"
        [description]="user?.description"
      ></main-info-content>

      <main-info-first-buttons *ngIf="user?.id" [ownerId]="openedLogin">
      </main-info-first-buttons>
    </div>
  </div>

  <div class="components-zone" *ngIf="flexyViews && flexyViews.length">
    <cnt-flexy-view
      *ngFor="let flexyView of flexyViews"
      [hash]="flexyView.hash"
      [viewName]="flexyView.viewName"
      [component]="flexyView.component"
      [payload]="flexyView.payload"
      [options]="flexyView.options"
      [version]="flexyView.version"
      [owner]="flexyView.creator"
      [deps]="flexyView.deps"
      [theme]="null"
    >
    </cnt-flexy-view>
  </div>
</main>
