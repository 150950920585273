import * as uuidv4 from 'uuid/v4';
import * as mzCommon from 'mz-common';
import * as _ from 'lodash';

export class OldConnectLibrary {
  constructor() {}

  //@< for objects

  /*
   *
   * */
  public getValueFromObjectByPath(path: string, obj: object): any | null {
    // //@
    // let obj                 = iNobject,
    //   splitedPathArray    = iNpath.split('.'),
    //   arrayName           = splitedPathArray[0],
    //   result;
    // // we have not sub path -> get result
    // result = obj[arrayName]
    // if (result) {
    //   // if we have object -> check for subpath
    //   if ( splitedPathArray.length > 1 ) {
    //     // we have sub path -> did request
    //     let newPath = splitedPathArray.splice(1).join('.');
    //     if ( typeof result === 'object' ) {
    //       // if we have subpath and have object  -> recursive object
    //       let r =  this.getValueFromObjectByPath( newPath, result );
    //       return r;
    //     } else {
    //       // if we have subpath but have not object -> return null
    //       return null;
    //     }
    //   } else {
    //     // if we have not subpath -> return result
    //     return result;
    //   }
    // }
    // // if we have not object
    // return null;
    return mzCommon.getValueFromObjectByPath(path, obj);
  }

  public addValueToObjectByPath(
    obj: any,
    path: string,
    data: any,
    withMerge: boolean = true
  ) {
    return mzCommon.addValueToObjectByPath(obj, path, data, withMerge);
  }
  //@> for objects

  /**
   *
   * */
  public getRegexFromString(iNstr) {
    const result = /\/(.*)\/(.*)/.exec(iNstr);
    if (result) {
      return new RegExp(result[1], result[2]);
    }
    return null;
  }

  /**
   * get uniq id
   * */
  public getUuid() {
    return uuidv4();
  }

  public deepcopy<T>(object: T): T {
    // // return JSON.parse(JSON.stringify(o));
    // let node;
    // if (object === null) {
    //   node = object;
    // }
    // else if (Array.isArray(object)) {
    //   node = object.slice(0) || [];
    //   node.forEach(n => {
    //     if (typeof n === 'object' && n !== {} || Array.isArray(n)) {
    //       n = this.deepcopy(n);
    //     }
    //   });
    // }
    // else if (typeof object === 'object') {
    //   node = Object.assign({}, object);
    //   Object.keys(node).forEach(key => {
    //     if (typeof node[key] === 'object' && node[key] !== {}) {
    //       node[key] = this.deepcopy(node[key]);
    //     }
    //   });
    // }
    // else {
    //   node = object;
    // }
    // return node;
    return _.cloneDeep(object);
  }

  public mergeObject(iNobject, iNobject2) {
    // let arrOfKeys = Object.keys(iNobject2);
    // for (let k of arrOfKeys) {
    //   //
    //   let el = iNobject2[k];
    //
    //   if ( typeof el === 'object' && !Array.isArray(el) && el) {
    //     // create object if not
    //     if ( typeof iNobject[k] !== 'object' ) iNobject[k] = {};
    //     // copy this object
    //     this.mergeObject(iNobject[k], el);
    //   } else {
    //     // set new val if in original object is not isset
    //     if ( typeof iNobject[k] === 'undefined' ) {
    //       iNobject[k] = el;
    //     }
    //   }
    // }
    // return iNobject;

    return mzCommon.mergeObject(iNobject, iNobject2);
  }

  //
}
