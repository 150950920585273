import { Injectable } from '@angular/core';
import { PlatformSpecificServiceInterface } from '../../@shared/@interface/platform-specific.service.interface';
import { PlatformTypeEnum } from '../../@shared/@enum/platform-specific.service.enum';
import { PushService } from '../push/push.service';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformSpecificService
  implements PlatformSpecificServiceInterface {
  /*
   * platform type enum
   * */
  public type: PlatformTypeEnum = PlatformTypeEnum.web;

  constructor(
    public pushService: PushService,
    public analyticsService: AnalyticsService
  ) {}
}
