export class FreeformModel {
  constructor(
    public id: string,
    public initialMap: FreeforModelReferenceModel[],
    public map: FreeforObjectReferenceModel[],
    public pages: {}, // pageId: { name, status}
    public groups: {}, // pageId: { name, status}
    public rows: {}, // pageId: { name, status}
    public fields: {}, // pageId: { name, status}
    public status: number
  ) {}
}
export class FreeforModelReferenceModel {
  constructor(public id: string, public weight: number, public inid?: string) {}
}
export class FreeforObjectReferenceModel {
  constructor(
    public baseid: string,
    public objid: string,
    public inid?: string,
    public weight?: number
  ) {}
}
export class FreeformGroupModel {
  constructor(
    public weight: number,
    public field: string[],
    public groupId: string
  ) {}
}

export class FreeformReadyObjectModel {
  constructor(public id: string) {}
}
