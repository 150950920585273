export enum CntMessengerTypeEnum {
  telegram = 'telegram',
  whatsapp = 'whatsapp',
  instagram = 'instagram',
  viber = 'viber',
  twitter = 'twitter'
}

export enum CntMessengersIconSrcEnum {
  whatsapp = '/assets/images/svg/messengers/whatsapp.svg',
  vk = '/assets/images/svg/messengers/vk.svg',
  instagram = '/assets/images/svg/messengers/instagram.svg',
  telegram = '/assets/images/svg/messengers/telegram.svg',
  facebook = '/assets/images/svg/messengers/facebook.svg',
  email = '/assets/images/svg/messengers/email.svg',
  kik = '/assets/images/svg/messengers/kik.svg',
  line = '/assets/images/svg/messengers/line.svg',
  liveChat = '/assets/images/svg/messengers/live-chat.svg',
  ok = '/assets/images/svg/messengers/ok.svg',
  qq = '/assets/images/svg/messengers/qq.svg',
  skype = '/assets/images/svg/messengers/skype.svg',
  twillio = '/assets/images/svg/messengers/twillio.svg',
  twitter = '/assets/images/svg/messengers/twitter.svg',
  viber = '/assets/images/svg/messengers/viber.svg',
  wechat = '/assets/images/svg/messengers/wechat.svg'
}

export enum CntMessengersNameEnum {
  whatsapp = 'Whatsapp',
  vk = 'Vk',
  instagram = 'Instagram',
  telegram = 'Telegram',
  facebook = 'Facebook',
  email = 'Email',
  kik = 'Kik',
  line = 'Line',
  liveChat = 'Live Chat',
  ok = 'Ok',
  qq = 'Qq',
  skype = 'Skype',
  twillio = 'Twillio',
  twitter = 'Twitter',
  viber = 'Viber',
  wechat = 'Wechat'
}
