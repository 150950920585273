import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CameraService } from '../../@res/@module/camera/@sub/@service/camera.service';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { LkService } from '../../@res/@module/shared/@sub/@service/lk/lk.service';
import { ActivatedRoute } from '@angular/router';
import { CntPubSubMessageService } from '@cnt-nx-workspace/feature/cnt-pub-sub-message';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';
import { Overlay } from '@angular/cdk/overlay';
import { NavigateService } from '../../@res/@module/main/@sub/@service/navigate/navigate.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-app-zone',
  templateUrl: './app-zone.component.html',
  styleUrls: ['./app-zone.component.scss'],
})
export class AppZoneComponent extends AbstractUnsubscribeViewControl
  implements OnInit {
  /**
   * */
  public cameraActivate = false;

  /**
   * */
  public frfActivate = false;

  constructor(
    private camera: CameraService,
    private cntPubSubMessageService: CntPubSubMessageService,
    private connectAuthService: ConnectAuthService,
    private activatedRoute: ActivatedRoute,
    public navigateService: NavigateService,
    private lk: LkService,
    private cdRef: ChangeDetectorRef,
    private pubnubService: CntPubSubMessageService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) {
    super();
    this.lk.lkActivatedRoute = this.activatedRoute;
    this.lk.activeFrfToast$(this.viewDestroy$).subscribe();
  }

  ngOnInit() {
    this.activeCamera();

    this.pubnubService.init(
      this.connectAuthService.user,
      this.connectAuthService.token,
      this.connectAuthService.userNumber,
      environment
    );
  }

  /**
   * enable camera listener
   * */
  public activeCamera() {
    this.camera.cameraState$
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe((result) => {
        this.cameraActivate = result;
        this.cdRef.markForCheck();
      });
  }
}
