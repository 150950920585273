<!--<div class="host" [ngStyle]="{'background-color': options?.backgroundColor}">-->
<!--  <div class="container">-->
<div
  class="flexy-view-container {{ selector }}"
  [ngClass]="{ loading: !loaded }"
>
  <div class="loading" *ngIf="!loaded">Загрузка...</div>
  <div class="flexy-view" #flexyView [attr.hash]="hash"></div>
</div>
<!--  </div>-->
<!--</div>-->
