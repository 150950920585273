import {
  AngularFirestore,
  CollectionReference,
  Query
} from '@angular/fire/firestore';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { WhiteBlackListFirestoreInterface } from '@cnt-multi-shared/@shared/sharepay/@sub/white-black-list/@interface/common.interface';

export interface FirestorePaginatorFlows {
  limit: number;
  collection?: string;
  collectionGroup?: string;
  ref: (ref: CollectionReference) => Query;
  lastPage?: number;
  lastElement?: any;
  firstElement?: any;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export abstract class AbstractFirestorePaginatorService {
  /**
   * our injected firestore service
   * */
  protected abstract firestore: AngularFirestore;

  protected readonly flowDestroy$: Subject<string> = new Subject();

  public abstract readonly flows: {
    [key: string]: FirestorePaginatorFlows;
  };

  /**
   * our ref with callbacks
   * */
  public abstract readonly ref: {
    [key: string]: null | ((ref: Query) => Query);
  } = {};

  public readonly flowsOptions$: {
    [key: string]: BehaviorSubject<FirestorePaginatorFlows>;
  } = {};

  public readonly flows$: {
    [key: string]: BehaviorSubject<any>;
  } = {};

  /**
   * update ref firestore params (for example sorting) and reload
   * */
  public addExtraRefToFlow(
    flowName: string,
    ref: null | ((ref: CollectionReference) => Query),
    reload = true
  ): boolean {
    if (flowName) {
      this.ref[flowName] = ref;
    }

    if (reload) {
      /* reload */
      this.load(flowName, null, null, 1);
    }

    return false;
  }

  /**
   * open page in firestore
   * */
  public openPage(flowName: string, page: number, limit?: number) {
    const flow$ = this.getFlowByName$(flowName);

    if (flow$) {
      if (page > flow$.lastPage && flow$.hasNextPage) {
        /* next page  */
        this.load(flowName, flow$.lastElement, false, page, limit);
      } else if (page < flow$.lastPage && flow$.hasPreviousPage) {
        /* previous page */
        this.load(flowName, flow$.firstElement, true, page, limit);
      } else if (page) {
        this.load(flowName, null, null, 1, limit);
      }
    }
  }

  /**
   * get data
   * */
  private getData$(flow: FirestorePaginatorFlows, queryGroupFn: any) {
    window['firestore'] = this.firestore;
    console.log('getData$ - collectionGroup', flow.collectionGroup, flow);
    return flow.collectionGroup
      ? this.firestore.collectionGroup(flow.collectionGroup, queryGroupFn)
      : this.firestore.collection<any>(flow.collection, queryGroupFn);
  }

  /**
   * load
   * */
  public load(
    flowName: string,
    startAfter: any = null,
    toPreviousPage: boolean = false,
    page?: number,
    limit?: number
  ) {
    const flow$ = this.getFlowByName$(flowName),
      currentPage = page || 1;

    if (!limit) {
      limit = flow$.limit;
    }

    /**
     * destroy previous firestore flows$
     * */
    this.flowDestroy$.next(flowName);

    if (flow$) {
      this.getData$(flow$, ref => {
        return (ref_ => {
          let result = this.flows[flowName].ref(ref_);

          /*
           * invoke extra params if we have
           * */
          if (typeof this.ref[flowName] === 'function') {
            result = this.ref[flowName](result);
          } else if (typeof this.ref['*'] === 'function') {
            result = this.ref['*'](result);
          }

          if (page > 1) {
            if (toPreviousPage) {
              result = result.startAfter(startAfter);
            } else {
              result = result.startAt(startAfter);
            }
          }

          return result.limit(limit + 1);
        })(ref);
      }) /*
            this.firestore
            .collection<any>(
              flow$.collection,
              (ref) => {
                  return ((ref_) => {
                      let result =  this.flows[flowName].ref(ref_);

                      /!*
                      * invoke extra params if we have
                      * *!/
                      if (typeof this.ref[flowName] === "function") {
                          result = this.ref[flowName](result);
                      } else if (typeof this.ref['*'] === "function") {
                          result = this.ref['*'](result);
                      }

                      if (page > 1) {
                          if (toPreviousPage) {
                            result =  result.startAfter(startAfter)
                          } else {
                            result =  result.startAt(startAfter)
                          }
                      }

                      return result.limit(
                          limit + 1
                      );
                  })(ref);
              }
            )*/
        .snapshotChanges()
        .pipe(
          takeUntil(
            this.flowDestroy$.pipe(
              filter(name => name === flowName),
              take(1)
            )
          ),
          map(doc => {
            /**
             * we have next page -> save next data -> delete last page
             * */
            if (doc && doc.length) {
              flow$.lastPage = currentPage;

              if (doc.length > limit) {
                const lastElement = doc.pop();
                flow$.hasNextPage = true;
                flow$.firstElement = flow$.lastElement;
                flow$.lastElement = lastElement.payload.doc;
              } else {
                flow$.hasNextPage = false;
              }

              if (page > 1) {
                flow$.hasPreviousPage = true;
              } else {
                flow$.firstElement = null;
                flow$.hasPreviousPage = false;
              }

              /**
               * emit options for listener page changes
               * */
              this.flowsOptions$[flowName].next(flow$);

              return doc.map(item => {
                const data = item.payload.doc.data() as WhiteBlackListFirestoreInterface;
                const id = item.payload.doc.id;

                return {
                  ...data,
                  docId: id
                };
              });
            } else {
              return [];
            }
          })
        )
        .subscribe(result => {
          const stream$ = this.getStreamByName$(flowName);
          stream$.next(result);
        });
    }
  }

  /**
   * get stream by name
   * */
  public getStreamByName$(flowName: string): BehaviorSubject<any> {
    if (!this.flows$[flowName]) {
      this.flows$[flowName] = new BehaviorSubject<any>(null);
    }

    return this.flows$[flowName];
  }

  /**
   * get flow options
   * */
  public getOptionsByName$(
    flowName: string
  ): BehaviorSubject<FirestorePaginatorFlows | null> {
    if (!this.flowsOptions$[flowName]) {
      this.flowsOptions$[flowName] = new BehaviorSubject<
        FirestorePaginatorFlows
      >(null);
    }

    return this.flowsOptions$[flowName];
  }

  /**
   * get flow by name
   * */
  protected getFlowByName$(
    flowName: string
  ): undefined | FirestorePaginatorFlows {
    return this.flows && this.flows[flowName];
  }

  /**
   * destory flow firestore
   * */
  public destroyFlow(flowName: string) {
    this.flowDestroy$.next(flowName);
  }
}
