import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { FrfInitialValueService } from '../../../../../../../../../../../../../../@service/frf-initial-value/frf-initial-value.service';
import { FreeformFieldValidatorLibrary } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform-field-validators.library';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { takeUntil } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'frf-field-date',
  templateUrl: './freeform-field-date.component.html',
  styleUrls: ['./freeform-field-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeformFieldDateComponent extends AbstractUnsubscribeViewControl
  implements OnInit, AfterViewInit {
  // reqired
  @Input('fieldid') fieldid;
  @Input('objid') objid;
  @Input('freeform') frf;
  @Input('disabled') disabled;

  // this field from freeform object
  public field;

  // create angular reactive form
  public form = new FormGroup({
    field: new FormControl('')
  });

  /* date controll for template */
  public date;

  /**
   *
   * */
  public readonly = false;

  constructor(
    private common: FreeformCommonService,
    private valueService: FrfValueSetterService,
    private frfInitialValueService: FrfInitialValueService,
    private frfValueSetterService: FrfValueSetterService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.field = this.frf.fields[this.fieldid].objects[this.objid];

    /* add required validator */
    new FreeformFieldValidatorLibrary(this.frf, this.field, this.form).run();

    this.updateViewDate();
    this.initReadonly();
  }

  ngAfterViewInit(): void {
    /*
     * set if need inital value
     * */
    this.frfInitialValueService.setIfNeedInitialValueIfItExist(
      this.field,
      this.frf,
      {}
    );

    this.watchFieldChanges();
  }

  /**
   *
   * */
  private initReadonly() {
    this.readonly =
      (this.field.body &&
        this.field.body.payload &&
        this.field.body.payload.readonly) ||
      false;
  }

  /*
   *
   * */
  private updateViewDate(date: string = this.field.body.value) {
    const dateObject = date && moment(date, 'DD.MM.YYYY').toDate();
    this.date = new FormControl(dateObject);
  }

  /**
   * watch field changes
   * */
  private watchFieldChanges() {
    this.frfValueSetterService
      .watchFieldChanges(this.fieldid, this.objid)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(() => {
        this.updateViewDate();
        this.initReadonly();
        this.cdRef.markForCheck();
      });
  }

  /**
   *
   * */
  public onKeyPress(event: any) {}

  /**
   *
   * */
  public onChange() {}

  /**
   *
   * */
  public dateChange(momentDate: any) {
    const date = momentDate['value'].toDate();
    this.date = new FormControl(date);

    /*
     * save
     * */
    this.save(date, momentDate);
  }

  /**
   * save
   * */
  private save(date: Date, momentDate: any) {
    this.saveTimestamp(date);
    this.saveTextValue(momentDate);
  }

  /**
   * need use before save text value
   * */
  private saveTimestamp(date: Date) {
    this.valueService.setElementDataByPath(
      'field',
      this.field,
      'payload.timestamp',
      date.getTime(),
      [],
      false,
      true,
      false,
      undefined,
      undefined,
      true
    );
  }

  /**
   *
   * */
  private saveTextValue(momentDate: any) {
    this.valueService.setFieldValue(
      this.field,
      momentDate.targetElement.value,
      ['onChange'],
      true
    );
  }

  /**
   *
   * */
  public dateInput(ed: MatDatepickerInputEvent<Date>) {}
}
