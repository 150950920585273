import { Injectable } from '@angular/core';
import { FrfElementInvokerMethodService } from '../../../../../../@group:value-services/frf-element-invoker/@sub/frf-element-invoker-method/frf-element-invoker-method.service';
import { FreeformCommonService } from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfTriggerActionInvokeElementMethodInterface } from './@res/@abstract/@interface/common.interface';
import { FrfValueGetterService } from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionInvokeElementMethodService {
  constructor(
    private common: FreeformCommonService,
    private frfValueGetterService: FrfValueGetterService,
    private frfElementInvokerMethod: FrfElementInvokerMethodService
  ) {}

  /**
   *
   * */
  public async run(
    action: FrfTriggerActionInvokeElementMethodInterface,
    startElement: any,
    scope: any
  ): Promise<boolean> {
    if (!action || !action.method) {
      console.error(
        'actionInvokeElementMethod',
        'not write format, not isset method name',
        action
      );
      return false;
    }

    const element = action.id
      ? this.frfValueGetterService.getFrfElementById(
          action.id,
          this.common.freeform,
          null,
          startElement
        )
      : startElement;

    if (!element) {
      console.error(
        'actionInvokeElementMethod',
        'not write format, not isset element',
        action,
        element
      );
      return false;
    }

    let params = [];

    if (Array.isArray(action.params)) {
      params = action.params.map(value => {
        return this.frfValueGetterService.getValue(
          value,
          element,
          this.common.freeform,
          element,
          scope
        );
      });
    }

    this.frfElementInvokerMethod.invokeElementMethod(
      element.modelid,
      element.id,
      action.method,
      params
    );

    return true;
  }
}
