/**
 * for work with values [set] difficult data
 * */
import {FrfMainLazyServiceModule} from '../../../../frf-main-lazy-service.module';
import {Injectable} from '@angular/core';
import {OldConnectLibrary} from '../../../../@res/shared/service/freeform/_sub/oldConnectLibrary';
import {FrfElementGetterService} from '../../@group:frf-elements/frf-element-getter.service';
import {FrfElementSetterService} from '../../@group:frf-elements/frf-element-setter.service';
import {isObservable, Observable, of} from 'rxjs';
import {FreeformCommonService} from '../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import {FrfValueGetterTempDataService} from './@sub/frf-value-getter-temp-data/frf-value-getter-temp-data.service';
import {FrfValueGetterElementStatus} from './@sub/frf-value-getter-element-status/@res/@abstract/@interface/common.interface';
import {
  FrfSelectorByTagInterface,
  FrfSelectorType,
  FrfValueGetterElementDataInterface,
  FrfValueGetterFieldValueInterface,
  FrfValueGetterLoadingStateInterface,
  FrfValueGetterServiceInterface,
  FrfValueGetterSystemDataInterface,
  FrfValueGetterTemptDataFromRequestActionInterface,
  FrfValueGetterTemptDataInterface
} from './@res/@abstract/@interface/common.inteface';
import {FrfValueGetterReferenceService} from './@sub/frf-value-getter-reference/frf-value-getter-reference.service';
import {FrfTriggerAndActionPipeService} from '../../frf-trigger/@sub/frf-trigger-and-action-pipe/frf-trigger-and-action-pipe.service';
import {
  FrfValueGetterByMaskInterface,
  FrfValueGetterClickedCustomMenuInterface
} from './@sub/frf-value-getter-by-mask/@res/@abstract/@interface/common.interface';
import {FrfValueGetterPayload} from './@sub/frf-value-getter-payload/@res/@abstract/@interface/common.interface';
import {FrfValueGetterPayloadOptions} from './@sub/frf-value-getter-payload-options/@res/@abstract/@interface/common.interface';
import {FrfValueGetterSystemDataService} from './@sub/frf-value-getter-system-data/frf-value-getter-system-data.service';
import {FrfValueGetterPayloadOptionsService} from './@sub/frf-value-getter-payload-options/frf-value-getter-payload-options.service';
import {FrfValueGetterClickedPreSubmitButtonInterface} from './@sub/frf-value-getter-clicked-pre-submit-button/@res/@interface/common.interface';
import {FrfValueGetterLoadingStateService} from './@sub/frf-value-getter-loading-state/frf-value-getter-loading-state.service';
import {FrfValueGetterElementStatusService} from './@sub/frf-value-getter-element-status/frf-value-getter-element-status.service';
import {FrfSharedStorageService} from '../../frf-shared-storage/frf-shared-storage.service';
import {FrfValueGetterTypeEnum} from './@res/@abstract/@enum/common.enum';
import {FrfValueGetterIfService} from './@sub/frf-value-getter-if/frf-value-getter-if.service';
import {FrfValueSetterTempDataStorageTypeEnum} from '../frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import {FrfValueGetterByMaskService} from './@sub/frf-value-getter-by-mask/frf-value-getter-by-mask.service';
import {frfTriggerActionRequestGetFullPathToTempDataFunction} from '../../frf-trigger/@sub/frf-trigger-action/@sub/@group:actions/frf-trigger-action-request/@res/@function/common.function';
import {FrfValueGetterClickedPreSubmitButtonService} from './@sub/frf-value-getter-clicked-pre-submit-button/frf-value-getter-clicked-pre-submit-button.service';
import {FrfValueGetterPayloadService} from './@sub/frf-value-getter-payload/frf-value-getter-payload.service';
import {FrfValueGetterCustomMenuService} from './@sub/frf-value-getter-custom-menu/frf-value-getter-custom-menu.service';
import {FrfValueGetterIfInterface} from './@sub/frf-value-getter-if/@res/@interface/common.interface';
import {FrfElementFolderTypeEnum} from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@enum/common.enum';
import * as _ from 'lodash';
import {FrfValueGetterMapElementsInterface} from "./@sub/frf-value-getter-map-elements/@res/@abstract/@interface/common.interface";
import {FrfValueGetterMapElementsService} from "./@sub/frf-value-getter-map-elements/frf-value-getter-map-elements.service";
import {FrfValueGetterCustomValueInterface} from "./@sub/frf-value-getter-custom-value/@res/@abstract/@interface/common.interface";
import {FrfValueGetterCustomValueService} from "./@sub/frf-value-getter-custom-value/frf-value-getter-custom-value.service";
import {FrfValueGetterScopeService} from "./@sub/frf-value-getter-scope/frf-value-getter-scope.service";
import {FrfValueGetterScopeInterface} from "./@sub/frf-value-getter-scope/@res/@abstract/@interface/common.interface";

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterService implements FrfValueGetterServiceInterface {
  private readonly keyForCustomValue = '{{value}}';

  constructor(
    private frfElementGetter: FrfElementGetterService,
    private frfElementSetter: FrfElementSetterService,
    private frfValueGetterReferenceService: FrfValueGetterReferenceService,
    private frfValueGetterByMaskService: FrfValueGetterByMaskService,
    private common: FreeformCommonService,
    private frfSharedStorageService: FrfSharedStorageService,
    private frfValueGetterTempData: FrfValueGetterTempDataService,
    private frfValueGetterLoadingState: FrfValueGetterLoadingStateService,
    private frfValueGetterSystemData: FrfValueGetterSystemDataService,
    private frfValueGetterCustomMenu: FrfValueGetterCustomMenuService,
    private frfValueGetterClickedPreSubmitButton: FrfValueGetterClickedPreSubmitButtonService,
    private frfValueGetterIf: FrfValueGetterIfService,
    private frfValueGetterMapElementsService: FrfValueGetterMapElementsService,
    private frfValueGetterPayloadOptions: FrfValueGetterPayloadOptionsService,
    private frfValueGetterPayload: FrfValueGetterPayloadService,
    private frfValueGetterElementStatus: FrfValueGetterElementStatusService,
    private frfValueGetterCustomValueService: FrfValueGetterCustomValueService,
    private frfTriggerAndActionPipe: FrfTriggerAndActionPipeService,
    private frfValueGetterScopeService: FrfValueGetterScopeService
  ) {
    this.frfSharedStorageService.addFrfValueGetter(this);

    window['getElementByPath'] = (path: string) => {
      const el = this.getFreefomObjectByInId('g1_r1_id2', this.common.freeform);

      return this.getElementByPath(this.common.freeform, path, el);
    };
  }

  /*
   * TODO delete latere replace to mz COMMON
   * */
  private connect = new OldConnectLibrary();

  /**
   * get value getter with flow$
   * */
  public getValueWithPipe(
    valueObject: any | null,
    frfElementObject: any | null,
    /* TODO add type later*/
    frf: any,
    startFrfElement: any | null,
    scope: any
  ): Observable<any> {
    const data = this.getValue(valueObject, frfElementObject, frf, startFrfElement, scope);

    return isObservable(data)
      ? data
      : of(
          data
      ).pipe(this.frfTriggerAndActionPipe.add(valueObject, frfElementObject, scope));
  }

  /**
   * create if not scope
   * */
  public safeGetScope (scope: any)
  {
    return (!scope || typeof scope !== 'object' || Array.isArray(scope))
    ? {}
    : scope;
  }

  /**
   * get value of difficult value block
   * TODO add valueObjects Interfaces
   * */
  public getValue(
    valueObject: any | null,
    frfElementObject: any | null,
    /* TODO add type later*/
    frf: any,
    startFrfElement: any | null = null,
    scope: any
  ) {
    const obj = valueObject,
      startElement = startFrfElement;

    if (typeof obj !== 'object') {
      return obj;
    } else {
      const type = obj['type'] || 'value';

      switch (type) {
        /**
         * input-param
         * TODO move ALL names and to sub services to enumcase
         * */
        case FrfValueGetterTypeEnum.inputParam: {
          const result = this.connect.getValueFromObjectByPath(
            obj['path'],
            frf && frf['inputParam']
          );
          return result;
        }

        /**
         * clicked-custom-menu
         * */
        case FrfValueGetterTypeEnum.clickedCustomMenu: {
          const data = <FrfValueGetterClickedCustomMenuInterface>obj,
            frfObject = data.id
              ? this.getFrfElementById(data.id, frf, null, startElement)
              : startElement;

          return this.frfValueGetterCustomMenu.getClickedMenu(
            data.key,
            frfObject,
            data.path
          );
        }

        /**
         * get payload options
         * */
        case FrfValueGetterTypeEnum.getPayloadOptions: {
          return this.frfValueGetterPayloadOptions.get(
            <FrfValueGetterPayloadOptions>obj,
            startElement,
            frf
          );
        }

        /**
         * get scope
         * */
        case FrfValueGetterTypeEnum.scope: {
          return this.frfValueGetterScopeService.get(
            <FrfValueGetterScopeInterface>obj,
            this,
            startElement,
            frf,
            scope
          );
        }

        /**
         * get payload
         * */
        case FrfValueGetterTypeEnum.getPayload: {
          return this.frfValueGetterPayload.get(
            <FrfValueGetterPayload>obj,
            startElement,
            frf
          );
        }

        /**
         * get element status
         * */
        case FrfValueGetterTypeEnum.elementStatus: {
          return this.frfValueGetterElementStatus.get(
            <FrfValueGetterElementStatus>obj,
            startElement,
            frf
          );
        }

        /**
         * clicked-pre-submit-button
         * */
        case FrfValueGetterTypeEnum.clickedPreSubmitButton: {
          const data = <FrfValueGetterClickedPreSubmitButtonInterface>obj,
            frfObject = data.id
              ? this.getFrfElementById(data.id, frf, null, startElement)
              : startElement;

          return this.frfValueGetterClickedPreSubmitButton.get(
            frfObject,
            data.path
          );
        }

        /**
         * if value getter
         * */
        case FrfValueGetterTypeEnum.if: {
          const data = <FrfValueGetterIfInterface>obj,
            frfObject = data.id
              ? this.getFrfElementById(data.id, frf, null, startElement)
              : startElement;

          return this.frfValueGetterIf.get(data, frfObject, scope);
        }

        /**
         *
         * */
        case FrfValueGetterTypeEnum.mapElements: {
          const data = <FrfValueGetterMapElementsInterface>obj,
            frfObject = data.id
              ? this.getFrfElementById(data.id, frf, data.scope || null, startElement)
              : startElement;

          return this.frfValueGetterMapElementsService.get(data, this, frfObject, frf, startElement, scope);
        }

        /**
         * custom-value
         * { value: any}
         * */
        case FrfValueGetterTypeEnum.customValue: {
          const data = <FrfValueGetterCustomValueInterface>obj,
                frfObject = obj.id
                  ? this.getFrfElementById(obj.id, frf, null, startElement)
                  : startElement;

          return  this.frfValueGetterCustomValueService.get(
            data,
            this,
            frfObject,
            frf,
            startElement,
            scope
          );
        }

        /**
         * system data
         * { value: any}
         * */
        case FrfValueGetterTypeEnum.systemData: {
          const data = <FrfValueGetterSystemDataInterface>obj,
            result = this.frfValueGetterSystemData.get(
              frf,
              data,
              frfElementObject,
              this,
              scope
            );

          return result;
        }

        /**
         * by-mask
         * { value: any}
         * */
        case FrfValueGetterTypeEnum.byMask: {
          const data = <FrfValueGetterByMaskInterface>obj,
            frfObject = data.id
              ? this.getFrfElementById(data.id, frf, null, startElement)
              : startElement,
            result = this.frfValueGetterByMaskService.get(
              data.mask,
              data.data,
              this,
              frfObject,
              frf,
              startElement,
              scope
            );

          return result;
        }

        /**
         * value:
         * */
        case FrfValueGetterTypeEnum.value:
          return obj['value'];

        /**
         * @self
         * */
        case FrfValueGetterTypeEnum.getThisElement:
          // get trigger initiator object (NEW)
          if (obj['pathToValue'] && startElement) {
            return this.connect.getValueFromObjectByPath(
              obj['pathToValue'],
              startElement
            );
          } else {
            return startElement;
          }

        /**
         * @self-value
         * */
        case FrfValueGetterTypeEnum.getThisElementValue:
          // get value from trigger initiator object (NEW) - CHECKED SUCCESS
          return this.connect.getValueFromObjectByPath(
            'body.value',
            startElement
          );
        // startElement['body']['value'];

        /**
         * @lid
         * */
        case FrfValueGetterTypeEnum.getDataFromLocalId: {
          // get this element by local id || get value by path from this element by local id (NEW)
          const elIdBylidEl = this.frfElementGetter.getByLocalId(
            frf,
            obj['lid'],
            startElement
          );
          // const elIdBylidEl = this.getElementIdByLocalId (iNfreeform, obj['lid'], startElement);
          if (elIdBylidEl) {
            const lidEl = this.getFreefomObjectByInId(
              elIdBylidEl,
              frf
              // ['fields','pages','groups','rows']
            );

            if (obj['pathToValue'] && lidEl) {
              return this.connect.getValueFromObjectByPath(
                obj['pathToValue'],
                lidEl
              );
            } else {
              return elIdBylidEl;
            }
          }
          return null;
        }

        /**
         * @lid-value
         * */
        case FrfValueGetterTypeEnum.getElementValueByLocalId: {
          // get this element by local id || get value by path from this element by local id (NEW)
          const elIdBylidEl = this.frfElementGetter.getByLocalId(
            frf,
            obj['lid'],
            startElement
          );
          // const elIdBylidEl = this.getElementIdByLocalId (iNfreeform, obj['lid'], startElement);
          if (elIdBylidEl) {
            const lidEl = this.getFreefomObjectByInId(
              elIdBylidEl,
              frf
              // ['fields','pages','groups','rows']
            );
            if (lidEl) {
              return this.connect.getValueFromObjectByPath('body.value', lidEl);
            } else {
              return elIdBylidEl;
            }
          }
          return null;
        }

        /**
         * @lid-id
         * */
        case FrfValueGetterTypeEnum.getElementByLocalId: {
          // get this element by local id || get value by path from this element by local id (NEW) (CHECKED SUCCESS)
          const elIdBylidEl = this.frfElementGetter.getByLocalId(
            frf,
            obj['lid'],
            startElement
          );

          return elIdBylidEl;
        }

        /**
         * @this-element
         * (ONLY WITH iNfreeformObject)
         * */
        case FrfValueGetterTypeEnum.thisElement: // (ONLY WITH iNfreeformObject)
          // get this element || get value by path from this element (NEW)
          if (obj['pathToValue'] && frfElementObject) {
            return this.connect.getValueFromObjectByPath(
              obj['pathToValue'],
              frfElementObject
            );
          } else {
            return frfElementObject;
          }

        case FrfValueGetterTypeEnum.byPathToElement: {
          return this.getElementByPath(frf, obj['path'], startElement);
        }

        /**
         * thisFieldValue
         * (ONLY WITH iNfreeformObject)
         * */
        /* TODO later will be deleted */
        case FrfValueGetterTypeEnum.thisFieldValueOld:
        case FrfValueGetterTypeEnum.thisFieldValue:
          // * only for - field-*
          return frfElementObject['body']['value'];

        // case 'fromAutocompleteResponse': //* only for - field-autocmplete
        case FrfValueGetterTypeEnum.fromAutocompleteResponse:
        /* TODO later will be deleted */
        case FrfValueGetterTypeEnum.fromAutocompleteResponseOld: {
          const id = obj['id'], // freeform object id
            freeformObject = id
              ? this.getFrfElementById(id, frf, null, startElement)
              : null,
            /* get freeform object (field-autocomplete) */
            gen = this.frfElementSetter.getGeneratedBlockForFreeformObject(
              freeformObject || startElement
            ), // get generated block
            response = (gen['response'] || {})['field-autocomplete'] || null; // get if we have selected obj last for field autocomplete
          let  result = null; // default result

          if (response) {
            result = this.connect.getValueFromObjectByPath(
              obj['pathToValue'],
              response
            );
          }

          return result;
        }

        // case 'fromAutocompleteSelected': //* only for - field-autocmplete
        case FrfValueGetterTypeEnum.fromAutocompleteSelected:
        /* TODO later will be deleted */
        case FrfValueGetterTypeEnum.fromAutocompleteSelectedOld: {
          const id = obj['id'], // freeform object id
            freeformObject = id
              ? this.getFrfElementById(id, frf, null, startElement)
              : startElement,
            // freeformObject  = this.getFreefomObjectByInId(id, frf), // get freeform object (field-autocomplete)
            gen = freeformObject
              ? this.frfElementSetter.getGeneratedBlockForFreeformObject(
                  freeformObject
                )
              : {}, // get generated block
            selected = (gen['selected'] || {})['field-autocomplete'] || null; // get if we have selected obj last for field autocomplete
          let  result = null; // default result

          if (selected) {
            result = this.connect.getValueFromObjectByPath(
              obj['pathToValue'],
              selected
            );
          }

          return result;
        }

        // case 'fieldValue': // ( for field-* )
        case FrfValueGetterTypeEnum.fieldValueOld: // ( for field-* )
        case FrfValueGetterTypeEnum.fieldValue: {
          // ( for field-* )
          const data = <FrfValueGetterFieldValueInterface>obj,
            id = data.id, // freeform object id
            frfFieldObject = id
              ? this.getFrfElementById(id, frf, null, startElement)
              : startElement;

          return (
            frfFieldObject &&
            frfFieldObject['body'] &&
            frfFieldObject['body']['value']
          );
        }

        case FrfValueGetterTypeEnum.tempData: {
          const data = <FrfValueGetterTemptDataInterface>obj,
            el = data.id
              ? this.getFrfElementById(data.id, frf, null, startElement)
              : startElement;

          return this.getTempDataByPath(
            data.path,
            el,
            FrfValueSetterTempDataStorageTypeEnum.user
          );
        }

        /**
         *
         * */
        case FrfValueGetterTypeEnum.loadingState: {
          const data = <FrfValueGetterLoadingStateInterface>obj,
            el = data.id
              ? this.getFrfElementById(data.id, frf, null, startElement)
              : startElement;

          return this.frfValueGetterLoadingState.get(el);
        }

        case FrfValueGetterTypeEnum.tempDataFromRequestAction: {
          const data = <FrfValueGetterTemptDataFromRequestActionInterface>obj,
            el = data.id
              ? this.getFrfElementById(data.id, frf, null, startElement)
              : startElement,
            pathToTempData = frfTriggerActionRequestGetFullPathToTempDataFunction(
              data.requestType,
              data.key
            );

          return this.getTempDataByPath(
            pathToTempData + ((data.path && `.${data.path}`) || ''),
            el,
            FrfValueSetterTempDataStorageTypeEnum.system
          );
        }

        /*
         * element user data ( for field-* )
         * */
        case FrfValueGetterTypeEnum.getElementDataOld: // element user data ( for field-* )
        case FrfValueGetterTypeEnum.getElementData: {
          // element user data ( for field-* )
          const //id        = this.getValue (obj['id'], frfElementObject, frf, stareFrfElement) ,
            freeform = frf,
            data = <FrfValueGetterElementDataInterface>obj,
            pathToValue = data.path, // required element (2/2)
            el = data.id
              ? this.getFrfElementById(data.id, freeform, null, startElement)
              : startElement;

          if (
            typeof pathToValue === 'string' &&
            pathToValue &&
            el &&
            el['body']['payload'] &&
            el['body']['payload']['$ed']
          ) {
            /* we have $ed (custom user data) for object -> r value */
            return this.connect.getValueFromObjectByPath(
              pathToValue,
              el['body']['payload']['$ed']
            );
          }

          /* we have no $ed for this object -> r null */
          return null;
        }
      }
    }
  }

  /**
   * get element or elements (if tag) by inId or localId
   * */
  public getFrfElementById(
    idBlock: { id: any; type: FrfSelectorType } | string,
    frf: any,
    searchObjects: FrfElementFolderTypeEnum[],
    startField: any,
    scope: any = {}
  ): any | any[] {
    if (typeof idBlock === 'object') {
      if (idBlock.type === 'lid') {
        return this.frfElementGetter.getByLocalId(frf, idBlock.id, startField);
      } else if (idBlock.type === 'byPath') {
        return this.getElementByPath(frf, idBlock.id, startField);
      } else if (idBlock.type === 'bySelector') {
        /* получение по тегам */
        return this.getElementsByTag(frf, idBlock.id, null, startField);
      } else {
        return this.getFreefomObjectByInId(idBlock.id, frf, searchObjects);
      }
    } else {
      return this.getFreefomObjectByInId(idBlock, frf, searchObjects);
    }
  }

  /**
   *
   * */
  public getFreefomObjectByInId(
    inId: string,
    frf: any,
    folders: FrfElementFolderTypeEnum[] | null = null
  ): any {
    // get model of this
    const freeform = frf; //this._freeform;

    // categories which search
    // const search     = iNsearchObjects || [ 'fields', 'pages', 'groups', 'rows' ];
    const search = folders || [
      FrfElementFolderTypeEnum.field,
      FrfElementFolderTypeEnum.page,
      FrfElementFolderTypeEnum.group,
      FrfElementFolderTypeEnum.row
    ];

    for (const type of search) {
      // search in this cateogry
      for (const modelid of Object.keys(freeform[type])) {
        //search in this categories' models if this model has objects (created object by this model)
        if (!freeform[type][modelid]['objects']) {
          continue;
        }

        for (const objid of Object.keys(freeform[type][modelid]['objects'])) {
          // search in this models' objects
          const obj = freeform[type][modelid]['objects'][objid];
          if (inId === obj['id']) {
            return obj;
          }
        }
      }
    }
    return null;
  }

  /**
   * get frf elements by tags
   * */
  public getElementsByTag (
    frf: any,
    tags: FrfSelectorByTagInterface,
    folders: FrfElementFolderTypeEnum[] | null = null,
    startField: any
  ): any[] {
    /* our full freeform object */
    const freeform = frf;

    /* categories which search */
    const search = folders || [
        FrfElementFolderTypeEnum.field,
        FrfElementFolderTypeEnum.page,
        FrfElementFolderTypeEnum.group,
        FrfElementFolderTypeEnum.row
      ],
      result: any[] = [];

    console.log(
      'getElementsByTag - search',
      {tags, folders, search, startField}
    );

    for (const type of search) {
      /* search in this cateogry */
      for (const modelid of Object.keys(freeform[type])) {
        /* search in this categories' models if this model has objects (created object by this model) */
        if (!freeform[type][modelid]['objects']) {
          continue;
        }

        for (const objid of Object.keys(freeform[type][modelid]['objects'])) {
          /* search in this models' objects */
          const obj = freeform[type][modelid]['objects'][objid],
                frfObjectTags: FrfSelectorByTagInterface = obj?.body?.label || null;

          if (
            frfObjectTags &&
            _.isEqual(tags, frfObjectTags)
          ) {
            result.push(obj)
          }
        }
      }
    }

    return result.length ? result : null;
  }

  /**
   * get element by path
   * */
  public getElementByPath (
    frf: any,
    path: string,
    frfElement: any
  ) {
    return this.frfValueGetterReferenceService
      .getElementByPath(
        frf,
        path,
        frfElement,
        this
      );
  }

  /**
   * get element by path
   * */
  public getTempDataByPath(
    path: string,
    frfElement: any,
    storageType: FrfValueSetterTempDataStorageTypeEnum = FrfValueSetterTempDataStorageTypeEnum.user
  ) {
    return this.frfValueGetterTempData.get(path, frfElement, storageType);
  }
}
