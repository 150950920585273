import {Injectable} from '@angular/core';
import {FrfTriggerActionCopyElementInterface} from './@res/@abstract/@interface/common.interface';
import {FreeformCommonService} from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import {FrfSharedStorageService} from '../../../../../../frf-shared-storage/frf-shared-storage.service';
import {FrfValueGetterService} from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import {FrfMainLazyServiceModule} from '../../../../../../../../frf-main-lazy-service.module';
import {FrfTriggerActionServiceInterface} from "../../../@res/@abstract/@interface/common.interface";
import {FrfCommon2Service} from "../../../../../../@shared/frf-common-2/frf-common-2.service";
import {FrfElementTypeEnum} from "@cnt-multi-shared/@shared/freeform/@res/@abstract/@enum/common.enum";
import {FreeformGroupService} from "../../../../../../../../@res/shared/service/freeform/_sub/freeform.group.service";

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionCopyElementService {
  constructor(
    private common: FreeformCommonService,
    private commonNew: FrfCommon2Service,
    private frfGroupService: FreeformGroupService
  ) {}

  /**
   *
   * */
  public async run(
    action: FrfTriggerActionCopyElementInterface,
    actionService: FrfTriggerActionServiceInterface,
    startElement: any,
    scope: any
  ): Promise<boolean> {
    const elements = actionService.getElementFromActionIfExist(action.id, startElement),
          firstElement = elements && elements[0],
          place = action.place ?? 'post',
          length = action.length,
          needLengthArray = Array(length ?? 1).fill(true)

    if (!elements || !Array.isArray(elements) || !firstElement) {
      return false;
    }

    const type = this.commonNew.getFrfObjectType(firstElement);

    /* while TODO work only for group */
    // tslint:disable-next-line:forin
    for (const idx in needLengthArray) {
      /* stop if we get length and we have already cout of elemnt */
      if (
        typeof length === "number" &&
        (parseInt(idx, 10) + 1) >= length
      ) {
        return true;
      }

      if (type === FrfElementTypeEnum.group) {
        if (place === 'post') {
          this.frfGroupService.copyToPost(
            firstElement
          )
        } else {
          this.frfGroupService.copyToPre(
            firstElement
          )
        }
      } else {
        return;
      }
    }

    return false;
  }
}
