import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CntFlexyViewComponent } from './@sub/@view/cnt-flexy-view/cnt-flexy-view.component';
import { CntFlexyViewContainerComponent } from './@sub/@view/cnt-flexy-view-container/cnt-flexy-view-container.component';
import { CntFlexyViewService } from './@sub/@service/cnt-flexy-view/cnt-flexy-view.service';
import { CntFlexyViewApiService } from './@sub/@service/cnt-flexy-view-api/cnt-flexy-view-api.service';
import { CntFlexyViewCategoryService } from './@sub/@service/cnt-flexy-view-category/cnt-flexy-view-category.service';

@NgModule({
  declarations: [CntFlexyViewComponent, CntFlexyViewContainerComponent],
  imports: [CommonModule],
  exports: [CntFlexyViewComponent, CntFlexyViewContainerComponent],
  providers: [
    CntFlexyViewService,
    CntFlexyViewApiService,
    CntFlexyViewCategoryService
  ]
})
export class FeatureCntFlexyViewModule {}
