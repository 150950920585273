import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CntFlexyViewHeaderMenuInterface } from './@res/@abstract/@interface/common.interface';
import { CntFlexyViewHeaderMenuEnum } from './@res/@abstract/@enum/common.enum';
import { CntFlexyViewCategoryForOwnerItemInterface } from '@cnt-nx-workspace/feature/cnt-flexy-view';

@Component({
  selector: 'cnt-flexy-view-header-menu',
  templateUrl: './cnt-flexy-view-header-menu.component.html',
  styleUrls: ['./cnt-flexy-view-header-menu.component.scss'],
})
export class CntFlexyViewHeaderMenuComponent implements OnInit {
  /**
   * */
  @Input() menus: CntFlexyViewHeaderMenuInterface[] = [
    { name: 'Войти', type: CntFlexyViewHeaderMenuEnum.signIn },
    { name: 'Выйти', type: CntFlexyViewHeaderMenuEnum.signOut },
    { name: 'Верификация', type: CntFlexyViewHeaderMenuEnum.verificate },
    { name: 'Выход', type: CntFlexyViewHeaderMenuEnum.quit },
  ];
  /**
   * */
  @Input() categories: CntFlexyViewCategoryForOwnerItemInterface[] = [];

  /**
   * */
  @Output() changedMenu: EventEmitter<
    CntFlexyViewHeaderMenuInterface
  > = new EventEmitter();

  /**
   * */
  @Output() clickToSearch: EventEmitter<void> = new EventEmitter();

  /**
   *
   * */
  @Output() clickToMenu: EventEmitter<
    CntFlexyViewCategoryForOwnerItemInterface
  > = new EventEmitter<CntFlexyViewCategoryForOwnerItemInterface>();

  /**
   * */
  items = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  /*
   *
   * */
  public changeMenu(menu: CntFlexyViewHeaderMenuInterface) {
    this.changedMenu.emit(menu);
  }

  /*
   *
   * */
  public clickToSearchHandler() {
    this.clickToSearch.emit();
  }

  /**
   * */
  public updateView() {
    this.cdRef.markForCheck();
  }
}
