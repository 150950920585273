import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { mapTo, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MzOnlineService {
  /**
   * */
  public static flow$: Observable<boolean> = merge(
    of(!!navigator?.onLine),
    fromEvent(window, 'online').pipe(mapTo(true)),
    fromEvent(window, 'offline').pipe(mapTo(false))
  ).pipe(shareReplay(1));

  /**
   * */
  public flow$ = MzOnlineService.flow$;
}
