import { Injectable } from '@angular/core';
import { FrfSubmitPathEnum } from '../../../../../../frf-submit/@res/@abstract/@enum/common.enum';
import { FrfTriggerActionSetElementStateInterface } from './@res/@abstract/@interface/common.interface';
import { FreeformCommonService } from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfValueGetterService } from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';
import { FrfElementTypeEnum } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@enum/common.enum';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionSetElementStateService {
  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfValueGetterService: FrfValueGetterService
  ) {}

  /**
   * set element state
   * */
  public async run(
    action: FrfTriggerActionSetElementStateInterface,
    startElement: any,
    scope: any
  ) {
    const element = action.id
        ? this.frfValueGetterService.getFrfElementById(
            action.id,
            this.common.freeform,
            null,
            startElement
          )
        : startElement,
      state = this.frfValueGetterService.getValue(
        action.value,
        element,
        this.common.freeform,
        element,
        scope
      );

    this.frfSharedStorage.frfValueSetter.setElementDataByPath(
      <FrfElementTypeEnum>startElement.options.object,
      element,
      FrfSubmitPathEnum.pathToElementState,
      state,
      [],
      true,
      false,
      true,
      action.checker,
      action.default,
      false
    );
  }
}
