import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FrfSubmitStateButtonInterface } from '../../../../../../../../@service/frf-submit/@res/@abstract/@interface/common.interface';
import { FrfPageSubmitInterface } from './@res/@abstract/@interface/common.interface';
import { FrfTriggerService } from '../../../../../../../../@service/frf-trigger/frf-trigger.service';
import { FrfSubmitService } from '../../../../../../../../@service/frf-submit/frf-submit.service';
import { FrfTriggerNameEnum } from '../../../../../../../../@service/frf-trigger/@res/@abstract/@enum/common.enum';
import { FrfValueSetterService } from '../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { takeUntil } from 'rxjs/operators';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'frf-page-submit',
  templateUrl: './frf-page-submit.component.html',
  styleUrls: ['./frf-page-submit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrfPageSubmitComponent extends AbstractUnsubscribeViewControl
  implements OnInit, OnChanges, AfterViewInit {
  @Input() frfElement: any;
  @Input() frf: any;
  @Input() isLastPage: any;
  @Input() status: boolean;
  @Input() modelId: string;
  @Input() objId: string;
  @Input() isLeftSide!: boolean;
  @Input() pageNumber!: number;
  @Input() pagesCount!: number;
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();

  public preSubmitButtonOfElement: FrfSubmitStateButtonInterface;
  public preSubmitButtonOfForm: FrfSubmitStateButtonInterface;
  public preSubmitButton: FrfPageSubmitInterface;
  public nextButton: FrfSubmitStateButtonInterface;
  public submitButton: FrfSubmitStateButtonInterface;
  public previousButton: FrfSubmitStateButtonInterface;

  constructor(
    public frfSubmit: FrfSubmitService,
    private cdRef: ChangeDetectorRef,
    private frfValueSetterService: FrfValueSetterService,
    public trigger: FrfTriggerService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initButtons();
  }

  ngAfterViewInit(): void {
    this.watchPageChanges();

    /* run trigger */
    this.trigger.run(this.frfElement, [FrfTriggerNameEnum.onInit], {});
  }

  /**
   * watch field changes
   * */
  private watchPageChanges() {
    this.frfValueSetterService
      .watchPageChanges(this.modelId, this.objId)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(() => {
        this.initButtons();
        this.cdRef.markForCheck();
      });
  }

  /**
   *
   * */
  public initButtons() {
    this.preSubmitButtonOfElement = this.frfSubmit.getElementPreSubmitButton(
      this.frfElement,
      this.isLeftSide
    );

    this.preSubmitButtonOfForm = this.frfSubmit.getFormPreSubmitButton(
      this.frf
    );

    if (this.isLeftSide) {
      this.previousButton = this.frfSubmit.getFrfElementPreviousButton(
        this.frfElement
      );
    } else {
      this.nextButton = this.frfSubmit.getFrfElementNextButton(this.frfElement);

      this.submitButton = this.frfSubmit.getFrfElementSubmitButton(
        this.frfElement
      );
    }

    /* get form button */
    if (this.preSubmitButtonOfElement) {
      this.preSubmitButton = {
        ...this.preSubmitButtonOfElement,
        isFormButton: false
      };
    } else if (this.preSubmitButtonOfForm) {
      this.preSubmitButton = {
        ...this.preSubmitButtonOfForm,
        isFormButton: false
      };
    } else {
      this.preSubmitButton = null;
    }
  }

  /**
   *
   * */
  public nextPage(button: any) {
    this.next.emit(button);
  }

  /**
   *
   * */
  public previousPage(button: any) {
    this.previous.emit(button);
  }

  /**
   *
   * */
  public submitPage(button: any) {
    this.submit.emit(button);
  }

  /**
   *
   * */
  public preSubmitPage(button: any) {
    this.frfSubmit.saveLastClickedPreSubmitBtnToElement(
      button,
      this.frf,
      this.frfElement
    );
  }
}
