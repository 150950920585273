import { HttpClient } from '@angular/common/http';
import { FrfValueGetterService } from '../../../../../@sub/@service/@group:value-services/frf-value-getter/frf-value-getter.service';

export class FreeformUrlLibrary {
  // later we add freeform
  freeform;

  // late we add http
  http;

  /**
   *
   * */
  private frfValueGetterService: FrfValueGetterService;

  setFreeform(iNfreeform) {
    this.freeform = iNfreeform;
  }

  // freeform common library
  // freeformCommon = new FreeformCommonLibrary();

  constructor(
    freeform: any,
    http: HttpClient,
    frfValueGetterService: FrfValueGetterService
  ) {
    this.freeform = freeform;
    this.http = http;
    this.frfValueGetterService = frfValueGetterService;
  }

  //@< url
  private getUrlWithQueryString(iNurl: string, iNparams: object | null = null) {
    const url = iNurl,
      params = iNparams;

    if (typeof params === 'object') {
      const queryStrings = this.getQueryStringFromParams(params);
      if (queryStrings) {
        const findParams = url.indexOf('?');
        if (findParams > -1) {
          // if we have allready in url params -> add new params
          return url + '&' + queryStrings;
        } else {
          // if we have  not allready in url params -> set new params
          return url + '?' + queryStrings;
        }
      } else {
        return url;
      }
    }
  }

  private getQueryStringFromParams(iNparams) {
    const params = iNparams;
    return Object.keys(params)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
  }
  //@> url

  public doByRequest(
    iNrequest,
    freeformObject,
    callback,
    freeform: object | null = null,
    scope: any
  ) {
    /* set freeform if passed */
    if (freeform) this.setFreeform(freeform);

    const field = freeformObject,
      request = iNrequest,
      url = request['url'],
      params = this.getParams(
        request['params'],
        freeformObject,
        freeform,
        scope
      ),
      body = this.getBodyForPost(
        request['body'],
        freeformObject,
        freeform,
        scope
      ),
      headers = this.getHeaders(
        request['headers'],
        freeformObject,
        freeform,
        scope
      ),
      requestType = request['requestType'] || 'get';
    // callback    = callback;

    if (typeof callback === 'function') {
      // do request with callaback function
      this.doRequestWithCallback(
        callback,
        requestType,
        url,
        params,
        body,
        headers
      );
    } else {
      // do request without callaback function
      this.doRequest(requestType, url, params, body, headers);
    }
  }

  public doRequest(
    iNurl,
    iNtype: string | null,
    iNparams: object | null,
    iNbody: object | null,
    iNheaders: object | null
  ) {
    const headers = iNheaders,
      body = iNbody,
      params = iNparams,
      type = iNtype || 'get';
    let url = iNurl,
      options;

    /* update url params if we have */
    if (params) {
      url = this.getUrlWithQueryString(url, params);
    }
    // add headers if we have
    if (headers && typeof headers === 'object') {
      options = {
        headers: headers
      };
    }
    switch (type) {
      case 'post':
        // post request
        if (options) return this.http.post(url, body, options);
        else return this.http.post(url, body);
      // break;
      default:
        // get reauest
        if (options) {
          return this.http.getClickedMenu(url, options);
        } else {
          return this.http.getClickedMenu(url);
        }
      // break;
    }
  }

  private doRequestWithCallback(
    iNcallback,
    iNtype: string | null,
    iNurl,
    iNparams: object | null,
    iNbody: object | null,
    iNheaders: object | null
  ) {
    const resultOfRequest = this.doRequest(
        iNurl,
        iNtype,
        iNparams,
        iNbody,
        iNheaders
      ),
      callback = iNcallback;
    //
    // console.log(
    //     'doRequestWithCallback - ',
    //     resultOfRequest,
    //     callback
    // );
    resultOfRequest.subscribe(response => {
      // console.log(
      //     'doRequestWithCallback - callback',
      //     typeof callback
      // );
      if (typeof callback === 'function') {
        callback(response);
      }
    });
  }

  // private getRequestType (iNfield): string | null {
  //   let field = iNfield;
  //   if (
  //     typeof field.body.payload.request === 'object' &&
  //     typeof field.body.payload.request.requestType === 'string'
  //   ) {
  //     return field.body.payload.request.requestType;
  //   }
  //   return null;
  // }

  /**
   *
   * */
  private getHeaders(
    obj: Array<any> | null,
    field: any,
    freeform: any,
    scope: any
  ): object | null {
    /* get headers for request */
    const objects = obj,
      result = {};
    let isHeader = false;

    if (objects) {
      for (const item of objects) {
        const key = item['key'];
        // val = this.freeformCommon.getFreeformObjectValue(obj, field, freeform);

        /* add to result object */
        result[key] = this.frfValueGetterService.getValue(
          typeof item.value !== 'undefined' ? item.value : item,
          field,
          freeform,
          field,
          scope
        );

        /* set true when get first result */
        if (!isHeader) isHeader = true;
      }
    }
    return isHeader ? result : null;
  }

  private getBodyForPost(
    obj: Array<object> | null,
    field: object,
    freeform: object,
    scope: any
  ): object | null {
    return this.getHeaders(obj, field, freeform, scope);
  }

  /**
   * get params for query string
   * */
  private getParams(params: any[], field: any, freeform: any, scope: any) {
    /*
     * get 'get' params  for request
     * */
    const objects = params,
      result = {};
    let isParams = false;
    if (objects) {
      for (const item of objects) {
        const key = item['key'];
        // val = this.freeformCommon.getFreeformObjectValue(obj['val'], iNfield, iNfreeform);
        /* add to result object */
        result[key] = this.frfValueGetterService.getValue(
          typeof item.value !== 'undefined' ? item.value : item,
          field,
          freeform,
          field,
          scope
        );

        /* set true when get first result */
        if (!isParams) isParams = true;
      }
    }

    return isParams ? result : null;
  }
}
