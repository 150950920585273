import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular-boost';
import { SignTypeEnum } from './@sub/@abstract/@enum/common.enum';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserFirestoreDataInterface } from './@sub/@abstract/@interface/common.interface';
import { MzStorageService } from 'mz-storage';

@Injectable()
export class ConnectAuthService {
  public get token() {
    return this.storage.get('token', 'auth-service');
  }
  public set token(data: string) {
    this.storage.set('token', data, 'auth-service');
  }

  public get phone() {
    return this.storage.get('phone', 'auth-service');
  }
  public set phone(data: string) {
    this.storage.set('phone', data, 'auth-service');
  }

  public get type(): SignTypeEnum {
    return this.storage.get('type', 'auth-service');
  }
  public set type(data) {
    this.storage.set('type', data, 'auth-service');
  }

  public get user(): string {
    return this.storage.get('user', 'auth-service');
  }
  public set user(data: string) {
    this.storage.set('user', data, 'auth-service');
  }

  public get userNumber(): number {
    return parseInt(this.storage.get('un', 'auth-service'), 10) || 0;
  }
  public set userNumber(data: number) {
    this.storage.set('un', data, 'auth-service');
  }

  public get uid(): string {
    return this.storage.get('uid', 'auth-service');
  }
  public set uid(data: string) {
    this.storage.set('uid', data, 'auth-service');
  }

  public get login(): string {
    return this.storage.get('login', 'auth-service');
  }
  public set login(data: string) {
    this.storage.set('login', data, 'auth-service');
  }

  public get name(): string {
    return this.storage.get('name', 'auth-service');
  }
  public set name(data: string) {
    this.storage.set('name', data, 'auth-service');
  }

  public get icon(): string {
    return this.storage.get('icon', 'auth-service');
  }
  public set icon(data: string) {
    this.storage.set('icon', data, 'auth-service');
  }

  constructor(
    private apollo: Apollo,
    private storage: MzStorageService,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore
  ) {}

  /*
   * save token to storage for late access
   * */
  public save(
    user: string,
    token: string,
    type: SignTypeEnum,
    uid: string,
    phone: string,
    login: string,
    icon: string,
    name: string
  ) {
    this.token = token;
    this.type = type;
    this.user = user;
    this.uid = uid;
    this.phone = phone;
    this.login = login;
    this.icon = icon;
    this.name = name;
  }

  /*
   * sign in with user login and password
   * */
  public signInWithLoginAndPassword(
    user: string,
    pswd: string
  ): Observable<any> {
    return this.apollo
      .watchQuery({
        query: gql`
          query authSignIn($user: String, $pswd: String) {
            authSignIn(user: $user, pswd: $pswd) {
              status
              token
              user
              phone
              login
              name
              icon
              uid
              displayName
              info {
                icon
                firstname
                lastname
              }
              error {
                code
                text
              }
            }
          }
        `,
        variables: {
          user,
          pswd,
        },
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.authSignIn)
      );
    // return this.apollo
    //   .watchQuery({
    //     query: gql`
    //           {
    //               authSignIn (user: "${user.trim()}", pswd: "${pswd.trim()}") {
    //                   status,
    //                   token,
    //                   user,
    //                   phone,
    //                   uid,
    //                   displayName,
    //                   info {
    //                       icon,
    //                       firstname,
    //                       lastname
    //                   }
    //                   error {
    //                       code,
    //                       text
    //                   }
    //               }
    //           }
    //       `,
    //   })
    //   .valueChanges.pipe(
    //     map((result: any) => result.data && result.data.authSignIn)
    //   );
  }

  /*
   * sign up with user login, password, phone
   * */
  public signUpWithLoginAndPassword(
    user: string,
    pswd: string,
    phone: number
  ): Observable<any> {
    return this.apollo
      .watchQuery({
        query: gql`
                {
                    authSignUp (login: "${user.trim()}", pswd: "${pswd.trim()}",  phone: "${phone}") {
                        status,
                        token,
                        login,
                        phone,
                        uid,
                        displayName,
                        info {
                            icon,
                            firstname,
                            lastname
                        }
                        error {
                            code,
                            text
                        }
                    }
                }
            `,
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.authSignUp)
      );
  }

  /*
   * check login for isset
   * */
  public checkLogin(login: string) {
    return this.apollo
      .watchQuery({
        query: gql`
              {
                  authCheckIssetLogin (login: "${login}") {
                      status
                  }
              }
          `,
      })
      .valueChanges.pipe(
        map((result: any) => {
          return result.data && result.data.authCheckIssetLogin;
        })
      );
  }

  /*
   * check phone for isset
   * */
  public checkPhone(phone: number) {
    return this.apollo
      .watchQuery({
        query: gql`
            {
                authCheckIssetPhone (phone: "${phone}") {
                    status
                }
            }
            `,
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.authCheckIssetPhone)
      );
  }

  /*
   * check code
   * */
  public checkCode(
    type: SignTypeEnum,
    token: string,
    login: string,
    code: string
  ) {
    if (type == SignTypeEnum.forSignUp) {
      return this.apollo
        .watchQuery({
          query: gql`
              {
                  authCheckCodeForSignUp (login: "${login}", code:"${code}", token: "${token}", passFromAnonym: false, anonymUid: null, anonymToken: null) {
                    fkey,
                    un,
                    status,
                    moved
                  }
              }
          `,
        })
        .valueChanges.pipe(
          map(
            (result: any) => result.data && result.data.authCheckCodeForSignUp
          )
        );
    } else {
      return this.apollo
        .watchQuery({
          query: gql`
              {
                  authCheckCodeForSignIn (login: "${login}", code:"${code}", token: "${token}", passFromAnonym: false, anonymUid: null, anonymToken: null) {
                    fkey,
                    un,
                    status,
                    moved
                  }
              }
          `,
        })
        .valueChanges.pipe(
          map(
            (result: any) => result.data && result.data.authCheckCodeForSignIn
          )
        );
    }
  }

  /*
   * resend message with code
   * */
  public resendMessage(uid: string, token: string) {
    return this.apollo
      .watchQuery({
        query: gql`
                {
                    authResendMessage (uid: "${uid}", token: "${token}") {
                        token,
                        status,
                        error {
                            code,
                            text
                        }
                    }
                }
            `,
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.authResendMessage)
      );
  }

  /*
   * sign out
   * */
  public disableToken(uid: string, token: string) {
    return this.apollo
      .watchQuery({
        query: gql`
                {
                    authSignOut (
                      uid: "${uid}",
                      token: "${token}"
                    ) {
                          status,
                          error {
                            code,
                            text
                          }
                    }
                }
            `,
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.authSignOut)
      );
  }

  /*
   *
   * */
  public getUserIdFromFirebase(): Observable<any> {
    return this.afAuth.authState;
  }

  /**
   *
   * */
  public getUidFromFirebase() {
    return this.getUserIdFromFirebase().pipe(
      map((result) => result && result.uid)
    );
  }

  /*
   *
   * */
  public isLogined(): Observable<boolean> {
    return Observable.create((observer: Observer<boolean>) => {
      this.getUserIdFromFirebase().subscribe((user) => {
        let isLogined = false;
        if (user) {
          // if we have user
          isLogined = true;
        }
        observer.next(isLogined);
        observer.complete();
      });
    });
  }

  /*
   * get user id
   * */
  public getUserId(): Observable<string> {
    return Observable.create((observer: Observer<boolean>) => {
      this.getUidFromFirebase().subscribe((user) => {
        observer.next(user);
        observer.complete();
      });
    });
  }

  /*
   * sign out
   * */
  public signOut(): Observable<boolean> {
    return Observable.create((observer: Observer<boolean>) => {
      this.disableToken(this.uid, this.token).subscribe((response) => {
        // if (response) {
        // } else {
        //   observer.next(false);
        //   observer.complete();
        // }
        this.signOutFromFirebase(observer);
      });
    });
  }

  private signOutFromFirebase(observer: Observer<boolean>) {
    this.afAuth /*.auth*/
      .signOut()
      .then(() => {
        // TODO delete local storage data for this user
        this.storage.clearFull();
        observer.next(true);
        observer.complete();
      })
      .catch(() => {
        observer.next(false);
        observer.complete();
      });
  }

  /*
   * sign in for firebase with custom token
   * */
  public signWithToken(token): Observable<boolean> {
    return Observable.create((observer: Observer<boolean>) => {
      // this.afAuth.auth.signInWithCustomToken(token).then(
      this.afAuth.signInWithCustomToken(token).then(
        () => {
          observer.next(true);
          observer.complete();
        },
        () => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  /*
   * get user from firebase
   * */
  public getCurrentUser(
    userId: string
  ): Observable<UserFirestoreDataInterface | null | any> {
    return this.firestore
      .doc(`users/${userId}`)
      .valueChanges()
      .pipe(
        map((response) => {
          // const result: UserFirestoreDataInterface = response;
          return response; /* &&
                        result.info &&
                        result.info.data*/
        })
      );
  }
}
