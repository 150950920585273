<div class="freeform-side">
  <router-outlet
    [ngClass]="{ _hide: !frfActivate }"
    (activate)="frfActivate = true"
    (deactivate)="frfActivate = false"
    name="freeform"
  >
  </router-outlet>
</div>

<div class="camera-side" *ngIf="cameraActivate">
  <mz-camera></mz-camera>
</div>

<div class="content">
  <router-outlet></router-outlet>
</div>

<app-zone-footer
  [ownerId]="navigateService.openedLogin$ | async"
></app-zone-footer>
