import { Injectable } from '@angular/core';
import { FrfValueGetterClassInterface } from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueSetterTempDataStorageTypeEnum } from '../../../frf-value-setter/@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FrfValueGetterTempDataService } from '../frf-value-getter-temp-data/frf-value-getter-temp-data.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfTriggerActionSetLoadingStateGetTempPathFunction } from '../../../frf-value-setter/@sub/frf-value-setter-payload/@res/@function/common.function';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterLoadingStateService
  implements FrfValueGetterClassInterface {
  constructor(private frfValueGetterTempData: FrfValueGetterTempDataService) {}

  /**
   *
   * */
  public get(frfElement: any) {
    return this.frfValueGetterTempData.get(
      FrfTriggerActionSetLoadingStateGetTempPathFunction(),
      frfElement,
      FrfValueSetterTempDataStorageTypeEnum.system
    );
  }
}
