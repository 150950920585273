import {
  MzCacheState,
  MzCacheType,
  MzCacheTypeEnum
} from './@res/@abstract/@enum/common.enum';
import { Observable, Observer, Subject } from 'rxjs';
import { filter, take, tap, takeUntil, map } from 'rxjs/operators';
import {
  MzCacheDecoratorOptionsType,
  MzCacheDecoratorSimpleType,
  MzCacheDecoratorType,
  MzCacheState$,
  MzStateType,
  MzStorageType
} from './@res/@abstract/@type/common.type';

/**
 * Class CacheData
 *
 * Needs for cache data
 * */
export class MzCacheClass {
  /**
   * destroy changes
   * */
  public static readonly destroy$: Subject<any> = new Subject();

  /**
   * object storage from data
   * */
  private static storage: MzStorageType = {};

  /**
   * storage with states
   * */
  private static state: MzStateType = {};

  /**
   * flow for spy changes state
   * */
  public static readonly state$: Subject<MzCacheState$> = new Subject();

  /**
   * check has data in cache
   *
   * @param {number} group - id of storage data
   * @param {number | string} key - additional key of storage data
   * @param {function} compare - compare function
   * @param {string} hash - hash string
   * */
  public static hasInCache(
    group: string,
    key: string | number,
    compare: any,
    hash: string
  ): boolean {
    return (
      typeof compare === 'function' &&
      compare(
        this.storage[group] &&
          this.storage[group][key] &&
          this.storage[group][key][hash]
      )
    );
  }

  /**
   * update cache
   * TODO
   * @param {number} group - id of storage data
   * @param {string} key - additional key
   * @param {any} data - data for save
   * @param {any[]} args - argument
   * @param {string} hash - hash
   * */
  public static updateStorageDataByGroupAndKey(
    group: string,
    key: string,
    data: any,
    args: any[] = [],
    hash?: string
  ) {
    this.safePrepareStorage(group, key);

    if (typeof hash === 'undefined') {
      hash = this.getHashFromArguments(args);
    }

    this.storage[group][key][hash] = data;

    this.changeState(group, key, MzCacheState.ready, data, args, hash);
  }

  /*
   *
   * */
  private static safePrepareStorage(group: string, key: string) {
    if (typeof this.storage[group] !== 'object') this.storage[group] = {};
    if (typeof this.storage[group][key] !== 'object')
      this.storage[group][key] = {};
  }

  /*
   *
   * */
  private static safePrepareStateStorage(group: string, key: string) {
    if (typeof this.state[group] !== 'object') this.state[group] = {};
    if (typeof this.state[group][key] !== 'object') this.state[group][key] = {};
  }

  /**
   * update state
   * TODO
   * @param {enum number} group - id of storage data
   * @param {string | number} key - additional key
   * @param {any[]} args - arguments passed to function
   * @param {string} hash - hash string
   * @param {enum CacheState} state - data for save
   * @param {any} data - data for emmit from flow$
   * */
  public static changeState(
    group: string,
    key: string,
    state: MzCacheState,
    data: any,
    args: any[] = [],
    hash?: string
  ) {
    this.safePrepareStateStorage(group, key);

    if (typeof hash === 'undefined') {
      hash = this.getHashFromArguments(args);
    }

    /*
     * add to state
     * */
    this.state[group][key][hash] = {
      group: group,
      state,
      key: key,
      hash,
      args,
      data,
      time: new Date().getTime()
    };
    /*
     * emit for for change state
     * */
    this.state$.next({
      group,
      state,
      key,
      hash,
      args,
      time: new Date().getTime(),
      data
    });
  }

  /**
   * get from cache after data will be ready
   *
   * @param {enum number} group - unique id for storage data
   * @param {enum number} key - unique id for storage data
   * @param {enum number} args - unique id for storage data
   * @param {boolean} onlyChanges - get only changes
   * */
  public static getDataByGroupAndKeyAndArgs$(
    group: string,
    args: any[] = [],
    key = '0',
    onlyChanges: boolean = false
  ): Observable<MzCacheState$> {
    const hash = this.getHashFromArguments(args);

    return Observable.create((observer: Observer<any>) => {
      const result$ = this.state$
        .pipe(
          filter(state => {
            return (
              state.group === group &&
              state.key === key &&
              state.hash === hash &&
              state.state === MzCacheState.ready
            );
          })
        )
        .subscribe(result => {
          observer.next(result);
        });

      if (
        onlyChanges === false &&
        this.state[group] &&
        this.state[group][key] &&
        this.state[group][key][hash] &&
        this.state[group][key][hash].state !== MzCacheState.notReady
      ) {
        observer.next(this.state[group][key][hash]);
      }

      return () => {
        result$.unsubscribe();
      };
    });
  }

  /**
   * get from cache after data will be ready
   *
   * @param {enum number} group - unique id for storage data
   * @param {enum number} key - unique id for storage data
   * @param {boolean} onlyChanges - get only changes
   * */
  public static getDataByGroupAndKey$(
    group: string,
    key = '0',
    onlyChanges: boolean = false
  ): Observable<MzCacheState$> {
    return Observable.create((observer: Observer<any>) => {
      const result$ = this.state$
        .pipe(
          filter(state => {
            return (
              state.group === group &&
              state.key === key &&
              state.state === MzCacheState.ready
            );
          })
        )
        .subscribe(result => {
          observer.next(result);
        });

      /*
       * initial data emit
       * */
      if (!onlyChanges && this.state[group] && this.state[group][key]) {
        for (const hash of Object.keys(this.state[group][key])) {
          if (this.state[group][key][hash].state !== MzCacheState.notReady) {
            observer.next(this.state[group][key][hash]);
          }
        }
      }

      return () => {
        result$.unsubscribe();
      };
    });
  }

  /**
   * get from cache after data will be ready
   *
   * @param {enum number} group - unique id for storage data
   * @param {enum number} key - unique id for storage data
   * @param {boolean} onlyChanges - get only changes
   * */
  public static getDataByGroup$(
    group: string,
    onlyChanges: boolean = false
  ): Observable<MzCacheState$> {
    return Observable.create((observer: Observer<any>) => {
      const result$ = this.state$
        .pipe(
          filter(state => {
            return state.group === group && state.state === MzCacheState.ready;
          })
        )
        .subscribe(result => {
          observer.next(result);
        });

      /*
       * initial data emit
       * */
      if (!onlyChanges && this.state[group]) {
        for (const key of Object.keys(this.state[group])) {
          if (this.state[group][key]) {
            for (const hash of Object.keys(this.state[group][key])) {
              if (
                this.state[group][key][hash].state !== MzCacheState.notReady
              ) {
                observer.next(this.state[group][key][hash]);
              }
            }
          }
        }
      }

      return () => {
        result$.unsubscribe();
      };
    });
  }

  /**
   * get all changes
   *
   * @param {boolean} onlyChanges - get only changes
   * */
  public static get$(onlyChanges: boolean = false): Observable<MzCacheState$> {
    return Observable.create((observer: Observer<any>) => {
      const result$ = this.state$
        .pipe(
          filter(state => {
            return state.state === MzCacheState.ready;
          })
        )
        .subscribe(result => {
          observer.next(result);
        });

      /*
       * initial data emit
       * */
      if (!onlyChanges && this.state) {
        for (const group of Object.keys(this.state)) {
          for (const key of Object.keys(this.state[group])) {
            if (this.state[group][key]) {
              for (const hash of Object.keys(this.state[group][key])) {
                if (
                  this.state[group][key][hash].state !== MzCacheState.notReady
                ) {
                  observer.next(this.state[group][key][hash]);
                }
              }
            }
          }
        }
      }

      return () => {
        result$.unsubscribe();
      };
    });
  }

  public static getStorageData(group: string, key = '0', args: any[] = []) {
    const hash = this.getHashFromArguments(args);

    return (
      this.storage[group] &&
      this.storage[group][key] &&
      this.storage[group][key][hash]
    );
  }

  /**
   * check is progress or no
   *
   * @param {number} group - unique id for storage data
   * @param {number | number} key - additional id for storage data
   * @param {string} hash - from function
   * */
  public static isProgress(
    group: string,
    key: string | number,
    hash: string
  ): boolean {
    return (
      this.state[group] &&
      this.state[group][key] &&
      this.state[group][key][hash] &&
      this.state[group][key][hash] &&
      this.state[group][key][hash].state === MzCacheState.notReady
    );
  }

  /**
   * checkTimeout
   * @param {number} group - unique id for storage data
   * @param {string} key - additionalKey
   * @param {string} hash - unique id for storage data
   * @param {number} ms - timeout period
   * */
  public static isTimeoutExpired(
    group: string,
    key: string,
    hash: string,
    ms: number
  ): boolean {
    return (
      ms !== 0 &&
      this.state[group] &&
      this.state[group][key] &&
      this.state[group][key][hash] &&
      this.state[group][key][hash].state !== MzCacheState.notReady &&
      this.state[group][key][hash].time + ms < new Date().getTime()
    );
  }

  /**
   * clear from cache by key
   * @param {number} group - unique id for storage data
   * */
  public static clearByGroup(group: string) {
    if (this.storage && this.storage[group]) {
      for (const additionalKey of Object.keys(this.storage[group])) {
        this.clearByGroupAndKey(group, additionalKey);
      }

      /*
       * delete storage
       * */
      delete this.storage[group];

      /*
       * delete additional key
       * */
      delete this.state[group];
    }
  }

  /**
   * clear by keys array
   * */
  public static clearByGroups(group: string[]) {
    for (let key of group) {
      this.clearByGroup(key);
    }
  }

  /**
   * clear from cache by key
   * @param {string} group - unique id for storage data
   * @param {string} key - unique id for storage data
   * */
  public static clearByGroupAndKey(group: string, key: string) {
    if (this.storage[group] && this.storage[group][key]) {
      for (const hash of Object.keys(this.storage[group][key])) {
        this.clearByGroupAndKeyAndHash(group, hash, key);
      }
    }

    /*
     * delete storage
     * */
    delete this.storage[group][key];

    /*
     * delete additional key
     * */
    delete this.state[group][key];
  }

  /*
   * clear by key additional key and argus
   * */
  public static clearByGroupAndKeyAndArgs(
    group: string,
    args: any[] = [],
    key: string = '0'
  ) {
    this.clearByGroupAndKeyAndHash(group, this.getHashFromArguments(args), key);
  }

  /*
   * clear by key additional key and hash
   * */
  public static clearByGroupAndKeyAndHash(
    group: string,
    hash: string,
    key: string = '0'
  ) {
    /*
     * delete storage
     * */
    if (
      this.storage[group] &&
      this.storage[group][key] &&
      this.storage[group][key][hash]
    ) {
      delete this.storage[group][key][hash];
    }

    /*
     * stop state
     * */
    if (
      this.state[group] &&
      this.state[group][key] &&
      this.state[group][key][hash]
    ) {
      const state = this.state[group][key][hash];
      /*
       * emit
       * */
      this.state$.next({
        ...state,
        time: new Date().getTime(),
        args: state.args,
        state: MzCacheState.ready
      });

      /*
       * destroy originals
       * */
      this.destroyOriginSource(group, key, hash);

      delete this.state[group][key][hash];
    }
  }

  /**
   * clear full
   * */
  public static clear() {
    this.clearByGroups(Object.keys(this.storage));
  }

  /**
   * get changes with flow$ by key and state
   * @param {enum number[]} groups - unique id for storage data
   * @param {enum CacheState} state - state for filter result
   * */
  public static getChangesByGroup$(
    groups: string[],
    state: MzCacheState | null = MzCacheState.ready
  ): Observable<MzCacheState$> {
    return this.state$.pipe(
      filter(item => {
        if (typeof state === 'number' && state !== item.state) {
          return false;
        }

        return groups.indexOf(item.group) !== -1;
      })
    );
  }

  /**
   * hash string
   * */
  public static hash(str: string): string {
    let hash = 0,
      i,
      chr;
    if (str.length === 0) return hash + '';
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return hash + '';
  }

  /**
   * get hash from arguments
   * */
  public static getHashFromArguments(
    args: any[],
    optionsIndex: number | null = null
  ): string {
    if (typeof optionsIndex === 'number') {
      args = args.filter((item, idx) => idx !== optionsIndex);
    }
    const result = args
      .map(arg => {
        return JSON.stringify(arg);
      })
      .join('.');

    return this.hash(result);
  }

  /*
   * destroy origin source
   * */
  public static destroyOriginSource(group: string, key: string, hash: string) {
    MzCacheClass.destroy$.next({
      group,
      key,
      hash
    });
  }
}

function destroyOriginal$(group: string, key: string, hash: string) {
  return MzCacheClass.destroy$.pipe(
    filter(result => {
      return (
        result.group === group && result.key === key && result.hash === hash
      );
    })
  );
}

/**
 * mz cache observable get cache (@decorator)
 * */
export function MzCache(data: MzCacheDecoratorType): MethodDecorator {
  /*
   * set initial data
   * */
  if (typeof data.timeout !== 'number') data.timeout = 0;

  /*
   * callback function
   * */
  if (typeof data.hasInCache !== 'function')
    data.hasInCache = dataFromStorage => typeof dataFromStorage !== 'undefined';

  /*
   * TODO add later (save in local storage) NOW save only object
   * where save data
   * */
  if (typeof data.storage !== 'number') data.storage = MzCacheType.inObject;

  // @ts-ignore
  return function(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const method = descriptor.value;
    descriptor.value = function(...args: any[]) {
      const optionsIndex =
          typeof data.optionsIndex === 'number' ? data.optionsIndex : null,
        /*
         * get options if isset in args
         * */
        options: MzCacheDecoratorOptionsType =
          typeof optionsIndex === 'number' && args[optionsIndex],
        /*
         * get hash from arguments
         * */
        hash = MzCacheClass.getHashFromArguments(args, optionsIndex),
        /*
         * get callback function from options of from params decorators
         * */
        callbackForCompare =
          options && typeof options.hasInCache === 'function'
            ? options.hasInCache
            : data.hasInCache,
        /*
         * timeout get from options or params
         * */
        timeout: number = (options && options.timeout) || data.timeout || 0,
        /*
         * additional key
         * */
        key = options && options.key ? options.key : data.key ? data.key : '0';

      if (
        !MzCacheClass.isProgress(data.group, key, hash) &&
        (!MzCacheClass.hasInCache(data.group, key, callbackForCompare, hash) ||
          /*
           * if passed forseUpdate as argument to function
           * */
          (options && options.forceUpdate) ||
          /*
           *
           * */
          MzCacheClass.isTimeoutExpired(data.group, key, hash, timeout))
      ) {
        /*
         * destroy previous connects
         * */
        MzCacheClass.destroyOriginSource(data.group, key, hash);

        /*
         * set not ready state
         * */
        MzCacheClass.changeState(
          data.group,
          key,
          MzCacheState.notReady,
          null,
          args,
          hash
        );

        switch (data.type) {
          case MzCacheTypeEnum.observable:
            return Observable.create((observer: Observer<any>) => {
              method
                .apply(this, args)
                .pipe(takeUntil(destroyOriginal$(data.group, key, hash)))
                .subscribe(
                  (result: any) => {
                    /*
                     * update
                     * */
                    MzCacheClass.updateStorageDataByGroupAndKey(
                      data.group,
                      key,
                      result,
                      args,
                      hash
                    );

                    observer.next(result);
                  },
                  (err: any) => {
                    observer.error(err);
                  },
                  () => {
                    observer.complete();
                  }
                );
            });

          case MzCacheTypeEnum.promise:
            return new Promise((resolve, reject) => {
              method
                .apply(this, args)
                .then((response: any) => {
                  /*
                   * update
                   * */
                  MzCacheClass.updateStorageDataByGroupAndKey(
                    data.group,
                    key,
                    response,
                    args,
                    hash
                  );

                  resolve(response);
                })
                .catch((err: any) => {
                  reject(err);
                });
            });

          case MzCacheTypeEnum.simple:
            const response = method.apply(this, args);

            /*
             * update
             * */
            MzCacheClass.updateStorageDataByGroupAndKey(
              data.group,
              key,
              response,
              args,
              hash
            );

            return response;
        }
      } else {
        switch (data.type) {
          case MzCacheTypeEnum.observable:
            return MzCacheClass.getDataByGroupAndKeyAndArgs$(
              data.group,
              args,
              key
            ).pipe(
              takeUntil(destroyOriginal$(data.group, key, hash)),
              map(state => {
                return state.data;
              })
            );

          case MzCacheTypeEnum.promise:
            return MzCacheClass.getDataByGroupAndKeyAndArgs$(
              data.group,
              args,
              key
            )
              .pipe(
                take(1),
                map(state => state.data)
              )
              .toPromise();

          case MzCacheTypeEnum.simple:
            return MzCacheClass.getStorageData(data.group, key, args);
        }
      }
    };
    return descriptor;
  };
}

export function MzCacheObservable(data: MzCacheDecoratorSimpleType) {
  return MzCache({
    ...data,
    type: MzCacheTypeEnum.observable
  });
}

export function MzCachePromise(data: MzCacheDecoratorSimpleType) {
  return MzCache({
    ...data,
    type: MzCacheTypeEnum.promise
  });
}

export function MzCacheSimple(data: MzCacheDecoratorSimpleType) {
  return MzCache({
    ...data,
    type: MzCacheTypeEnum.simple
  });
}
