import { Injectable } from '@angular/core';
import {
  FrfValueGetterClassInterface,
  FrfValueGetterServiceInterface
} from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueCheckerService } from '../../../frf-value-checker/frf-value-checker.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfValueGetterMapElementsInterface } from './@res/@abstract/@interface/common.interface';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { tap } from 'rxjs/operators';

/**
 * получение с обходом элементов
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterMapElementsService
  implements FrfValueGetterClassInterface {
  constructor(private frfValueChecker: FrfValueCheckerService) {}

  /**
   *
   * */
  public get(
    data: FrfValueGetterMapElementsInterface,
    valueGetter: FrfValueGetterServiceInterface,
    frfElement: any | any[],
    frf: any,
    startFrfElement: any,
    scope: any
  ): Observable<any[]> {
    console.log('FrfValueGetterMapElementsService - get', 1, {
      frfElement,
      data
    });
    return fromPromise(
      this.getInPromise(
        data,
        valueGetter,
        frfElement,
        frf,
        startFrfElement,
        scope
      )
    ).pipe(
      tap(result => {
        console.log(
          'FrfValueGetterMapElementsService - get',
          2,
          JSON.stringify(result)
        );
      })
    );
  }
  /**
   *
   * */
  public async getInPromise(
    data: FrfValueGetterMapElementsInterface,
    valueGetter: FrfValueGetterServiceInterface,
    frfElement: any | any[],
    frf: any,
    startFrfElement: any,
    scope: any
  ): Promise<any[]> {
    const result = [],
      elements = Array.isArray(frfElement) ? frfElement : [frfElement];

    console.log('getInPromise', 1, {
      elements,
      data
    });

    for (const el of elements) {
      const value = await valueGetter
        .getValueWithPipe(data.value, el, frf, el, scope)
        .toPromise();

      result.push(value);
    }
    console.log('getInPromise', 2, {
      result
    });

    return result;
  }
}
