<header class="header-height cnt-flexy-page-header">
  <div class="container">
    <div class="block">
      <div class="logo">
        <img [src]="getLogo()" alt="User logo" />
      </div>

      <ng-template [ifScreenTypeNot]="'small'" (mzScreenChange)="updateView()">
        <div class="menu-block">
          <ul class="menu">
            <li class="menu" *ngFor="let category of categories">
              <cnt-flexy-view-category
                [type]="category.type"
                [value]="category.value"
                [name]="category.name"
                (clickToMenu)="clickToMenu(category)"
              >
              </cnt-flexy-view-category>
            </li>
          </ul>
        </div>
      </ng-template>

      <div class="system-buttons">
        <div class="menu">
          <cnt-flexy-view-header-menu
            [categories]="categories"
            (changedMenu)="clickedHeaderMenu($event)"
            (clickToSearch)="showSearchModalWindow()"
            (clickToMenu)="clickToMenu($event)"
            [menus]="headerMenus"
          ></cnt-flexy-view-header-menu>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="header-height pseudo"></div>
