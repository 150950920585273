<div
  class="fast-btns-block"
  *ngIf="fastButtonsForMain && fastButtonsForMain.length"
>
  <div class="in_active_block">
    <ng-container *ngFor="let fastButton of fastButtonsForMain">
      <div
        class="fast-btn-item"
        [title]="fastButton.title"
        (click)="openFastButton(fastButton)"
      >
        <div class="block_in_icons_active">
          <div class="div_active_img">
            <img [src]="fastButton.icon" class="active_img_in" />
          </div>
          <div class="text_active_block">{{ fastButton.name }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
