import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { UserFirestoreService } from '../../../../../../../shared/@sub/@service/user-firestore/user-firestore.service';
import { NavigateService } from '../../../../../@service/navigate/navigate.service';
import { takeUntil } from 'rxjs/operators';
import {
  CntFlexyViewCategoryForOwnerItemInterface,
  CntFlexyViewCategoryService,
  CntFlexyViewForOwnerItemInterface,
  CntFlexyViewService,
} from '@cnt-nx-workspace/feature/cnt-flexy-view';
import { MainSharedService } from '../../../../../../../shared/@sub/@service/main-shared/main-shared.service';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { MainUserService } from '../../../../../../../shared/@sub/@service/@main/@user/main-user/main-user.service';
import { CntFlexyViewUserInfoInterface } from '@cnt-multi-shared/@shared/@library/@group:flexy-view/@res/@interface/user-info.enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main-info',
  templateUrl: './main-info.component.html',
  styleUrls: ['./main-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainInfoComponent extends AbstractUnsubscribeViewControl
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('templatePortalContent') templatePortalContent: TemplateRef<any>;

  /**
   * */
  // public user: UserFirestoreType;
  public user: CntFlexyViewUserInfoInterface;

  /**
   *
   * */
  public categories: CntFlexyViewCategoryForOwnerItemInterface[] = [];

  /*
   *
   * */
  public flexyViews: CntFlexyViewForOwnerItemInterface[] = [];

  /**
   *
   * */
  public templatePortal: TemplatePortal<any>;

  /**
   *
   * */
  public openedLogin: string;

  constructor(
    private connectAuthService: ConnectAuthService,
    private userFirestore: UserFirestoreService,
    private mainUserService: MainUserService,
    private navigateService: NavigateService,
    private mainSharedService: MainSharedService,
    private cntFlexyViewCategory: CntFlexyViewCategoryService,
    private cntFlexyView: CntFlexyViewService,
    private cdRef: ChangeDetectorRef,
    private overlay: Overlay,
    private activatedRoute: ActivatedRoute,
    private _viewContainerRef: ViewContainerRef
  ) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.setTitle();
    this.setFavicon();
  }

  /**
   * */
  private initQueryParamsListener() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe((params) => {
        console.log('initFlexyViews queryParams', params.page, params);
        this.initFlexyViews(params.page);
      });
  }

  /**
   *
   * */
  private init() {
    this.activatedRoute.params.subscribe((params) => {
      this.mainSharedService.showLoader();
      this.initUser();
    });
  }

  /**
   *
   * */
  private setFavicon(favicon = 'favicon.ico') {
    const issetLink = document.querySelector("link[rel*='icon']"),
      link: any = document.createElement('link');

    if (issetLink) {
      issetLink.remove();
    }

    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = favicon;

    document.getElementsByTagName('head')[0].appendChild(link);
  }

  /**
   *
   * */
  private initFlexyViews(page?: string) {
    this.cntFlexyView
      .getForOwner(this.user.id, page)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe((result) => {
        this.flexyViews = result || [];
        this.cdRef.markForCheck();
      });
  }

  /**
   *
   * */
  private initUserDependents() {
    this.initCategories();
    // this.initFlexyViews();
  }

  /**
   *
   * */
  private initUser() {
    this.openedLogin = this.navigateService.getOpenedLogin();
    this.mainUserService
      .requestGetUserInfo(this.openedLogin)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.setTitle(user.name);
          this.initUserDependents();
          this.initQueryParamsListener();
          if (user.favicon) {
            this.setFavicon(user.favicon);
          }
        }
        this.mainSharedService.hideLoader(250);
        this.cdRef.markForCheck();
      });
  }

  /**
   *
   * */
  private setTitle(title?: string | { '*': string }) {
    const titleValue = typeof title === 'object' ? title : title;
    document.title =
      (titleValue ? `${titleValue}. ` : '') + "Connect - it's simple";
  }

  /**
   *
   * */
  private initCategories() {
    this.cntFlexyViewCategory
      .getCategoriesForOwner(this.user.id)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe((categories) => {
        this.categories = categories || [];
        this.cdRef.markForCheck();
      });
  }

  ngAfterViewInit() {
    // this.componentPortal = new ComponentPortal(ComponentPortalExample);
    // this.templatePortal = new TemplatePortal(
    //   this.templatePortalContent,
    //   this._viewContainerRef
    // );
  }
}
