<div class="modal-block">
  <div class="buttons">
    <a
      class="button-link"
      (click)="open(btn.id, btn.type)"
      *ngFor="let btn of messengersArray"
    >
      <div class="button">
        <div class="img-block">
          <img [src]="btn.icon" />
        </div>
        <div class="name">{{ btn.name }}</div>
      </div>
    </a>
  </div>
</div>
