<form [formGroup]="form">
  <mat-form-field>
    <input
      [matDatepicker]="picker"
      (keypress)="onKeyPress($event)"
      (change)="onChange()"
      (dateChange)="dateChange($event)"
      (dateInput)="dateInput($event)"
      matInput
      [formControl]="date"
      placeholder="{{ field.body.name }}"
      [readonly]="readonly"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="picker"
      [disabled]="
        field['clientGeneration'] ||
        disabled ||
        field.body.status.disabled ||
        (false && !field.options.access.write)
      "
    ></mat-datepicker-toggle>
    <mat-datepicker
      #picker
      [disabled]="field.body.status.disabled"
    ></mat-datepicker>
  </mat-form-field>
</form>
