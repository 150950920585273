import { FreeformModel } from './model/freeform.model';
import { Observable, Observer, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FreeformCommonService } from './_sub/freeform.common.service';
import { FreeformPageService } from './_sub/freeform.page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { FrfMainLazyServiceModule } from '../../../../frf-main-lazy-service.module';
import { FrfEmitterService } from '../../../../@sub/@service/frf-emitter/frf-emitter.service';
import { FrfInputParamsService } from '../../../../@sub/@service/frf-input-params/frf-input-params.service';
import { FreeformObjectInterface } from '@cnt-multi-shared/@shared/freeform/@doc/@form/freeform-object.interface';
import { environment } from '../../../../../../../../../../apps/main/cnt-main/src/environments/environment';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FreeformService {
  /**
   * class for external form styles
   * for later delete from dom
   * */
  private readonly addedByFreeformStyleClass = 'addedByFreeformStyle';

  /**
   * class for external form styles
   * for later delete from dom
   * */
  private readonly addedByFreeformJsClass = 'addedByFreeformScript';

  /**
   * public info about form with only read access
   * */
  public get formInfo(): { formId: string; uid: string; modelId: string } {
    return this.formInfo_;
  }

  /**
   * private info about form with write access
   * */
  private formInfo_: { formId: string; uid: string; modelId: string };

  constructor(
    private http: HttpClient,
    public common: FreeformCommonService,
    private pages: FreeformPageService,
    private auth: ConnectAuthService,
    private frfEmiter: FrfEmitterService,
    private frfInputParams: FrfInputParamsService,
    private activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this.auth.getUserIdFromFirebase().subscribe(result => {});
  }

  /**
   * сохранить информация о форме и ее владельце
   * */
  private saveDataAboutOwner(uid: string, modelId: string, formId: string) {
    this.formInfo_ = {
      uid,
      modelId,
      formId
    };
  }

  public async getForm(iNuserId, iNmodelId, iNformId = null, iNcallback) {
    // update form to firebase
    let uid = (FreeformCommonService.userId = iNuserId),
      callback = iNcallback,
      modelId = (FreeformCommonService.formModelId = iNmodelId),
      formId = (FreeformCommonService.formId = iNformId),
      err = false, // default - we have no error
      token = this.auth.token;

    this.auth.getUserIdFromFirebase().subscribe((result: any) => {
      // if (result && result.uid) {
      const myUid = (result && result.uid) || '';
      // const url = formId
      //   ? `https://b.cnt.one/sls/v0/service/frf/get/${uid}/${modelId}?formId=${formId}&uid=${myUid}&token=${token}`
      //   : `https://b.
      const url = formId
        ? `${environment.domain.frfDomain}/get/${uid}/${modelId}?formId=${formId}&uid=${myUid}&token=${token}`
        : `${environment.domain.frfDomain}/create/${uid}/${modelId}?uid=${myUid}&token=${token}&getForm=1`;

      this.http.get(url).subscribe(data => {
        if (typeof callback === 'function') {
          const formData = data;

          if (!formData || formData['status'] !== 1) {
            // if we have not object -> set result to true -> invoke callback
            err = true;
          } else if (formData['formId'] && formId !== formData['formId']) {
            // we created form -> ser form id
            formId = FreeformCommonService.formId = formData['formId'];
            // change get url
            this.addUrlParams({ formid: formData['formId'] });
          }

          if (typeof callback === 'function') {
            // invoke callback
            callback(err, formData['form'], formId);
          }
        }
      });
      // }
    });
  }

  /**
   * changes the route without moving from the current view or triggering a navigation event
   * */
  public addUrlParams(iNqueryParams) {
    this._router.navigate([], {
      relativeTo: this.activatedRoute,

      queryParams: iNqueryParams,

      queryParamsHandling: 'merge',
      /* preserve the existing query params in the route */
      skipLocationChange: false
      /* do not trigger navigation */
    });
  }

  /**
   * Получение модели с url запросом
   * */
  public getPageByIdAndUid(
    uid: string,
    modelid: string,
    formid: string
  ): Observable<FreeformModel> {
    return Observable.create((observer: Observer<FreeformModel>) => {
      this.getForm(uid, modelid, formid, async (err, form, formId) => {
        if (err) {
          /* if we have err -> return result */
          observer.error('Not access');
        } else {
          /**
           * save data about owner
           * */
          this.saveDataAboutOwner(uid, modelid, formId);

          /* if we have not err -> open form */
          await this.init(form);

          observer.next(form);
          observer.complete();
        }
      });
    });
  }

  public setFreeformForSingleton(iNfreeform) {
    this.common.freeform = iNfreeform;
  }

  /**
   * инициализация формы
   * */
  public init(frf: FreeformObjectInterface) {
    /* set freeform for later access */
    this.setFreeformForSingleton(frf);

    /*
     * if we need to add input-params from front
     * */
    this.frfInputParams.addInputParamsFromFrontIfExist(frf);
    this.pages.init();
  }

  /**
   * добавить стили в текст
   * */
  public addStyleByText(css: string) {
    const head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.classList.add(this.addedByFreeformStyleClass);
    style.type = 'text/css';

    /* Добавление в head */
    head.appendChild(style);

    style.appendChild(document.createTextNode(css));
  }

  /**
   * добавить css по ссылке если уже не добавлен
   * */
  public addStyleByUrlIfNotExist(
    urlToFile: string,
    className = this.addedByFreeformStyleClass
  ): Observable<boolean> {
    const element = document.querySelector(`link[href="${urlToFile}"]`);

    if (!element) {
      return this.addStyleByUrl(urlToFile, className);
    }

    return of(true);
  }

  /**
   * добавить стили в текст
   * */
  public addStyleByUrl(
    urlToFile: string,
    className = this.addedByFreeformStyleClass
  ): Observable<boolean> {
    const head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('link');

    style.classList.add(className);

    style.href = urlToFile;
    style.type = 'text/css';
    style.rel = 'stylesheet';

    const flow$ = new Observable<boolean>(observer => {
      style.onload = () => {
        observer.next(true);
        observer.complete();
      };

      style.onerror = () => {
        observer.next(false);
        observer.complete();
      };
    });

    /* Добавление в head */
    head.appendChild(style);

    return flow$;
  }

  /**
   * добавить js по ссылке
   * */
  public addScriptByUrlIfNotExist(
    urlToFile: string,
    className = this.addedByFreeformJsClass
  ): Observable<boolean> {
    const element = document.querySelector(`script[src="${urlToFile}"]`);

    if (!element) {
      return this.addScriptByUrl(urlToFile, className);
    }

    return of(true);
  }

  /**
   * добавить js по ссылке
   * */
  public addScriptByUrl(
    urlToFile: string,
    className = this.addedByFreeformJsClass
  ): Observable<boolean> {
    const head = document.head || document.getElementsByTagName('head')[0],
      script = document.createElement('script');

    script.classList.add(className);

    script.src = urlToFile;
    script.type = 'text/javascript';

    const flow$ = new Observable<boolean>(observer => {
      script.onload = () => {
        observer.next(true);
        observer.complete();
      };

      script.onerror = () => {
        observer.next(false);
        observer.complete();
      };
    });

    /* Добавление в head */
    head.appendChild(script);

    return flow$;
  }

  /**
   * clear all added style
   * */
  public clearAddedStyles(className = this.addedByFreeformStyleClass) {
    const elements = document.querySelectorAll(`.${className}`);

    if (elements) {
      elements.forEach(elem => {
        elem.parentNode.removeChild(elem);
      });
    }
  }

  /**
   *
   * */
  public clearAddedExternalData() {
    this.clearAddedStyles();
    this.clearAddedScript();
  }

  /**
   * clear all added script
   * */
  public clearAddedScript(className = this.addedByFreeformJsClass) {
    const elements = document.querySelectorAll(`.${className}`);

    if (elements) {
      elements.forEach(elem => {
        elem.parentNode.removeChild(elem);
      });
    }
  }

  /**
   * добавляем если есть стили внешние для фриформ
   * */
  public safeInitExtraStylesIfNeed(freeform: any) {
    /*
     * очистка предыдущих стилей
     * */
    this.clearAddedStyles();
    if (freeform && freeform.options) {
      if (Array.isArray(freeform.options.extraStyleUrls)) {
        for (const extraStyleUrl of freeform.options.extraStyleUrls) {
          this.addStyleByUrl(extraStyleUrl);
        }
      }
      if (Array.isArray(freeform.options.extraStyles)) {
        for (const extraStyle of freeform.options.extraStyles) {
          this.addStyleByText(extraStyle);
        }
      }
    }
  }
}
