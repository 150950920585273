import { Injectable } from '@angular/core';
import { AnalyticsServiceInterface } from '../../@shared/@interface/analytics-service/analytics-service.interface';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements AnalyticsServiceInterface {
  // public readonly ga = window['ga'];
  private readonly gtag = window['gtag'];
  private readonly gtagId = 'UA-110143782-1';

  private readonly ym = window['ym'];
  private readonly ymId = 46784811;

  constructor(private router: Router) {
    // if (!environment.production) return; // <-- If you want to enable GA only in production
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setPage(event.url);
      }
    });
  }

  /*
   * set page
   * */
  public setPage(url: string): void {
    /*
     * save page in google analytics
     * */
    // this.ga('set', 'page', url);
    // this.ga('send', 'pageview');
    this.gtag('config', this.gtagId, {
      page_path: url
    });

    /*
     * save page in yandex metrika
     * */
    this.ym(this.ymId, 'hit', url);
  }

  /*
   * set user id
   * */
  public setUserId(uid: string) {
    /*
     * set in yandex metrika
     * */
    this.ym(this.ymId, 'setUserID', uid);
    /*
     * set in google analytics
     * */
    this.gtag('set', { user_id: uid });
  }
}
