import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { ScreenDetectorService } from 'mz-screen-detector';
import {
  AppsIdEnum,
  ConnectAppControllerInerface,
  ConnectAppControllerSelectors,
  ConnectAppsListInterface,
  ConnectAppsStorageInterface,
  SwitchAppAction
} from '@cnt-nx-workspace/feature/app-controller';
import { Router } from '@angular/router';
import { ConnectUrlService } from '../../../../../@service/connect-url/connect-url.service';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { SumSubComponent } from '../../../../../../../../@view/lk/@sub/@view/sum-sub/sum-sub.component';
import { MainSharedService } from '../../../../../@service/main-shared/main-shared.service';
import { MzModalWindowService } from 'mz-modal-window';

@Component({
  selector: 'mz-web-base-app-menu-block',
  templateUrl: './app-menu-block.component.html',
  styleUrls: ['./app-menu-block.component.scss']
})
export class AppMenuBlockComponent implements OnInit, OnDestroy {
  @Input()
  menus: { name: string; id: string }[] = [
    { name: 'Верификация', id: 'default-virificate' },
    { name: 'Выход', id: 'default-sign-out' }
  ];
  @Output() changedMenu: EventEmitter<any> = new EventEmitter();

  public alive: boolean = true;
  public currentAppId: string;
  public appList: ConnectAppsListInterface[];
  public resultAppList: ConnectAppsListInterface[];
  public appStorage: ConnectAppsStorageInterface;
  public screenDetectorType: string;
  public verificate = 0;

  constructor(
    private store: Store<ConnectAppControllerInerface>,
    private screenDetector: ScreenDetectorService,
    private router: Router,
    private connectUrlService: ConnectUrlService,
    public connectAuthService: ConnectAuthService,
    public mainShared: MainSharedService,
    private mzModalWindowService: MzModalWindowService
  ) {}

  ngOnInit() {
    // подписка на изменение экрана
    this.screenDetector.subscription$
      .pipe(takeWhile(() => this.alive))
      .subscribe(answer => {
        this.screenDetectorType = answer.type;
        this.updateAppList();
      });

    // подписка на изменения данных приложения
    this.store
      .pipe(
        takeWhile(() => this.alive),
        select(ConnectAppControllerSelectors.getApps)
      )
      .subscribe(apps => {
        this.appStorage = apps.storage;
        this.appList = apps.list;
        this.currentAppId = apps.currentId;
        this.updateAppList();
        // TODO add change routing method to service
      });

    // initial change app from url
    this.changeApp(this.connectUrlService.app, false);

    // подписка на изменения данных пользователя
    this.subscribe();
  }

  ngOnDestroy() {}

  /*
   * получить список приложений с правильным порядком (для мобильных и для компьютеров)
   * */
  private getAppList(): ConnectAppsListInterface[] {
    if (!this.screenDetectorType || !this.appList || !this.currentAppId) return;
    const resultArray = [],
      screenType: string = this.screenDetectorType;

    let currentApp: any, currentAppIndex: number;

    for (const idx in this.appList) {
      const app = this.appList[idx];
      if (app) {
        if (app.id !== this.currentAppId) {
          resultArray.push(app);
        } else {
          currentAppIndex = parseInt(idx, 10);
          currentApp = app;
        }
      }
    }

    // if mobile screen size -> to center app
    if (typeof currentAppIndex === 'number') {
      resultArray.splice(
        // if mobile screen size (+)-> add to center app (-)-> add to order
        screenType === 'small' ? 2 : currentAppIndex,
        0,
        currentApp
      );
    }

    return resultArray;
  }

  /*
   * обновить список приложений для правильного порядка на мобильных и на компьютерах
   * */
  private updateAppList() {
    this.resultAppList = this.getAppList();
  }

  /*
   * переключить приложение
   * */
  public changeApp(appId: AppsIdEnum, navigate: boolean = true) {
    // if this new app
    if (appId !== this.currentAppId) {
      this.store.dispatch(new SwitchAppAction(appId));
      if (navigate) this.router.navigate([`/none/${appId}`]);
    }
  }

  /*
   * show panel
   * */
  public showPanel() {
    this.mzModalWindowService.open(
      SumSubComponent,
      //TODO later delete, set diference of create and edit
      'sub-sub-panel' // this.panelId
    );
  }

  /*
   * подписка на изменения данных пользователя
   * */
  public subscribe() {
    this.store
      .pipe(select(ConnectAppControllerSelectors.getCurrentUser))
      .subscribe(currentUser => {
        if (currentUser) {
          this.verificate = currentUser.rates.verification;
          // if (this.verificate) {
          //   this.menus = this.menus.filter(
          //       (menu) => menu.id !== 'default-virificate'
          //   );
          // }
        }
      });
  }

  /*
   * change menu item
   * */
  public changeMenu(menuItem: any) {
    switch (menuItem.id) {
      case 'default-virificate':
        this.showPanel();
        break;
      case 'default-sign-out':
        this.mainShared.signOut();
        break;
    }
    this.changedMenu.emit(menuItem);
  }
}
