import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  MzCountdownExpiredReplacementType,
  MzCountdownExpiredType
} from './@res/@abstract/@type/common.type';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MzCountdownGetDateInterface } from './@res/@abstract/@interface/common.interface';

@Pipe({
  name: 'mzCountdown',
  pure: false
})
export class MzCountdownPipe implements OnDestroy, PipeTransform {
  private last_expired: number;
  private stream$: Subscription;
  private alive = true;
  private lastResult;
  private expiredTime: number;
  private onExpired: (...any: any[]) => any;
  private emittedTime: number;

  constructor(private cdRef: ChangeDetectorRef) {}

  transform(
    expired: MzCountdownExpiredType,
    onExpired?: (...any: any[]) => any,
    expiredReplacement?: MzCountdownExpiredReplacementType,
    callback?: (date: MzCountdownGetDateInterface) => string,
    intervalPeriod: number = 1000
  ): any {
    const time = (this.expiredTime = this.getExpired(expired));

    /*
     * callback on function
     * */
    if (typeof onExpired === 'function') {
      this.onExpired = onExpired;
    }

    if (this.isNeedChange(time)) {
      this.updateLastValue(time);

      /*
       * start changes
       * */
      this.startChanges(intervalPeriod, expiredReplacement, callback);
    }

    return this.getCountDownDateWithCallback(
      time,
      expiredReplacement,
      callback
    );
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  /**
   * emit only one size
   * */
  private emit() {
    if (this.emittedTime !== this.last_expired) {
      this.emittedTime = this.last_expired;

      if (typeof this.onExpired === 'function') {
        this.onExpired();
      }
    }
  }

  /**
   * default callback function
   * */
  private defaultCallback(date: MzCountdownGetDateInterface) {
    let dateString = '';

    if (date.days) {
      dateString = date.days + ':';
    }

    if (date.hours) {
      dateString = date.hours + ':';
    }

    /*
     * base format
     * */
    dateString += `${date.minutes}:${date.seconds}`;

    return dateString;
  }

  /**
   * get expired replacement
   * */
  private getExpiredReplacement(
    expiredReplacement?: MzCountdownExpiredReplacementType
  ): string {
    if (typeof expiredReplacement === 'string') {
      return expiredReplacement;
    } else if (typeof expiredReplacement === 'function') {
      return expiredReplacement();
    } else {
      return '';
    }
  }

  /**
   * get expired
   * */
  private getExpired(expired: MzCountdownExpiredType) {
    let time;

    if (typeof expired === 'number') {
      time = expired;
    } else if (typeof expired === 'object' && expired instanceof Date) {
      time = expired.getTime();
    }

    return time;
  }

  /**
   * need update value
   * */
  private isNeedChange(time: number) {
    return time !== this.last_expired;
  }

  /**
   * get count down date
   * */
  public static getCountdownDate(
    countDownDate: number
  ): MzCountdownGetDateInterface {
    const now = new Date().getTime();

    /*
     * Find the distance between now and the count down date
     * */
    const distance = countDownDate - now,
      expired = distance <= 0;

    if (expired) {
      return {
        expired
      };
    }

    const oneSecond = 1000,
      oneMinute = oneSecond * 60,
      oneHour = oneMinute * 60,
      fullDay = oneHour * 24;

    /*
     * Time calculations for days, hours, minutes and seconds
     * */
    const days = Math.floor(distance / fullDay),
      hours = Math.floor((distance % fullDay) / oneHour),
      minutes = Math.floor((distance % oneHour) / oneMinute),
      seconds = Math.floor((distance % oneMinute) / oneSecond),
      ms = Math.floor(distance % oneSecond);

    return {
      days,
      hours,
      minutes,
      seconds,
      ms,
      expired
    };
  }

  /**
   * get countdown date with callback
   * */
  private getCountDownDateWithCallback(
    countDownDate: number,
    expired?: MzCountdownExpiredReplacementType,
    callback?: (date: MzCountdownGetDateInterface) => string
  ) {
    callback = typeof callback === 'function' ? callback : this.defaultCallback;

    const data = MzCountdownPipe.getCountdownDate(countDownDate);

    if (data.expired) {
      this.emit();
    }

    return data.expired ? this.getExpiredReplacement(expired) : callback(data);
  }

  /**
   * start changes
   * */
  private startChanges(
    intervalPeriod: number,
    expiredReplacement: MzCountdownExpiredReplacementType,
    callback?: (date: MzCountdownGetDateInterface) => string
  ) {
    /*
     * unsubscribe when start
     * */
    if (this.stream$) {
      this.stream$.unsubscribe();
    }

    /*
     * start updater every second
     * */
    this.stream$ = interval(intervalPeriod)
      .pipe(
        takeWhile(() => this.alive && this.emittedTime !== this.last_expired)
      )
      .subscribe(() => {
        const result = this.getCountDownDateWithCallback(
          this.expiredTime,
          expiredReplacement,
          callback
        );

        if (result !== this.lastResult) {
          this.lastResult = result;
          this.cdRef.markForCheck();
        }
      });
  }

  /**
   * update last value
   * */
  private updateLastValue(time: number) {
    this.last_expired = time;
  }
}
