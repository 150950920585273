import {ConnectAppControllerInerface} from "../../../../../@res/@abstract/@interface/app/connect-app-controller.inerface";

export function SetMainUserReducerAction (state: ConnectAppControllerInerface, payload): any {
  state = {
    ...state,
    auth: {
        ...state.auth,
        currentUser: {
            // TODO add if need
            // ...state.auth.currentUser,
            ...payload
        }
    }
  };
  return state;
}
