/*
 * block type
 * */
export enum FrfValueSetterBlockerTypeEnum {
  /*
   * full pages with submit buttons
   * */
  fullPage = 'full-page',
  /*
   * content of pages without submit buttons
   * */
  pageContent = 'page-content'
}

export enum FrfValueElementSetterBlockerTypeEnum {
  /*
   * full element with submit buttons
   * */
  full = 'full',

  /*
   * content of element without submit buttons
   * */
  noSubmit = 'no-submit'
}
