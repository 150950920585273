import { Injectable } from '@angular/core';
import { Observable, Observer, Subject, timer } from 'rxjs';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { Router, UrlTree } from '@angular/router';
import { PlatformSpecificService } from '../../../../../@service/platform-specific/platform-specific.service';
import { ScreenDetectorService } from 'mz-screen-detector';
import { CIconFontTypeEnum } from '../../@view/c-icon/@res/@abstract/@enum/common.enum';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class MainSharedService {
  /**
   * modal - component
   * */
  public modalComponent: ComponentPortal<any>;

  /**
   * */
  private _loader = false;
  public get loader() {
    return this._loader;
  }
  public set loader(state: boolean) {
    if (!state) {
      setTimeout(() => {
        this.loader$.next(state);
      }, 1000);
    } else {
      this.loader$.next(state);
    }
  }

  public readonly cIcon = CIconFontTypeEnum;

  /**
   * show footer
   * */
  public isShowFooter = true;

  /**
   * */
  public loader$: Subject<boolean> = new Subject<boolean>();

  /*
   * sceen type
   * */
  public screenType: string;

  /**
   * show footer
   * */
  public showFooter() {
    this.isShowFooter = true;
  }

  /**
   * hide footer
   * */
  public hideFooter() {
    this.isShowFooter = false;
  }

  constructor(
    public connectAuthService: ConnectAuthService,
    private router: Router,
    private platformSpecificService: PlatformSpecificService,
    private screenDetector: ScreenDetectorService
  ) {
    this.loader$.subscribe((state: boolean) => {
      this._loader = state;
    });

    this.screenDetector.subscription$.subscribe(result => {
      this.screenType = result.type;
    });
  }

  /*
   * show loader
   * */
  public showLoader(time?: number) {
    if (typeof time === 'number') {
      timer(time).subscribe(() => {
        this.showLoader();
      });
    } else {
      this.loader = true;
    }
  }

  /*
   * hide loader
   * */
  public hideLoader(time?: number) {
    if (typeof time === 'number') {
      timer(time).subscribe(() => {
        this.hideLoader();
      });
    } else {
      this.loader = false;
    }
  }

  /*
   * sign out
   * */
  public signOut() {
    this.showLoader();
    this.connectAuthService.signOut().subscribe(
      () => {
        this.hideLoader();
        this.platformSpecificService.pushService.setAsSignedOut();
        this.router.navigate(['sign']);
      },
      () => {
        this.hideLoader();
        alert('Неизвестная ошибка');
      }
    );
  }

  /**
   * is computer and values
   * */
  public isComp(...values: boolean[]): boolean {
    return this.isScreen(['medium', 'large'], values);
  }

  /**
   * is screen
   * */
  public isScreen(screenTypes: string[], values: boolean[] = []): boolean {
    const isFalse = values.findIndex(item => !item) !== -1;
    return !isFalse && screenTypes.indexOf(this.screenType) !== -1;
  }

  /**
   * is mobile and all values passed is bool
   * */
  public isMobile(...values: boolean[]): boolean {
    return this.isScreen(['small'], values);
  }

  /**
   *
   * */
  public getSizeByBytes(bytes: number): string {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0B';
    // @ts-ignore
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    // @ts-ignore
    return Math.round(bytes / Math.pow(1024, i), 2) + '' + sizes[i];
  }

  /**
   * download by url
   * */
  public downloadByUrl(
    url: string,
    fileName: string
  ): { xhr: XMLHttpRequest; progress$: Observable<number>; start: () => void } {
    console.log('downloadByUrl 1', { url });
    const xhr = new XMLHttpRequest(),
      result = {
        xhr: xhr,
        progress$: null,
        start: () => {
          console.log('downloadByUrl 2', { url });
          xhr.open('GET', url);
          /*
           * download request
           * */
          xhr.send();
        }
      };

    xhr.responseType = 'blob';

    result.progress$ = Observable.create((observer: Observer<number>) => {
      /*
       * add callback
       * */
      xhr.onprogress = progress => {
        const percent = (progress.loaded / progress.total) * 100;
        observer.next(percent);
      };

      xhr.onload = event => {
        /* create element and add child to body*/
        const a = document.createElement('a');
        document.body.appendChild(a);

        /*
         * get blob
         * */
        const blob = xhr.response;

        /*
         * get url and add to tag
         * */
        const blobToUrl = window.URL.createObjectURL(blob);
        a.href = blobToUrl;
        console.log('downloadByUrl 3', { a });
        /*
         * add file name
         * */
        if (fileName) {
          a.download = fileName;
        }

        /*
         * trigger click
         * */
        a.click();

        /*
         * delete tag and object url
         * */
        a.remove();
        window.URL.revokeObjectURL(blobToUrl);

        console.log('downloadByUrl 4', { a });
        observer.complete();
      };

      return () => {
        xhr.abort();
      };
    });

    return result;
  }

  /**
   * Clear Text Selection
   * */
  public clearSelection() {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document['selection']) {
      // IE?
      document['selection'].empty();
    }
  }

  /**
   * get parent url
   * */
  public getParentUrl(
    offset: number | null = -1,
    newPath: string = '',
    url: string = '',
    prefixDelete = false,
    fromStart?: number
  ) {
    const result =
      (url || window.location.pathname)
        .split('/')
        .slice(fromStart ? fromStart : 0, offset === null ? undefined : offset)
        .join('/') + (newPath ? `/${newPath}` : newPath);

    return prefixDelete ? result.slice(1) : result;
  }

  /**
   * get parent url
   * */
  public getParentUrlTree(
    offset: number | null = -1,
    newPath: string = '',
    url: string = '',
    fromStart?: number
  ): UrlTree | null {
    return this.router.parseUrl(this.getParentUrl(offset, newPath, url, true));
  }
}
