import { Injectable } from '@angular/core';
import * as uuidV4 from 'uuid/v4';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { FrfMainLazyServiceModule } from '../../../../../frf-main-lazy-service.module';
import { FrfValueGetterService } from '../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { ValueForSetFieldSourceActionType } from '../../../../../@doc/@field/@trigger/frf-actions.interface';
import { FreeformCommonService } from '../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../frf-shared-storage/frf-shared-storage.service';
import {
  FrfTriggerActionEnum,
  FrfTriggerActionSequenceTypeEnum
} from './@res/@abstract/@enum/common.enum';
import {
  ActionFinisher$Flow$Interface,
  ActionStarter$Flow$Interface,
  FrfTriggerActionBaseInterface,
  FrfTriggerActionRequestByCntGraphQlInterface,
  FrfTriggerActionRequestByGetInterface,
  FrfTriggerActionRequestByGraphQlInterface,
  FrfTriggerActionRequestByInterface,
  FrfTriggerActionRequestByPostInterface,
  FrfTriggerActionServiceInterface,
  FrfTriggerActionSetElementDataInterface,
  FrfTriggerActionSetFieldValueInterface,
  FrfTriggerActionSetLoadingStateInterface,
  FrfTriggerActionSetTempDataInterface
} from './@res/@abstract/@interface/common.interface';
import { FrfTriggerActionRequestService } from './@sub/@group:actions/frf-trigger-action-request/frf-trigger-action-request.service';
import { FrfTriggerActionSetTempDataService } from './@sub/@group:actions/frf-trigger-action-set-temp-data/frf-trigger-action-set-temp-data.service';
import { FrfTriggerActionSetLoadingStateService } from './@sub/@group:actions/frf-trigger-action-set-loading-state/frf-trigger-action-set-loading-state.service';
import { FrfTriggerAndActionPipeService } from '../frf-trigger-and-action-pipe/frf-trigger-and-action-pipe.service';
import { FrfTriggerActionSetPayloadOptionsService } from './@sub/@group:actions/frf-trigger-action-set-payload-options/frf-trigger-action-set-payload-options.service';
import { FrfTriggerActionSetPayloadOptionsInterface } from './@sub/@group:actions/frf-trigger-action-set-payload-options/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionToastService } from './@sub/@group:actions/frf-trigger-action-toast/frf-trigger-action-toast.service';
import { FrfTriggerActionShowToastInterface } from './@sub/@group:actions/frf-trigger-action-toast/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionSetElementStateInterface } from './@sub/@group:actions/frf-trigger-action-set-element-state/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionElementBlockerInterface } from './@sub/@group:actions/frf-trigger-action-element-blocker/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionInvokeElementMethodService } from './@sub/@group:actions/frf-trigger-action-invoke-element-method/frf-trigger-action-invoke-element-method.service';
import { FrfTriggerActionSetPayloadInterface } from './@sub/@group:actions/frf-trigger-action-set-payload/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionInvokeElementMethodInterface } from './@sub/@group:actions/frf-trigger-action-invoke-element-method/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionElementBlockerService } from './@sub/@group:actions/frf-trigger-action-element-blocker/frf-trigger-action-element-blocker.service';
import { FrfTriggerActionSetPayloadService } from './@sub/@group:actions/frf-trigger-action-set-payload/frf-trigger-action-set-payload.service';
import { FrfTriggerActionBlockerInterface } from './@sub/@group:actions/frf-trigger-action-blocker/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionBlockerService } from './@sub/@group:actions/frf-trigger-action-blocker/frf-trigger-action-blocker.service';
import { FrfTriggerActionSetElementStateService } from './@sub/@group:actions/frf-trigger-action-set-element-state/frf-trigger-action-set-element-state.service';
import { FrfElementFolderTypeEnum } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@enum/common.enum';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';
import { FrfTriggerActionForArrayService } from './@sub/@group:actions/frf-trigger-action-for-array/frf-trigger-action-for-array.service';
import { FrfTriggerActionForArrayInterface } from './@sub/@group:actions/frf-trigger-action-for-array/@res/@abstract/@interface/common.interface';
import { FrfTriggerActionCopyElementService } from './@sub/@group:actions/frf-trigger-action-copy-element/frf-trigger-action-copy-element.service';
import { FrfTriggerActionCopyElementInterface } from './@sub/@group:actions/frf-trigger-action-copy-element/@res/@abstract/@interface/common.interface';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionService extends AbstractUnsubscribeViewControl
  implements FrfTriggerActionServiceInterface {
  /**
   * for start action in flow$ for debounce, throttle
   * */
  private actionStarter$: {
    [actionUuid: string]: Subject<ActionStarter$Flow$Interface>;
  } = {};
  private actionFinisher$: {
    [actionUuid: string]: Subject<ActionFinisher$Flow$Interface>;
  } = {};

  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfValueGetterService: FrfValueGetterService,
    private frfTriggerActionRequest: FrfTriggerActionRequestService,
    private frfTriggerActionSetTempData: FrfTriggerActionSetTempDataService,
    public frfTriggerActionSetLoadingStateService: FrfTriggerActionSetLoadingStateService,
    private frfTriggerActionPipe: FrfTriggerAndActionPipeService,
    private frfTriggerActionSetPayloadOptions: FrfTriggerActionSetPayloadOptionsService,
    private frfTriggerActionToast: FrfTriggerActionToastService,
    private frfTriggerActionSetElementState: FrfTriggerActionSetElementStateService,
    private frfTriggerActionBlocker: FrfTriggerActionBlockerService,
    private frfTriggerActionElementBlocker: FrfTriggerActionElementBlockerService,
    private frfTriggerActionSetPayload: FrfTriggerActionSetPayloadService,
    private frfTriggerActionInvokeElementMethod: FrfTriggerActionInvokeElementMethodService,
    private frfTriggerActionForArrayService: FrfTriggerActionForArrayService,
    private frfTriggerActionCopyElementService: FrfTriggerActionCopyElementService
  ) {
    super();
  }

  /**
   *
   * */
  public destroyFlows() {
    for (const uuid of Object.keys(this.actionStarter$)) {
      if (this.actionStarter$[uuid]) {
        // this.actionStarter$[uuid].unsubscribe();
        this.actionStarter$[uuid].complete();
      }

      this.actionStarter$ = {};
    }

    for (const uuid of Object.keys(this.actionFinisher$)) {
      if (this.actionFinisher$[uuid]) {
        // this.actionFinisher$[uuid].unsubscribe();
        this.actionFinisher$[uuid].complete();
      }

      this.actionFinisher$ = {};
    }
  }

  /**
   *
   * */
  public async run(
    frfElement: any,
    actions: any[],
    scope: any
  ): Promise<boolean> {
    if (
      typeof actions === 'object' && // if triggers is object
      Array.isArray(actions) // if we have body functions is active
    ) {
      /* get action functios */
      // for ( const action of <FrfTriggerActionBaseInterface[]>this.sortActionsByWeight(actions) ) {
      for (const action of this.common.sortByWeight<
        FrfTriggerActionBaseInterface
      >(actions)) {
        // start action
        // this.startAction(field, action);
        await this.runAction(action, frfElement, scope);
      }
    }
    return false;
  }

  /**
   *
   * */
  private async startActionWithSendFinishResultToFlow(
    element,
    action: FrfTriggerActionBaseInterface,
    operationId: string,
    scope: any
  ) {
    const response = await new Promise(resolve => {
      this.startAction(element, action, scope)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          console.error(
            'startAction ERROR - ',
            error,
            operationId,
            action,
            element
          );
          resolve(true);
        });
    });

    this.actionFinisher$[action.gen$.uuid].next({
      action,
      element,
      response,
      id: operationId,
      scope
    });
  }

  /**
   *
   * */
  private async startAction(
    element,
    action: FrfTriggerActionBaseInterface,
    scope: any
  ): Promise<any> {
    const active = action.active, // action active
      type = <FrfTriggerActionEnum>action['type']; // action type

    /* if triggers is not active stop funciton */
    if (!action || (typeof active === 'boolean' && !active)) {
      return false;
    }
    switch (type) {
      // case 'setFieldValue':
      case FrfTriggerActionEnum.setFieldValueOld:
      case FrfTriggerActionEnum.setFieldValue:
        /*
            @discr -
              type    : string = setFieldValue
              id      : string
              value   : fieldValue
        */
        return this.setFieldValue(action, element, scope);

      case FrfTriggerActionEnum.setTempData:
        return this.frfTriggerActionSetTempData.run(
          <FrfTriggerActionSetTempDataInterface>action,
          element,
          scope
        );
      case FrfTriggerActionEnum.copyElement:
        return this.frfTriggerActionCopyElementService.run(
          <FrfTriggerActionCopyElementInterface>action,
          this,
          element,
          scope
        );

      /* TODO later add new set-custom-menu action */
      case FrfTriggerActionEnum.setCustomMenu:
        return true;

      /* TODO later add new set-custom-menu action */
      case FrfTriggerActionEnum.setElementState:
        return this.frfTriggerActionSetElementState.run(
          <FrfTriggerActionSetElementStateInterface>action,
          element,
          scope
        );

      case FrfTriggerActionEnum.setFileSourceOld:
      case FrfTriggerActionEnum.setFileSource:
        /*
            @discr -
              type    : string = setFileSource
              id      : string
              value   : fieldValue
        */
        return this.setFileSource(action, element, scope);

      case FrfTriggerActionEnum.setElementData:
        return this.setElementData(
          <FrfTriggerActionSetElementDataInterface>action,
          element,
          scope
        );

      case FrfTriggerActionEnum.showToast:
        return this.frfTriggerActionToast.run(
          <FrfTriggerActionShowToastInterface>action,
          scope
        );

      case FrfTriggerActionEnum.forArray:
        return this.frfTriggerActionForArrayService.run(
          <FrfTriggerActionForArrayInterface>action,
          this,
          element,
          this.common.freeform,
          scope
        );

      case FrfTriggerActionEnum.requestByGraphQl:
        return this.frfTriggerActionRequest.requestByGraphQl(
          <FrfTriggerActionRequestByGraphQlInterface>action,
          element,
          this.common.freeform,
          scope
        );

      case FrfTriggerActionEnum.requestByGet:
        return this.frfTriggerActionRequest.requestByGet(
          <FrfTriggerActionRequestByGetInterface>action,
          element,
          this.common.freeform,
          scope
        );

      case FrfTriggerActionEnum.requestByPost:
        return this.frfTriggerActionRequest.requestByPost(
          <FrfTriggerActionRequestByPostInterface>action,
          element,
          this.common.freeform,
          scope
        );

      case FrfTriggerActionEnum.requestByCntGraphQl:
        return this.frfTriggerActionRequest.requestByCntGraphQl(
          <FrfTriggerActionRequestByCntGraphQlInterface>action,
          element,
          this.common.freeform,
          scope
        );

      case FrfTriggerActionEnum.blocker:
        return this.frfTriggerActionBlocker.run(
          <FrfTriggerActionBlockerInterface>action,
          scope
        );

      case FrfTriggerActionEnum.elementBlocker:
        return this.frfTriggerActionElementBlocker.run(
          <FrfTriggerActionElementBlockerInterface>action,
          element,
          scope
        );

      case FrfTriggerActionEnum.requestBy:
        return this.frfTriggerActionRequest.requestBy(
          <FrfTriggerActionRequestByInterface>action,
          element,
          this.common.freeform,
          scope
        );

      case FrfTriggerActionEnum.setPayloadOptions:
        return this.frfTriggerActionSetPayloadOptions.run(
          <FrfTriggerActionSetPayloadOptionsInterface>action,
          element,
          scope
        );

      case FrfTriggerActionEnum.setPayload:
        return this.frfTriggerActionSetPayload.run(
          <FrfTriggerActionSetPayloadInterface>action,
          element,
          scope
        );

      case FrfTriggerActionEnum.invokeMethod:
        return this.frfTriggerActionInvokeElementMethod.run(
          <FrfTriggerActionInvokeElementMethodInterface>action,
          element,
          scope
        );

      case FrfTriggerActionEnum.setLoadingState:
        return this.frfTriggerActionSetLoadingStateService.run(
          <FrfTriggerActionSetLoadingStateInterface>action,
          element,
          scope
        );
    }
  }

  /**
   * устанавливает значение для field элементов
   * */
  private async setFieldValue(
    action: { id: string; value: string } | any,
    startElement: any,
    scope: any
  ): Promise<boolean> {
    const data = <FrfTriggerActionSetFieldValueInterface>action,
      id = data.id,
      value = data.value,
      field = id
        ? this.frfValueGetterService.getFrfElementById(
            id,
            this.common.freeform,
            // ['fields'],
            [FrfElementFolderTypeEnum.field],
            startElement,
            scope
          )
        : startElement;

    if (field) {
      // if field is isset
      const valueResult = await this.frfValueGetterService
        .getValueWithPipe(
          value,
          field,
          this.common.freeform,
          startElement,
          scope
        )
        .toPromise();

      // if ( valueResult && !data.checker ) {
      /* set new value of freeform */
      this.frfSharedStorage.frfValueSetter.setFieldValue(
        field,
        valueResult,
        ['onChange', 'onSetValue'],
        true,
        null,
        data.checker,
        data.default
      );
      return true;
      // }
    }
    return false;
  }

  /**
   * @action - setFileSource (only for field-image, field-video)
   * */
  private async setFileSource(
    action: any,
    startElement: any,
    scope: any
  ): Promise<boolean> {
    const id = this.frfValueGetterService.getValue(
        action['id'],
        null,
        this.common.freeform,
        startElement,
        scope
      ), // field object
      value = action['value'], // field object
      field = this.frfValueGetterService.getFreefomObjectByInId(
        id,
        this.common.freeform
      );

    if (field) {
      /*
       * if field is isset -> get field value
       * */
      const valueResult = <ValueForSetFieldSourceActionType>(
        this.frfValueGetterService.getValue(
          value,
          field,
          this.common.freeform,
          startElement,
          scope
        )
      );

      if (
        typeof valueResult === 'string' ||
        (typeof valueResult === 'object' &&
          typeof valueResult.path === 'string')
      ) {
        /*
         * set new value of freeform
         * */
        this.frfSharedStorage.frfValueSetter.setElementDataByPath(
          'field',
          field,
          'payload.src',
          valueResult,
          ['onChange', 'onSetSrc'],
          true,
          true
        );

        return true;
      }
    }
    return false;
  }

  /**
   * @action - setElementData (only for field))
   * */
  private async setElementData(
    action: FrfTriggerActionSetElementDataInterface,
    startElement: any,
    scope: any
  ): Promise<boolean> {
    const id = this.frfValueGetterService.getValue(
        action.id,
        null,
        this.common.freeform,
        startElement,
        scope
      ), // field object
      value = action.value, // field object
      field = id
        ? this.frfValueGetterService.getFreefomObjectByInId(
            id,
            this.common.freeform
          )
        : startElement;

    if (field) {
      /*
       * if field is isset -> get field value
       * */
      const valueResult = <ValueForSetFieldSourceActionType>(
        this.frfValueGetterService.getValue(
          value,
          field,
          this.common.freeform,
          startElement,
          scope
        )
      );

      if (
        typeof valueResult === 'string' ||
        (typeof valueResult === 'object' &&
          typeof valueResult.path === 'string')
      ) {
        /*
         * set element data
         * */
        this.frfSharedStorage.frfValueSetter.setElementDataByPath(
          'field',
          field,
          `payload.$ed.${action.path}`,
          valueResult,
          ['onChange', 'onSetSrc'],
          true,
          true
        );

        return true;
      }
    }

    return false;
  }

  /**
   * uuid for action need for start seperate flow$ for debounce, throttle
   * */
  private addUuidToActionIfNotExist(action: FrfTriggerActionBaseInterface) {
    if (!action.gen$) {
      action.gen$ = {
        uuid: uuidV4()
      };
    } else if (!action.gen$.uuid) {
      action.gen$.uuid = uuidV4();
    }
  }

  /**
   *
   * */
  private createFlowForActionWithSubscribeIfNotDoneYet(
    action: FrfTriggerActionBaseInterface,
    frfObject: any,
    scope: any
  ) {
    let needCreate = false;

    if (!action.gen$) {
      action.gen$ = {
        uuid: uuidV4()
      };
      needCreate = true;
    } else if (!action.gen$.uuid) {
      action.gen$.uuid = uuidV4();
      needCreate = true;
    }

    if (needCreate) {
      /*
       * create starter and finisher
       * */
      if (!this.actionStarter$[action.gen$.uuid]) {
        this.actionStarter$[action.gen$.uuid] = new Subject();
        this.actionFinisher$[action.gen$.uuid] = new Subject();

        this.actionStarter$[action.gen$.uuid]
          .pipe(
            this.frfTriggerActionPipe.add(action, frfObject, scope),
            takeUntil(this.viewDestroy$)
          )
          .subscribe(data => {
            this.startActionWithSendFinishResultToFlow(
              data.element,
              data.action,
              data.id,
              scope
            );
          });
      }
    }
  }

  /**
   * запускаем action правильно: pre actions and after actions run
   * */
  private async runAction(
    action: FrfTriggerActionBaseInterface,
    element: any,
    scope: any
  ) {
    /* guard for action - skip only active action */
    if (typeof action.active === 'boolean' && !action.active) {
      return;
    }

    /* if not exist flow create it -> need for work pipes */
    this.createFlowForActionWithSubscribeIfNotDoneYet(action, element, scope);

    const preActionsPromises = [],
      postActionsPromises = [];

    if (this.hasBeforeStartActions(action)) {
      for (const preAction of this.common.sortByWeight<
        FrfTriggerActionBaseInterface
      >(action.options$.pre)) {
        preActionsPromises.push(this.runAction(preAction, element, scope));
      }
    }

    /**
     * вызываем все preactions
     * */
    await this.runSubAction(
      preActionsPromises,
      action.options$ && action.options$.preSequenceType
    );

    /*
     * create unique operation id for later start
     * */
    const operationId = uuidV4();

    if (!this.actionStarter$[action.gen$.uuid]) {
      return;
    }

    /*
     * invoke action
     * */
    this.actionStarter$[action.gen$.uuid].next({
      element,
      action,
      id: operationId,
      scope
    });

    await this.actionFinisher$[action.gen$.uuid]
      .pipe(
        filter(result => {
          return result.id === operationId;
        }),
        takeUntil(this.viewDestroy$),
        take(1)
      )
      .toPromise();

    if (this.hasAfterFinishActions(action)) {
      for (const postAction of this.common.sortByWeight<
        FrfTriggerActionBaseInterface
      >(action.options$.post)) {
        postActionsPromises.push(this.runAction(postAction, element, scope));
      }
    }

    /**
     * вызываем все post-actions
     * */
    await this.runSubAction(
      postActionsPromises,
      action.options$ && action.options$.postSequenceType
    );

    return true;
  }

  private async runSubAction(
    actions: Promise<any>[],
    sequence?: FrfTriggerActionSequenceTypeEnum
  ) {
    if (!actions || !actions.length) {
      return;
    }

    if (sequence === FrfTriggerActionSequenceTypeEnum.concurrency) {
      return this.runActionsConcurrency(actions);
    }

    return this.runActionsParallel(actions);
  }

  private async runActionsConcurrency(actions: Promise<any>[]) {
    for (const actionPromise of actions) {
      await actionPromise;
    }

    return true;
  }

  private async runActionsParallel(actions: Promise<any>[]) {
    return Promise.all(actions);
  }

  /**
   *
   * */
  private hasBeforeStartActions(
    action: FrfTriggerActionBaseInterface
  ): boolean {
    return !!(
      action &&
      action.options$ &&
      Array.isArray(action.options$.pre) &&
      action.options$.pre.length
    );
  }

  /**
   *
   * */
  private hasAfterFinishActions(
    action: FrfTriggerActionBaseInterface
  ): boolean {
    return !!(
      action &&
      action.options$ &&
      Array.isArray(action.options$.post) &&
      action.options$.post.length
    );
  }

  /**
   *
   * */
  public getElementFromActionIfExist(
    id?: any,
    startElement?: any
  ): any | undefined {
    return id
      ? this.frfValueGetterService.getFrfElementById(
          id,
          this.common.freeform,
          null,
          startElement
        )
      : startElement;
  }
}
