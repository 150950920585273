<div [ngClass]="{ hideStepper: hideStepper }">
  <mat-horizontal-stepper
    [linear]="isLinear"
    [selectedIndex]="selectedIndex"
    (selectionChange)="changePage($event)"
    #stepper="matHorizontalStepper"
  >
    <ng-template
      #fullPageTemplate
      let-freeform="freeform"
      let-pageid="pageid"
      let-objid="objid"
      let-pages="freeform.pages"
      let-pageNumber="pageNumber"
      let-pagesCount="pagesCount"
    >
      <!--from object-->
      <ng-container
        *ngFor="
          let pre of (pages[pageid]?.objects)[objid]?.pre;
          trackBy: trackBy
        "
      >
        <ng-container
          *ngTemplateOutlet="
            fullPageTemplate;
            context: {
              pageid: pre.baseid,
              objid: pre.objid,
              freeform: freeform
            }
          "
        ></ng-container>
      </ng-container>

      <ng-container>
        <mat-step
          [stepControl]="{
            invalid: !(pages[pageid]?.objects)[objid]?.body.status.value
          }"
        >
          <ng-template matStepLabel>{{
            (pages[pageid]?.objects)[objid]?.body.name
          }}</ng-template>
          <div
            class="body_ pl-sm-5 pr-sm-5"
            freeformObject
            [freeformType]="'page'"
            [freeformObjId]="objid"
            [freeformBaseId]="pageid"
          >
            <!-- блокировка содержимого кроме кнопок -->
            <div
              class="block-page-content"
              *ngIf="
                frfValueSetterBlockForm.getNoSubmitStateOfBlockElement(
                  pageid,
                  objid,
                  isBlockPageContent
                )
              "
            ></div>
            <!-- блокировка содержимого с кнопоками -->
            <div
              class="block-page-all"
              *ngIf="
                frfValueSetterBlockForm.getFullStateOfBlockElement(
                  pageid,
                  objid,
                  isBlockFullPage
                )
              "
            ></div>

            <frf-group
              [pageid]="pageid"
              [objid]="objid"
              [freeform]="freeform"
              [disabled]="
                (pages[pageid]?.objects)[objid]?.body.clientGeneration ||
                (pages[pageid]?.objects)[objid]?.body?.status?.disabled ||
                disabled
              "
            ></frf-group>

            <!--helpers-->
            <ng-container *ngIf="(pages[pageid]?.objects)[objid]?.body?.helper">
              <div
                class="helper_ alert alert-info fade in alert-dismissible row"
                *ngIf="
                  (pages[pageid]?.objects)[objid]?.body?.status?.focus ===
                    true && (pages[pageid]?.objects)[objid]?.body?.helper?.text
                "
              >
                <div class="leftBlock_ col-1">
                  <div class="icon_">
                    <i class="fas fa-question-circle"></i>
                  </div>
                </div>
                <div class="body_ col-11">
                  {{ (pages[pageid]?.objects)[objid]?.body?.helper?.text }}
                </div>
              </div>

              <div
                class="success_ alert alert-success fade in alert-dismissible row"
                *ngIf="
                  (pages[pageid]?.objects)[objid]?.body?.helper?.success &&
                  (pages[pageid]?.objects)[objid]?.body?.status?.value === true
                "
              >
                <div class="leftBlock_ ol-1">
                  <div class="icon_">
                    <i class="fas fa-check-circle"></i>
                  </div>
                </div>
                <div class="body_ col-11">
                  {{ (pages[pageid]?.objects)[objid]?.body?.helper?.success }}
                </div>
              </div>

              <div
                class="error_ alert alert-danger fade in alert-dismissible row"
                *ngIf="
                  (pages[pageid]?.objects)[objid]?.body?.helper?.error &&
                  (pages[pageid]?.objects)[objid]?.body?.status?.untouched ===
                    true &&
                  (pages[pageid]?.objects)[objid]?.body?.status?.value !==
                    true &&
                  (pages[pageid]?.objects)[objid]?.body?.status?.required
                    .length > 0
                "
              >
                <div class="leftBlock_ col-1">
                  <div class="icon_">
                    <i class="fas fa-exclamation-circle"></i>
                  </div>
                </div>
                <div class="body_ col-11">
                  {{ (pages[pageid]?.objects)[objid]?.body?.helper?.error }}
                </div>
              </div>
            </ng-container>

            <div class="row buttons">
              <frf-page-submit
                class="left"
                (previous)="previousPage($event)"
                [isLeftSide]="true"
                [modelId]="pageid"
                [objId]="objid"
                [pageNumber]="pageNumber"
                [pagesCount]="pagesCount"
                [isLastPage]="pageNumber === pagesCount"
                [status]="(pages[pageid]?.objects)[objid]?.body?.status?.value"
                [frfElement]="(pages[pageid]?.objects)[objid]"
              >
              </frf-page-submit>
              <frf-page-submit
                class="right"
                (submit)="submitPage($event)"
                (next)="nextPage($event)"
                [isLeftSide]="false"
                [modelId]="pageid"
                [objId]="objid"
                [pageNumber]="pageNumber"
                [pagesCount]="pagesCount"
                [isLastPage]="pageNumber === pagesCount"
                [status]="(pages[pageid]?.objects)[objid]?.body?.status?.value"
                [frfElement]="(pages[pageid]?.objects)[objid]"
              >
              </frf-page-submit>
            </div>
          </div>
        </mat-step>
      </ng-container>

      <!--from object-->
      <ng-container
        *ngFor="
          let post of (pages[pageid]?.objects)[objid]?.post;
          trackBy: trackBy
        "
      >
        <ng-container
          *ngTemplateOutlet="
            fullPageTemplate;
            context: {
              pageid: post.baseid,
              objid: post.objid,
              freeform: freeform
            }
          "
        ></ng-container>
      </ng-container>
    </ng-template>

    <ng-container
      *ngFor="let page of freeform.map; trackBy: trackBy; let pageIndex = index"
    >
      <ng-container
        *ngTemplateOutlet="
          fullPageTemplate;
          context: {
            pageid: page.baseid,
            objid: page.objid,
            freeform: freeform,
            pageNumber: pageIndex + 1,
            pagesCount: freeform.map.length
          }
        "
      ></ng-container>
    </ng-container>
  </mat-horizontal-stepper>
</div>
