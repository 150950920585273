import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Apollo, gql } from 'apollo-angular-boost';
import { catchError, map } from 'rxjs/operators';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { CntFlexyViewCategoryForOwnerItemInterface } from './@res/@interface/common.interface';
import * as SmoothScroll from 'smooth-scroll';

@Injectable({
  providedIn: 'root'
})
export class CntFlexyViewCategoryService {
  constructor(
    private apollo: Apollo,
    private connectAuthService: ConnectAuthService
  ) {}

  /**
   * get categories for owner
   * */
  public getCategoriesForOwner(
    owner: string
  ): Observable<CntFlexyViewCategoryForOwnerItemInterface[]> {
    const query = gql`
      query flexyViewGetCategory(
        $uid: String
        $owner: String!
        $token: String
      ) {
        flexyViewGetCategory(uid: $uid, owner: $owner, token: $token) {
          status
          data {
            id
            parent
            name
            type
            value
          }
          error {
            code
            text
          }
        }
      }
    `;

    return this.apollo
      .watchQuery({
        query: query,
        variables: {
          uid: this.connectAuthService.uid,
          token: this.connectAuthService.token,
          owner: owner
        }
      })
      .valueChanges.pipe(
        catchError(() => of(null)),
        map((result: any) => {
          return result && result.data && result.data.flexyViewGetCategory;
        }),
        map(
          (result: { data: CntFlexyViewCategoryForOwnerItemInterface[] }) =>
            result && result.data
        )
      );
  }

  /**
   *
   * */
  public goToAppByHash(hash: string) {
    // TODO #temServeDisable
    const anchor = document.querySelector(`.flexy-view[hash="${hash}"]`),
      scroll = new SmoothScroll();

    scroll.animateScroll(anchor, undefined, {
      speed: 750,
      updateURL: false,
      header: '.cnt-flexy-page-header'
    });
  }
}
