import { Injectable, NgZone } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { debounceTime, filter, skipWhile } from 'rxjs/operators';

@Injectable()
// {
//     providedIn: CntTableFlexLazyLoadServiceModule
// }
export class CntTableFlexAutoWidthService {
  /**
   * class for external form styles
   * for later delete from dom
   * */
  private addedByCntFlexTableStyleClass = 'addedByCntFlexStyle-';

  /**
   *
   * */
  private tableId: string = '';

  /**
   * хранение всех стримов
   * */
  public readonly streams: { [key: string]: Subject<number> } = {};

  /**
   * params of columns
   * */
  public readonly columns: {
    [group: string]: {
      [key: string]: {
        width?: number;
        currentWidth?: number;
        firstMiniWidth?: number;
        el: HTMLElement;
      }[];
    };
  } = {};

  /**
   * обновление ширины с debouncing
   * */
  public correctWidth$ = new Subject();

  /**
   * correct width skip
   * */
  public correctWidthNeedPass = true;

  /**
   *
   * */
  public baseStyleForColumns: any = {};

  constructor(private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      this.correctWidth$
        .pipe(
          debounceTime(300),
          filter(() => {
            return this.correctWidthNeedPass;
          })
        )
        .subscribe(() => {
          this.correctWidthNeedPass = false;
          this.correctWidth();
        });
    });
  }

  /**
   *
   * */
  public addStyleByText$ = new Subject();

  /**
   * Получить всех стримов по колнке
   * */
  public getStreamByColumnKey(key: string) {
    this.safeCreateStream(key);
    return this.streams[key];
  }

  /**
   * обновить ширину
   * */
  public updateWidth(key: string, width: number) {
    this.safeCreateStream(key);
    this.streams[key].next(width);
  }

  /**
   * обновить ширину
   * */
  public pushElement(group: string, key: string, el: HTMLElement, idx: number) {
    this.safeCreateStream(key);

    if (!this.columns[group]) {
      this.columns[group] = {};
    }

    if (!this.columns[group][key]) {
      this.columns[group][key] = [];
    }

    this.columns[group][key][idx] = { el: el };
  }

  /**
   * обновить ширину
   * */
  public delElement(group: string, key: string, el: HTMLElement) {
    if (
      this.columns[group] &&
      this.columns[group][key] &&
      Array.isArray(this.columns[group][key]) &&
      this.columns[group][key].length
    ) {
      this.columns[group][key] = this.columns[group][key].filter(
        column => column && column.el !== el
      );
    }
  }

  /**
   * создаем если нет
   * */
  private safeCreateStream(key: string) {
    if (!this.streams[key]) {
      this.streams[key] = new Subject();
    }
  }

  /**
   * set style postfix for multi use
   * */
  public setStylePostFix(tableId: string) {
    this.setTableId(tableId);
    this.addedByCntFlexTableStyleClass += `-${tableId}`;
    this.addedByCntFlexTableStyleClass = this.addedByCntFlexTableStyleClass.replace(
      /[^a-zA-z0-9-]+/g,
      ''
    );
  }

  /**
   * set table id for set unique styles
   * */
  private setTableId(tableId: string) {
    this.tableId = `cntFlexTable-${tableId}`.replace(/[^a-zA-z0-9-]+/g, '');
  }

  /**
   * get table id for set unique styles
   * */
  public getTableId() {
    return this.tableId;
  }

  /**
   * get style postfix for multi use
   * */
  public getStylePostFix() {
    return this.addedByCntFlexTableStyleClass;
  }

  /**
   * корректировать ширину
   * */
  public correctWidth(groups?: string[], keys?: string[]) {
    const maxWidthKey: any = {};

    groups = groups || Object.keys(this.columns);

    /**
     * clear styles
     * */
    this.baseStyleForColumns = {};

    /*
     * clear early added style
     * */
    this.clearAddedStyles();

    /*
     * run after clear styles from dom
     * */
    timer(500).subscribe(() => {
      for (const group of groups) {
        keys = keys || Object.keys(this.columns[group]);

        /**
         * safe add group
         * */
        if (!this.baseStyleForColumns[group]) {
          this.baseStyleForColumns[group] = {};
        }

        for (const key of keys) {
          /**
           * safe add key in group
           * */
          if (!this.baseStyleForColumns[group][key]) {
            this.baseStyleForColumns[group][key] = {};
          }

          if (!maxWidthKey[key]) {
            maxWidthKey[key] = 0;
          }

          this.columns[group][key].forEach(column => {
            column.width = column.el.clientWidth;

            if (typeof column.firstMiniWidth === 'undefined' && column.width) {
              column.firstMiniWidth =
                column.width /* column.el.clientWidth*/ || 0;
            }

            /*
             * установить максимальную ширину
             * */
            if (maxWidthKey[key] < column.width) {
              // console.log('correctWidth - column',  key, column.width, column);
              maxWidthKey[key] = column.width;
            }
          });

          this.updateWidthByColumn(group, key, maxWidthKey[key]);
        }
      }

      this.addStyleByText(this.generateStyleCode());
    });
  }

  private generateStyleCode(): string {
    let startStyle = '',
      basePath = '';

    Object.keys(this.baseStyleForColumns).forEach(groupKey => {
      basePath = `#${this.getTableId()} .flex-table.group_${groupKey} `;

      Object.keys(this.baseStyleForColumns[groupKey]).forEach(key => {
        startStyle += `${basePath} .flex-cell.key_${key} {`;
        if (this.baseStyleForColumns[groupKey][key]) {
          startStyle += `
                                                    will-change: contents;
                                                    transition: all 300ms linear !important;
                                                `;

          Object.keys(this.baseStyleForColumns[groupKey][key]).forEach(
            styleKey => {
              const styleVal = this.baseStyleForColumns[groupKey][key][
                styleKey
              ];
              startStyle += `${styleKey}: ${styleVal};`;
            }
          );
        }

        startStyle += '}';
      });
    });

    return startStyle;
  }

  /**
   * установить максимальную ширину
   * */
  public updateWidthByColumn(group: string, key: string, maxWidth: number) {
    this.columns[group][key].forEach(column => {
      const maxCssWidth = parseInt(column.el.style.maxWidth, 10) || 0;

      if (
        column.currentWidth !== maxWidth &&
        (!maxCssWidth || maxCssWidth >= maxWidth)
      ) {
        column.currentWidth = maxWidth;

        this.baseStyleForColumns[group][key]['min-width'] = `${maxWidth}px`;
        // this.baseStyleForColumns[group][key]['max-width'] = `${maxWidth}px`;
        this.baseStyleForColumns[group][key].width = `${maxWidth}px`;
      } /* else {

                // }*/
    });
  }

  /**
   *
   * */
  public restoreOldWidth(keys: string[] | string, groups?: string[]) {
    // if (!Array.isArray(keys)) {
    //     keys = [keys];
    // }
    //
    // if (!Array.isArray(groups)) {
    //     groups = Object.keys(this.columns);
    // }
    //
    // for (const group of groups) {
    //     for (const key of keys) {
    //         if (this.columns[group] && this.columns[group][key]) {
    //             this.columns[group][key].forEach(
    //                 (column, index) => {
    //                     if (
    //                         true
    //                         // column.currentWidth  &&
    //                         // column.currentWidth !== column.firstMiniWidth
    //                     ) {
    //                         /*
    //                         *
    //                         * */
    //                         window.requestAnimationFrame(
    //                             () => {
    //                                 column.el.style.minWidth = column.firstMiniWidth
    //                                  ? `${column.firstMiniWidth}px`
    //                                  : 'none';
    //                             }
    //                         );
    //                         /*
    //                         *
    //                         * */
    //                         column.currentWidth = column.firstMiniWidth;
    //                     }
    //                 }
    //             );
    //         }
    //     }
    // }
  }

  /**
   * добавить стили в текст
   * */
  public addStyleByText(css: string) {
    const head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.classList.add(this.addedByCntFlexTableStyleClass);
    style.type = 'text/css';

    /* Добавление в head */
    head.appendChild(style);

    style.appendChild(document.createTextNode(css));

    style.onload = () => {
      this.addStyleByText$.next();
    };
  }

  /**
   * clear all added style
   * */
  public clearAddedStyles(code: string = '') {
    const elements = document.querySelectorAll(
      `.${this.addedByCntFlexTableStyleClass}`
    );

    if (elements) {
      elements.forEach(elem => {
        elem.parentNode.removeChild(elem);
      });
    }
  }
}
