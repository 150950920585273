import { Injectable } from '@angular/core';
import { FrfValueSetterService } from '../@group:value-services/frf-value-setter/frf-value-setter.service';
import { FrfValueGetterService } from '../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';

/**
 *
 *
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfInitialValueService {
  constructor(
    private frfValueGetter: FrfValueGetterService,
    private frfValueSetter: FrfValueSetterService
  ) {}

  /**
   * set initial value only if we has this value and field has not base value
   * */
  public setIfNeedInitialValueIfItExist(
    field: any,
    frf: any,
    scope: any
  ): any | null {
    if (field && field.body && field.body.initialValue && !field.body.value) {
      const initialValue = this.frfValueGetter.getValue(
        field.body.initialValue,
        field,
        frf,
        field,
        scope
      );

      if (initialValue) {
        this.frfValueSetter.setFieldValue(field, initialValue, [
          'onChange',
          'onSelect'
        ]);
        return initialValue;
      }
    }

    return null;
  }
}
