import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FrfToastInterface } from './@res/@abstract/@interface/common.interface';

/**
 * Мост для запуска кастомных тостов
 * */
@Injectable({
  providedIn: 'root',
})
export class FrfBridgeToastService {
  /**
   * */
  private toast$: Subject<FrfToastInterface> = new Subject();

  /**
   * */
  public create(toast: FrfToastInterface) {
    this.toast$.next(toast);
  }

  /**
   * */
  public get$(): Observable<FrfToastInterface> {
    return this.toast$;
  }
}
