import { Injectable } from '@angular/core';
import { CntModalApiService } from '../../../../../../../../libs/feature/cnt-flexy-view/src/lib/@sub/@service/cnt-modal-api/cnt-modal-api.service';
import { Apollo } from 'apollo-angular';
import { Observable, of, Subject } from 'rxjs';
import { gql } from 'apollo-angular-boost';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import {
  FlexyViewMainSearchItemInterface,
  SCntBaseSearhOutputItem,
} from './@res/@abstract/@interface/common.interface';
import { RnWebElementSetParams } from '@rn-codebase/function/js/full/element/web-element.function';
import { SCntBaseSearhInputItem } from '@cnt-nx-workspace/design/stencil/airtafa';
import { NavigateService } from '../../@module/main/@sub/@service/navigate/navigate.service';
import { CntModuleApiInterface } from '../../../../../../../../libs/feature/cnt-flexy-view/src/lib/@sub/@service/cnt-modal-api/@res/@class/cnt-module-api/@res/@abstract/@interface/common.interface';
import {
  RnKeyboardDetect,
  RnKeyboardDetectEnter,
  RnKeyboardDetectEsc,
} from '@rn-codebase/function/js/full/keyboard/keyboard.function';
import { RnKeyboardControlValueEnum } from '@rn-codebase/function/js/full/keyboard/@res/@abstract/@enum/common.enum';
import { CntUserTypeMemsqlEnum } from '@cnt-multi-shared/@shared/@main/@res/@abstract/@enum/common.enum';

@Injectable({
  providedIn: 'root',
})
export class MainSearchService {
  /**
   * */
  public openedTag: HTMLElement;

  /**
   * */
  public items: SCntBaseSearhOutputItem[] = [];

  /**
   * */
  public openedHost: HTMLElement;

  /**
   * */
  public cntModalApi: CntModuleApiInterface;

  /**
   * */
  private search$: Subject<string> = new Subject();

  /**
   * */
  public placeholderValue = 'Введите запрос';

  /**
   * */
  public listenerForCloseWindowAfterPresEsc = (e: KeyboardEvent) => {
    if (RnKeyboardDetectEsc(e)) {
      /* close after press esc */
      this.hide();
    }

    if (RnKeyboardDetectEnter(e) && this.items?.length) {
      /* open first item */
      this.clickHandlerFromSearcher(this.items[0]);
    }
  };

  constructor(
    private apollo: Apollo,
    private navigateService: NavigateService
  ) {
    this.initSearch();
  }

  /**
   *
   * */
  public initSearch(): Observable<any> {
    return this.search$.pipe(
      debounceTime(150),
      switchMap((value) =>
        value.replace(/[ \r\t\n ]/g, '').length
          ? this.requestForGetSearch(value)
          : of([])
      ),
      tap((items) => {
        this.updateSearcher(
          items &&
            items.map((item) => ({
              ...item,
              header: item.value,
              img: item.icon,
            }))
        );
      })
    );
  }

  /**
   *
   * */
  public close(): void {
    this.cntModalApi?.setOverflow(document.body, false);
    this.openedHost?.remove();
  }

  /**
   *
   * */
  public hide() {
    if (this.openedTag) {
      // @ts-ignore
      this.openedTag.turnOff();
      setTimeout(() => {
        this.close();
      }, 250);
    }
  }

  /**
   *
   * */
  private requestForGetSearch(
    value: string
  ): Observable<FlexyViewMainSearchItemInterface[]> {
    return this.apollo
      .watchQuery({
        query: gql`
          query flexyViewMainSearch(
            $uid: String
            $value: String!
            $token: String
          ) {
            flexyViewMainSearch(uid: $uid, value: $value, token: $token) {
              status
              data {
                type
                icon
                value
                login
                description
                id
                oid
              }
              error {
                code
                text
              }
            }
          }
        `,
        fetchPolicy: 'network-only',
        variables: {
          uid: null,
          value: value,
          token: null,
        },
      })
      .valueChanges.pipe(
        map((result: any) => result.data?.flexyViewMainSearch?.data || null),
        catchError((error) => {
          console.warn('requestForGetSearch - error', error);
          return of(null);
        })
      );
  }

  private updateSearcher(items: SCntBaseSearhInputItem[]): void {
    // @ts-ignore
    this.items = items;

    if (this.openedTag) {
      RnWebElementSetParams(this.openedTag, {
        items: items && items.filter((item) => item && item.header),
      });
    }
  }

  /**
   * */
  public show(): void {
    this.close();

    const cntModalApiService = new CntModalApiService();

    cntModalApiService.init({
      pathToAssets: '',
      cntApi: null,
    });

    const result = cntModalApiService.openByTagName('s-cnt-base-search-modal', {
      params: {
        items: [],
        placeholder: this.placeholderValue,
      },
      listeners: {
        clickToBackground: (e) => {},
        expand: (e) => {},
        hide: (e) => {},
        clickHint: (e: CustomEvent<SCntBaseSearhOutputItem>) => {
          this.clickHandlerFromSearcher(e.detail);
        },
        update: (ev: CustomEvent<string>) => {
          this.search$.next(ev.detail);
        },
        clickToCloseButton: (e) => {
          this.hide();
        },
      },
      onInit: () => {
        document.addEventListener(
          'keyup',
          this.listenerForCloseWindowAfterPresEsc
        );
      },
      onDestroy: () => {
        document.removeEventListener(
          'keyup',
          this.listenerForCloseWindowAfterPresEsc
        );
      },
    });
    this.openedTag = result.tag;
    this.openedHost = result.host;
    this.cntModalApi = result.modalApi;
  }

  /**
   *
   * */
  public clickHandlerFromSearcher(item: SCntBaseSearhOutputItem): void {
    this.close();
    if (item) {
      switch (item.type) {
        case CntUserTypeMemsqlEnum.simple:
          const login = item.login && item.login[0];
          if (login) {
            this.navigateService.openByLogin(login);
          }
          break;
      }
    }
  }

  /**
   *
   * */
  public isPressHotKeysToShowModal(e: KeyboardEvent): boolean {
    return RnKeyboardDetect(
      e,
      ['f'],
      [[RnKeyboardControlValueEnum.meta], [RnKeyboardControlValueEnum.ctrl]]
    );
  }
}
