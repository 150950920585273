import { Injectable } from '@angular/core';
import { FrfTriggerActionShowToastInterface } from './@res/@abstract/@interface/common.interface';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
} from '@angular/material/snack-bar';
import { FrfBridgeToastService } from '../../../../../../frf-bridge-toast/frf-bridge-toast.service';

@Injectable({
  providedIn: FrfMainLazyServiceModule,
})
export class FrfTriggerActionToastService {
  constructor(
    private snackBar: MatSnackBar,
    private frfBridgeToastService: FrfBridgeToastService
  ) {}

  /**
   * show
   * */
  public run(action: FrfTriggerActionShowToastInterface, scope: any) {
    /**
     * TODO late active bridge after fix overlay container problem */
    // this.frfBridgeToastService.create(action);
    return this.showWaitingBar(action.message, action.duration);
  }

  /**
   * show snackbar
   * */
  public showSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  /**
   * show bar when we start async operation
   * */
  public showWaitingBar(
    message: string,
    duration?: number,
    horizontalPosition?: MatSnackBarHorizontalPosition
  ) {
    if (!horizontalPosition) {
      horizontalPosition = 'start';
    }

    this.snackBar.open(message, undefined, { duration, horizontalPosition });
  }
}
