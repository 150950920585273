import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FreeformCommonService } from '../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfEmitterService } from '../../../../../@service/frf-emitter/frf-emitter.service';
import * as mzCommon from 'mz-common';
import { filter, takeUntil } from 'rxjs/operators';
import { FrfValueSetterBlockerTypeEnum } from '../../../../../@service/@group:value-services/frf-value-setter/@sub/frf-value-setter-blocker/@res/@abstract/@enum/common.enum';
import { FrfValueSetterBlockerService } from '../../../../../@service/@group:value-services/frf-value-setter/@sub/frf-value-setter-blocker/frf-value-setter-blocker.service';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'frf-page',
  templateUrl: './freeform-page.component.html',
  styleUrls: ['./freeform-page.component.scss']
})
export class FreeformPageComponent extends AbstractUnsubscribeViewControl
  implements OnInit {
  @Input('freeform') freeform;
  @Input('disabled') disabled;

  /**
   * stepper options
   * */
  public isLinear = true;

  /**
   * show/hide stepper of form
   * */
  public hideStepper = false;

  /**
   * current opened page index
   * */
  public selectedIndex = 0;

  /**
   * block content without full buttons
   * */
  public isBlockPageContent = false;

  /**
   * block full page with submit buttons
   * */
  public isBlockFullPage = false;

  constructor(
    private common: FreeformCommonService,
    private frfEmiter: FrfEmitterService,
    public frfValueSetterBlockForm: FrfValueSetterBlockerService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    //@< hide stepper
    if (
      this.common.freeform.options.view &&
      this.common.freeform.options.view.hideStepper
    ) {
      this.hideStepper = this.common.freeform.options.view.hideStepper;
    }
    //@> hide stepper

    this.initBlocker();
    this.initElementBlocker();
  }

  /**
   *
   * */
  private initElementBlocker() {
    return this.frfValueSetterBlockForm.stateElement$
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(input => {
        this.cdRef.markForCheck();
      });
  }

  /**
   * blocker/unblocker for page content or full page
   * */
  private initBlocker() {
    this.frfValueSetterBlockForm.state$
      .pipe(
        takeUntil(this.viewDestroy$),
        filter(input => !!input)
      )
      .subscribe(input => {
        if (input.clear) {
          this.isBlockPageContent = this.isBlockFullPage = false;
        } else if (input.type === FrfValueSetterBlockerTypeEnum.fullPage) {
          this.isBlockFullPage = !!input.state;
        } else if (input.type === FrfValueSetterBlockerTypeEnum.pageContent) {
          this.isBlockPageContent = !!input.state;
        }

        this.cdRef.markForCheck();
      });
  }

  /**
   * submit this page
   * */
  public submitPage(btn) {
    /* show loader */
    this.common.freeform.downloaded = false;

    /*
     * init for emit if need
     * */
    this.frfEmiter.initFormToEmit(this.common.freeform);

    /*
     * emit on submit
     * */
    this.frfEmiter.emitOnSubmit(true);

    /*
     * hide loader
     * */
    this.common.freeform.downloaded = true;

    /**
     * close form
     * */
    this.common.freeform['destroyed'] = true;

    /*
     * set message
     * */
    this.common.freeform['success'] = { '*': 'Спасибо за заполнение.' };

    /* TODO later active */
    /*this.common.submitForm(
      this.frf.formInfo.uid,
      this.frf.formInfo.modelId,
      this.frf.formInfo.formId,
      (error) => {
        /!*
        * init for emit if need
        * *!/
        this.frfEmiter.initFormToEmit(this.common.freeform);
        /!*
        * emit on submit
        * *!/
        this.frfEmiter.emitOnSubmit();

        //hide loader
        this.common.freeform.downloaded = true;

        if (error) {
          // show error message -> not close form
          //!**LATER add multilanguage
          this.common.freeform['error'] = {'*': 'Не возможно сохранить форму.'}
          return;
        }
        // we successed submit form -> close form -> show success message
        this.common.freeform['success'] = {'*': 'Форма успешно сохранена.'}
        //close form
        this.common.freeform['destroyed'] = true;
      }
    );*/
    // console.log('submitPage 3', btn);
  }

  /**
   *
   * */
  public previousPage(iNbtn) {
    // const btn = iNbtn;
    this.selectedIndex--;
    // console.log('previousPage', btn);
  }

  /**
   *
   * */
  public nextPage(iNbtn) {
    // const btn = iNbtn;
    this.selectedIndex++;
  }

  /**
   *
   * */
  public trackBy(idx: number, item: any) {
    return idx;
  }

  /**
   *
   * */
  public getElementStatus(page: any) {
    /**
     *
     * */
    return mzCommon.getValueFromObjectByPath('body.status.value', page);
  }

  /**
   *
   * */
  public changePage(page) {}
}
