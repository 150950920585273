import { Injectable } from '@angular/core';
import {
  FrfValueGetterClassInterface,
  FrfValueGetterCustomDataInterface,
  FrfValueGetterServiceInterface
} from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueCheckerService } from '../../../frf-value-checker/frf-value-checker.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfValueGetterCustomValueInterface } from './@res/@abstract/@interface/common.interface';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import * as mzCommon from 'mz-common';

/**
 * получение с обходом элементов
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterCustomValueService
  implements FrfValueGetterClassInterface {
  constructor() {}

  /**
   *
   * */
  public get(
    data: FrfValueGetterCustomValueInterface,
    valueGetter: FrfValueGetterServiceInterface,
    frfElement: any | any[],
    frf: any,
    startFrfElement: any,
    scope: any
  ): Observable<any[]> {
    return fromPromise(
      this.getInPromise(
        data,
        valueGetter,
        frfElement,
        frf,
        startFrfElement,
        scope
      )
    );
  }
  /**
   *
   * */
  public async getInPromise(
    data: FrfValueGetterCustomValueInterface,
    valueGetter: FrfValueGetterServiceInterface,
    frfElement: any | any[],
    frf: any,
    startFrfElement: any,
    scope: any
  ): Promise<any> {
    return this.getCustomValue(
      data.value,
      valueGetter,
      frfElement,
      frf,
      startFrfElement,
      undefined,
      scope
    );
  }

  /**
   * get custom value with {value}
   * */
  private async getCustomValue(
    customValue: string,
    valueGetter: FrfValueGetterServiceInterface,
    frfElementObject: any | null,
    /* TODO add type later*/
    frf: any,
    startFrfElement: any | null = null,
    pathToValue: string,
    scope: any
  ) {
    let value = customValue;
    const result = {};

    if (pathToValue) {
      value = mzCommon.getValueFromObjectByPath(pathToValue, customValue);
    }

    if (value && typeof value === 'object') {
      const keys = Object.keys(value);

      for (const key of keys) {
        let data;
        const customKey = this.getCustomParamKey(key);

        if (customKey /*this.getCustomParamKey(key) */) {
          const customData: FrfValueGetterCustomDataInterface = value[key],
            customDataKey = customData.key || customKey;

          /* get value */
          data = await valueGetter
            .getValueWithPipe(
              customData.value,
              frfElementObject,
              frf,
              startFrfElement,
              scope
            )
            .toPromise();
          result[customDataKey] = data;
        } else {
          data = await this.getCustomValue(
            value,
            valueGetter,
            frfElementObject,
            frf,
            startFrfElement,
            `${key}`,
            scope
          );

          result[key] = data;
        }
      }

      return result;
    } else {
      return value;
    }
  }

  /**
   * get custom param
   * */
  private getCustomParamKey(key: string): string | null {
    const regex = /^\{\{[^\{\}]+\}\}$/g,
      find = key.match(regex);

    return (find && find[0] && find[0].replace(/[\{\}]+/g, '')) || null;
  }
}
