import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FrfCustomFieldApiUploadResultInterface,
  FrfCustomFieldApiUploadServiceInterface
} from './@res/@abstract/@interface/common.interface';
import { FrfFileUploadService } from '../../../../../../../../../frf-field-upload/@sub/@service/frf-file-upload.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../../../../../../../../../../../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfCustomFieldApiUploadService
  implements FrfCustomFieldApiUploadServiceInterface {
  constructor(private frfFileUpload: FrfFileUploadService) {}

  /**
   * upload file
   * */
  public uploadFile(
    field: any,
    fileList: FileList
  ): Observable<FrfCustomFieldApiUploadResultInterface> {
    return this.frfFileUpload.lightUploadFile(field, fileList);
  }
}
