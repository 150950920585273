export enum FrfValueCheckerEnum {
  isString = 'isString',
  isAny = 'isAny',
  isArray = 'isArray',
  isNotArray = '!isArray',
  isNumber = 'isNumber',
  isNull = 'isNull',
  isNotString = '!isString',
  isNotNumber = '!isNumber',
  isNotNull = '!isNull'
}
