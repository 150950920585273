export const GetSharepayWbListMock = [
  {
    fname: 'Зураб',
    lname: 'Магомадов',
    mname: 'Азнаурович',
    inn: '2051023364',
    snils: '145-0312312',
    phones: ['79287377782'],
    passport: ['123456789'],
    owner: 'airbank',
    author: '@user-test',
    uid: '',
    created: undefined,
    updated: undefined,
    deleted: undefined
  },
  {
    fname: 'Ислам',
    lname: 'Магомадов',
    mname: 'Азнаурович',
    inn: '8951023841',
    snils: '143-1212378',
    phones: ['79959051993'],
    passport: ['122356954'],
    owner: 'airbank',
    author: '@user-test',
    uid: '',
    created: undefined,
    updated: undefined,
    deleted: undefined
  }
];
