import { Injectable } from '@angular/core';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { frfValueGetterCustomMenuGetBasePath } from '../../../frf-value-getter/@sub/frf-value-getter-custom-menu/@res/@function/common.function';
import { FrfTriggerNameEnum } from '../../../../frf-trigger/@res/@abstract/@enum/common.enum';
import { FrfValueSetterTempDataStorageTypeEnum } from '../frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FrfValueSetterTempDataService } from '../frf-value-setter-temp-data/frf-value-setter-temp-data.service';
import { FrfValueSetterCustomMenuInterface } from './@res/@abstract/@interface/common.interface';
import { FrfValueSetterInterface } from '../../../@res/@abstract/@interface/common.interface';
import { FreeformCommonService } from '../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfTriggerService } from '../../../../frf-trigger/frf-trigger.service';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueSetterCustomMenuService
  implements FrfValueSetterInterface {
  constructor(
    private trigger: FrfTriggerService,
    private common: FreeformCommonService,
    private frfValueSetterTempData: FrfValueSetterTempDataService
  ) {}

  /**
   * сохраняет временные данные (доступные только до закрытие/перезагрузки формы) к элементу
   * */
  public set(
    element: any,
    input: FrfValueSetterCustomMenuInterface,
    trigger = [FrfTriggerNameEnum.onClickToCustomMenu]
  ): void {}

  public saveLastClick(
    element: any,
    customMenu: FrfValueSetterCustomMenuInterface,
    trigger = [FrfTriggerNameEnum.onClickToCustomMenu]
  ): void {
    if (typeof customMenu.active === 'boolean' && !customMenu.active) {
      return;
    }

    // const fullPath = `${FrfValueSetterCustomMenuEnum.tempPath}.${customMenu.key}`;
    const fullPath = frfValueGetterCustomMenuGetBasePath(customMenu.key);

    this.frfValueSetterTempData.set(
      element,
      fullPath,
      customMenu,
      FrfValueSetterTempDataStorageTypeEnum.system,
      trigger,
      false
    );
  }

  /**
   *
   * */
  public getCustomMenuWithSystemMenu(
    menu: FrfValueSetterCustomMenuInterface[],
    systemCustomMenus: FrfValueSetterCustomMenuInterface[] = []
  ): FrfValueSetterCustomMenuInterface[] {
    let menuSorted = [];

    if (Array.isArray(menu) && menu.length) {
      menuSorted = this.common.sortByWeight<FrfValueSetterCustomMenuInterface>(
        menu
      );
    }

    return menuSorted
      .concat(this.common.sortByWeight(systemCustomMenus))
      .filter(
        (menu: FrfValueSetterCustomMenuInterface) =>
          typeof menu.active !== 'boolean' || menu.active
      );
  }

  /**
   *
   * */
  private getCustomMenuFromElement(
    frfElement: any
  ): FrfValueSetterCustomMenuInterface[] {
    return (
      (frfElement &&
        frfElement.body &&
        frfElement.body.payload &&
        frfElement.body.payload.$shared &&
        frfElement.body.payload.$shared.customMenu) ||
      []
    );
  }

  /**
   *
   * */
  public getCustomMenuWithSystemMenuByField(
    frfElement: any[],
    systemCustomMenus: FrfValueSetterCustomMenuInterface[] = []
  ): FrfValueSetterCustomMenuInterface[] {
    return this.getCustomMenuWithSystemMenu(
      this.getCustomMenuFromElement(frfElement),
      systemCustomMenus
    );
  }
}
