import { Injectable } from '@angular/core';
import {
  CntApiMessengerInterface,
  CntApiMessengerSendDataInput
} from './@res/@interface/cnt-api-messenger.interface';
import {
  CntApiMessengerSendMessageEndpointEnum,
  CntApiMessengerEnum
} from './@res/@enum/cnt-api-messenger.enum';

@Injectable({
  providedIn: 'root'
})
export class CntFlexyViewApiMessengerService
  implements CntApiMessengerInterface {
  /**
   *
   * */
  private telegramId: string;

  /**
   *
   * */
  private whatsappId: string;

  /**
   *
   * */
  private viberId: string;

  constructor() {}

  /**
   *
   * */
  public send(
    data: CntApiMessengerSendDataInput,
    messenger: CntApiMessengerEnum = CntApiMessengerEnum.cnt
  ): boolean {
    switch (messenger) {
      case CntApiMessengerEnum.telegram:
        return this.sendViaTelegram(data);

      case CntApiMessengerEnum.whatsapp:
        return this.sendViaWhatsapp(data);

      case CntApiMessengerEnum.viber:
        return this.sendViaViber(data);

      case CntApiMessengerEnum.vk:
        /* TODO later add*/
        return false;

      case CntApiMessengerEnum.cnt:
        /* TODO later add*/
        return false;
    }

    return false;
  }

  /**
   *
   * */
  private getTelegramId(id?: string): string | null {
    return id || this.telegramId || null;
  }

  /**
   *
   * */
  private getWhatsappId(id?: string): string | null {
    return id || this.whatsappId || null;
  }

  /**
   *
   * */
  private getViberId(id?: string): string | null {
    return id || this.viberId || null;
  }

  /**
   *
   * */
  private sendViaTelegram(data: CntApiMessengerSendDataInput): boolean {
    const telegramId = this.getTelegramId(data.id);

    if (telegramId) {
      window.open(
        this.buildUrl(
          CntApiMessengerSendMessageEndpointEnum.telegram + telegramId,
          {
            // url: data.title ? data.title : data.message,
            // text: data.title ? data.message : '',
            // to: telegramId
          }
        ),
        '_blank'
      );

      return true;
    }

    return false;
  }

  /**
   *
   * */
  private sendViaWhatsapp(data: CntApiMessengerSendDataInput): boolean {
    const whatsappId = this.getWhatsappId(data.id);

    if (whatsappId) {
      window.open(
        this.buildUrl(CntApiMessengerSendMessageEndpointEnum.whatsapp, {
          text: data.title ? `*${data.title}*\n${data.message}` : data.message,
          phone: whatsappId
        }),
        '_blank'
      );

      return true;
    }

    return false;
  }

  /**
   *
   * */
  private sendViaViber(data: CntApiMessengerSendDataInput): boolean {
    const viberId = this.getViberId(data.id);

    if (viberId) {
      window.open(
        this.buildUrl(CntApiMessengerSendMessageEndpointEnum.viber, {
          text: data.title ? `*${data.title}*\n${data.message}` : data.message,
          chatURI: viberId
        }),
        '_blank'
      );

      return true;
    }

    return false;
  }

  /**
   *
   * */
  private buildUrl(url: string, data: { [key: string]: any }) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));

    return `${url}?` + ret.join('&');
  }

  /**
   * TODO later add all messengers
   * */
  public get(messenger: CntApiMessengerEnum): string | number | null {
    switch (messenger) {
      case CntApiMessengerEnum.telegram:
        return this.telegramId;

      case CntApiMessengerEnum.whatsapp:
        return this.whatsappId;

      case CntApiMessengerEnum.viber:
        return this.viberId;
    }

    return null;
  }

  /**
   *
   * */
  public check(messenger: CntApiMessengerEnum, id?: string | number): boolean {
    return !!(id || this.get(messenger));
  }
}
