/**
 * */
import { RnKeyboardControlValueEnum } from '@rn-codebase/function/js/full/keyboard/@res/@abstract/@enum/common.enum';

export function RnKeyboardDetectByKey(
  event: KeyboardEvent,
  keys: string[] | string
): boolean {
  keys = Array.isArray(keys) ? keys : [keys];

  return !!(event && event.key && keys.indexOf(event.key) !== -1);
}

/**
 * */
export function RnKeyboardDetectEnter(event: KeyboardEvent): boolean {
  return RnKeyboardDetectByKey(event, 'Enter');
}

/**
 * */
export function RnKeyboardDetect(
  event: KeyboardEvent,
  keys: string[],
  control: RnKeyboardControlValueEnum[][] = [],
  preventDefault = true
): boolean {
  if (preventDefault) {
    event.preventDefault();
  }
  const pressedControlKey = control?.length
  ? (
      control.find(items => {
        return !items.find(item => !(event && event[item]));
      })
  )
  : true;

  return !!(pressedControlKey && RnKeyboardDetectByKey(event, keys));
}

/**
 * */
export function RnKeyboardDetectEsc(event: KeyboardEvent): boolean {
  return RnKeyboardDetectByKey(event, 'Escape');
}
