import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FrfElementInvokerMethodService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-element-invoker/@sub/frf-element-invoker-method/frf-element-invoker-method.service';
import { FrfInitialValueService } from '../../../../../../../../../../../../../../@service/frf-initial-value/frf-initial-value.service';
import { FrfValueCheckerEnum } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-checker/@res/@abstract/@enum/common.enum';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfCustomFieldWrapperService } from './@sub/@service/frf-custom-field-wrapper/frf-custom-field-wrapper.service';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { FrfValueSetterPayloadService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/@sub/frf-value-setter-payload/frf-value-setter-payload.service';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';
import { Subject } from 'rxjs';

@Component({
  selector: 'frf-custom-field-wrapper',
  templateUrl: './frf-custom-field-wrapper.component.html',
  styleUrls: ['./frf-custom-field-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrfCustomFieldWrapperComponent
  extends AbstractUnsubscribeViewControl
  implements OnInit, OnChanges, AfterViewInit {
  /* reqired */
  @Input() fieldid;
  @Input() customIdWithPrefix;
  @Input() objid;
  @Input() frf;
  @Input() disabled;

  /* optional */
  @Input() type = 'text';

  /**
   *
   * */
  @Output() changeStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * this field from freeform object
   * */
  public field;

  /**
   *
   * */
  private element: any;

  /**
   *
   * */
  public customId: string;

  /**
   *
   * */
  public updateStatus$: Subject<boolean> = new Subject();

  constructor(
    private common: FreeformCommonService,
    private frfInitialValueService: FrfInitialValueService,
    private frfValueSetterService: FrfValueSetterService,
    private frfCustomFieldWrapper: FrfCustomFieldWrapperService,
    private elementRef: ElementRef,
    private frfElementInvokerMethod: FrfElementInvokerMethodService,
    private frfValueSetterPayload: FrfValueSetterPayloadService,
    private frfValueSetter: FrfValueSetterService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {
    this.field = this.frf.fields[this.fieldid].objects[this.objid];
    this.type = this.field.type || this.type;
    this.customId = this.frfCustomFieldWrapper.getClearIdWithoutPrefix(
      this.customIdWithPrefix
    );

    this.initCustomComponent();
  }

  ngAfterViewInit(): void {
    /*
     * set if need inital value
     * */
    this.frfInitialValueService.setIfNeedInitialValueIfItExist(
      this.field,
      this.frf,
      {}
    );

    this.watchFieldChanges();
    this.listenElementMethodInvoker();
  }

  /**
   *
   * */
  private initListenersForCustomComponent$() {
    this.updateStatus$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.viewDestroy$)
      )
      .subscribe(status => {
        this.frfValueSetter.updateStatus(
          this.field,
          status,
          this.common.freeform
        );

        this.changeStatus.emit(status);
      });
  }

  /**
   *
   * */
  private initCustomComponent() {
    this.initListenersForCustomComponent$();

    this.frfCustomFieldWrapper
      .initComponent(
        this.elementRef,
        this.customId,
        this.field,
        (status: boolean) => {
          this.updateStatus$.next(status);
        },
        value => {
          this.frfValueSetterService.setFieldValue(
            this.field,
            value,
            ['onChange'],
            true,
            undefined,
            FrfValueCheckerEnum.isAny,
            undefined,
            false,
            false
          );
        },
        data => {
          this.frfValueSetterPayload.set(data.value, data.path, this.field);
        },
        element => {
          this.element = element;
        }
      )
      .subscribe();
  }

  /**
   * watch field changes
   * */
  private watchFieldChanges() {
    this.frfValueSetterService
      .watchFieldChanges(this.fieldid, this.objid)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(() => {
        this.updateElement();
      });
  }

  /**
   *
   * */
  private listenElementMethodInvoker() {
    this.frfElementInvokerMethod
      .listenElementInvoker$(this.fieldid, this.objid)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(data => {
        if (data && data.method && data.method.name) {
          this.invokeElementMethodIfExist(data.method.name, data.method.params);
        }
      });
  }

  /**
   *
   * */
  private updateElement(forse = true) {
    this.invokeElementMethodIfExist('updateElement', [forse]);
  }

  /**
   *
   * */
  private invokeElementMethodIfExist(methodName: string, params?: any[]) {
    if (!Array.isArray(params)) {
      params = [];
    }

    if (this.element && typeof this.element[methodName] === 'function') {
      this.element[methodName](...params);
    }
  }
}
