import { Injectable } from '@angular/core';
// import { FrfFieldObjectInterface } from '../../../../@multi-shared/connect-multi-shared/@shared/freeform/@res/@abstract/@interface/@field/@object/frf-field.object.interface';
import {
  FrfElementGetterServiceInterface,
  FrfValueGetterServiceInterface
} from '../../@res/@abstract/@interface/common.inteface';
import {
  FrfValueReferenceFunctionEnum,
  FrfValueReferenceOperatorPrefixEnum
} from './@res/@abstract/@enum/common.enum';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import {
  FrfElementFolderTypeEnum,
  FrfElementTypeEnum
} from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@enum/common.enum';
import { FrfFieldObjectInterface } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@interface/@field/@object/frf-field.object.interface';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterReferenceService {
  /**
   *
   * */
  private getDataFromBracketOfString(iNstr) {
    let str = iNstr,
      result = str.match(/\([^\)\(]+\)/g);

    if (result) return result[0].replace(/[\(\)]+/g, '');

    return null;
  }

  /**
   * split str
   * */
  private splitStr(str: string, seperator = '.'): string[] {
    return str.split(seperator);
  }

  /**
   * get data without bracket of string
   * */
  private getDataWithoutBracketOfString(input: string) {
    const value = input.match(/[^\(\)]+/g);

    return (value && value[0]) || null;
  }

  /**
   * get by function
   * */
  private getByFunction(
    funcName: string,
    value: string,
    element: any,
    frf: any,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    switch (funcName) {
      case FrfValueReferenceFunctionEnum.thisElement:
        return this.funcNameGetThisElement(element);

      case FrfValueReferenceFunctionEnum.byChildLocalId:
        return this.funcNameByLid(value, element, frf, frfValueGetterService);

      case FrfValueReferenceFunctionEnum.byInId:
        return this.funcNameByInId(value, frf, frfValueGetterService);

      case FrfValueReferenceFunctionEnum.parentByType:
        return this.funcNameGetParent(
          value,
          frf,
          element,
          frfValueGetterService
        );

      case FrfValueReferenceFunctionEnum.parentGroup:
        return this.funcNameGetParentGroup(frf, element, frfValueGetterService);

      case FrfValueReferenceFunctionEnum.parentRow:
        return this.funcNameGetParentRow(frf, element, frfValueGetterService);

      case FrfValueReferenceFunctionEnum.parentPage:
        return this.funcNameGetParentPage(frf, element, frfValueGetterService);
    }
  }

  /**
   *
   * */
  private getOperatorName(
    value: string,
    prefix: FrfValueReferenceOperatorPrefixEnum
  ): string {
    const regexString = `(?=${prefix})[^\\(\\)\\.]+`,
      regex = new RegExp(regexString, 'g'),
      result = value.match(regex);
    return (result && result[0] && result[0].slice(prefix.length)) || null;
  }

  /**
   * TODO not use yet
   * */
  private getInIdByLocalId(frfElement: any, localId: string): string | null {
    if (
      frfElement &&
      frfElement['body']['gen'] &&
      frfElement['body']['gen']['lid'] &&
      frfElement['body']['gen']['lid'][localId]
    ) {
      return frfElement['body']['gen']['lid'][localId];
    } else {
      return null;
    }
  }

  /**
   * TODO not use yet
   * */
  private getKeysForParenIdAndModelId(iNtype) {
    //@private
    const type = iNtype;
    let keyObjId, keyModelId;

    switch (type) {
      case 'page':
        keyObjId = 'p-p-id';
        keyModelId = 'p-p-mid';
        break;
      case 'row':
        keyObjId = 'p-r-id';
        keyModelId = 'p-r-mid';
        break;
      case 'group':
        keyObjId = 'p-g-id';
        keyModelId = 'p-g-mid';
        break;
      case 'collection':
        keyObjId = 'p-c-id';
        keyModelId = 'p-c-mid';
        break;
    }

    if (keyObjId) {
      return {
        id: keyObjId,
        mid: keyModelId
      };
    }

    return null;
  }

  /**
   * get element by path
   * */
  public getElementByPath(
    frf: any,
    path: string,
    currentElement: any,
    frfValueGetterService: FrfValueGetterServiceInterface,
    /* TODO add start element*/
    startElement: any = null
  ) {
    const seperator = '.',
      splitPathArray = path.split(seperator),
      /* get first element */
      currentBlock = splitPathArray.shift();

    if (!currentBlock) return null;

    const lastResult = this.invokeFunction(
      currentBlock,
      currentElement,
      frf,
      frfValueGetterService
    );

    if (!splitPathArray.length) {
      return lastResult;
    } else {
      return lastResult
        ? this.getElementByPath(
            frf,
            splitPathArray.join(seperator),
            lastResult,
            frfValueGetterService,
            startElement
          )
        : null;
    }
  }

  private invokeFunction(
    currentValue: string,
    element: any,
    frf: any,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    const functionName = this.getOperatorName(
        currentValue,
        FrfValueReferenceOperatorPrefixEnum.none
      ),
      valueForFunction = this.getDataFromBracketOfString(currentValue);

    return this.getByFunction(
      functionName,
      valueForFunction,
      element,
      frf,
      frfValueGetterService
    );
  }

  private funcNameGetThisElement(frfElement: any) {
    return frfElement;
  }

  /**
   * reference function
   * */
  private funcNameByInId(
    inId: string,
    frf: any,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    return frfValueGetterService.getFreefomObjectByInId(inId, frf);
  }

  /**
   * func - get parent by passed type
   * */
  private funcNameGetParent(
    value: any,
    frf: any,
    element: FrfFieldObjectInterface,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    const parentType = <FrfElementTypeEnum>value;

    return this.getParentElementByType(
      parentType,
      frf,
      element,
      frfValueGetterService
    );
  }

  /**
   * get parent group of this element
   * */
  private funcNameGetParentGroup(
    frf: any,
    element: FrfFieldObjectInterface,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    return this.getParentElementByType(
      FrfElementTypeEnum.group,
      frf,
      element,
      frfValueGetterService
    );
  }

  /**
   * get parent page of this element
   * */
  private funcNameGetParentPage(
    frf: any,
    element: FrfFieldObjectInterface,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    return this.getParentElementByType(
      FrfElementTypeEnum.page,
      frf,
      element,
      frfValueGetterService
    );
  }

  /**
   * get parent row of this element
   * */
  private funcNameGetParentRow(
    frf: any,
    element: FrfFieldObjectInterface,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    return this.getParentElementByType(
      FrfElementTypeEnum.row,
      frf,
      element,
      frfValueGetterService
    );
  }

  /**
   *
   * */
  private getParentElementByType(
    parentType: FrfElementTypeEnum,
    frf: any,
    element: FrfFieldObjectInterface,
    frfValueGetterService: FrfValueGetterServiceInterface
  ): FrfFieldObjectInterface | null {
    if (!parentType) return null;

    switch (parentType) {
      case FrfElementTypeEnum.row:
        return frfValueGetterService.getFreefomObjectByInId(
          element.options['p-r-id'],
          frf,
          [FrfElementFolderTypeEnum.row]
        );
      case FrfElementTypeEnum.group:
        return frfValueGetterService.getFreefomObjectByInId(
          element.options['p-g-id'],
          frf,
          [FrfElementFolderTypeEnum.group]
        );

      case FrfElementTypeEnum.page:
        return frfValueGetterService.getFreefomObjectByInId(
          element.options['p-p-id'],
          frf,
          [FrfElementFolderTypeEnum.page]
        );
    }
  }

  /**
   * reference function
   * */
  private funcNameClosestParentByLid(
    lid: string,
    startField: any,
    frf: any,
    frfElementGetterService: FrfElementGetterServiceInterface
  ) {
    return frfElementGetterService.getByLocalId(frf, lid, startField);
  }

  /**
   * func - by lid
   * */
  private funcNameByLid(
    lid: string,
    startField: any,
    frf: any,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    const inId = this.getByLidOfElement(
      lid,
      startField,
      frfValueGetterService,
      frf
    );

    return inId
      ? frfValueGetterService.getFreefomObjectByInId(inId, frf)
      : null;
  }

  /**
   *
   * */
  private getByLidOfElement(
    lid: string,
    el: FrfFieldObjectInterface,
    frfValueGetterService: FrfValueGetterServiceInterface,
    frf: any
  ) {
    if (el.body.gen.lid && el.body.gen.lid[lid]) {
      return el.body.gen.lid[lid];
    }

    return null;
  }

  /**
   * reference function
   * */
  private funcNameChildByLid(
    lid: string,
    startField: FrfFieldObjectInterface,
    frf: any,
    frfValueGetterService: FrfValueGetterServiceInterface
  ) {
    /*
     * определить тип
     * */
    /**/
    const folder = this.getChildElementFolderByFrfObject(startField),
      modelId = startField.modelid,
      objectId = startField.id;

    if (!folder || !objectId || !modelId) return null;

    if (frf && frf[folder] && frf[folder].objects) {
      const objects = <FrfFieldObjectInterface[]>frf[folder].objects;

      for (let obj of objects) {
        const parentId = this.getParentIdOfFrfObject(obj);

        if (
          parentId &&
          parentId.id === objectId &&
          parentId.modelid === modelId
        ) {
          /*
           * it is the chidld of this parent > check this lid
           * */
          if (obj.body.gen.lid && obj.body.gen.lid[lid]) {
            const inId = obj.body.gen.lid[lid];

            return frfValueGetterService.getFreefomObjectByInId(inId, frf);
          }
        }
      }
    }
  }

  /**
   * @not-finished
   * */
  private getParentIdOfFrfObject(
    startField: FrfFieldObjectInterface
  ): { id: string; modelid: string } {
    if (startField && startField.options && startField.options['p-type']) {
      switch (startField.options['p-type']) {
        case FrfElementTypeEnum.collection:
        case FrfElementTypeEnum.row:
          return {
            modelid: startField.options['p-r-mid'],
            id: startField.options['p-r-id']
          };
        case FrfElementTypeEnum.group:
          return {
            modelid: startField.options['p-g-mid'],
            id: startField.options['p-g-id']
          };
        case FrfElementTypeEnum.page:
          return {
            modelid: startField.options['p-p-mid'],
            id: startField.options['p-p-id']
          };
      }
    }

    return null;
  }

  /**
   *
   * */
  private getChildElementFolderByFrfObject(
    field: FrfFieldObjectInterface
  ): FrfElementFolderTypeEnum {
    const obj = field.options.object;

    let folder;

    switch (obj) {
      case FrfElementTypeEnum.collection:
        return FrfElementFolderTypeEnum.field;

      case FrfElementTypeEnum.row:
        return FrfElementFolderTypeEnum.field;

      case FrfElementTypeEnum.group:
        return FrfElementFolderTypeEnum.row;

      case FrfElementTypeEnum.page:
        return FrfElementFolderTypeEnum.group;
    }

    return null;
  }
}
