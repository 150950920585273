import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FreeformService } from '../../../../../../@res/shared/service/freeform/freeform.service';
import {
  FrfValueGetterServiceInterface,
  FrfValueGetterSystemDataInterface
} from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueGetterSystemDataValueEnum } from './@res/@abstract/@enum/common.enum';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfRootService } from '../../../../frf-root/frf-root.service';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterSystemDataService {
  constructor(
    private auth: ConnectAuthService,
    private freeformService: FreeformService,
    private frfRoot: FrfRootService,
    private route: ActivatedRoute
  ) {}

  /**
   *
   * */
  public get(
    frf: any,
    /* value-getter any value*/
    data: FrfValueGetterSystemDataInterface,
    currentElement: any,
    frfValueGetterService: FrfValueGetterServiceInterface,
    scope: any
  ): string {
    const value = <FrfValueGetterSystemDataValueEnum>(
      frfValueGetterService.getValue(
        data.value,
        currentElement,
        frf,
        currentElement,
        scope
      )
    );

    switch (value) {
      case FrfValueGetterSystemDataValueEnum.token:
        return this.auth.token;

      case FrfValueGetterSystemDataValueEnum.uid:
        return this.auth.uid;

      case FrfValueGetterSystemDataValueEnum.formId:
        return this.freeformService.formInfo.formId;

      case FrfValueGetterSystemDataValueEnum.queryParam: {
        let key = data.params && data.params.key;
        if (key) {
          key = frfValueGetterService.getValue(
            key,
            currentElement,
            frf,
            currentElement,
            scope
          );

          return this.getUrlParam(key) || null;
        }

        return null;
      }

      case FrfValueGetterSystemDataValueEnum.formModelId:
        return this.freeformService.formInfo.modelId;

      case FrfValueGetterSystemDataValueEnum.cntSharepayBankOwner:
        return this.frfRoot.cntVar$.sharepayBankOwner;

      default:
        return null;
    }
  }

  /**
   *
   * */
  private getUrlParam(name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}
