import { b as bootstrapLazy } from './index-0f5fe303.js';
import { a as patchEsm } from './patch-589c8af2.js';

const defineCustomElements = (win, options) => {
  if (typeof window === 'undefined') return Promise.resolve();
  return patchEsm().then(() => {
  return bootstrapLazy([["cnt-charts-column-stack",[[1,"cnt-charts-column-stack",{"f2":[8,"f-2"],"data":[16],"f2TouchEmulator":[8,"f-2-touch-emulator"],"tickCount":[2,"tick-count"],"canvasWidth":[2,"canvas-width"],"canvasHeight":[2,"canvas-height"],"colors":[16],"update":[64]}]]],["cnt-charts-donut-basic",[[2,"cnt-charts-donut-basic",{"f2":[8,"f-2"],"data":[16],"f2TouchEmulator":[8,"f-2-touch-emulator"],"callbackWhenClick":[16],"callbackForHtml":[16],"callbackForShowLabel1":[16],"callbackForShowLabel2":[16],"toFixed":[16],"canvasWidth":[2,"canvas-width"],"canvasHeight":[2,"canvas-height"],"update":[64]}]]],["cnt-charts-donut-simple",[[2,"cnt-charts-donut-simple",{"f2":[8,"f-2"],"data":[16],"f2TouchEmulator":[8,"f-2-touch-emulator"],"formatter":[16],"itemFormatter":[16],"centerHtml":[1,"center-html"],"total":[2],"canvasWidth":[2,"canvas-width"],"canvasHeight":[2,"canvas-height"],"nameStyle":[16],"selectMode":[1,"select-mode"],"update":[64]}]]]], options);
  });
};

export { defineCustomElements };
