import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  FrfValueSetterBlockerInterface,
  FrfValueSetterElementBlockerInterface
} from './@res/@abstract/@interface/common.interface';
import { FrfValueElementSetterBlockerTypeEnum } from './@res/@abstract/@enum/common.enum';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfValueSetterInterface } from '../../../@res/@abstract/@interface/common.interface';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueSetterBlockerService implements FrfValueSetterInterface {
  /**
   *
   * */
  public state$: BehaviorSubject<
    FrfValueSetterBlockerInterface
  > = new BehaviorSubject(null);

  /**
   * state
   * */
  public stateElement$: BehaviorSubject<
    FrfValueSetterElementBlockerInterface
  > = new BehaviorSubject(null);

  /**
   *
   * */
  public elementsState: {
    [modelId: string]: { [id: string]: FrfValueSetterElementBlockerInterface };
  } = {};

  constructor() {}

  /**
   *
   * */
  public destroy() {
    this.state$.next(null);
    this.stateElement$.next(null);
    this.elementsState = {};
  }

  /**
   * блокирует форму
   * */
  public set(input: FrfValueSetterBlockerInterface): void {
    this.state$.next(input);
  }

  /**
   *
   * */
  public getBlockElement(
    modelid: string,
    id: string,
    type: FrfValueElementSetterBlockerTypeEnum
  ): boolean {
    // console.log(
    //   'getBlockElement',
    //   _.cloneDeep(this.elementsState)
    // );
    return !!(
      this.elementsState[modelid] &&
      this.elementsState[modelid][id] &&
      this.elementsState[modelid][id].type === type &&
      this.elementsState[modelid][id].state
    );
  }

  /**
   *
   * */
  public getNoSubmitStateOfBlockElement(
    modelid: string,
    id: string,
    extraState = false
  ): boolean {
    return (
      this.getBlockElement(
        modelid,
        id,
        FrfValueElementSetterBlockerTypeEnum.noSubmit
      ) || extraState
    );
  }

  /**
   *
   * */
  public getFullStateOfBlockElement(
    modelid: string,
    id: string,
    extraState = false
  ): boolean {
    return (
      this.getBlockElement(
        modelid,
        id,
        FrfValueElementSetterBlockerTypeEnum.full
      ) || extraState
    );
  }

  /**
   *
   * */
  public blockElement(
    modelid: string,
    objid: string,
    input: FrfValueSetterElementBlockerInterface
  ): void {
    if (!this.elementsState[modelid]) {
      this.elementsState[modelid] = {};
    }

    input = {
      ...input,
      id: objid,
      modelid: modelid
    };

    this.elementsState[modelid][objid] = input;

    this.stateElement$.next(input);
  }

  /**
   * блокирует element
   * */
  public getForElement(
    modelid: string,
    objid: string
  ): Observable<FrfValueSetterElementBlockerInterface> {
    return this.stateElement$.pipe(
      filter(input => input.modelid === modelid && input.id === objid)
    );
  }
}
