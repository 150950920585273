import { Injectable } from '@angular/core';
import { FreeformFieldStateLibrary } from '../../../../@res/shared/service/freeform/_sub/freeform-field-state.library';
import { FreeformCommonService } from '../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfTriggerService } from '../../frf-trigger/frf-trigger.service';
import { OldConnectLibrary } from '../../../../@res/shared/service/freeform/_sub/oldConnectLibrary';
import { FrfMainLazyServiceModule } from '../../../../frf-main-lazy-service.module';
import { Subject } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { FrfEmitterService } from '../../frf-emitter/frf-emitter.service';
import { FrfValueCheckerService } from '../frf-value-checker/frf-value-checker.service';
import { FrfCustomFieldNamespace } from '../../../@view/freeform/@sub/@view/freeform-row/@sub/@view/freeform-field/@sub/@view/freeform-field-simple/@sub/@view/frf-custom-field-wrapper/@sub/@service/frf-custom-field-wrapper/@res/@namespace/common.namespace';
import { FrfValueSetterTempDataStorageTypeEnum } from './@sub/frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FrfValueBasePathToElementDataInElement } from '../@res/@abstract/@const/common.const';
import { FrfTriggerNameEnum } from '../../frf-trigger/@res/@abstract/@enum/common.enum';
import { FrfTriggerNameTempType } from '../../frf-trigger/@res/@abstract/@type/common.type';
import { FrfValueSetterServiceInterface } from '../@res/@abstract/@interface/common.interface';
import { FrfValueCheckerEnum } from '../frf-value-checker/@res/@abstract/@enum/common.enum';
import { FrfSharedStorageService } from '../../frf-shared-storage/frf-shared-storage.service';
import { FrfValueSetterLoadingStateService } from './@sub/frf-value-setter-loading-state/frf-value-setter-loading-state.service';
import { FrfValueGetterService } from '../frf-value-getter/frf-value-getter.service';
import { FrfValueSetterTempDataService } from './@sub/frf-value-setter-temp-data/frf-value-setter-temp-data.service';
import { FrfElementTypeEnum } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@enum/common.enum';
import { ValueForSetFieldSourceActionType } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@interface/@field/@trigger/frf-actions.interface';
import { FrfStorageService } from '../../@storage/frf-storage/frf-storage.service';

/**
 * for work with values [set] difficult data
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueSetterService implements FrfValueSetterServiceInterface {
  /* connect library */
  private connect = new OldConnectLibrary();

  /**
   * для контроля и применения изменения
   * */
  public readonly fieldChanges$: Subject<{
    modelId: string;
    objId: string;
  }> = new Subject();
  public readonly pageChanges$: Subject<{
    modelId: string;
    objId: string;
  }> = new Subject();

  constructor(
    public common: FreeformCommonService,
    public trigger: FrfTriggerService,
    public frfValueGetterService: FrfValueGetterService,
    private frfEmitter: FrfEmitterService,
    private frfStorageService: FrfStorageService,
    private frfSharedStorageService: FrfSharedStorageService,
    private frfValueSetterTempDataService: FrfValueSetterTempDataService,
    public frfValueSetterLoadingState: FrfValueSetterLoadingStateService,
    private frfValueChecker: FrfValueCheckerService
  ) {
    /*
     * add to global
     * */
    this.frfSharedStorageService.addFrfValueSetter(this);

    window['addValueToObjectByPath'] = (
      element,
      fullPath,
      value,
      withMerge = false
    ) => {
      this.connect.addValueToObjectByPath(
        // element['body'],
        element,
        fullPath,
        value,
        withMerge
      );
    };
  }

  /**
   * set body.payload.options for freeform element
   * */
  public setFieldPayloadOptions(
    iNelement: any,
    iNvalue: any,
    iNtrigersStartList: string[] = ['onChange', 'onSetValue'],
    iNdependetStart: boolean = false
  ) {
    const element = iNelement,
      value = iNvalue,
      freeform = this.common.freeform;

    let status;

    if (typeof value !== 'object' || !Array.isArray(value)) return;

    status = value.length > 0;

    this.setElementDataByPath(
      'field',
      iNelement,
      'payload.options',
      value,
      iNtrigersStartList,
      iNdependetStart,
      true
    );

    //change state
    new FreeformFieldStateLibrary().setFieldStatusLater(
      element['id'],
      element['modelid'],
      status,
      freeform,
      (iNtype, iNmodelId, iNelId, iNstatus) => {
        // update status
        this.common.sendStatusOfFreefomObjectToDb(
          iNtype,
          iNmodelId,
          iNelId,
          iNstatus
        );
      }
    );
  }

  /**
   * set field value
   * */
  public setFieldValue<valueType = string | number | any>(
    formElement: any,
    value: valueType,
    triggersList: string[] = ['onChange', 'onSetValue'],
    isDependentElementsStart: boolean = false,
    typeOfPassedValue?: string,
    checker?: FrfValueCheckerEnum,
    defaultValue?: any,
    updateWatchChanges: boolean = true,
    updateStatus: boolean = true,
    mergeValues = true
  ) {
    const freeform = this.common.freeform;

    /* TODO LATER (remove circular used trigger) get library trigger */

    if (!typeOfPassedValue) {
      /* set value for field -> safe convert to string */
      if (typeof value === 'string' || typeof value === 'number') {
        // @ts-ignore
        value = value + '';
      } else if (typeof checker === 'undefined') return;
    } else {
      if (typeof value !== typeOfPassedValue) {
        return;
      }
    }

    if (!this.frfValueChecker.check(value, checker)) {
      if (typeof defaultValue === 'undefined') {
        return;
      }
      {
        value = <any>defaultValue;
      }
    }

    const status = !!value,
      elementType = formElement.body.type || '',
      isCustomElement = FrfCustomFieldNamespace.isCustomComponentType(
        elementType
      );

    /* add value to db */
    this.setElementDataByPath(
      'field',
      formElement,
      'value',
      value,
      triggersList,
      isDependentElementsStart,
      true,
      updateWatchChanges
    );

    /* change state */
    if (updateStatus && !isCustomElement) {
      this.updateStatus(formElement, status, freeform);
    }
  }

  /**
   *
   * */
  public updateStatus(formElement: any, status: boolean, frf: any) {
    const body = formElement && formElement.body,
      oldStatus = this.frfStorageService.getElementStatus(formElement); // body['status']['value'];

    if (oldStatus !== status) {
      /* for save while real changed */
      this.frfStorageService.setElementStatus(formElement, status);
      new FreeformFieldStateLibrary().setFieldStatusLater(
        formElement['id'],
        formElement['modelid'],
        status,
        frf,
        (iNtype, iNmodelId, iNelId, iNstatus) => {
          /* update status */
          this.common.sendStatusOfFreefomObjectToDb(
            iNtype,
            iNmodelId,
            iNelId,
            iNstatus
          );

          /*
           * we have triggers for start
           * */
          this.trigger.run(
            formElement,
            [FrfTriggerNameEnum.onChangeStatus],
            {}
          );
        }
      );

      // /*
      //  * we have triggers for start
      //  * */
      // this.trigger.run(formElement, [FrfTriggerNameEnum.onChangeStatus]);
    }
  }

  /**
   * set element data by path
   * TODO add types
   * */
  public setElementDataByPath(
    /* TODO need delete */
    /* does work now - elType */
    elType: FrfElementTypeEnum | string,
    element: any,
    path: string,
    value: any,
    triggersList = ['onChange'],
    dependetStart: boolean = false,
    synsWithDB: boolean = false,
    updateWatchChanges: boolean = true,
    checker?: FrfValueCheckerEnum,
    defaultValue?: string,
    startWithBody = true
  ) {
    const id = element['id'],
      modelid = element['modelid'],
      freeform = this.common.freeform,
      depStart = dependetStart,
      basePath = FrfValueBasePathToElementDataInElement;
    // ${basePath}. TODO later add create action to add value ed$

    /* path to value*/
    let fullPath;

    if (startWithBody) {
      fullPath = `body.${path}`;
    } else {
      fullPath = `${path}`;
    }

    if (element && element.options && element.options.object) {
      elType = element.options.object;
    }

    if (!this.frfValueChecker.check(value, checker)) {
      if (typeof defaultValue === 'undefined') {
        return;
      }
      {
        value = <any>defaultValue;
      }
    }

    /* set value for element */
    this.connect.addValueToObjectByPath(
      // element['body'],
      element,
      fullPath,
      value,
      false
    );

    if (elType === FrfElementTypeEnum.field) {
      /*
       * emit what field change for re render value
       * */
      if (updateWatchChanges) {
        this.applyFieldChanges(modelid, id);
      }

      /*
       * add for later emit
       * */
      this.frfEmitter.safeAddValueByPathToField(element, fullPath, value);
    } else if (elType === FrfElementTypeEnum.page) {
      /*
       * emit what page change for re render value
       * */
      if (updateWatchChanges) {
        this.applyPageChanges(modelid, id);
      }

      /*
       * add for later emit
       * */
      this.frfEmitter.safeAddValueByPathToField(element, fullPath, value);
    }

    if (synsWithDB) {
      // if we need sync with db -> save data by path in DB
      this.common.sendElementValueByPathToDb(elType, modelid, id, path, value);
    }

    //**LATER (remove circular used trigger) run onChange trigger if exist
    if (
      typeof triggersList === 'object' &&
      Array.isArray(triggersList) &&
      triggersList.length > 0
    ) {
      // we have triggers for start
      this.trigger.run(element, triggersList, {});
    }

    if (depStart) {
      /* start analyse dependents if need */
      this.common.for_dependentStartByObject(element);
    }
  }

  /**
   * следить за изменения поля
   * */
  public watchFieldChanges(modelId: string, objid: string) {
    return this.fieldChanges$.pipe(
      filter(result => {
        return result && result.modelId === modelId && result.objId === objid;
      }),
      share()
    );
  }

  /**
   * следить за изменения поля
   * */
  public watchPageChanges(modelId: string, objid: string) {
    return this.pageChanges$.pipe(
      filter(result => {
        return result && result.modelId === modelId && result.objId === objid;
      }),
      share()
    );
  }

  /**
   * применить изменения к field элементу
   * */
  private applyFieldChanges(modelId: string, objid: string) {
    return this.fieldChanges$.next({
      modelId: modelId,
      objId: objid
    });
  }

  /**
   * применить изменения к page элементу
   * */
  private applyPageChanges(modelId: string, objid: string) {
    return this.pageChanges$.next({
      modelId: modelId,
      objId: objid
    });
  }

  /**
   * @action - setFileSource (only for field-image, field-video)
   * */
  public setFileSource(
    action: any,
    startElement: any,
    frf: any,
    scope: any
  ): boolean {
    const id = this.frfValueGetterService.getValue(
        action['id'],
        null,
        frf,
        startElement,
        scope
      ), // field object
      value = action['value'], // field object
      field = this.frfValueGetterService.getFreefomObjectByInId(
        id,
        frf
        // ['fields','pages','groups','rows']
      );

    if (field) {
      /*
       * if field is isset -> get field value
       * */
      const valueResult = <ValueForSetFieldSourceActionType>(
        this.frfValueGetterService.getValue(
          value,
          field,
          frf,
          startElement,
          scope
        )
      );

      if (
        typeof valueResult === 'string' ||
        (typeof valueResult === 'object' &&
          typeof valueResult.path === 'string')
      ) {
        /*
         * set new value of freeform
         * */
        // this.valueService.setFieldValue( field, valueResult,['onChange','onSetValue'], true );
        this.setElementDataByPath(
          'field',
          field,
          'payload.src',
          valueResult,
          ['onChange', 'onSetSrc'],
          true,
          true
        );

        // run trigger
        return true;
      }
    }
    return false;
  }

  /**
   *
   * */
  public setTempDataInElement(
    element: any,
    path: string,
    value: any,
    storageType: FrfValueSetterTempDataStorageTypeEnum = FrfValueSetterTempDataStorageTypeEnum.user,
    triggersList: FrfTriggerNameTempType[] = [FrfTriggerNameEnum.onSetTempData],
    dependetStart: boolean = false
  ): void {
    this.frfValueSetterTempDataService.set(
      element,
      path,
      value,
      storageType,
      triggersList,
      dependetStart
    );
  }
}
