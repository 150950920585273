export enum FastMenuBtnTypeEnum {
  /*
   * Ссылка на внешную страницу
   * */
  url = 'url',
  urlMessengerTelegram = 'url:messenger:telegram',
  urlMessengerMyTelegram = 'url:messenger:my:telegram',
  urlMessengerWhatsapp = 'url:messenger:whatsapp',
  urlMessengerMyWhatsapp = 'url:messenger:my:whatsapp',
  urlMessengerVkontakte = 'url:messenger:vkontakte',
  urlMessengerMyVkontakte = 'url:messenger:my:vkontakte',
  urlSocialInstagram = 'url:social:instagram',
  urlSocialMyInstagram = 'url:social:my:instagram',
  urlMessengerViber = 'url:messenger:viber',
  urlMessengerMyViber = 'url:messenger:my:viber',
  /*
   * Абсолютная ссылка на внутренную страницу
   * */
  innerUrl = 'inner-url',

  /**
   *
   * */
  freeform = 'frf',

  /*
   * TODO добавить позднее логику
   * Ссылка к чата
   * */
  toChat = 'to-chat',

  /**
   * btn to open sharepay bank
   * */
  sharepayBank = 'sharepay:bank'
}
