<div class="value-block">
  <div class="visibility" (click)="expandIfCan()">
    <!-- специальный шаблон для колонки приоритетнее -->
    <ng-container
      *ngIf="rowTemplates[displayedColumn.key]; else commonTemplate"
      [ngTemplateOutletContext]="{
        element: element.data$,
        key: displayedColumn.key,
        displayedColumn: displayedColumn,
        rowIdx: rowIdx,
        value: displayedColumn.valueFormatter(
          element.data$[displayedColumn.key],
          displayedColumn.key
        )
      }"
      [ngTemplateOutlet]="rowTemplates[displayedColumn.key]"
    >
    </ng-container>

    <!-- общий шаблон для колонок тела таблицы -->
    <ng-template #commonTemplate>
      <ng-container
        *ngIf="rowTemplates[symbolAll]; else simpleValue"
        [ngTemplateOutletContext]="{
          element: element.data$,
          key: displayedColumn.key,
          displayedColumn: displayedColumn,
          rowIdx: rowIdx,
          value: displayedColumn.valueFormatter(
            element.data$[displayedColumn.key],
            displayedColumn.key
          )
        }"
        [ngTemplateOutlet]="rowTemplates[symbolAll]"
      >
      </ng-container>
    </ng-template>

    <!-- вывод значений -->
    <ng-template #simpleValue>
      <span class="value">{{
        displayedColumn?.valueFormatter(
          element.data$[displayedColumn.key],
          displayedColumn.key
        ) || '&nbsp;&nbsp;'
      }}</span>
    </ng-template>
  </div>
</div>
