import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CntTableFlexService } from '../cnt-table-flex/@sub/@service/cnt-table-flex/cnt-table-flex.service';
import { BodyFlexTableInterface } from '../cnt-table-flex/@res/@abstract/@interface/common.interface';
import { AbstractSimpleGraphqlPaginatorService } from '../../@service/@abstract/abstract-simple-graphql-paginator/abstract-simple-graphql-paginator.service';
import { Subject } from 'rxjs';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'paginator-simple-graphql',
  templateUrl: './paginator-simple-graphql.component.html',
  styleUrls: ['./paginator-simple-graphql.component.scss'],
})
export class PaginatorSimpleGraphqlComponent
  extends AbstractUnsubscribeViewControl
  implements OnInit, OnDestroy {
  /**
   *
   * */
  @Input()
  private simpleGraphqlPaginatorService!: AbstractSimpleGraphqlPaginatorService;

  /**
   *
   * */
  @Input() public pageKey!: string;

  /**
   * need destory firestore flow
   * */
  @Input() public needDestroy?: boolean = true;

  /**
   * need destory firestore flow
   * */
  @Input() public flexTableFormatEmit?: boolean = true;

  /**
   * need destory firestore flow
   * */
  @Input() public loadDataOnInit?: boolean = true;

  /**
   * update rows pass data
   * */
  @Input() public updateRows$: Subject<any[]> = new Subject<any[]>();

  // @Inpu

  /**
   *
   * */
  @Output() changed: EventEmitter<
    BodyFlexTableInterface[] | any[]
  > = new EventEmitter();
  @Output() clearData: EventEmitter<any[]> = new EventEmitter();

  /**
   * paginator
   * */
  public paginatorShowOptions: {
    isNextPage?: boolean;
    isPreviousPage?: boolean;
  } = {};

  /**
   *
   * */
  private lastClearData: any[];

  /**
   *
   * */
  public block = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private cntTableFlex: CntTableFlexService
  ) {
    super();
  }

  ngOnInit() {
    this.simpleGraphqlPaginatorService.initKey(this.pageKey);

    this.initData();
    this.activeUpdaterRows();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   *
   * */
  private activeUpdaterRows() {
    this.updateRows$.pipe(takeUntil(this.viewDestroy$)).subscribe((data) => {
      console.log('activeUpdaterRows data', data);
      this.updateRows(data);
    });
  }

  /**
   * when open page
   * */
  public openPage($event: {
    currentPage: number;
    currentSize: number;
    offset: number;
  }) {
    this.block = true;
    this.simpleGraphqlPaginatorService.updatePage(
      $event.currentPage,
      $event.currentSize
    );
  }

  /**/
  public initData() {
    this.simpleGraphqlPaginatorService
      .getStream$()
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe((result) => {
        this.block = false;
        if (result) {
          this.paginatorShowOptions = {
            isNextPage: result.isNextPage,
            isPreviousPage: result.isPreviousPage,
          };

          this.updateRows(result && result.data);
        }
      });

    this.simpleGraphqlPaginatorService.updateRequest();
  }

  /**
   *
   * */
  private updateRows(data: any[]) {
    let rows = [];

    this.lastClearData = data;

    if (data && data.length) {
      if (this.flexTableFormatEmit) {
        rows = [...this.cntTableFlex.getRows(data)];
      } else {
        rows = data;
      }
    }

    this.changed.emit(rows);
    this.clearData.emit(this.lastClearData);

    this.cdRef.markForCheck();
  }
}
