export enum FrfValueReferencePathTypeEnum {
  reference = 'reference',
  var = 'var',
  function = 'function',
  id = 'id'
}

export enum FrfValueReferenceFunctionEnum {
  thisElement = 'self',
  byInId = 'byInId',
  parentGroup = 'parentGroup',
  parentRow = 'parentRow',
  parentPage = 'parentPage',
  parentByType = 'parentByType',
  byChildLocalId = 'byChildLid'
}

export enum FrfValueReferenceOperatorPrefixEnum {
  function = '@',
  none = ''
}
