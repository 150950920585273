import { Injectable } from '@angular/core';
import {
  FrfValueGetterClassInterface,
  FrfValueGetterServiceInterface
} from '../../@res/@abstract/@interface/common.inteface';
import { FrfValueCheckerService } from '../../../frf-value-checker/frf-value-checker.service';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfValueGetterScopeInterface } from './@res/@abstract/@interface/common.interface';
import * as mzCommon from 'mz-common';
import * as _ from 'lodash';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterScopeService
  implements FrfValueGetterClassInterface {
  constructor(private frfValueChecker: FrfValueCheckerService) {}

  /**
   *
   * */
  public get(
    data: FrfValueGetterScopeInterface,
    valueGetter: FrfValueGetterServiceInterface,
    startFrfElement: any,
    frf: any,
    scope: any
  ): string {
    return _.cloneDeep(mzCommon.getValueFromObjectByPath(data.path, scope));
  }
}
