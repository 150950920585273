import {Injectable} from '@angular/core';
import {Observable, of, timer} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {ConnectAuthService} from '@cnt-nx-workspace/feature/auth';
import {FastMenuButtonInterface} from '@cnt-multi-shared/@shared/first-buttons/@res/@abstract/@interface/common.interface';
import {
  CntMessengersIconSrcEnum,
  CntMessengersService,
  CntMessengerTypeEnum
} from '@cnt-nx-workspace/feature/cnt-messengers';
import {CntFastButtonFreeformButtonInterface} from '@cnt-multi-shared/@shared/first-buttons/@res/@abstract/@group/freeform/@interface/freeform.interface';
import {FrfRootService} from '@cnt-nx-workspace/frf/main';
import {ActivatedRoute, Router} from '@angular/router';
import {CntFastButtonSharepayBankValueInterface} from '@cnt-multi-shared/@shared/first-buttons/@res/@abstract/@group/sharepay/@interface/sharepay-bank.interface';
import {CntFirstButtonsLoaderControllerInterface} from './@res/@interface/common.interface';
import {Apollo, gql} from 'apollo-angular-boost';
import {CntMainFastButtonInterface} from "@cnt-multi-shared/@shared/@main/@res/@abstract/@interface/cnt-fast-button.interface";
import {
  CntMainFastButtonAccessEnum,
  CntMainFastButtonTypeEnum,
  CntMainFastButtonZoneEnum
} from "@cnt-multi-shared/@shared/@main/@res/@abstract/@enum/cnt-fast-button.enum";
import {NavigateService} from "../../../../../apps/main/cnt-main/src/app/@res/@module/main/@sub/@service/navigate/navigate.service";

@Injectable({
  providedIn: 'root'
})
export class CntFirstButtonsService {
  public buttons$: Observable<FastMenuButtonInterface[]>;
  public privateButtons: FastMenuButtonInterface[] = [];
  public publicButtons: FastMenuButtonInterface[] = [];

  constructor(
    private firestore: AngularFirestore,
    private frfRoot: FrfRootService,
    private router: Router,
    private cntMessengersService: CntMessengersService,
    private apollo: Apollo,
    private navigateService: NavigateService,
    private connectAuthService: ConnectAuthService
  ) {}

  /**
   * @deprecated
   * */
  // public initFirstButtonsOld(
  //   owner: string
  // ): Observable<FastMenuButtonInterface[]> {
  //   // return this.buttons$
  //   //   ? this.buttons$
  //   //   : (this.buttons$ = this.connectAuthService.getUserId().pipe(
  //   //       switchMap(uid => {
  //   //         return merge(
  //   //           this.getPublicFirstButtons(owner),
  //   //           this.getPrivateFirstButtons(owner, uid)
  //   //         ).pipe(
  //   //           share(),
  //   //           map(() => this.privateButtons.concat(this.publicButtons)),
  //   //           /* add icon for some fast buttons */
  //   //           map(btns => this.safeSetIconForLogicButtons(btns))
  //   //         );
  //   //       })
  //   //     ));
  // }

  /**
   * @deprecated
   * */
  // public initFirstButtonsOld2(
  //   owner: string
  // ): Observable<FastMenuButtonInterface[]> {
  //   // return this.buttons$
  //   //   ? this.buttons$
  //   //   : (this.buttons$ = this.connectAuthService.getUserId().pipe(
  //   //       switchMap(uid => {
  //   //         return combineLatest([
  //   //           this.getPublicFirstButtons(owner),
  //   //           this.getPrivateFirstButtons(owner, uid)
  //   //         ]).pipe(
  //   //           // shareReplay(1),
  //   //           // map(() => this.privateButtons.concat(this.publicButtons)),
  //   //           map(v => v[0].concat(v[1])),
  //   //           /* add icon for some fast buttons */
  //   //           map(btns => this.safeSetIconForLogicButtons(btns)),
  //   //           tap(result => {
  //   //             console.log('initFirstButtons , owner', { owner, result });
  //   //           })
  //   //         );
  //   //       })
  //   //     ));
  // }

  /**
   *
   * */
  public initFirstButtons(login: string): Observable<FastMenuButtonInterface[]> {
    return this.requestFirstButtons(
      login
    ).pipe(
      map(
        (fastButtons) => {
          return fastButtons && fastButtons.map(
            (fastButton) => {
              const data: FastMenuButtonInterface = {
                name: fastButton.name,
                title: fastButton.title,
                icon: this.safeGetDefaultButtons(fastButton),
                showInFooter: fastButton.zone?.indexOf(CntMainFastButtonZoneEnum.footer) !== -1,
                showInMain: fastButton.zone?.indexOf(CntMainFastButtonZoneEnum.main) !== -1,
                type: fastButton.type,
                id: fastButton.id,
                value: this.extractFastButtonValue(fastButton.val),
                payload: null,
                uid: null,
                public: fastButton.access === CntMainFastButtonAccessEnum.all,
                active: !!fastButton.active,
                weight: fastButton.weight,
              };

              return data;
            }
          )
        }
      )
    )
  }

  private extractFastButtonValue (
    value: string
  )
  {
    let result = null;
    try {
      const data =  value && JSON.parse(
        value
      );
      if (typeof data.$ !== "undefined") {
        result = data.$;
      } else {
        result = data;
      }
    } catch (e) {

    }

    return result;
  }

  /**
   *
   * */
  public requestFirstButtons(
    login: string
  ): Observable<CntMainFastButtonInterface[]> {
    const query = gql`
      query flexyViewFastButtonGet(
        $uid: String
        $login: String!
        $token: String
      ) {
        flexyViewFastButtonGet(uid: $uid, login: $login, token: $token) {
          status
          data {
            id
            name
            un
            icon
            val
            access
            zone
            title
            type
            weight
            active
          }
          error {
            code
            text
          }
        }
      }
    `;

    return this.apollo
      .watchQuery({
        query: query,
        variables: {
          uid: this.connectAuthService.uid,
          token: this.connectAuthService.token,
          login: login
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        catchError(() => of(null)),
        map((result: any) => {
          return (
            result &&
            result.data &&
            result.data.flexyViewFastButtonGet &&
            result.data.flexyViewFastButtonGet.data
          );
        })
      );
  }

  /**
   *
   * */
  // private getPublicFirstButtons(
  //   owner: string
  // ): Observable<FastMenuButtonInterface[]> {
  //   return this.firestore
  //     .collection(CntFirstButtonsGetPathToCollection(owner), ref => {
  //       return ref
  //         .where('public', '==', true)
  //         .where('active', '==', true)
  //         .orderBy('weight', 'desc');
  //     })
  //     .valueChanges()
  //     .pipe(
  //       catchError(error => {
  //         console.warn('getPublicFirstButtons', error);
  //
  //         return of(null);
  //       }),
  //       tap(result => {
  //         console.log('getPublicFirstButtons , owner', { owner, result });
  //         this.publicButtons = result ? result : [];
  //       })
  //     );
  // }

  /**
   *
   * */
  // private getPrivateFirstButtons(
  //   owner: string,
  //   uid = this.connectAuthService.uid
  // ): Observable<FastMenuButtonInterface[]> {
  //   console.log('getPrivateFirstButtons 0 , uid', { owner, uid });
  //   return !uid
  //     ? of([])
  //     : this.firestore
  //         .collection(CntFirstButtonsGetPathToCollection(owner), ref => {
  //           return ref
  //             .where('uid', '==', uid)
  //             .where('active', '==', true)
  //             .orderBy('weight', 'desc');
  //         })
  //         .valueChanges()
  //         .pipe(
  //           catchError(error => {
  //             console.warn('getPrivateFirstButtons', error);
  //             return of(null);
  //           }),
  //           tap(result => {
  //             console.log('getPrivateFirstButtons 1 , owner', {
  //               owner,
  //               result
  //             });
  //             this.privateButtons = result ? result : [];
  //           })
  //         );
  // }

  /**
   *
   * */
  // public safeSetIconForLogicButtons(buttons: FastMenuButtonInterface[]) {
  //   return buttons.map(btn => {
  //     if (!btn.icon) {
  //       switch (btn.type) {
  //         case CntMainFastButtonTypeEnum.urlMessengerTelegram:
  //         case CntMainFastButtonTypeEnum.urlMessengerMyTelegram:
  //           btn.icon = CntMessengersIconSrcEnum.telegram;
  //           break;
  //
  //         case CntMainFastButtonTypeEnum.urlMessengerViber:
  //         case CntMainFastButtonTypeEnum.urlMessengerMyViber:
  //           btn.icon = CntMessengersIconSrcEnum.viber;
  //           break;
  //
  //         case CntMainFastButtonTypeEnum.urlMessengerWhatsapp:
  //         case CntMainFastButtonTypeEnum.urlMessengerMyWhatsapp:
  //           btn.icon = CntMessengersIconSrcEnum.whatsapp;
  //           break;
  //
  //         case CntMainFastButtonTypeEnum.urlMessengerVkontakte:
  //         case CntMainFastButtonTypeEnum.urlMessengerMyVkontakte:
  //           btn.icon = CntMessengersIconSrcEnum.vk;
  //           break;
  //
  //         case CntMainFastButtonTypeEnum.urlSocialInstagram:
  //         case CntMainFastButtonTypeEnum.urlSocialMyInstagram:
  //           btn.icon = CntMessengersIconSrcEnum.instagram;
  //           break;
  //       }
  //     }
  //     return btn;
  //   });
  // }

  /**
   *
   * */
  public safeGetDefaultButtons (
    btn: CntMainFastButtonInterface
  ): string {
    switch (btn.type) {
      case CntMainFastButtonTypeEnum.urlMessengerTelegram:
      case CntMainFastButtonTypeEnum.urlMessengerMyTelegram:
        return  CntMessengersIconSrcEnum.telegram;
      // break;

      case CntMainFastButtonTypeEnum.urlMessengerViber:
      case CntMainFastButtonTypeEnum.urlMessengerMyViber:
        return  CntMessengersIconSrcEnum.viber;
      // break;

      case CntMainFastButtonTypeEnum.urlMessengerWhatsapp:
      case CntMainFastButtonTypeEnum.urlMessengerMyWhatsapp:
        return  CntMessengersIconSrcEnum.whatsapp;
      // break;

      case CntMainFastButtonTypeEnum.urlMessengerVkontakte:
      case CntMainFastButtonTypeEnum.urlMessengerMyVkontakte:
        return  CntMessengersIconSrcEnum.vk;
      // break;

      case CntMainFastButtonTypeEnum.urlSocialInstagram:
      case CntMainFastButtonTypeEnum.urlSocialMyInstagram:
        return  CntMessengersIconSrcEnum.instagram;
      // break;

      default:
        return btn.icon
    }
  }

  /**
   * TODO add queryString later
   * */
  private openInnerLink(
    link: any,
    activatedRoute: ActivatedRoute,
    queryString?: { [key: string]: string }
  ) {
    timer(100).subscribe(() => {
      this.router.navigate([link], {
        relativeTo: activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: queryString || {}
      });
    });
  }

  /**
   *
   * */
  public openFastButton(
    fastButton: FastMenuButtonInterface,
    activatedRoute: ActivatedRoute,
    loaderController: CntFirstButtonsLoaderControllerInterface
  ) {
    switch (fastButton && fastButton.type) {
      case CntMainFastButtonTypeEnum.innerUrl:
        this.openInnerLink(fastButton.value, activatedRoute);
        break;

      case CntMainFastButtonTypeEnum.freeform:
        this.openFreeform(fastButton);
      break;

      case CntMainFastButtonTypeEnum.urlMessengerTelegram:
      case CntMainFastButtonTypeEnum.urlMessengerViber:
      case CntMainFastButtonTypeEnum.urlMessengerWhatsapp:
      case CntMainFastButtonTypeEnum.urlMessengerVkontakte:
      case CntMainFastButtonTypeEnum.urlSocialInstagram:
        this.openMessengerLink(fastButton);
      break;

      case CntMainFastButtonTypeEnum.sharepayBank:
        this.openSharepayFastButton(
          fastButton,
          activatedRoute,
          loaderController
        );
      break;

      case CntMainFastButtonTypeEnum.appChat:
        this.navigateService.openAppChat();
      break;

      case CntMainFastButtonTypeEnum.appVisit:
        this.navigateService.openAppVisit();
      break;
    }
  }

  /**
   * TODO later add my types (take value from user)
   * */
  private openFreeform(fastButton: CntFastButtonFreeformButtonInterface) {
    if (!fastButton) {
      return;
    }

    const val = fastButton.value;
    if (typeof val === 'object' && val.owner && val.modelId && val.objId) {
      this.frfRoot.openFrf(val.owner, val.modelId, val.objId);
    }
  }

  /**
   * TODO later add my types (take value from user)
   * */
  private openMessengerLink(fastButton: FastMenuButtonInterface) {
    if (!fastButton) {
      return;
    }

    let type: CntMessengerTypeEnum;
    const  id: any = fastButton.value,
      fbType = fastButton.type;
    switch (fbType) {
      case CntMainFastButtonTypeEnum.urlMessengerTelegram:
      case CntMainFastButtonTypeEnum.urlMessengerMyTelegram:
        type = CntMessengerTypeEnum.telegram;
        break;

      case CntMainFastButtonTypeEnum.urlMessengerViber:
      case CntMainFastButtonTypeEnum.urlMessengerMyViber:
        type = CntMessengerTypeEnum.viber;
        break;

      case CntMainFastButtonTypeEnum.urlMessengerWhatsapp:
      case CntMainFastButtonTypeEnum.urlMessengerMyWhatsapp:
        type = CntMessengerTypeEnum.whatsapp;
        break;

      case CntMainFastButtonTypeEnum.urlMessengerVkontakte:
      case CntMainFastButtonTypeEnum.urlMessengerMyVkontakte:
        // type = CntMessengerTypeEnum.;
        break;

      case CntMainFastButtonTypeEnum.urlSocialInstagram:
      case CntMainFastButtonTypeEnum.urlSocialMyInstagram:
        type = CntMessengerTypeEnum.instagram;
        break;
    }

    if (id && type) {
      this.cntMessengersService.open(id, type);
    }
  }

  /**
   * fast buttons for sharepay
   * */
  public openSharepayFastButton(
    fastButton: FastMenuButtonInterface,
    activatedRoute: ActivatedRoute,
    loaderController: CntFirstButtonsLoaderControllerInterface
  ) {
    switch (fastButton.type) {
      case CntMainFastButtonTypeEnum.sharepayBank:
        if (fastButton.value) {
          const value = <CntFastButtonSharepayBankValueInterface>(
            fastButton.value
          );

          if (value && value.owner) {
            loaderController.showLoader();
            timer(100).subscribe(() => {
              this.openInnerLink(
                `./sharepay/bank/${value.owner}`,
                activatedRoute
              );
            });
          }
        }
        break;
    }
  }
}
