export enum CntAppEnum {
  sharepay = 'sharepay',
  onepay = 'onepay',
  edocument = 'edocument',
  chat = 'chat',
  base = 'base'
}


export enum CntUserTypeMemsqlEnum {
    simple = 'user',
    service = 'service',
    app = 'app'
}

export enum CntUserCountryCodeMemsqlEnum {
    russia = 'RUS',
}

export enum CntUserLangMemsqlEnum {
    russia = 'ru',
}

