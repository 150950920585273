export const environmentShared = {
  sentry: {
    webDsn: 'https://0864a776c7da48d7a690b6bc61ec8e5d@sentry.io/1355146',
  },
  onesignal: {
    appId: 'a547e3ff-6e19-4d88-82d6-f60cd81a87b8',
  },
  // qiscus: {
  //   id: 'connect-1eajb8ctlwyvf'
  // },
  firebase: {
    apiKey: 'AIzaSyB1IKj5-WoBbWeBfnwDkSYWX_PWMQy5G30',
    authDomain: 'ramman-connect.firebaseapp.com',
    databaseURL: 'https://ramman-connect.firebaseio.com',
    projectId: 'ramman-connect',
    storageBucket: 'ramman-connect.appspot.com',
    messagingSenderId: '830730717633',
  },
  pubnub: {
    publishKey: 'pub-c-ebe543f1-f416-461e-a465-f9ece8f841cd',
    subscribeKey: 'sub-c-e1d96592-d7c4-11ea-b3f2-c27cb65b13f4',
  },
  freeform: {
    frfPrivateStorage: {
      publicDomain: 'https://storage.googleapis.com/cnt-public/',
      publicGoogleStorage: 'gs://cnt-public',
      privateGoogleStorage: 'gs://ramman-connect.appspot.com',
    },
  },
  domain: {
    cdn: 'http://img-storage.ramman.net',
    graphqlDomain: 'https://cl.cnt.one/graphql',
    // graphqlDomain: 'http://localhost:8080/',
    frfDomain: 'https://cl.cnt.one/frf',
  },
  algolia: {
    appId: 'OJY8ANLTXX',
  },
};
