import {Injectable} from '@angular/core';
import {FrfTriggerActionForArrayInterface} from './@res/@abstract/@interface/common.interface';
import {FreeformCommonService} from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import {FrfSharedStorageService} from '../../../../../../frf-shared-storage/frf-shared-storage.service';
import {FrfValueGetterService} from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import {FrfMainLazyServiceModule} from '../../../../../../../../frf-main-lazy-service.module';
import {FrfTriggerActionServiceInterface} from "../../../@res/@abstract/@interface/common.interface";
import {FrfTriggerActionEnum} from "../../../@res/@abstract/@enum/common.enum";
import {FrfActionForArrayResevedKey} from "./@res/@abstract/@enum/common.enum";

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionForArrayService {
  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfValueGetterService: FrfValueGetterService
  ) {}

  /**
   *
   * */
  public async run(
    action: FrfTriggerActionForArrayInterface,
    actionService: FrfTriggerActionServiceInterface,
    startElement: any,
    frf: any,
    scope: any
  ): Promise<boolean> {
    const elements = actionService.getElementFromActionIfExist(action.id, startElement),
      element = elements && elements[0];

    if (!element) {
      return false;
    }

    const array = await this.frfValueGetterService.getValueWithPipe(
      action.array,
      element,
      frf,
      startElement,
      scope
    ).toPromise();

    if (!array?.length) {
      return false;
    }

    /* if need create copy element safe if not enough by array length */
    await this.ifNeedCreateCopiesOfElement(
      action,
      array,
      actionService,
      startElement,
      scope
    );

    const elementsAfterSafeCopy = actionService.getElementFromActionIfExist(action.id, startElement);

    // tslint:disable-next-line:forin
    for (const idx in array) {
      const elementAfterSafeCopy = elementsAfterSafeCopy[idx],
        value = array[idx]/*,await this.frfValueGetterService.getValueWithPipe(
        array[idx],
        elementAfterSafeCopy,
        this.common.freeform,
        startElement,
        scope
      ).toPromise()*/;

      scope = this.frfValueGetterService.safeGetScope(scope);

      /* get keys */
      const idxKey = action.replaceIdxKeyInScopeTo ?? FrfActionForArrayResevedKey.idx;
      const valueKey = action.replaceValueKeyInScopeTo ?? FrfActionForArrayResevedKey.value;
      const lengthKey = action.replaceLengthKeyInScopeTo ?? FrfActionForArrayResevedKey.length;

      /* write to scope */
      scope[idxKey] = idx;
      scope[valueKey] = value;
      scope[lengthKey] = array.length;

      /* TODO подумать что делать */
      await actionService.run(
        elementAfterSafeCopy,
        action.actions,
        scope
      );

      /* clear scope */
      delete scope[idxKey];
      delete scope[valueKey];
      delete scope[lengthKey];
    }

    return true;
  }

  /**
   * создаем достаточное копий элемента если передана позиция
   * */
  private async ifNeedCreateCopiesOfElement (
    action: FrfTriggerActionForArrayInterface,
    arr: any[],
    actionService: FrfTriggerActionServiceInterface,
    startElement: any,
    scope: any
  )
  {
    if (arr?.length && action.alignCopiesToLengthIn) {
      return  actionService.run(
        startElement,
        [
          {
            place: action.alignCopiesToLengthIn,
            type: FrfTriggerActionEnum.copyElement,
            id: action.id,
            length: arr.length
          }
        ],
        scope
      )
    }

    return false;
  }
}
