import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { DisplayedColumnsInterface } from '../../../@res/@abstract/@interface/common.interface';
import { Subject } from 'rxjs';
import { CntTableFlexAutoWidthService } from '../../@service/cnt-table-flex-auto-width/cnt-table-flex-auto-width.service';
import { CntTableExpandDateCellInterface } from './@res/@abstract/@interface/common.interface';

@Component({
  selector: 'cnt-table-cell',
  templateUrl: './cnt-table-cell.component.html',
  styleUrls: ['./cnt-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CntTableCellComponent implements AfterViewInit, OnInit {
  @Input() displayedColumn!: DisplayedColumnsInterface;
  @Input() element!: any;
  @Input() rowTemplates!: any;
  @Input() rowIdx!: number;
  @Input() group!: string;
  @Input() idx!: number;
  @Input() lengthOfRows!: number;
  @Output() onChangeExpandState: EventEmitter<
    CntTableExpandDateCellInterface
  > = new EventEmitter();
  /*
   * symbol for choose all
   * */
  public readonly symbolAll: string = '*';

  /**
   *
   * */
  public isExpanded: boolean = false;

  constructor(private cntTableFlexAutoWidth: CntTableFlexAutoWidthService) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.group !== 'template' && this.lengthOfRows === this.rowIdx + 1) {
      this.cntTableFlexAutoWidth.correctWidth$.next();
    }
  }

  /**
   *
   * */
  public expandIfCan() {
    if (this.displayedColumn.canExpand) {
      this.isExpanded = !this.isExpanded;

      this.onChangeExpandState.emit({
        element: this.element,
        state: this.isExpanded
      });
    }
  }
}
