<ng-container [ngSwitch]="type">
  <div
    class="link-container"
    (click)="open()"
    *ngSwitchCase="flexyViewCategoryTypeEnum.hash"
  >
    <a>{{ name }}</a>
  </div>

  <!-- TODO add later  -->
  <div *ngSwitchCase="flexyViewCategoryTypeEnum.externalLink">
    <!--    <a [href]="value" target="_blank">{{ name }}</a>-->
    <a (click)="open()" target="_blank">{{ name }}</a>
  </div>
</ng-container>
