<div class="info_of_people">
  <div class="left-side">
    <div class="user-icon-block" [ngClass]="{ 'not-image': !icon }">
      <img *ngIf="icon; else iconPlaceholder" [src]="icon" alt="user icon" />
      <ng-template #iconPlaceholder>
        <div class="icon-placeholder">
          {{ defaultStringForIcon }}
        </div>
      </ng-template>
    </div>
    <div class="contact-buttons">
      <div class="contact-button g-cr-click">
        <a>
          <img src="assets/images/png/base/contact/facetime.png" alt="" />
        </a>
      </div>
      <div class="contact-button g-cr-click" *ngIf="phone">
        <a [href]="'tel:' + phone">
          <img src="assets/images/png/base/contact/telephone.png" alt="" />
        </a>
      </div>
      <div
        class="contact-button g-cr-click"
        *ngIf="messengers"
        (click)="openDialog()"
      >
        <a>
          <img src="assets/images/png/base/contact/signal.png" alt="" />
        </a>
      </div>
    </div>
  </div>
  <div class="right-side content_persons ">
    <div class="block_my_content">
      <div class="cnt-id">{{ uid }}</div>
      <div class="cnt-user-name">{{ name }}</div>

      <div
        class="user-description"
        [innerHTML]="description || 'Без описания'"
      ></div>
    </div>
  </div>
</div>
