import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FrfRequestTypeEnum } from './@res/@abstract/@enum/common.enum';
import {
  FrfRequestGraphQlTypeInterface,
  FrfRequestParamTypeInterface,
  FrfRequestTypeInterface
} from './@res/@abstract/@interface/common.interface';
import { FrfValueGetterService } from '../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../frf-main-lazy-service.module';
import { GraphqlService } from '../../graphql/graphql.service';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfRequestService {
  constructor(
    private http: HttpClient,
    private frfValueGetterService: FrfValueGetterService,
    private graphqlService: GraphqlService
  ) {}

  /**
   *
   * */
  public doByRequestInput(
    request: FrfRequestTypeInterface,
    frfElement: any,
    frf: any,
    scope: any
  ): Observable<any> {
    const url = request.url,
      params = this.getParams(request.params, frfElement, frf, scope),
      body = this.getBodyForPost(request.body, frfElement, frf, scope),
      headers = this.getHeaders(request.headers, frfElement, frf, scope),
      requestType = request.requestType;

    return this.doRequest(url, requestType, params, body, headers, request);
  }

  /**
   *
   * */
  public doRequest(
    url: string,
    type: FrfRequestTypeEnum,
    params: any,
    body: any,
    headers: any,
    graphQlInput?: FrfRequestGraphQlTypeInterface
  ): Observable<any> {
    let options;

    /* update url params if we have */
    if (params) {
      url = this.getUrlWithQueryString(url, params);
    }
    // add headers if we have
    if (headers && typeof headers === 'object') {
      options = {
        headers: headers
      };
    }

    switch (type) {
      case FrfRequestTypeEnum.post:
        if (options) return this.http.post(url, body, options);
        else return this.http.post(url, body);

      /*
       * graphql query
       * */
      case FrfRequestTypeEnum.graphql:
      case FrfRequestTypeEnum.cntGraphQl:
        return this.graphqlService.query(
          url,
          graphQlInput.graphqlQuery,
          graphQlInput.graphqlName,
          headers,
          graphQlInput.graphqlVariables
        );
      case FrfRequestTypeEnum.get:
      default:
        if (options) {
          return this.http.get(url, options);
        } else {
          return this.http.get(url);
        }
    }
  }

  private getUrlWithQueryString(url: string, params: any = null) {
    if (typeof params === 'object') {
      const queryStrings = this.getQueryStringFromParams(params);
      if (queryStrings) {
        const findParams = url.indexOf('?');
        if (findParams > -1) {
          // if we have allready in url params -> add new params
          return url + '&' + queryStrings;
        } else {
          // if we have  not allready in url params -> set new params
          return url + '?' + queryStrings;
        }
      } else {
        return url;
      }
    }
  }

  /**
   *
   * */
  private getQueryStringFromParams(params: { [key: string]: string }): string {
    return Object.keys(params)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
  }

  /**
   * get params for query string
   * */
  private getParams(
    params: FrfRequestParamTypeInterface[],
    field: any,
    freeform: any,
    scope: any
  ) {
    /*
     * get 'get' params  for request
     * */
    const objects = params,
      result = {};
    let isParams = false;
    if (objects) {
      for (const obj of objects) {
        const key = obj.key;
        /* add to result object */
        result[key] = this.frfValueGetterService.getValue(
          typeof obj.value !== 'undefined' ? obj.value : obj,
          field,
          freeform,
          field,
          scope
        );

        /* set true when get first result */
        if (!isParams) isParams = true;
      }
    }

    return isParams ? result : null;
  }

  /**
   *
   * */
  private getHeaders(
    obj: FrfRequestParamTypeInterface[],
    field: any,
    freeform: any,
    scope: any
  ): any | null {
    /* get headers for request */
    const objects = obj,
      result = {};
    let isHeader = false;

    if (objects) {
      for (const data of objects) {
        const key = data.key;
        /* add to result object */
        result[key] = this.frfValueGetterService.getValue(
          typeof data.value !== 'undefined' ? data.value : data,
          field,
          freeform,
          field,
          scope
        );

        /* set true when get first result */
        if (!isHeader) isHeader = true;
      }
    }
    return isHeader ? result : null;
  }

  /**
   *
   * */
  private getBodyForPost(
    obj: FrfRequestParamTypeInterface[],
    field: any,
    freeform: any,
    scope: any
  ): any {
    return this.getHeaders(obj, field, freeform, scope);
  }
}
