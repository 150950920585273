<div class="host" [ngSwitch]="options?.fullWidth" [ngStyle]="style">
  <div class="full-width-block" *ngSwitchCase="true">
    <ng-container [ngTemplateOutlet]="flexContainer"></ng-container>
  </div>

  <div class="container" *ngSwitchDefault>
    <ng-container [ngTemplateOutlet]="flexContainer"></ng-container>
  </div>
</div>

<ng-template #flexContainer>
  <cnt-flexy-view-self
    [hash]="hash"
    [viewName]="viewName"
    [component]="component"
    [payload]="payload"
    [options]="options"
    [version]="version"
    [owner]="owner"
    [deps]="deps"
    [theme]="theme"
  >
  </cnt-flexy-view-self>
</ng-template>
