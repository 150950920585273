import { FreeformDataFromPathToFileOfFieldInterface } from './@res/@abstract/@interface/common.interface';

export namespace FreeformCommonNamespace {
  /**
   * получение сылке к файлу на скачивание по пути из базы firestore
   * */
  export function getDownloadPathToFieldForFistore(
    formOnwerUid: string,
    modelId: string,
    formId: string,
    fieldId: string,
    uid: string,
    fileId: string,
    fileName: string
  ) {
    return `freeform/${formOnwerUid}/${modelId}/${formId}/${fieldId}/${uid}/${fileId}/${fileName}`;
  }

  /**
   * получение данных от ссылки к файлу
   * */
  export function getDataByToFileOfFieldIdForFirestore(
    path: string
  ): FreeformDataFromPathToFileOfFieldInterface {
    const resultArr = path.split('/');

    return {
      status: resultArr.length === 8,
      data: {
        formOnwerUid: resultArr[1],
        modelId: resultArr[2],
        formId: resultArr[3],
        fieldId: resultArr[4],
        uid: resultArr[5],
        fileName: resultArr[6]
      }
    };
  }
}
