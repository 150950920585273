import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { FreeformAutocompleteService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/freeform-autocomplete.service';
import { FreeformFieldValidatorLibrary } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform-field-validators.library';
import { FrfInitialValueService } from '../../../../../../../../../../../../../../@service/frf-initial-value/frf-initial-value.service';
import { FrfFieldAutocompleteObjectInterface } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@interface/@field/@types/frf-field-autocomplete/@object/frf-field-autocomplete.object.interface';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'frf-field-autocomplete',
  templateUrl: './freeform-field-autocomplete.component.html',
  styleUrls: ['./freeform-field-autocomplete.component.scss']
})
export class FreeformFieldAutocompleteComponent
  extends AbstractUnsubscribeViewControl
  implements OnInit, AfterViewInit {
  // required
  @Input('fieldid') fieldid: any;
  @Input('objid') objid: any;
  @Input('freeform') frf: any;
  @Input('disabled') disabled: any;

  // optional
  @Input('type') type = 'text';

  /**
   * this field from freeform object
   * */
  public field: FrfFieldAutocompleteObjectInterface;

  /**
   * this field we add mask
   * */
  public mask;

  /**
   * filtered options
   * */
  public filteredOptions: Observable<any[]>;

  /**
   * we add all options from field
   * */
  public options;

  /**
   * we add later options
   * */
  public arrayForAccessToIndexByValue = {};

  /**
   * create angular reactive form
   * */
  public form = new FormGroup({
    field: new FormControl('')
  });

  /*
   * flow for debouncing
   * */
  private autocompleteRequest$: Subject<any> = new Subject();

  constructor(
    private common: FreeformCommonService,
    private autocomplete: FreeformAutocompleteService,
    private valueService: FrfValueSetterService,
    private frfInitialValueService: FrfInitialValueService,
    private frfValueSetterService: FrfValueSetterService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.field = this.frf.fields[this.fieldid].objects[this.objid];
    this.options = this.field.body.payload.options;

    /* add autocomplete array */
    this.changeAutocompleteArr();

    /* start listener autocomplete */
    this.startListenerAutocomplete();

    /* add required validator */
    new FreeformFieldValidatorLibrary(this.frf, this.field, this.form).run();
  }

  ngAfterViewInit(): void {
    /*
     * set if need inital value
     * */
    this.frfInitialValueService.setIfNeedInitialValueIfItExist(
      this.field,
      this.frf,
      {}
    );

    this.watchFieldChanges();
  }

  /**
   * watch field changes
   * */
  private watchFieldChanges() {
    this.frfValueSetterService
      .watchFieldChanges(this.fieldid, this.objid)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(() => {
        this.cdRef.markForCheck();
      });
  }

  private filter(option: any) {
    return this.autocomplete.filter(option, this.options);
  }

  private changeAutocompleteArr() {
    this.filteredOptions = this.form.valueChanges.pipe(
      startWith(''),
      map(option => (option ? this.filter(option) : this.options.slice()))
    );
  }

  /**
   * on select from autcomplete options
   * */
  public onSelect(iNvalue) {
    const val = iNvalue,
      index = this.arrayForAccessToIndexByValue[iNvalue],
      selectedObject = this.options[index];

    /* set clicked value to this */
    this.field.body.value = val;

    /* save selected object (would need for late access) */
    this.autocomplete.saveAutocompleteSelectedToFreeformObject(
      this.field,
      selectedObject
    );

    /* run onChange trigger if need */
    this.valueService.setFieldValue(this.field, val, ['onSelect'], false);
  }

  /**
   * start flow for did request with debouncing
   * */
  private startListenerAutocomplete() {
    this.autocompleteRequest$
      .pipe(debounceTime(this.autocomplete.getTimeout(this.field)))
      .subscribe(() => {
        this.autocomplete.run(
          (iNresultArray, iNindexArray) => {
            // save index array
            this.arrayForAccessToIndexByValue = iNindexArray;
            // save new val
            this.options = this.field.body.payload.options = iNresultArray;
            // change autocompleter array
            this.changeAutocompleteArr();
          },
          this.field,
          this.frf
        );
      });
  }

  public onKeyPress(iNevent) {
    const event = iNevent,
      symbol = event.key,
      keyCode = event.keyCode;

    /* dont block this symbols (enter or backspace or delete () and so on...) */
    if (
      keyCode === 8 || // enter
      keyCode === 9 || // tab
      keyCode === 13 || //backspase
      keyCode === 110 || // delete
      keyCode === 39 || // >
      keyCode === 37 // <
    ) {
      /* run autocomplete service */
      this.autocompleteRequest$.next();

      return true;
    }
    /* LATER add key up work only for need fields */
    if (
      this.field.body.rules.resolvedSymbols &&
      Array.isArray(this.field.body.rules.resolvedSymbols)
    ) {
      const result = this.common.checkSymbolForResolved(
        symbol,
        this.field.body.rules.resolvedSymbols
      );
      if (!result) {
        this.autocompleteRequest$.next();

        return true;
      }
    } else if (!Array.isArray(this.field.body.rules.resolvedSymbols)) {
      this.autocompleteRequest$.next();

      return true;
    }

    return false;
  }
}
