import { Injectable } from '@angular/core';
import {
  BodyFlexTableInterface,
  CntTableSortState$ValueInterface,
  DisplayedColumnsInterface
} from '../../../@res/@abstract/@interface/common.interface';
import { CntTableSortEnum } from '../../../@res/@abstract/@enum/common.enum';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CntTableFlexService {
  /**
   * default table name (for light use)
   * */
  public readonly defaultTableIdForSorting = '@default';

  /**
   * our sort state
   * */
  private readonly sortState: {
    [tableId: string]: {
      [columnKey: string]: CntTableSortEnum;
    };
  } = {};

  /**
   * enum for sort and use in template
   * */
  public readonly cntTableSortEnum = CntTableSortEnum;

  /**
   * enum for sort and use in template
   * */
  private readonly sortState$: Subject<
    CntTableSortState$ValueInterface
  > = new Subject();

  /**
   * update sort state
   * */
  public updateSortState(
    columnKey: string,
    state: CntTableSortEnum,
    tableId: string = this.defaultTableIdForSorting
  ): void {
    if (!this.sortState[tableId]) {
      this.sortState[tableId] = {};
    }

    /*
     * clear other fields
     * */
    Object.keys(this.sortState[tableId]).forEach(key => {
      this.sortState[tableId][key] = CntTableSortEnum.note;
    });

    this.sortState[tableId][columnKey] = state;

    this.sortState$.next({
      tableId,
      key: columnKey,
      state
    });
  }

  /**
   * get sort state flow$ with light add destroy
   * */
  public getSortState$WithDestroy(
    forDestroy$?: Observable<any>
  ): Observable<CntTableSortState$ValueInterface> {
    return forDestroy$
      ? this.sortState$.pipe(takeUntil(forDestroy$))
      : this.sortState$.pipe();
  }

  /**
   * TODO save auto unique for each table
   * get sort state for column
   * */
  public getSortStateByKey(
    columnKey: string,
    tableId: string = this.defaultTableIdForSorting
  ): CntTableSortEnum {
    if (!this.sortState[tableId]) {
      this.sortState[tableId] = {};
    }

    if (!this.sortState[tableId][columnKey]) {
      this.sortState[tableId][columnKey] = CntTableSortEnum.note;
    }

    return this.sortState[tableId][columnKey];
  }

  /**
   * получить колонки для хедера
   * */
  public getHeaderColumns(
    data: DisplayedColumnsInterface[]
  ): DisplayedColumnsInterface[] {
    return data.map(column => {
      if (typeof column.draggable !== 'boolean') {
        column.draggable = false;
      }

      if (typeof column.style !== 'object' && !Array.isArray(column.style)) {
        column.style = {};
      }

      if (
        !column.state ||
        !Array.isArray(column.state) ||
        !column.state.length
      ) {
        column.state = ['*'];
      }

      return column;
    });
  }

  /**
   * получить массив данных
   * */
  public getRows(rows: BodyFlexTableInterface[]): BodyFlexTableInterface[] {
    return rows.map(row => {
      let options$: any = {},
        data$: any = {};

      /*
       * создаем/отделяем настройки
       * */
      if (!row.options$) {
        options$ = {};
      } else {
        options$ = row.options$;

        delete row.options$;
      }

      /*
       * разрешено ли перетаскивать
       * */
      if (typeof options$.draggable !== 'boolean') {
        options$.draggable = false;
      }

      /*
       * основные данные
       * */
      if (!row.data$) {
        data$ = row;
      } else {
        data$ = row.data$;
      }

      return {
        data$,
        options$
      };
    });
  }
}
