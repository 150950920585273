export enum FlexyViewUserEnum {
  all = '*',
  signed = '@',
  unsigned = '?'
}

export enum FlexyViewCategoryPositionEnum {
  header = 0,
  footer = 1
}

export enum FlexyViewCategoryTypeEnum {
  /**
   * scroll to component with curent hash (passed in value)
   * */
  hash = 'hash',

  /**
   * inner application menu
   * */
  menu = 'menu',

  /**
   * open new page in new blank
   * */
  externalLink = 'external-link'
}
