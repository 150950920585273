<div
  class="menus"
  mz-screen-detector
  *ngIf="mainShared.isComp() || mainShared.isMobile(mainShared.isShowFooter)"
>
  <ng-container *ngFor="let app of resultAppList; let idx = index">
    <div
      class="box {{ 'num-' + (idx + 1) }} app-name-{{ app.id }}"
      (click)="changeApp(app.id)"
      [ngClass]="{ active: app.id === currentAppId }"
    >
      <div class="menu app-name-{{ app.id }}">
        <div class="inner-helper">
          <div class="icon app-name-{{ app.id }}"></div>
          <div class="title">{{ appStorage[app.id].name }}</div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="footer" *ifScreenType="['medium', 'large']">
    <!--<div class="box bell">-->
    <!--<div class="menu bell">-->
    <!--<div class="icon bell"></div>-->
    <!--<div class="title"></div>-->
    <!--</div>-->
    <!--</div>-->
    <div class="box account" [matMenuTriggerFor]="menu">
      <div class="menu account">
        <div class="icon account"></div>
        <div class="title" [ngSwitch]="!!connectAuthService.user">
          <ng-container *ngSwitchCase="true">
            <span>{{ connectAuthService.user }}</span>
          </ng-container>
          <span *ngSwitchDefault>
            Войти
          </span>
        </div>
      </div>
    </div>
    <mat-menu #menu="matMenu" [xPosition]="'after'" [yPosition]="'below'">
      <button
        mat-menu-item
        *ngFor="let menu of menus"
        (click)="changeMenu(menu)"
      >
        {{ menu.name }}
      </button>
    </mat-menu>
  </div>
</div>
