import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { connectAppControllerReducer } from './+state/connect-app-controller.reducer';
import {SwitchAppAction} from "./+state/states/actions/apps/switchApp/switch-app.action";
import {SetMainUserAction} from "./+state/states/actions/user/setMainUser/set-main-user.action";
import {ConnectAppControllerInerface} from "./@res/@abstract/@interface/app/connect-app-controller.inerface";
import {AppsIdEnum, ConnectAppControllerActionsEnum} from "./@res/@abstract/@enums/app/apps-id.enum";
import {DbUserTypeEnum} from "./@res/@abstract/@enums/user/common.enum";
import {
  ConnectAppsListInterface,
  ConnectAppsStorageInterface
} from "./@res/@abstract/@interface/app/_sub/app-base.interface";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'connectAppController',
      connectAppControllerReducer
    )
  ]
})
export class ConnectAppControllerModule {}

export {SwitchAppAction} from "./+state/states/actions/apps/switchApp/switch-app.action";
export {SetMainUserAction} from "./+state/states/actions/user/setMainUser/set-main-user.action";
export {ConnectAppControllerInerface} from "./@res/@abstract/@interface/app/connect-app-controller.inerface";
export {AppsIdEnum, ConnectAppControllerActionsEnum} from "./@res/@abstract/@enums/app/apps-id.enum";
export {DbUserTypeEnum} from "./@res/@abstract/@enums/user/common.enum";
export {ConnectAppsListInterface, ConnectAppsStorageInterface} from "./@res/@abstract/@interface/app/_sub/app-base.interface";
