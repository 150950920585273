import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FrfBridgeToastService,
  FrfFormActionTypeEnum,
  FrfRootService,
  FrfToastInterface,
} from '@cnt-nx-workspace/frf/main';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';
import {
  SnackBarHorizontalPosition,
  SnackBarType,
} from './@res/@interface/common.interface';
import { ToastPosition } from './@res/@enum/common.enum';
import { Observable, of, Subject } from 'rxjs';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { SharepayPurchaseBankFilterWindowComponent } from '../../../../../../@route-module/sharepay-app/@sub/@module/sharepay-bank/@sub/@view/sharepay-bank/@sub/@view/sharepay-bank-purchase/@sub/@view/sharepay-bank-purchase-all-table/@sub/@view/sharepay-purchase-bank-filter-window/sharepay-purchase-bank-filter-window.component';
import { Overlay } from '@angular/cdk/overlay';
import { OverlayConfig } from '@angular/cdk/overlay/overlay-config';
import { OverlayRef } from '@angular/cdk/overlay/overlay-ref';
import { takeUntil } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/portal/portal';

/**
 * Сервис для открытия вспомогательных роутов
 * */
@Injectable({
  providedIn: 'root',
})
export class LkService extends AbstractUnsubscribeViewControl {
  /**
   * */
  public lkActivatedRoute: ActivatedRoute;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private frfRoot: FrfRootService,
    private overlay: Overlay,
    private injector: Injector,
    private frfBridgeToastService: FrfBridgeToastService
  ) {
    super();

    this.frfRoot.listenFormsAction$(this.viewDestroy$).subscribe((data) => {
      if (data && data.type === FrfFormActionTypeEnum.open) {
        this.openFreeform(data.owner, data.modelId, data.formId);
      } else if (data && data.type === FrfFormActionTypeEnum.close) {
        this.closeFreeform();
      }
    });
  }

  /**
   * */
  public activeFrfToast$(viewDestroy$: any): Observable<FrfToastInterface> {
    return of(null);
    // return this.frfBridgeToastService.get$().pipe(
    //   takeUntil(viewDestroy$),
    //   tap((toast) => {
    //     this.toast(
    //       <any>toast.status,
    //       toast.title || toast.message,
    //       toast.title ? toast.message : null,
    //       <any>toast.position,
    //       toast.duration
    //     );
    //   })
    // );
  }

  /**
   * open freeform
   * */
  private openFreeform(
    user: string = 'wideFormat24',
    model: string = 'addWork',
    formid?: string
  ) {
    this.router.navigate([{ outlets: { freeform: ['frf', user, model] } }], {
      relativeTo: this.lkActivatedRoute,
      queryParams: { formid },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * close freeform
   * */
  private closeFreeform() {
    this.router.navigate(
      [
        {
          outlets: { freeform: null },
        },
      ],
      {
        relativeTo: this.lkActivatedRoute,
        queryParamsHandling: 'merge',
      }
    );
  }

  /**
   * @deprecated - use toast
   * show snackbar
   * */
  public showSnackBar(
    title: string,
    message?: string,
    horizontalPosition?: SnackBarHorizontalPosition
  ) {
    this.snackBar.open(title, message, {
      horizontalPosition,
    });
    // this.toast(
    //   'basic',
    //   title,
    //   message,
    //   this.getToastPosition(horizontalPosition),
    //   10000
    // );
  }

  /**
   * @deprecated - use toast
   * show bar when we start async operation
   * */
  public showWaitingBar(
    title: string,
    duration?: number,
    horizontalPosition?: SnackBarHorizontalPosition
  ) {
    if (!horizontalPosition) {
      horizontalPosition = 'left';
    }
    this.snackBar.open(title, '', {
      duration,
      horizontalPosition,
    });

    // this.toast(
    //   'basic',
    //   title,
    //   '',
    //   this.getToastPosition(horizontalPosition),
    //   duration
    // );
  }

  /**
   *
   * */
  private getToastPosition(type?: SnackBarHorizontalPosition): ToastPosition {
    switch (type) {
      case 'start':
        return ToastPosition.TOP_LEFT;
      case 'end':
        return ToastPosition.TOP_RIGHT;
      case 'right':
        return ToastPosition.BOTTOM_RIGHT;
      default:
        return ToastPosition.BOTTOM_LEFT;
    }
  }

  /**
   * */
  public toast(
    type: SnackBarType,
    title: string,
    message: string,
    position?: ToastPosition,
    duration?: number
  ): void {
    // this.nbToastrService.show(message, title, {
    //   status: type,
    //   position: <any>position || ToastPosition.BOTTOM_LEFT,
    //   duration: duration,
    // });
  }

  /**
   * show bar when we start async operation
   * */
  public hideWaitingBar() {
    this.snackBar.dismiss();
  }

  /**
   * */
  public openModalWindow(
    overlay: Overlay,
    component: ComponentType<any>,
    config: OverlayConfig,
    closeClickOnBackdrop = false,
    data: any = {},
    backdropClickCallback?: (
      overlayRef: OverlayRef,
      destroy$: Subject<void>
    ) => void
  ): OverlayRef {
    const overlayRef = overlay.create(config),
      userProfilePortal = new ComponentPortal(component);

    if (config.hasBackdrop && closeClickOnBackdrop) {
      const destroy$: Subject<void> = new Subject();

      overlayRef
        .backdropClick()
        .pipe(takeUntil(destroy$))
        .subscribe(() => {
          if (typeof backdropClickCallback !== 'function') {
            overlayRef.detach();
            destroy$.next();
            destroy$.unsubscribe();
          } else {
            backdropClickCallback(overlayRef, destroy$);
          }
        });
    }

    overlayRef.attach(userProfilePortal).instance.data = data;

    return overlayRef;
  }

  // public createInjector(dataToPass: any): PortalInjector {
  //   const injectorTokens = new WeakMap();
  //   injectorTokens.set('data', dataToPass);
  //   return new PortalInjector(this.injector, injectorTokens);
  // }
}
