import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfViewImageService } from './@sub/@service/frf-view-image.service';
import { ValueForSetFieldSourceActionType } from '../../../../../../../../../../../../../../../@doc/@field/@trigger/frf-actions.interface';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { takeUntil } from 'rxjs/operators';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'frf-view-image',
  templateUrl: './freeform-view-image.component.html',
  styleUrls: ['./freeform-view-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeformViewImageComponent extends AbstractUnsubscribeViewControl
  implements OnInit, OnChanges {
  @Input('fieldid') fieldid;
  @Input('objid') objid;
  @Input('freeform') freeform;
  @Input('disabled') disabled;

  /* this field from freeform object */
  public field;

  /**
   * значение ссылки
   * */
  public src: string;

  /**
   * последнее значение ссылки
   * */
  public lastFieldValue: ValueForSetFieldSourceActionType;

  constructor(
    private common: FreeformCommonService,
    private frfViewImageService: FrfViewImageService,
    private cdRef: ChangeDetectorRef,
    private frfValueService: FrfValueSetterService
  ) {
    super();
  }

  ngOnInit() {
    this.frfValueService
      .watchFieldChanges(this.fieldid, this.objid)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(() => {
        this.init();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  /**
   * получить значение для вывода ссылки
   * */
  public init() {
    this.field = this.freeform.fields[this.fieldid].objects[this.objid];
    /* set for view block not required */
    this.field.body.status.required = [];

    const value: ValueForSetFieldSourceActionType =
      this.field &&
      this.field.body &&
      this.field.body.payload &&
      this.field.body.payload.src;

    /**
     * зашита от повторных напрасных запросов
     * */
    if (
      (typeof this.lastFieldValue === 'object' &&
        typeof value === 'object' &&
        this.lastFieldValue.path === value.path &&
        this.lastFieldValue.public === value.public) ||
      typeof this.lastFieldValue === value
    ) {
      this.cdRef.markForCheck();
      return;
    }

    /*
     * save last value
     * */
    this.lastFieldValue = value;

    if (value) {
      /*
       * TODO добавить get value service, что бы могу наше любое значение принимать
       * */
      this.frfViewImageService.getUrlFromValue(value).subscribe(url => {
        if (url) {
          this.src = url;
          this.cdRef.markForCheck();
        }
      });
    }
  }
}
