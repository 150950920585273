import { Component, Inject, Input, OnInit } from '@angular/core';
import { MainSharedService } from '../../../../../@module/shared/@sub/@service/main-shared/main-shared.service';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { SumSubService } from 'mz-connect-sumsub';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sum-sub',
  templateUrl: './sum-sub.component.html',
  styleUrls: ['./sum-sub.component.scss']
})
export class SumSubComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SumSubComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sumSubService: SumSubService,
    private connectAuthService: ConnectAuthService,
    private mainSharedService: MainSharedService
  ) {}

  ngOnInit() {
    this.openModalForVerificate();
  }

  /**
   *
   * */
  public hidePanel() {
    this.dialogRef.close();
  }

  /*
   * open modal with sumsub window
   * */
  public openModalForVerificate() {
    this.mainSharedService.showLoader();

    const selector = '#sum-sub-window',
      uid = this.connectAuthService.uid,
      token = this.connectAuthService.token;

    this.sumSubService.getToken(uid, token).subscribe(result => {
      // hide loader
      this.mainSharedService.hideLoader();

      if (result.status && result.tokenForVerificate) {
        this.sumSubService
          .openWindow(result.tokenForVerificate, selector)
          .subscribe(windowResult => {});
      } else {
        // TODO add normal task
        alert('Неизвестная ошибка');
      }
    });
  }
}
