import { Injectable } from '@angular/core';
import * as mzCommon from 'mz-common';
import { FrfValueGetterClassInterface } from '../../@res/@abstract/@interface/common.inteface';
import { FrfSharedStorageService } from '../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfValueGetterPayload } from './@res/@abstract/@interface/common.interface';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueGetterPayloadService
  implements FrfValueGetterClassInterface {
  constructor(private frfSharedStorage: FrfSharedStorageService) {}

  /**
   *
   * */
  public get(getter: FrfValueGetterPayload, frfElement: any, frf: any): any {
    const frfObject = getter.id
        ? this.frfSharedStorage.frfValueGetter.getFrfElementById(
            getter.id,
            frf,
            null,
            frfElement
          )
        : frfElement,
      basePathToPayload = 'body.payload',
      path = getter.path
        ? `${basePathToPayload}.${getter.path}`
        : basePathToPayload;

    if (frfObject) {
      return mzCommon.getValueFromObjectByPath(path, frfObject);
    }

    return null;
  }
}
