import { Injectable } from '@angular/core';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';
import { FrfRootService } from '../frf-root/frf-root.service';
import * as mzCommon from 'mz-common';
import {
  FrfEmitStateEnum,
  PathToFieldValuesForEmitEnum
} from '../frf-root/@res/@abstract/@enum/common.enum';
import { FrfFieldObjectInterface } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@interface/@field/@object/frf-field.object.interface';
import { FrfInterface } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@interface/@freeform/common.interface';

/**
 * для эмита через глобальный frf-root service
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfEmitterService {
  constructor(private frfRoot: FrfRootService) {}

  /**
   * init form for later emit
   * */
  public initFormToEmit(frf: FrfInterface) {
    if (
      frf &&
      frf.fields &&
      /**
       * if state has al least one state for emit
       * */
      this.frfRoot.statesForEmit.length &&
      /**
       * if pathToFieldValuesForEmit has al least one state for emit
       * */
      this.frfRoot.pathToFieldValuesForEmit.length
    ) {
      Object.keys(frf.fields).forEach(fieldModelId => {
        if (frf.fields[fieldModelId].object) {
          Object.keys(frf.fields[fieldModelId].object).forEach(
            fieldObjectId => {
              if (
                this.frfRoot.pathToFieldValuesForEmit.indexOf(
                  PathToFieldValuesForEmitEnum.all
                ) !== -1
              ) {
                /*
                 * need full body add to emit
                 * */
                this.safeAddValueByPathToField(
                  frf.fields[fieldModelId].object[fieldObjectId],
                  PathToFieldValuesForEmitEnum.all
                );
              } else {
                /*
                 * need add to emit this all path
                 * */
                this.frfRoot.pathToFieldValuesForEmit.forEach(path => {
                  const value = mzCommon.getValueFromObjectByPath(
                    path,
                    frf.fields[fieldModelId].object[fieldObjectId].body
                  );

                  this.safeAddValueByPathToField(
                    frf.fields[fieldModelId].object[fieldObjectId],
                    path,
                    value
                  );
                });
              }
            }
          );
        }
      });
    }
  }

  /**
   * emit this field change if need with save values
   * */
  public emitOnSubmit(state: boolean): boolean {
    if (this.frfRoot.statesForEmit.indexOf(FrfEmitStateEnum.onSubmit) !== -1) {
      /*
       * can access to emit -> emit
       * */
      this.frfRoot.onSubmit$.next({
        ...this.frfRoot.values,
        inputParams$: this.frfRoot.inputParams,
        state: state
      });
    }

    return true;
  }

  /**
   * emit this field change if need with save values
   * */
  public emitFieldChanges(
    field: FrfFieldObjectInterface,
    pathToValue: string,
    value: any
  ): boolean {
    if (this.canEmitThisPath(pathToValue)) {
      /*
       * save value for emit on submit if need
       * */
      this.safeAddValueByPathToField(field, pathToValue, value);

      if (
        this.frfRoot.statesForEmit.indexOf(FrfEmitStateEnum.fieldChanges) !== -1
      ) {
        /*
         * can access to emit -> emit
         * */
        this.frfRoot.fieldChanges$.next({
          field: field,
          path: pathToValue,
          value: value
        });

        return true;
      }
    }

    return false;
  }

  /**
   * can we emit by this path value
   * */
  private canEmitThisPath(pathToValue: string) {
    return (
      this.frfRoot.pathToFieldValuesForEmit.indexOf(pathToValue) !== -1 ||
      this.frfRoot.pathToFieldValuesForEmit.indexOf(
        PathToFieldValuesForEmitEnum.all
      )
    );
  }

  /**
   * safe add value with create object by path to field object
   * */
  public safeAddValueByPathToField(
    field: FrfFieldObjectInterface,
    pathToValue: PathToFieldValuesForEmitEnum | string,
    value?: any
  ): boolean {
    if (field && field && field.options) {
      const rowModelId = field.options['p-r-mid'],
        rowObjectId = field.options['p-r-id'],
        groupModelId = field.options['p-g-mid'],
        groupObjectId = field.options['p-g-id'],
        pageModelId = field.options['p-p-mid'],
        pageObjectId = field.options['p-p-id'],
        fieldObjectId = field.id,
        fieldModelId = field.modelid;

      if (
        fieldModelId &&
        fieldObjectId &&
        rowModelId &&
        rowObjectId &&
        groupModelId &&
        groupObjectId &&
        pageModelId &&
        pageObjectId
      ) {
        this.safeAddField(
          fieldModelId,
          fieldObjectId,
          rowModelId,
          rowObjectId,
          groupModelId,
          groupObjectId,
          pageModelId,
          pageObjectId
        );

        if (pathToValue === PathToFieldValuesForEmitEnum.all) {
          /*
                        this.frfRoot.values.form$.base.page$[pageModelId][pageObjectId].group$[groupModelId][groupObjectId].row$[rowModelId][rowObjectId].field$[fieldModelId][fieldObjectId] = field.body;
                    */
          this.frfRoot.values.fieldsWithModel$[fieldModelId][fieldObjectId] =
            field.body;
          this.frfRoot.values.fields$[fieldObjectId] = field.body;
        } else {
          /*
                        mzCommon.addValueToObjectByPath(
                            this.frfRoot.values.form$.base.page$[pageModelId][pageObjectId].group$[groupModelId][groupObjectId].row$[rowModelId][rowObjectId].field$[fieldModelId][fieldObjectId],
                            pathToValue,
                            value
                        );
                    */

          /**
           * add value with model to fields block
           * */
          mzCommon.addValueToObjectByPath(
            this.frfRoot.values.fieldsWithModel$[fieldModelId][fieldObjectId],
            pathToValue,
            value,
            false
          );

          /**
           * add value to fields block
           * */
          mzCommon.addValueToObjectByPath(
            this.frfRoot.values.fields$[fieldObjectId],
            pathToValue,
            value,
            false
          );
        }

        return true;
      }
    }

    return false;
  }

  /**
   * safe add row to values for later emit
   * */
  private safeAddField(
    modelId: string,
    objId: string,
    rowModelId: string,
    rowObjId: string,
    groupModelId: string,
    groupObjId: string,
    pageModelId: string,
    pageObjId: string
  ) {
    /*this.safeAddRow(
            rowModelId,
            rowObjId,
            groupModelId,
            groupObjId,
            pageModelId,
            pageObjId
        );*/

    /**
     * add field
     * */
    if (!this.frfRoot.values.fieldsWithModel$[modelId]) {
      this.frfRoot.values.fieldsWithModel$[modelId] = {
        [objId]: {}
      };
    } else if (!this.frfRoot.values.fieldsWithModel$[modelId][objId]) {
      this.frfRoot.values.fieldsWithModel$[modelId][objId] = {};
    }

    if (!this.frfRoot.values.fields$[objId]) {
      this.frfRoot.values.fields$[objId] = {};
    }
  }

  /**
   * safe add row to values for later emit
   * */
  private safeAddRow(
    modelId: string,
    objId: string,
    groupModelId: string,
    groupObjId: string,
    pageModelId: string,
    pageObjId: string
  ) {
    this.safeAddGroup(groupModelId, groupObjId, pageModelId, pageObjId);

    if (
      !this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        groupModelId
      ][groupObjId].row$
    ) {
      this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        groupModelId
      ][groupObjId].row$ = {
        [modelId]: {
          [objId]: {
            field$: {}
          }
        }
      };
    } else if (
      !this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        groupModelId
      ][groupObjId].row$[modelId]
    ) {
      this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        groupModelId
      ][groupObjId].row$[modelId] = {
        [objId]: {
          field$: {}
        }
      };
    } else if (
      !this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        groupModelId
      ][groupObjId].row$[modelId][objId]
    ) {
      this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        groupModelId
      ][groupObjId].row$[modelId][objId] = { field$: {} };
    }
  }

  /**
   * safe add group to values for later emit
   * */
  private safeAddGroup(
    modelId: string,
    objId: string,
    pageModelId: string,
    pageObjId: string
  ) {
    this.safeAddPage(pageModelId, pageObjId);

    if (!this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$) {
      this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$ = {
        [modelId]: {
          [objId]: {
            row$: {}
          }
        }
      };
    } else if (
      !this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        modelId
      ]
    ) {
      this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        modelId
      ] = {
        [objId]: {
          row$: {}
        }
      };
    } else if (
      !this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        modelId
      ][objId]
    ) {
      this.frfRoot.values.form$.base.page$[pageModelId][pageObjId].group$[
        modelId
      ][objId] = { row$: {} };
    }
  }

  /**
   * safe add page to values for later emit
   * */
  private safeAddPage(modelId: string, objId: string) {
    if (!this.frfRoot.values.form$.base.page$) {
      this.frfRoot.values.form$.base = {
        page$: {
          [modelId]: {
            [objId]: {}
          }
        }
      };
    } else if (!this.frfRoot.values.form$.base.page$[modelId]) {
      this.frfRoot.values.form$.base.page$[modelId] = {
        [objId]: {}
      };
    } else if (!this.frfRoot.values.form$.base.page$[modelId][objId]) {
      this.frfRoot.values.form$.page$[modelId][objId] = {};
    }
  }
}
