import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mz-web-base-app-menu-left-footer-controller',
  templateUrl: './app-menu-left-footer-controller.component.html',
  styleUrls: ['./app-menu-left-footer-controller.component.css']
})
export class AppMenuLeftFooterControllerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
