import { Injectable, NgZone } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';
import {
  FunctionScrollDirection,
  FunctionScrollVisibilityState,
} from './@res/@enum/common.enum';

@Injectable({
  providedIn: 'root',
})
export class FunctionScrollService {
  constructor(private zone: NgZone) {}

  /**
   * */
  public start(
    elementRef: HTMLElement,
    alive$: Subject<any>,
    minimumActionValue = 0,
    infelicity = 10
  ) {
    let oldScrollValue, lastVisibilityState, isVisible;

    this.zone.runOutsideAngular(() => {
      const flow$ = fromEvent(window, 'scroll').pipe(
        throttleTime(10),
        map(() => window.pageYOffset),
        takeUntil(alive$)
      );

      const scroll$ = flow$.pipe(
        filter((y1) => {
          const y2 = oldScrollValue;
          // @ts-ignore
          return (
            !infelicity || !y2 || y1 === 0 || Math.abs(y1 - y2) >= infelicity
          );
        }),
        map(
          (y1): FunctionScrollDirection => {
            const y2 = oldScrollValue,
              result =
                y1 <= minimumActionValue
                  ? FunctionScrollDirection.None
                  : y1 > y2
                  ? FunctionScrollDirection.Down
                  : FunctionScrollDirection.Up;
            // @ts-ignore
            oldScrollValue = y1;
            return result;
          }
        ),
        distinctUntilChanged(),
        share(),
        takeUntil(alive$)
      );
      const goingUp$ = scroll$.pipe(
        filter((direction) => direction === FunctionScrollDirection.Up),
        takeUntil(alive$)
      );
      const goingNone$ = scroll$.pipe(
        filter((direction) => direction === FunctionScrollDirection.None),
        takeUntil(alive$)
      );
      const goingDown$ = scroll$.pipe(
        filter((direction) => direction === FunctionScrollDirection.Down),
        takeUntil(alive$)
      );

      goingNone$.subscribe((offset) => {
        lastVisibilityState = FunctionScrollVisibilityState.none;

        elementRef.classList.remove('scrollUp');
        elementRef.classList.remove('scrollDown');
      });

      goingUp$.subscribe((offset) => {
        isVisible = true;
        lastVisibilityState = FunctionScrollVisibilityState.Visible;

        elementRef.classList.add('scrollUp');
        elementRef.classList.remove('scrollDown');
      });

      goingDown$.subscribe((offset) => {
        isVisible = false;
        lastVisibilityState = FunctionScrollVisibilityState.Hidden;

        elementRef.classList.remove('scrollUp');
        elementRef.classList.add('scrollDown');
      });
    });
  }
}
