import { Injectable } from '@angular/core';
import { MzOnesignalWebService } from 'mz-onesignal';
import { PushServiceInterface } from '../../@shared/@interface/push-service/push-service.interface';
import {
  PlatformsEnum,
  SignedTypeEnum
} from '../../@shared/@interface/push-service/@res/@enum/common.enum';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class PushService implements PushServiceInterface {
  /*
   * platform type for add to push service (onesignal)
   * */
  public readonly platform: PlatformsEnum = PlatformsEnum.web;

  constructor(
    /*
     * platform specific service for one signal
     * */
    private onesignal: MzOnesignalWebService,
    private analyticsService: AnalyticsService
  ) {}

  /*
   * save user id with platform type
   * */
  public setAsSignedIn(uid: string) {
    this.onesignal.addTags({
      uid,
      platform: this.platform,
      signedType: SignedTypeEnum.signed
    });
    this.analyticsService.setUserId(uid);
  }

  /*
   * clear all from onesignal service
   * */
  public setAsSignedOut() {
    this.onesignal.deleteTags(['uid']);
    // add tags
    this.onesignal.addTags({
      signedType: SignedTypeEnum.notSigned
    });
  }

  /*
   * init onesignal push service
   * */
  public init(oneSignalAppId: string, messagingSenderId?: string) {
    this.onesignal.init(oneSignalAppId);
  }
}
