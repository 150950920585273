import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges, ViewChild
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {FastMenuButtonInterface} from '@cnt-multi-shared/@shared/first-buttons/@res/@abstract/@interface/common.interface';
import {NavigateService} from '../../../../../@res/@module/main/@sub/@service/navigate/navigate.service';
import {MainSharedService} from '../../../../../@res/@module/shared/@sub/@service/main-shared/main-shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FastMenuBtnTypeEnum} from '@cnt-multi-shared/@shared/first-buttons/@res/@abstract/@enum/common.enum';
import {FrfRootService} from '@cnt-nx-workspace/frf/main';
import {CntMessengersService} from '@cnt-nx-workspace/feature/cnt-messengers';
import {CntFirstButtonsService} from '@cnt-nx-workspace/feature/cnt-first-buttons';
import {AbstractUnsubscribeViewControl} from '@cnt-nx-workspace/function/shared/base';
import {CntMainFastButtonTypeEnum} from "@cnt-multi-shared/@shared/@main/@res/@abstract/@enum/cnt-fast-button.enum";
import {FunctionScrollService} from "../../../../../../../../../../libs/function/shared/base/src/lib/@sub/@service/function-scroll/function-scroll.service";

const footer = {
  systemButtons: [
    {
      name: 'Визитка',
      title: 'Визитка',
      type: CntMainFastButtonTypeEnum.appVisit,
      icon:
        'https://cdn.ramman.net/connect/assets/icons/visit-icon.jpg',
      badges: 0,
      id: 1
    },
    {
      name: 'Чат',
      title: 'Чат',
      type: CntMainFastButtonTypeEnum.appChat,
      icon:
        'https://cdn.ramman.net/connect/assets/icons/chat-icon.png',
      badges: 0,
      id: 1
    },
    // {
    //   name: 'Маркет',
    //   title: 'Чат',
    //   icon:
    //     'https://cdn.ramman.net/connect/assets/icons/market-icon.png',
    //   badges: 0,
    //   id: 1
    // }
  ],
  // userButtons: [
  //   {
  //     name: 'images',
  //     title: 'images',
  //     icon:
  //       'https://support.apple.com/library/content/dam/edam/applecare/images/en_US/iOS/ios10-message-imessage-images-app.png',
  //     badges: 0,
  //     id: 1
  //   },
  //   {
  //     name: 'Apple Pay',
  //     title: 'Apple Pay',
  //     icon:
  //       'https://support.apple.com/library/content/dam/edam/applecare/images/en_US/iOS/ios11-message-imessage-apple-pay-app.png',
  //     badges: 4,
  //     id: 2
  //   },
  //   {
  //     name: 'Animoji',
  //     title: 'Animoji',
  //     icon:
  //       'https://support.apple.com/library/content/dam/edam/applecare/images/en_US/iOS/ios12-messages-imessage-memoji-icon.png',
  //     badges: 0,
  //     id: 3
  //   },
  //   {
  //     name: 'Memoji',
  //     title: 'Memoji',
  //     icon:
  //       'https://support.apple.com/library/content/dam/edam/applecare/images/en_US/iOS/ios13-imessage-memoji-sticker-icon.png',
  //     badges: 0,
  //     id: 4
  //   },
  //   {
  //     name: 'WhatsApp',
  //     title: 'WhatsApp',
  //     icon:
  //       'https://i2.wp.com/nigil.ru/wp-content/uploads/2015/06/whatsapp.png?resize=512%2C512',
  //     badges: 33,
  //     id: 5
  //   }
  // ]
};

@Component({
  selector: 'app-zone-footer',
  templateUrl: './app-zone-footer.component.html',
  styleUrls: ['./app-zone-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppZoneFooterComponent extends AbstractUnsubscribeViewControl
  implements OnInit, OnChanges {
  /**
   * */
  @Input() ownerId!: string;

  /**
   * */
  @ViewChild('cntBaseFooter', {static: true}) cntBaseFooter: ElementRef<any>;

  /**
   * @deprecated use - footerNew
   * */
  public footer = footer;

  /**
   * Fast menu list after click to three dot
   * */
  public fastButtonsForMain: FastMenuButtonInterface[] = [];

  /**
   * Fast menu list after click to three dot
   * */
  public fastButtonsForFooter: FastMenuButtonInterface[] = [];

  /**
   *
   * */
  public fastMenuTypeEnum = FastMenuBtnTypeEnum;

  constructor(
    private cdRef: ChangeDetectorRef,
    private functionScrollService: FunctionScrollService,
    private navigateService: NavigateService,
    private cntMessengersService: CntMessengersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private frfRoot: FrfRootService,
    private mainSharedService: MainSharedService,
    public cntFirstButtonsService: CntFirstButtonsService
  ) {
    super();
  }

  ngOnInit() {
    this.initFirstButtons();
    this.safeInitFooterAppearAnimation()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.ownerId?.firstChange) {
      this.initFirstButtons();
    }
  }

  /**
   * */
  private safeInitFooterAppearAnimation ()
  {
    if (this.cntBaseFooter) {
      this.functionScrollService.start(
        this.cntBaseFooter.nativeElement,
        this.viewDestroy$,
        0,
        30
      );
    }
  }

  /**
   * TODO later delete fix sharepay
   * */
  private initFirstButtons() {
    if (this.ownerId) {
      this.cntFirstButtonsService
        .initFirstButtons(this.ownerId)
        .pipe(takeUntil(this.viewDestroy$))
        .subscribe(
          buttons => {
            this.fastButtonsForMain = buttons.filter(btn => btn.showInMain);
            this.fastButtonsForFooter = buttons.filter(btn => btn.showInFooter);

            this.cdRef.markForCheck();
          }
        );
    }
  }

  /**
   *
   * */
  public openFastButton(fastButton: FastMenuButtonInterface) {
    this.cntFirstButtonsService.openFastButton(
      fastButton,
      this.activatedRoute,
      this.mainSharedService
    );
  }
}
