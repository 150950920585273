import { Injectable } from '@angular/core';
import { FrfMainLazyServiceModule } from '../../../../../../frf-main-lazy-service.module';
import { FrfValueSetterInterface } from '../../../@res/@abstract/@interface/common.interface';
import { FrfSharedStorageService } from '../../../../frf-shared-storage/frf-shared-storage.service';
import { FreeformCommonService } from '../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfValueSetterTempDataStorageTypeEnum } from '../frf-value-setter-temp-data/@res/@abstract/@enum/common.enum';
import { FrfTriggerActionSetLoadingStateGetTempPathFunction } from './@res/@function/common.function';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfValueSetterLoadingStateService
  implements FrfValueSetterInterface {
  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService
  ) {}

  /**
   * set loading state
   * */
  public set(state: boolean, element: any) {
    this.frfSharedStorage.frfValueSetter.setTempDataInElement(
      element,
      FrfTriggerActionSetLoadingStateGetTempPathFunction(),
      state,
      FrfValueSetterTempDataStorageTypeEnum.system
    );

    return true;
  }
}
