import { Injectable } from '@angular/core';
import { FreeformCommonService } from '../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfSharedStorageService } from '../../../../../../frf-shared-storage/frf-shared-storage.service';
import { FrfTriggerNameEnum } from '../../../../../@res/@abstract/@enum/common.enum';
import { FrfValueGetterService } from '../../../../../../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../frf-main-lazy-service.module';
import { FrfTriggerActionSetPayloadOptionsInterface } from './@res/@abstract/@interface/common.interface';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerActionSetPayloadOptionsService {
  constructor(
    private common: FreeformCommonService,
    private frfSharedStorage: FrfSharedStorageService,
    private frfValueGetterService: FrfValueGetterService
  ) {}

  /**
   *
   * */
  public async run(
    action: FrfTriggerActionSetPayloadOptionsInterface,
    startElement: any,
    scope: any
  ): Promise<boolean> {
    if (!startElement) {
      return false;
    }

    const valueResult = this.frfValueGetterService.getValue(
      action.value,
      startElement,
      this.common.freeform,
      startElement,
      scope
    );

    const element = action.id
      ? this.frfValueGetterService.getFrfElementById(
          action.id,
          this.common.freeform,
          null,
          startElement
        )
      : startElement;

    if (!element) {
      return false;
    }

    this.frfSharedStorage.frfValueSetter.setElementDataByPath(
      'field',
      element,
      'payload.options',
      valueResult,
      [FrfTriggerNameEnum.onChange, FrfTriggerNameEnum.onSetPayloadOptions],
      true,
      true
    );

    return true;
  }
}
