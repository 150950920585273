<div class="header-column" [ngStyle]="{ height: getHeightForHeader() }">
  <div class="desktop" *ifScreenType="['large', 'medium']">
    <!-- Быстрое меню для планшета и ПК -->
    <!--<div class="hidden-scroll">-->
    <div class="elements fast-menu">
      <ng-container
        *ngFor="let fastMenu of fastMenus; let idx = index"
        [ngTemplateOutlet]="fastMenuTemplate"
        [ngTemplateOutletContext]="{ fastMenu: fastMenu, idx: idx }"
      >
      </ng-container>
    </div>
    <!--</div>-->
    <div class="c-icon-more three-dot" [matMenuTriggerFor]="menu"></div>
  </div>

  <ng-container *ngIf="mainSharedService.isMobile()">
    <div class="mobile" *ngIf="!mobileTemplateFull; else mobileTemplateFull">
      <div
        class="left-side"
        *ngIf="!mobileTemplateWithMenuButton; else mobileTemplateWithMenuButton"
      >
        <div class="name">{{ title }}</div>
      </div>
      <div
        class="right-side"
        *ngIf="mainSharedService.isMobile(showThreeDotForMobile)"
      >
        <div class="c-icon-more three-dot" [matMenuTriggerFor]="menu"></div>
      </div>
    </div>

    <!-- Быстрое меню для мобильных -->
    <div class="mobile-device fast-menu">
      <div class="hidden-scroll">
        <div class="elements">
          <ng-container
            *ngFor="let fastMenu of fastMenus; let idx = index"
            [ngTemplateOutlet]="fastMenuTemplate"
            [ngTemplateOutletContext]="{ fastMenu: fastMenu, idx: idx }"
          >
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #fastMenuTemplate let-fastMenu="fastMenu" let-idx="idx">
    <ng-container [ngSwitch]="fastMenu.type">
      <!-- Ссылка на внутренную страницу -->
      <ng-container *ngSwitchCase="fastMenuTypeEnum.innerUrl">
        <!--<ng *ngIf="fastMenu.url">-->
        <div
          class="element"
          *ngIf="fastMenu.url"
          (click)="openInnerLink(fastMenu.url)"
        >
          <div
            class="icon {{ getClassColorId(idx) }}"
            [ngClass]="{ 'back-color': !fastMenu.icon }"
          >
            <img
              *ngIf="fastMenu.icon; else centerSymbol"
              [src]="fastMenu.icon"
            />
            <ng-template #centerSymbol>
              <span class="center-symbol">{{ fastMenu.name[0] }}</span>
            </ng-template>
          </div>
          <div class="name">{{ fastMenu.name }}</div>
        </div>
        <!--</a>-->
      </ng-container>
      <!-- Ссылка на внешную страницу -->
      <ng-container *ngSwitchDefault>
        <a [href]="fastMenu.url" target="_blank" *ngIf="fastMenu.url">
          <div class="element" [title]="fastMenu.title">
            <div
              class="icon {{ getClassColorId(idx) }}"
              [ngClass]="{ 'back-color': !fastMenu.icon }"
            >
              <img
                *ngIf="fastMenu.icon; else centerSymbol"
                [src]="fastMenu.icon"
              />
              <ng-template #centerSymbol>
                <span class="center-symbol">{{ fastMenu.name[0] }}</span>
              </ng-template>
            </div>
            <div class="name">{{ fastMenu.name }}</div>
          </div>
        </a>
      </ng-container>
    </ng-container>
  </ng-template>

  <div class="switcher" *ngIf="mainSharedService.isMobile(showTabBarForMobile)">
    <div class="camera icon c-icon-photo-camera"></div>
    <mat-tab-group
      [(selectedIndex)]="selectedTabIndex"
      (selectedIndexChange)="changeTab($event)"
    >
      <mat-tab [label]="tab" *ngFor="let tab of tabs"> </mat-tab>
      <mat-tab *ngFor="let template of tabTemplates">
        <ng-template mat-tab-label>
          <ng-container [ngTemplateOutlet]="template"></ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let menu of menus" (click)="changeMenu(menu)">
    {{ menu.name }}
  </button>
</mat-menu>
