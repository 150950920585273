import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'mdata',
  pure: true
})
export class MdatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    value =
      (value && typeof value === 'string') ? (parseInt(value, 10) || 0) : value;
    return value ? moment(value).format((args && args[0]) || 'LT') : '';
  }
}
