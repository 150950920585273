import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FreeformCommonService } from './_sub/freeform.common.service';
import { FreeformUrlLibrary } from './_sub/freeform-url.library';
import { FreeformCommonLibrary } from './_sub/freeform-common.library';
import { FrfMainLazyServiceModule } from '../../../../frf-main-lazy-service.module';
import { FrfValueGetterService } from '../../../../@sub/@service/@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfFieldAutocompleteObjectInterface } from '@cnt-multi-shared/@shared/freeform/@res/@abstract/@interface/@field/@types/frf-field-autocomplete/@object/frf-field-autocomplete.object.interface';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FreeformAutocompleteService {
  /**
   * we add late freeform url
   * */
  freeformUrl;

  /**
   * default timeout (if field has not timeout)
   * */
  private readonly defaultTimeout = 250;

  /**
   * get freeform common library
   * */
  freeformCommon = new FreeformCommonLibrary();

  constructor(
    private http: HttpClient,
    private common: FreeformCommonService,
    private frfValueGetterService: FrfValueGetterService
  ) {}

  /**
   * @deprecated
   * run with
   * */
  // public runWithTimeout (iNcallback, field: FrfFieldAutocompleteObjectInterface, frf: any) {
  //   this.run(iNcallback, field, frf);
  // }

  /**
   * did request for autocomplete
   * */
  public run(
    callback: (...any: any[]) => void,
    field: FrfFieldAutocompleteObjectInterface,
    freeform: any
  ) {
    const //field       = field,
      isRequest = this.isRequest(field),
      // freeform    = iNfreeform,
      // callback    = iNcallback,
      options = field['body']['payload']['options'];

    if (isRequest) {
      this.doRequestForAutocomplete(callback, field, options, freeform);
    }
  }

  private saveAutocompleteResponseToFreeformObject(
    field: FrfFieldAutocompleteObjectInterface,
    iNresponse
  ) {
    const key = 'field-autocomplete';
    this.freeformCommon.saveResponseToFreeformObject(field, key, iNresponse);
  }

  public saveAutocompleteSelectedToFreeformObject(
    field: FrfFieldAutocompleteObjectInterface,
    iNselectResult
  ) {
    const key = 'field-autocomplete';
    this.freeformCommon.saveSelectedToFreeformObject(
      field,
      key,
      iNselectResult
    );
  }

  private doRequestForAutocomplete(
    callback: any,
    field: FrfFieldAutocompleteObjectInterface,
    autocompleteObject,
    freeform
  ) {
    //** LATER create common request library or add to url library
    //set url library for work
    this.freeformUrl = new FreeformUrlLibrary(
      freeform,
      this.http,
      this.frfValueGetterService
    );

    const payload = field['body']['payload'],
      request = payload['request'],
      callbackResult = response => {
        // proccess this response
        this.processResponse(callback, field, response);
      };
    // do request with callaback function

    this.freeformUrl.doByRequest(request, field, callbackResult, freeform);

    // doByRequest ( iNrequest, iNfreeformObject, iNcallback
  }

  private isRequest(field: FrfFieldAutocompleteObjectInterface): boolean {
    if (
      typeof field.body.payload['request'] === 'object' &&
      typeof field.body.payload['request'].url === 'string' &&
      field.body.payload.type &&
      field.body.payload.type === 'request'
    ) {
      return true;
    }
    return false;
  }

  private getRequestType(
    field: FrfFieldAutocompleteObjectInterface
  ): string | null {
    if (
      typeof field.body.payload.request === 'object' &&
      typeof field.body.payload.request.requestType === 'string'
    ) {
      return field.body.payload.request.requestType;
    }
    return null;
  }

  /**
   * get timeout
   * */
  public getTimeout(field: FrfFieldAutocompleteObjectInterface): number | null {
    if (
      typeof field.body.payload.request === 'object' &&
      typeof field.body.payload.request.debounce === 'number'
    ) {
      return field.body.payload.request.debounce;
    }
    return this.defaultTimeout;
  }

  private saveIndexForLateAccess(
    iNvalue: string,
    iNndex: string,
    iNobject: object
  ) {
    iNobject[iNvalue] = iNndex;
  }

  private getIndexByValue(iNvalue, iNobject) {
    return iNobject[iNvalue];
  }

  /**
   *
   * */
  private getValueByPath(pathToValue: string, data: any): string {
    for (let path of pathToValue.split(',')) {
      const result = this.common.connect.getValueFromObjectByPath(path, data);

      if (result) return result;
    }

    return '';
  }

  private processResponse(
    iNcallback,
    field: FrfFieldAutocompleteObjectInterface,
    iNresponse: object | null
  ) {
    const response = iNresponse,
      pathToImg = this.getPathToImg(field),
      pathToValue = this.getPathToValue(field),
      pathToExtra = this.getPathToExtra(field),
      pathToChiefArray = this.getPathToChiefArray(field),
      callback = iNcallback,
      outputArray = [],
      indexArray = {};

    if (typeof response === 'object') {
      let chiefArray = this.common.connect.getValueFromObjectByPath(
        pathToChiefArray,
        iNresponse
      );

      if (
        chiefArray &&
        typeof chiefArray === 'object' &&
        Array.isArray(chiefArray) &&
        chiefArray.length > 0
      ) {
        for (const ind in chiefArray) {
          const el = chiefArray[ind],
            val = <any>this.getValueByPath(pathToValue, el),
            resultObj = {};
          let img, extra;

          resultObj['full'] = el;

          resultObj['val'] = val;
          // add image if exist
          if (pathToImg) {
            img = this.common.connect.getValueFromObjectByPath(pathToImg, el);
            if (img) {
              resultObj['img'] = img;
            }
          }

          // add extra field if exist
          if (pathToImg) {
            extra = this.common.connect.getValueFromObjectByPath(
              pathToExtra,
              el
            );
            if (extra) {
              resultObj['extra'] = extra;
            }
          }
          // add full object
          resultObj['full'] = el;

          // save index for late access to this array
          this.saveIndexForLateAccess(val, ind, indexArray);

          // add to array with autocomplete value result
          outputArray[ind] = resultObj;
        }
        // add new array to output object
        if (typeof callback === 'function') {
          callback(outputArray, indexArray);
        }
      }

      // save last response for this field for
      this.saveAutocompleteResponseToFreeformObject(field, response);

      // set data to automplete hepler
      chiefArray = this.common.connect.getValueFromObjectByPath(
        pathToChiefArray,
        iNresponse
      );
    }
  }

  private getPathToChiefArray(
    field: FrfFieldAutocompleteObjectInterface
  ): string | null {
    if (
      field.body.payload.pathToArray &&
      typeof field.body.payload.pathToArray === 'string'
    ) {
      return field.body.payload.pathToArray;
    }
    return null;
  }

  private getPathToValue(
    field: FrfFieldAutocompleteObjectInterface
  ): string | null {
    if (
      field.body.payload.pathToValue &&
      typeof field.body.payload.pathToValue === 'string'
    ) {
      return field.body.payload.pathToValue;
    }
    return null;
  }

  private getPathToImg(
    field: FrfFieldAutocompleteObjectInterface
  ): string | null {
    if (
      field.body.payload.pathToImg &&
      typeof field.body.payload.pathToImg === 'string'
    ) {
      return field.body.payload.pathToImg;
    }
    return null;
  }

  private getPathToExtra(
    field: FrfFieldAutocompleteObjectInterface
  ): string | null {
    if (
      field.body.payload.pathToExtra &&
      typeof field.body.payload.pathToExtra === 'string'
    ) {
      return field.body.payload.pathToExtra;
    }
    return null;
  }

  public filter(option: any, options: any[]): string[] {
    const val = option['field'];
    return options.filter(item => {
      return item.val.toLowerCase().indexOf(val.toLowerCase()) === 0;
    });
  }
}
