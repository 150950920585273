import {
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainSharedService } from '../../@service/main-shared/main-shared.service';
import { MzModalWindowService } from 'mz-modal-window';
import { select, Store } from '@ngrx/store';
import {
  ConnectAppControllerInerface,
  ConnectAppControllerSelectors
} from '@cnt-nx-workspace/feature/app-controller';
import { SumSubComponent } from '../../../../../@view/lk/@sub/@view/sum-sub/sum-sub.component';
import { takeWhile } from 'rxjs/operators';
import {
  FastMenuInterface,
  MenuInterface
} from './@res/@interface/common.interface';
import { FastMenuTypeEnum } from './@res/@enum/common.enum';
import { timer } from 'rxjs';
import { FastMenuButtonInterface } from '@cnt-multi-shared/@shared/first-buttons/@res/@abstract/@interface/common.interface';
import { FastMenuBtnTypeEnum } from '@cnt-multi-shared/@shared/first-buttons/@res/@abstract/@enum/common.enum';

@Component({
  selector: 'base-app-header',
  templateUrl: './base-app-header.component.html',
  styleUrls: ['./base-app-header.component.scss']
})
export class BaseAppHeaderComponent implements OnInit, OnDestroy {
  /**
   * height css of header for mobile devices
   * */
  @Input() mobileHeaderHeight: string = '10rem';

  /**
   * height css of header for computer devices
   * */
  @Input() compHeaderHeight: string = '80px';

  /**
   * TODO delete later - title for base header
   * */
  @Input() title: string = 'Connect';

  /**
   * Menu list after click to three dot
   * */
  @Input() menus: MenuInterface[] = [
    { name: 'Верификация', id: 'default-virificate' },
    { name: 'Выход', id: 'default-sign-out' }
  ];

  /**
   * Fast menu list after click to three dot
   * */
  @Input() fastMenus: FastMenuButtonInterface[] = [];

  /**
   * Simple tabs list
   * */
  @Input() tabs: string[] = [];

  /**
   * Index for tab bar element
   * */
  @Input() selectedTabIndex: number = 0;

  /**
   * need show tab bar for mobile devices or not
   * */
  @Input() showTabBarForMobile: boolean = true;

  /**
   * need three dot for show menu list
   * */
  @Input() showThreeDotForMobile: boolean = true;

  /**
   * Emitter when changed menu
   * */
  @Output() changedMenu: EventEmitter<any> = new EventEmitter();

  /**
   * emit when we click to fast menu item
   * */
  @Output() onClickToFastMenu = new EventEmitter();

  /**
   * Emitter when change selected index
   * */
  @Output() selectedTabIndexChange: EventEmitter<number> = new EventEmitter();

  /**
   * Templates for tab bar
   * */
  @ContentChildren('tabTemplates') tabTemplates: TemplateRef<ElementRef>[] = [];

  /**
   * Part template with menu button
   * */
  @ContentChild('mobileTemplateWithMenuButton')
  mobileTemplateWithMenuButton: TemplateRef<ElementRef>;

  /**
   * Full template for mobile
   * */
  @ContentChild('mobileTemplateFull') mobileTemplateFull: TemplateRef<
    ElementRef
  >;

  public verificate = 0;
  public fastMenuTypeEnum = FastMenuBtnTypeEnum;
  private alive = true;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public mainSharedService: MainSharedService,
    private mzModalWindowService: MzModalWindowService,
    private store: Store<ConnectAppControllerInerface>
  ) {}

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  /**
   * Invoke when tab change - emit
   * */
  public changeTab(index: number) {
    this.selectedTabIndexChange.emit(index);
  }

  /*
   * change menu item
   * */
  public changeMenu(menuItem: any) {
    switch (menuItem.id) {
      case 'default-virificate':
        this.showPanelForSumSubVerification();
        break;
      case 'default-sign-out':
        this.mainSharedService.signOut();
        break;
    }
    this.changedMenu.emit(menuItem);
  }

  /*
   * подписка на изменения данных приложения
   * */
  public subscribe() {
    if (this.showThreeDotForMobile) {
      this.store
        .pipe(
          select(ConnectAppControllerSelectors.getCurrentUser),
          takeWhile(() => this.alive)
        )
        .subscribe(currentUser => {
          if (currentUser) {
            this.verificate = currentUser.rates.verification;
            if (this.verificate) {
              // this.menus = this.menus.filter(
              //     (menu) => menu.id !== 'default-virificate'
              // );
            }
          }
        });
    }
  }

  /*
   * show panel for sum sub verification
   * */
  public showPanelForSumSubVerification() {
    this.mzModalWindowService.open(
      SumSubComponent,
      {
        // add hide panel
        // hidePanel: false,
        // add any @Input data to component
        // roleId: -1,
        // roleName: '',
        // image: '',
        // entities: false,
      },
      //TODO later delete, set diference of create and edit
      'sub-sub-panel' // this.panelId
    );
  }

  /*
   * get height for mobile and computer
   * */
  public getHeightForHeader(): string {
    if (this.mainSharedService.isMobile()) {
      return this.mobileHeaderHeight;
    } else {
      return this.compHeaderHeight;
    }
  }

  /**
   * TODO add queryString later
   * */
  public openInnerLink(link: string, queryString?: { [key: string]: string }) {
    this.mainSharedService.showLoader();

    timer(100).subscribe(() => {
      this.router.navigate([link], { relativeTo: this.activatedRoute.parent });
    });
  }

  /*
   *
   * */
  public getClassColorId(idx: number, max = 7): string {
    const colorId = idx % max;
    return `id-${Math.ceil(colorId + 1)}`;
  }
}
