import { Component, Input, OnInit } from '@angular/core';
import { FlexyViewComponentOptionsMysqlItem } from '@cnt-multi-shared/@shared/@library/@group:flexy-view/component/@res/@interface/common.interface';
import { CntFlexyViewDepsForOwnerItemInterface } from '../../@service/cnt-flexy-view/@res/@interface/common.interface';

@Component({
  selector: 'cnt-flexy-view',
  templateUrl: './cnt-flexy-view-container.component.html',
  styleUrls: ['./cnt-flexy-view-container.component.scss']
})
export class CntFlexyViewContainerComponent implements OnInit {
  @Input() hash: string;
  @Input() backColor = 'transparent';
  @Input() version!: number;
  @Input() viewName!: string;
  @Input() owner: string;
  @Input() component: string;
  @Input() payload: any;
  @Input() options?: FlexyViewComponentOptionsMysqlItem;
  @Input() deps: CntFlexyViewDepsForOwnerItemInterface;
  @Input() theme: any;

  /**
   *
   * */
  public style: { [key: string]: string } = {};

  constructor() {}

  ngOnInit() {
    this.initStyles();
  }

  /**
   *
   * */
  private initStyles() {
    this.options = this.options || <any>{};

    const css = (this.options.css || '').split(';').reduce((base, item) => {
      const val = item.split(':');

      if (val[0] && val[1]) {
        base[val[0].trim()] = val[1].trim();
      }

      return base;
    }, {});

    this.style = {
      ...(css || {}),
      'background-color': this.options.backgroundColor,
      'padding-top': this.options.paddingTop || '0px',
      'padding-bottom': this.options.paddingBottom || '0px'
    };
  }
}
