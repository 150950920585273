import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MzOnlineService } from '../../@service/mz-online/mz-online.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, throttleTime } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[mzOnline]',
})
export class MzOnlineDirective implements OnInit, OnDestroy {
  /**
   * */
  @Output() mzOnlineState: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * */
  @Input() mzOnlineDebounce = 0;

  /**
   * */
  @Input() mzOnlineThrottle = 0;

  /**
   *
   * */
  private viewDestroy$ = new Subject();

  constructor(private mzOnlineService: MzOnlineService) {}

  ngOnInit(): void {
    this.mzOnlineService.flow$
      .pipe(
        debounceTime(this.mzOnlineDebounce),
        throttleTime(this.mzOnlineThrottle),
        takeUntil(this.viewDestroy$)
      )
      .subscribe((state) => {
        this.mzOnlineState.emit(state);
      });
  }

  ngOnDestroy(): void {
    this.viewDestroy$.next();
    this.viewDestroy$.complete();
  }
}
