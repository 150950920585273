<div class="left-side">
  <div class="top-slider-block" *ifScreenType="['medium', 'large']">
    <mat-tab-group
      (selectedIndexChange)="changeTab($event)"
      [(selectedIndex)]="selectedTabIndex"
    >
      <mat-tab [label]="tab" *ngFor="let tab of tabs"></mat-tab>
      <mat-tab *ngFor="let template of tabTemplates">
        <ng-template mat-tab-label>
          <ng-container [ngTemplateOutlet]="template"></ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-content></ng-content>
</div>
