import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FreeformService } from '../../../@res/shared/service/freeform/freeform.service';
import { FreeformCommonService } from '../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FreeformModel } from '../../../@res/shared/service/freeform/model/freeform.model';
import { ActivatedRoute } from '@angular/router';
import { MzCacheClass } from 'mz-cache-decorator';
import { MzCacheFreeformGroupEnum } from './@res/@abstract/@enum/common.enum';
import { FrfValueSetterBlockerService } from '../../@service/@group:value-services/frf-value-setter/@sub/frf-value-setter-blocker/frf-value-setter-blocker.service';
import { FrfTriggerService } from '../../@service/frf-trigger/frf-trigger.service';
import { FrfStorageService } from '../../@service/@storage/frf-storage/frf-storage.service';

@Component({
  selector: 'frf',
  templateUrl: './freeform.component.html',
  styleUrls: ['./freeform.component.scss'],
  providers: []
})
export class FreeformComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('body', { read: ViewContainerRef }) body;
  @ViewChild('stepper', { read: ViewContainerRef }) stepper2;

  //input params
  @Input() userId: string;
  @Input() modelId: string;
  @Input() formId: string = null;
  // @Input() initialPageNumber: number;

  public loaderViewFlag = true;
  public bodyViewFlag = false;
  public freeform = null;

  constructor(
    private freeformService: FreeformService,
    private activatedRoute: ActivatedRoute,
    private frfValueSetterBlocker: FrfValueSetterBlockerService,
    private frfStorageService: FrfStorageService,
    private frfTrigger: FrfTriggerService
  ) {}

  ngOnChanges(iNchanges: SimpleChanges) {
    if (
      !iNchanges['modelId'] &&
      iNchanges['formId'] &&
      iNchanges['formId'].previousValue === undefined &&
      !iNchanges['formId'].firstChange
    ) {
      return true;
    }
    /* create form models */
    // this.openPageByIdAndUid ( this.userId, this.modelId, this.formId );
  }

  ngOnInit() {
    // this.activatedRoute.queryParams.subscribe(
    //     (params: {formid: string}) => {
    //       this.formId = params.formid;
    //       /* create form models */
    //       // this.openPageByIdAndUid ( this.userId, this.modelId, this.formId );
    //     }
    // );

    const params: { formid: string } = <any>(
      this.activatedRoute.snapshot.queryParams
    );
    this.formId = params.formid;
    /* create form models */
    this.openPageByIdAndUid(this.userId, this.modelId, this.formId);
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    /* clear frf element blocker*/
    this.frfValueSetterBlocker.destroy();

    /* safe clear extra freeform style and script early added */
    this.freeformService.clearAddedExternalData();

    /*
     * clear cache
     * */
    MzCacheClass.clearByGroup(MzCacheFreeformGroupEnum.freeform);

    /**
     * destoy trigger flows
     * TODO move destroy to frf-elements later
     * */
    this.frfTrigger.destroyFlows();

    this.frfStorageService.clear();
  }

  //@< loader
  /**
   *
   * */
  public hideLoader() {
    this.loaderViewFlag = false;
  }
  /**
   *
   * */
  public showLoader() {
    this.loaderViewFlag = true;
  }
  //@> loader

  //@< content
  /**
   *
   * */
  public showContent() {
    this.bodyViewFlag = true;
  }
  /**
   *
   * */
  public hideContent() {
    this.bodyViewFlag = false;
  }
  //@> content

  /**
   * получить форму
   * */
  public openPageByIdAndUid(
    uid: string,
    modelId: string,
    formId: string = null
  ) {
    this.showLoader();
    this.hideContent();

    /* anulater invoke counter (may be show with loader when form loading) */
    FreeformCommonService.invokeCountertimerIdForDownloadControl = 0;

    this.freeformService.getPageByIdAndUid(uid, modelId, formId).subscribe(
      (form: FreeformModel) => {
        /* set freeform -> view form */
        this.freeform = form;
        this.freeform.downloaded = false;

        /* if need add extra form styles */
        this.freeformService.safeInitExtraStylesIfNeed(this.freeform);

        //add angular form LATER add for ever form (multi form) not single form
        /*if (typeof  this.freeform['angular'] !== 'object' ) {this.freeform['angular'] = {}; }
        this.freeform['angular']['form'] = this.form;*/

        /* set freeform -> view form */
        this.hideLoader();
        this.showContent();
      },
      err => {
        console.warn('getPageByIdAndUid err', err);
        /* we have not access to this form or this form not exist -> return error */
        this.freeform = {
          error: 'Форма не существует или у Вас  нет доступа к этой форме.',
          destroyed: true
        };
      }
    );
  }
}
