import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {UserFirestoreService} from '../../../../../../../../../../shared/@sub/@service/user-firestore/user-firestore.service';
import {ConnectAuthService} from '@cnt-nx-workspace/feature/auth';
import {NavigateService} from '../../../../../../../../@service/navigate/navigate.service';
import {takeUntil} from 'rxjs/operators';
import {CntFlexyViewHeaderMenuInterface} from './@sub/@view/cnt-flexy-view-header-menu/@res/@abstract/@interface/common.interface';
import {CntFlexyViewHeaderMenuEnum} from './@sub/@view/cnt-flexy-view-header-menu/@res/@abstract/@enum/common.enum';
import {SumSubComponent} from '../../../../../../../../../../../@view/lk/@sub/@view/sum-sub/sum-sub.component';
import {MatDialog} from '@angular/material/dialog';
import {
  CntFlexyViewCategoryForOwnerItemInterface,
  CntFlexyViewCategoryService,
  CntFlexyViewService
} from '@cnt-nx-workspace/feature/cnt-flexy-view';
import {AbstractUnsubscribeViewControl} from '@cnt-nx-workspace/function/shared/base';
import {FlexyViewCategoryTypeEnum} from '@cnt-multi-shared/@shared/@library/@group:flexy-view/@res/@enum/common.enum';
import {MainSearchService} from "../../../../../../../../../../../@service/main-search/main-search.service";
import {ScreenDetectorService} from "mz-screen-detector";
import {CntPubSubMessageService} from "@cnt-nx-workspace/feature/cnt-pub-sub-message";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends AbstractUnsubscribeViewControl
  implements OnInit {

  /**
   * */
  @Input() logo!: string;

  /**
   * */
  @Input() categories!: CntFlexyViewCategoryForOwnerItemInterface[];

  /**
   * for show search modal window by press hot keys
   * */
  @HostListener('document:keyup', ['$event']) keyup(e) {
    if (this.mainSearchService.isPressHotKeysToShowModal(e)) {
      this.showSearchModalWindow();
    }
  }

  /**
   *
   * */
  private fullHeaderMenus: CntFlexyViewHeaderMenuInterface[] = [
    { name: 'Войти', type: CntFlexyViewHeaderMenuEnum.signIn },
    { name: 'Выйти', type: CntFlexyViewHeaderMenuEnum.signOut },
    { name: 'Верификация', type: CntFlexyViewHeaderMenuEnum.verificate }
  ];

  /**
   *
   * */
  public headerMenus: CntFlexyViewHeaderMenuInterface[] = [];

  constructor(
    private connectAuthService: ConnectAuthService,
    private cntFlexyViewService: CntFlexyViewService,
    private userFirestore: UserFirestoreService,
    private cntFlexyViewCategory: CntFlexyViewCategoryService,
    private navigateService: NavigateService,
    private pubnubService: CntPubSubMessageService,
    public dialog: MatDialog,
    public mainSearchService: MainSearchService,
    public screenDetectorService: ScreenDetectorService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  private init()
  {
    this.initMenus();
    this.cntFlexyViewService
      .getChangeInViewDetection$()
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(data => {
      });
    this.initSearchFromModal();
  }

  /**
   *
   * */
  public initSearchFromModal() {
    this.mainSearchService
      .initSearch()
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe();
  }

  /**
   *
   * */
  private initMenus() {
    this.userFirestore
      .getMyUser()
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(user => {
        const id = user && user.id;
        this.headerMenus = this.fullHeaderMenus.filter(menu =>
          id
            ? menu.type !== CntFlexyViewHeaderMenuEnum.signIn
            : menu.type !== CntFlexyViewHeaderMenuEnum.signOut
        );
        this.cdRef.markForCheck();
      });
  }

  /**
   * */
  public clickedHeaderMenu(menu: CntFlexyViewHeaderMenuInterface) {
    switch (menu.type) {
      case CntFlexyViewHeaderMenuEnum.signIn:
        this.navigateService.openSignPage();
        break;

      case CntFlexyViewHeaderMenuEnum.verificate:
        this.openForVerification();
        break;

      case CntFlexyViewHeaderMenuEnum.signOut:
        this.connectAuthService
          .signOut()
          .pipe(takeUntil(this.viewDestroy$))
          .subscribe(() => {
            this.pubnubService.unsubscribe();
            this.navigateService.openSignPage();
          });
        break;
    }
  }

  /**
   *
   * */
  public openForVerification() {
    const dialogRef = this.dialog
    .open(
      SumSubComponent,
      {
        data: { name: '' }
      }
    );
    dialogRef.afterClosed().subscribe(result => {});
  }

  /**
   *
   * */
  public getLogo() {
    return this.logo ? this.logo : 'https://cdn.ramman.net/users/cnt/logo/cnt-logo.png';
  }

  /**
   *
   * */
  private scrollByHash(hash: string) {
    this.cntFlexyViewCategory.goToAppByHash(hash);
  }

  /**
   *
   * */
  public clickToMenu(item: CntFlexyViewCategoryForOwnerItemInterface) {
    if (!item?.value) {
      return;
    }

    switch (item.type) {
      case FlexyViewCategoryTypeEnum.externalLink:
        window.open(item.value, '_blank');
      break;

      case FlexyViewCategoryTypeEnum.hash:
        this.scrollByHash(item.value)
      break;
    }
  }

  /**
   *
   * */
  public showSearchModalWindow()
  {
    this.mainSearchService.show();
  }

  public updateView () {
    this.cdRef.markForCheck()
  }
}
