import { NgModule } from '@angular/core';
import { MzCameraModule } from './@sub/mz-camera/mz-camera.module';

@NgModule({
  imports: [
    // CameraRoutingModule,
    MzCameraModule
  ],
  exports: [MzCameraModule]
})
export class CameraModule {}
