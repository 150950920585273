import {ConnectAppControllerInerface} from '../@res/@abstract/@interface/app/connect-app-controller.inerface';
import {AppsIdEnum, ConnectAppControllerActionsEnum} from '../@res/@abstract/@enums/app/apps-id.enum';
import {AngularAppIdEnum} from '../@res/@abstract/@enums/app/angular-app-id.enum';
import {SwitchAppReducerAction} from './states/actions/apps/switchApp/switch-app.reducer.action';
import {SetMainUserReducerAction} from "./states/actions/user/setMainUser/set-main-user.reducer.action";

export const initialState: ConnectAppControllerInerface = {
  auth: {
    currentUser: {
      type: null,
      id: null,
      name: null,
      phone: null,
      login: null,
      rates: {
        verification: null
      },
      pseudoUsers: []
    },
    users: []
  },
  apps: {
    storage: {
      [AppsIdEnum.chat]: {
        name: 'Chat',
        id: AppsIdEnum.chat
      },
      [AppsIdEnum.sharepay]: {
        name: 'Sharepay',
        id: AppsIdEnum.sharepay
      },
      [AppsIdEnum.onepay]: {
        name: 'Onepay',
        id: AppsIdEnum.onepay
      },
      [AppsIdEnum.market]: {
        name: 'Market',
        id: AppsIdEnum.market
      },
      [AppsIdEnum.edocument]: {
        name: 'Edocument',
        id: AppsIdEnum.edocument
      }
    },
    list: [
      { id: AppsIdEnum.chat, weight: 1 },
      { id: AppsIdEnum.sharepay, weight: 2 },
      { id: AppsIdEnum.onepay, weight: 3 },
      { id: AppsIdEnum.market, weight: 4 },
      { id: AppsIdEnum.edocument, weight: 5 }
    ],
    currentId: AppsIdEnum.chat
  },
  angularAppId: AngularAppIdEnum.web
};

export function connectAppControllerReducer(
  state: ConnectAppControllerInerface = initialState,
  action: { payload: any; type: ConnectAppControllerActionsEnum }
): ConnectAppControllerInerface {

  switch (action.type) {
    case ConnectAppControllerActionsEnum.appSwitchApp:
      state = SwitchAppReducerAction(state, action);
    break;
    case ConnectAppControllerActionsEnum.setMainUser:
      state = SetMainUserReducerAction(state, action.payload);
    break;
  }

  return state;
}
