import { ElementRef, Injectable } from '@angular/core';
import { forkJoin, Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';
import { FreeformService } from '../../../../../../../../../../../../../../../../../../@res/shared/service/freeform/freeform.service';
import { FrfCustomFieldNamespace } from './@res/@namespace/common.namespace';
import { FrfCustomFieldApiLayoutService } from './@sub/frf-custom-field-api-layout/frf-custom-field-api-layout.service';
import { FrfMainLazyServiceModule } from '../../../../../../../../../../../../../../../../../../frf-main-lazy-service.module';
import { FrfCustomFieldOutputMethodEnum } from './@res/@abstract/@enum/common.enum';
import { ChangePayloadOutputInterface } from './@res/@abstract/@interface/common.interface';
import { fromPromise } from 'rxjs/internal-compatibility';

/**
 * для эмита через глобальный frf-root service
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule,
})
export class FrfCustomFieldWrapperService {
  /**
   * TODO later move on back (components)
   * */
  private readonly components = [
    'sharepay/wc-frf-purchase-table',
    'sharepay/wc-frf-purchase-table@1.0.0',
    'sharepay/wc-frf-sharepay-payments',
    'sharepay/wc-frf-sharepay-payments@1.0.0',
    'sharepay/wc-frf-sharepay-invoice',
    'sharepay/wc-frf-sharepay-invoice@1.0.0',
  ];

  /**
   * base source href
   * */
  private readonly baseSourceHref =
    'https://cdn.ramman.net/frf/custom-component/';

  constructor(
    private frfService: FreeformService,
    // private frfApiSetFieldValue: FrfApiSetFieldValueService,
    private frfCustomFieldApiLayout: FrfCustomFieldApiLayoutService
  ) {}

  /**
   *
   * */
  private getBaseUrlToComponent(
    href: string,
    version: string,
    external = ''
  ): string {
    return this.baseSourceHref + href + '/' + version + '/' + external;
  }

  /**
   *
   * */
  private checkComponent(id: string): boolean {
    return this.components.indexOf(id) !== -1;
  }

  /**
   * add style and script
   * */
  private joinComponentResources(
    id: string,
    version: string
  ): Observable<boolean> {
    const linkToCss = this.getBaseUrlToComponent(id, version, 'main.css'),
      linkToJs = this.getBaseUrlToComponent(id, version, 'main.js');

    console.log('joinComponentResources', {
      id,
      version,
      linkToCss,
      linkToJs,
    });

    return forkJoin([
      this.frfService.addStyleByUrlIfNotExist(linkToCss),
      this.frfService.addScriptByUrlIfNotExist(linkToJs),
    ]).pipe(
      map((result) => {
        return !result.find((data) => !data);
      })
    );
  }

  /**
   *
   * */
  private getVersionFromHref(
    href: string
  ): { version: string; withoutVersion: string } {
    const versionData = href.match(/\@[^\@]+$/g),
      version =
        (versionData &&
          versionData[0] &&
          versionData[0].replace(/[\@]+/g, '')) ||
        '1.0.0';

    return {
      version,
      withoutVersion: href.replace(/@[^\@]*/g, ''),
    };
  }

  /**
   *
   * */
  public initComponent(
    parentElement: ElementRef<HTMLElement>,
    hrefWithVersion: string,
    field: any,
    changeStatus: (status: boolean) => void,
    changeValue: (value: any) => void,
    changePayload: (value: ChangePayloadOutputInterface) => void,
    elementRef: (element: any) => void
  ): Observable<boolean> {
    const { version, withoutVersion } = this.getVersionFromHref(
      hrefWithVersion
    );

    const id = withoutVersion.split('/').reverse().join('-');

    console.log('frf initComponent 1 ', {
      id,
      version,
      withoutVersion,

      parentElement,
      hrefWithVersion,
      field,
      changeStatus,
      changeValue,
      changePayload,
      elementRef,
    });

    if (!this.checkComponent(withoutVersion)) {
      console.error(`frf-custom-component - can not find component <${id}>`);
      return of(false);
    }

    console.log('frf initComponent 2 ', {
      withoutVersion,
      version,
    });

    return this.joinComponentResources(withoutVersion, version).pipe(
      switchMap((result) => {
        console.log('frf initComponent 3 ', {
          withoutVersion,
          version,
          result,
        });
        if (!result) {
          console.error(
            `frf-custom-component - can not get styles or scripts for <${id}>`
          );
          return of(false);
        } else {
          return this.joinComponentToDom(
            parentElement,
            id,
            version,
            field,
            changeStatus,
            changeValue,
            changePayload,
            elementRef
          );
        }
      })
    );
  }

  /**
   *
   * */
  private joinComponentToDom(
    parentElement: ElementRef<HTMLElement>,
    id: string,
    version: string,
    field: any,
    changeStatus: (status: boolean) => void,
    changeValue: (value: any) => void,
    changePayload: (value: ChangePayloadOutputInterface) => void,
    elementRef: (element: any) => void
  ): Observable<boolean> {
    const customElementFullNameWithVersion = this.getCustomElementNameWithVersion(
      id,
      version
    );
    console.log('frf joinComponentToDom [1] ', {
      customElementFullNameWithVersion,
    });

    return timer(500).pipe(
      switchMap(() =>
        fromPromise(
          customElements.whenDefined(customElementFullNameWithVersion)
        ).pipe(timeout(5000))
      ),
      catchError((err) => {
        console.log('frf joinComponentToDom [1.5] ', {
          customElementFullNameWithVersion,
          err,
        });
        return of(null);
      }),
      switchMap((r: any) => {
        // customElements.whenDefined(customElementFullNameWithVersion).then(r => {
        console.log('frf joinComponentToDom [2] ', {
          r,
          id,
          customElementFullNameWithVersion,
        });

        return this.joinComponentToDomSimple(
          parentElement,
          id,
          customElementFullNameWithVersion,
          field,
          changeStatus,
          changeValue,
          changePayload,
          elementRef
        );
        // });
      })
    );
  }

  private getCustomElementNameWithVersion(id: string, version: string) {
    return `${id}_${('v' + version).replace(/[\.]+/g, '-')}`;
  }

  /**
   *
   * */
  private joinComponentToDomSimple(
    parentElement: ElementRef<HTMLElement>,
    id: string,
    customElementFullNameWithVersion: string,
    field: any,
    changeStatus: (status: boolean) => void,
    changeValue: (value: any) => void,
    changePayload: (value: ChangePayloadOutputInterface) => void,
    elementRef: (element: any) => void
  ): Observable<boolean> {
    console.log('frf joinComponentToDomSimple 1 ', {
      id,
      customElementFullNameWithVersion,
    });
    const customElement = document.createElement(
      customElementFullNameWithVersion
    );

    // @ts-ignore
    customElement.field = field;
    // @ts-ignore
    customElement.frfApi = this.frfCustomFieldApiLayout;

    customElement.addEventListener(
      FrfCustomFieldOutputMethodEnum.changeStatus,
      (status: CustomEvent<boolean>) => {
        if (typeof changeStatus === 'function') changeStatus(status.detail);
      }
    );

    customElement.addEventListener(
      FrfCustomFieldOutputMethodEnum.changeValue,
      (value: CustomEvent<any>) => {
        if (typeof changeStatus === 'function') changeValue(value.detail);
      }
    );

    customElement.addEventListener(
      FrfCustomFieldOutputMethodEnum.changePayload,
      (value: CustomEvent<any>) => {
        if (typeof changePayload === 'function') changePayload(value.detail);
      }
    );

    if (typeof elementRef === 'function') {
      elementRef(customElement);
    }

    parentElement.nativeElement.appendChild(customElement);

    return of(true);
  }

  /**
   *
   * */
  public isCustomComponentType(idWithPrefix: string): boolean {
    return FrfCustomFieldNamespace.isCustomComponentType(idWithPrefix);
  }

  /**
   *
   * */
  public getClearIdWithoutPrefix(idWithPrefix: string): string {
    return idWithPrefix.replace(FrfCustomFieldNamespace.idPrefix, '');
  }
}
