import {
  Directive,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

/*
 * TODO create file-drop directive as npm module
 * */
@Directive({
  selector: '[file-drop]'
})
export class FileDropDirective implements OnInit, OnDestroy {
  @Output() filesDropped = new EventEmitter<File[]>();
  @Output() filesHovered = new EventEmitter<boolean>();

  private onHover$: Subject<boolean> = new Subject();
  private alive$ = new Subject();

  constructor() {}

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();

    const transfer = $event.dataTransfer;

    this.filesDropped.emit(transfer.files);
    this.onHover$.next(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    event.preventDefault();

    this.onHover$.next(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    this.onHover$.next(false);
  }

  ngOnInit(): void {
    this.onHover$
      .pipe(
        takeUntil(this.alive$),
        distinctUntilChanged()
      )
      .subscribe(state => {
        this.filesHovered.emit(state);
      });
  }

  ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.unsubscribe();
  }
}
