export const GetRequestForShopMock = [
  {
    request_state: 0,
    bank: '0672d294-a5f0-4c72-8796-1e26f822fc50',
    shop: '0672d294-a5f0-4c72-8796-1e26f822fc50',

    id: 'SUI73vlAm0Pnb4LILYDY',
    author: 'no-riba',
    shop_id: 'none',
    paid_sum: 42490,
    notify: 1,
    received_cashback: 717,
    current_invoice_group: 1,
    max_expected_markup: 0,
    actual_total_markup: 0,
    price: 97940,
    max_cashback: 2870,
    cost_price: 73000,
    sold_out: '1577998800000',
    invoice_nearest_payment_date: '1588453200000',
    nearest_payment_date: '1588453200000',
    nearest_payment_sum: 3490,
    created: '1579768542000',
    name: 'Apple Iphone 11 Pro Midnight Green, 64GB, MWCL2LL/A',
    first_contribution: 20000,
    client_count: 1,
    guarantor_count: 1,
    bank_count: 1,
    active: 1,
    simpleGeneratedDocuments: [
      {
        path:
          'edocument/ad390526-3abf-41ad-ab42-8a6859c484b5/Договор купли продажи-5.pdf',
        fileName: 'Договор купли продажи-5.pdf'
      },
      {
        path:
          'edocument/a3008305-0ef9-4494-b20a-3657aa18c66d/Договор поручительства-5.pdf',
        fileName: 'Договор поручительства-5.pdf'
      }
    ],
    products: [
      {
        name: 'Apple Iphone 11 Pro Midnight Green, 64GB, MWCL2LL/A',
        images: null,
        cost_price: 0,
        price: 97940,
        amount: 1
      }
    ],
    state: 1,
    status: 'new',
    clients: ['0672d294-a5f0-4c72-8796-1e26f822fc50'],
    guarantors: ['0672d294-a5f0-4c72-8796-1e26f822fc50'],
    banks: ['no-riba'],
    usersData: {
      '0672d294-a5f0-4c72-8796-1e26f822fc50': {
        uid: '0672d294-a5f0-4c72-8796-1e26f822fc50',
        name: 'TownMoney',
        firstname: 'Магомед',
        middlename: 'Сулайманович',
        lastname: 'Хакимов',
        phones: ['88002003565']
      }
    },
    paid_sum_invoice: 42490,
    remain_sum_invoice: 55450,
    days_count: 366,
    expired_count_invoice: 4,
    expired_sum_invoice: 22975,
    start_expired_time_invoice: 1588453200000,
    next_invoice_time: 1599080400000,
    next_invoice: 6495,
    expired_sum_paid_days: 66,
    expired_paid_invoice_count: 3,
    count_not_fully_paid_invoice: 9,
    count_invoice: 13,
    last_invoice_time: 1609621200000,
    first_invoice_time: 1577998800000,
    last_full_paid_time: 1586809950000,
    profit: 24940,
    comment: null,
    profit_percent: 31.999,
    day_percent: 0.08742889,
    year_percent: 31.911545,
    month_percent: 2.6228666,
    remainDays: -102,
    remainDaysColor: 'rgba(255,64,43,0.5)'
  },
  {
    request_state: 1,
    bank: '94d48aa3-f795-4f26-8a61-a10f92965a28',
    shop: '94d48aa3-f795-4f26-8a61-a10f92965a28',

    id: 'fjLmIN1PljOnD3MN4Mw3',
    author: 'no-riba',
    shop_id: 'none',
    paid_sum: 40614,
    notify: 1,
    received_cashback: 822,
    current_invoice_group: 1,
    max_expected_markup: 0,
    actual_total_markup: 0,
    price: 111988,
    max_cashback: 3290,
    cost_price: 80000,
    sold_out: '1577566800000',
    invoice_nearest_payment_date: '1588107600000',
    nearest_payment_date: '1591768800000',
    nearest_payment_sum: 3382,
    created: '1579629641000',
    name: 'Apple Iphone 11 pro max 64 gb',
    first_contribution: 10000,
    client_count: 1,
    guarantor_count: 1,
    bank_count: 1,
    active: 1,
    simpleGeneratedDocuments: [
      {
        path:
          'edocument/f5166a68-ab80-4b6f-a7c6-72df9c00d47c/Договор купли продажи-8.pdf',
        fileName: 'Договор купли продажи-8.pdf'
      },
      {
        path:
          'edocument/af743818-e638-4c98-be03-4a3101b727f9/Договор поручительства-8.pdf',
        fileName: 'Договор поручительства-8.pdf'
      }
    ],
    products: [
      {
        name: 'Apple Iphone 11 pro max 64 gb',
        images: null,
        cost_price: 0,
        price: 111988,
        amount: 1
      }
    ],
    state: 1,
    status: 'new',
    clients: ['94d48aa3-f795-4f26-8a61-a10f92965a28'],
    guarantors: ['0672d294-a5f0-4c72-8796-1e26f822fc50'],
    banks: ['no-riba'],
    usersData: {
      '94d48aa3-f795-4f26-8a61-a10f92965a28': {
        name: 'FinTown',
        uid: '94d48aa3-f795-4f26-8a61-a10f92965a28',
        phones: ['79288002010'],
        firstname: 'Алия',
        middlename: 'Асламбековна',
        lastname: 'Кимаева'
      },
      '0672d294-a5f0-4c72-8796-1e26f822fc50': {
        uid: '0672d294-a5f0-4c72-8796-1e26f822fc50',
        firstname: 'Магомед',
        middlename: 'Сулайманович',
        lastname: 'Хакимов',
        phones: []
      }
    },
    paid_sum_invoice: 40614,
    remain_sum_invoice: 71374,
    days_count: 366,
    expired_count_invoice: 4,
    expired_sum_invoice: 28879,
    start_expired_time_invoice: 1588107600000,
    next_invoice_time: 1598648400000,
    next_invoice: 8499,
    expired_sum_paid_days: 62,
    expired_paid_invoice_count: 2,
    count_not_fully_paid_invoice: 9,
    count_invoice: 13,
    last_invoice_time: 1609189200000,
    first_invoice_time: 1577566800000,
    last_full_paid_time: 1589379460000,
    profit: 31988,
    comment: null,
    profit_percent: 31.3645,
    day_percent: 0.08569528,
    year_percent: 31.278778,
    month_percent: 2.5708585,
    remainDays: -64,
    remainDaysColor: 'rgba(255,64,43,0.5)'
  },
  {
    request_state: 2,
    bank: '94d48aa3-f795-4f26-8a61-a10f92965a28',
    shop: '94d48aa3-f795-4f26-8a61-a10f92965a28',

    id: 'EIxwrvZAvdZLUGawWgue',
    author: 'no-riba',
    shop_id: 'none',
    paid_sum: 38140,
    notify: 1,
    received_cashback: 786,
    current_invoice_group: 1,
    max_expected_markup: 0,
    actual_total_markup: 0,
    price: 107680,
    max_cashback: 3150,
    cost_price: 77000,
    sold_out: '1581454800000',
    invoice_nearest_payment_date: '1591909200000',
    nearest_payment_date: '1591909200000',
    nearest_payment_sum: 4420,
    created: '1584282489000',
    name: 'Apple Iphone 11 Pro Midnight Green, 64GB, MWCL2LL/A',
    first_contribution: 10000,
    client_count: 1,
    guarantor_count: 1,
    bank_count: 1,
    active: 1,
    simpleGeneratedDocuments: [
      {
        path:
          'edocument/daf2b6af-58d2-47e3-a01d-46d070597383/Договор купли продажи-2.pdf',
        fileName: 'Договор купли продажи-2.pdf'
      },
      {
        path:
          'edocument/d03abbf2-73c7-44d6-95c8-89ad9d3fd6a8/Договор поручительства-2.pdf',
        fileName: 'Договор поручительства-2.pdf'
      }
    ],
    products: [
      {
        name: 'Apple Iphone 11 Pro Midnight Green, 64GB, MWCL2LL/A',
        images: null,
        cost_price: 0,
        price: 107680,
        amount: 1
      }
    ],
    state: 1,
    status: 'new',
    clients: ['94d48aa3-f795-4f26-8a61-a10f92965a28'],
    guarantors: ['0672d294-a5f0-4c72-8796-1e26f822fc50'],
    banks: ['no-riba'],
    usersData: {
      '94d48aa3-f795-4f26-8a61-a10f92965a28': {
        name: 'DidaRiba',
        uid: '94d48aa3-f795-4f26-8a61-a10f92965a28',
        phones: ['88002005099'],
        firstname: 'Муса',
        middlename: 'Русланович',
        lastname: 'Илесов'
      },
      '0672d294-a5f0-4c72-8796-1e26f822fc50': {
        uid: '0672d294-a5f0-4c72-8796-1e26f822fc50',
        firstname: 'Магомед',
        middlename: 'Сулайманович',
        lastname: 'Хакимов',
        phones: []
      }
    },
    paid_sum_invoice: 38140,
    remain_sum_invoice: 69540,
    days_count: 366,
    expired_count_invoice: 3,
    expired_sum_invoice: 20700,
    start_expired_time_invoice: 1591909200000,
    next_invoice_time: 1599858000000,
    next_invoice: 8140,
    expired_sum_paid_days: 139,
    expired_paid_invoice_count: 3,
    count_not_fully_paid_invoice: 9,
    count_invoice: 13,
    last_invoice_time: 1613077200000,
    first_invoice_time: 1581454800000,
    last_full_paid_time: 1592982275000,
    profit: 30680,
    comment: null,
    profit_percent: 31.4087,
    day_percent: 0.08581607,
    year_percent: 31.322865,
    month_percent: 2.574482,
    remainDays: -62,
    remainDaysColor: 'rgba(255,64,43,0.5)'
  },
  {
    request_state: 3,
    bank: '94d48aa3-f795-4f26-8a61-a10f92965a28',
    shop: '0672d294-a5f0-4c72-8796-1e26f822fc50',

    id: '1RGzLtI6AQuMpl6Lmudt',
    author: 'no-riba',
    shop_id: 'none',
    paid_sum: 63768,
    notify: 1,
    received_cashback: 1545,
    current_invoice_group: 1,
    max_expected_markup: 0,
    actual_total_markup: 0,
    price: 126304,
    max_cashback: 3710,
    cost_price: 90000,
    sold_out: '1577912400000',
    invoice_nearest_payment_date: '1593637200000',
    nearest_payment_date: '1593637200000',
    nearest_payment_sum: 4384,
    created: '1579800960000',
    name: 'Apple Iphone 11 pro max 256GB',
    first_contribution: 10000,
    client_count: 1,
    guarantor_count: 1,
    bank_count: 1,
    active: 1,
    simpleGeneratedDocuments: [
      {
        path:
          'edocument/239219a7-9102-4d9d-a23b-0bb2f6727c54/Договор купли продажи-3.pdf',
        fileName: 'Договор купли продажи-3.pdf'
      },
      {
        path:
          'edocument/4313ff45-3699-4e09-8efe-9d6cd805df82/Договор поручительства-3.pdf',
        fileName: 'Договор поручительства-3.pdf'
      }
    ],
    products: [
      {
        name: 'Apple Iphone 11 pro max 256GB',
        images: null,
        cost_price: 0,
        price: 126304,
        amount: 1
      }
    ],
    state: 1,
    status: 'new',
    clients: ['94d48aa3-f795-4f26-8a61-a10f92965a28'],
    guarantors: ['0672d294-a5f0-4c72-8796-1e26f822fc50'],
    banks: ['no-riba'],
    usersData: {
      '94d48aa3-f795-4f26-8a61-a10f92965a28': {
        uid: '94d48aa3-f795-4f26-8a61-a10f92965a28',
        name: 'No-riba',
        phones: ['79291234567'],
        firstname: 'Хамид',
        middlename: 'Алиевич',
        lastname: 'Идрисов'
      },
      '0672d294-a5f0-4c72-8796-1e26f822fc50': {
        uid: '0672d294-a5f0-4c72-8796-1e26f822fc50',
        name: 'ТЦ "Мирзаев"',
        firstname: 'Магомед',
        middlename: 'Сулайманович',
        lastname: 'Хакимов',
        phones: ['79291234567']
      }
    },
    paid_sum_invoice: 63768,
    remain_sum_invoice: 62536,
    days_count: 366,
    expired_count_invoice: 2,
    expired_sum_invoice: 14076,
    start_expired_time_invoice: 1593637200000,
    next_invoice_time: 1598994000000,
    next_invoice: 9692,
    expired_sum_paid_days: 171,
    expired_paid_invoice_count: 5,
    count_not_fully_paid_invoice: 7,
    count_invoice: 13,
    last_invoice_time: 1609534800000,
    first_invoice_time: 1577912400000,
    last_full_paid_time: 1596810636000,
    profit: 36304,
    comment: null,
    profit_percent: 31.2147,
    day_percent: 0.0852862,
    year_percent: 31.129461,
    month_percent: 2.558586,
    remainDays: -42,
    remainDaysColor: 'rgba(255,64,43,0.5)'
  },
  {
    request_state: 4,
    bank: '94d48aa3-f795-4f26-8a61-a10f92965a28',
    shop: '0672d294-a5f0-4c72-8796-1e26f822fc50',

    id: '1RGzLtI6AQuMpl6Lmudt',
    author: 'no-riba',
    shop_id: 'none',
    paid_sum: 63768,
    notify: 1,
    received_cashback: 1545,
    current_invoice_group: 1,
    max_expected_markup: 0,
    actual_total_markup: 0,
    price: 126304,
    max_cashback: 3710,
    cost_price: 90000,
    sold_out: '1577912400000',
    invoice_nearest_payment_date: '1593637200000',
    nearest_payment_date: '1593637200000',
    nearest_payment_sum: 4384,
    created: '1579800960000',
    name: 'Apple Iphone 11 pro max 256GB',
    first_contribution: 10000,
    client_count: 1,
    guarantor_count: 1,
    bank_count: 1,
    active: 1,
    simpleGeneratedDocuments: [
      {
        path:
          'edocument/239219a7-9102-4d9d-a23b-0bb2f6727c54/Договор купли продажи-3.pdf',
        fileName: 'Договор купли продажи-3.pdf'
      },
      {
        path:
          'edocument/4313ff45-3699-4e09-8efe-9d6cd805df82/Договор поручительства-3.pdf',
        fileName: 'Договор поручительства-3.pdf'
      }
    ],
    products: [
      {
        name: 'Apple Iphone 11 pro max 256GB',
        images: null,
        cost_price: 0,
        price: 126304,
        amount: 1
      }
    ],
    state: 1,
    status: 'new',
    clients: ['94d48aa3-f795-4f26-8a61-a10f92965a28'],
    guarantors: ['0672d294-a5f0-4c72-8796-1e26f822fc50'],
    banks: ['no-riba'],
    usersData: {
      '94d48aa3-f795-4f26-8a61-a10f92965a28': {
        uid: '94d48aa3-f795-4f26-8a61-a10f92965a28',
        name: 'BigMoney',
        phones: ['79631234567'],
        firstname: 'Иса',
        middlename: 'Аптиевич',
        lastname: 'Магомаев'
      },
      '0672d294-a5f0-4c72-8796-1e26f822fc50': {
        uid: '0672d294-a5f0-4c72-8796-1e26f822fc50',
        name: 'ТЦ "Киргу"',
        firstname: 'Магомед',
        middlename: 'Сулайманович',
        lastname: 'Хакимов',
        phones: ['79631234567']
      }
    },
    paid_sum_invoice: 63768,
    remain_sum_invoice: 62536,
    days_count: 366,
    expired_count_invoice: 2,
    expired_sum_invoice: 14076,
    start_expired_time_invoice: 1593637200000,
    next_invoice_time: 1598994000000,
    next_invoice: 9692,
    expired_sum_paid_days: 171,
    expired_paid_invoice_count: 5,
    count_not_fully_paid_invoice: 7,
    count_invoice: 13,
    last_invoice_time: 1609534800000,
    first_invoice_time: 1577912400000,
    last_full_paid_time: 1596810636000,
    profit: 36304,
    comment: null,
    profit_percent: 31.2147,
    day_percent: 0.0852862,
    year_percent: 31.129461,
    month_percent: 2.558586,
    remainDays: -42,
    remainDaysColor: 'rgba(255,64,43,0.5)'
  }
];
