import { NgModule } from '@angular/core';
import { CntTableFlexComponent } from './cnt-table-flex.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { MzHeaderDefDirective } from './@sub/@directive/mz-header-def/mz-header-def.directive';
import { MzColumnDefDirective } from './@sub/@directive/mz-columng-def/mz-column-def.directive';
import { MzExtraRowViewDirective } from './@sub/@directive/mz-extra-row-view/mz-extra-row-view.directive';
import { CntTableFlexAutoWidthDirective } from './@sub/@directive/cnt-table-flex-auto-width/cnt-table-flex-auto-width.directive';
import { ObserversModule } from '@angular/cdk/observers';
import { CntTableCellComponent } from './@sub/@view/cnt-table-cell/cnt-table-cell.component';
import { CntTableFlexLazyLoadServiceModule } from './cnt-table-flex-lazy-load-service.module';

@NgModule({
  imports: [
    DragDropModule,
    CommonModule,
    ObserversModule,
    CntTableFlexLazyLoadServiceModule
  ],
  exports: [
    MzColumnDefDirective,
    MzHeaderDefDirective,
    MzExtraRowViewDirective,
    CntTableFlexComponent,
    CntTableCellComponent
  ],
  declarations: [
    MzColumnDefDirective,
    MzHeaderDefDirective,
    MzExtraRowViewDirective,
    CntTableFlexAutoWidthDirective,
    CntTableFlexComponent,
    CntTableCellComponent
  ]
})
export class CntTableFlexModule {}
