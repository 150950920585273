import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function cntFunctionCalculateTopOffset(
  viewDestroy$: Subject<any>,
  htmlElement: HTMLElement,
  cb: (tableHeight: number) => void,
  minHeight = 400,
  extaMinusHeight = 150,
  debounceTime = 250
) {
  timer(debounceTime)
    .pipe(takeUntil(viewDestroy$))
    .subscribe(() => {
      const position = htmlElement?.getBoundingClientRect();
      let tableHeight = position
        ? window.innerHeight - position.top - extaMinusHeight
        : minHeight;

      tableHeight = tableHeight > minHeight ? tableHeight : minHeight;

      /**
       * */
      cb(tableHeight);
    });
}
