<div class="header" *ngIf="videos.length > 1 || microphones.length > 1">
  <select
    class="video-stream"
    *ngIf="videos.length > 1"
    (change)="changeVideo($event.target?.value)"
  >
    <option [value]="video.id" *ngFor="let video of videos">{{
      video.label
    }}</option>
  </select>
  <select
    class="audio-stream"
    *ngIf="microphones.length > 1 && false"
    (change)="changeAudio($event.target?.value)"
  >
    <option [value]="microphone.id" *ngFor="let microphone of microphones">{{
      microphone.label
    }}</option>
  </select>
</div>

<div class="body">
  <video #video id="mz-camera-video" autoplay></video>
  <canvas #canvas style="display:none;"></canvas>

  <div class="button-block">
    <div class="c-icon-delete delete" (click)="cancel()"></div>

    <div
      [ngClass]="{
        visibilityHidden: !(maxImages > images.length)
      }"
      class="take-screenshot c-icon-photo-camera"
      (click)="takeSnapshot()"
    ></div>

    <div
      class="c-icon-checked checked"
      [ngClass]="{
        visibilityHidden: !images.length
      }"
      (click)="success()"
    ></div>
  </div>
</div>

<div class="footer">
  <div class="images" *ngIf="images?.length">
    <div class="image" *ngFor="let image of images; let idx = index">
      <div
        class="delete-button c-icon-delete"
        (click)="deleteIcon(image)"
      ></div>
      <img [src]="image.src" />
      <div class="number" (click)="openPage(image)">{{ idx + 1 }}</div>
    </div>
  </div>
</div>

<div class="modal-block" *ngIf="openImage">
  <div class="overlay" (click)="closeModal()"></div>

  <div class="image-block">
    <div
      class="delete-button c-icon-delete"
      (click)="deleteIcon(openImage)"
    ></div>
    <div class="image">
      <img [src]="openImage.src" />
    </div>
  </div>
</div>
