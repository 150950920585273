<form [formGroup]="form">
  <mat-form-field class="example-full-width">
    <input
      freeformObject
      (keypress)="onKeyPress($event)"
      [(ngModel)]="field.body.value"
      [freeformType]="'field'"
      [freeformBaseId]="fieldid"
      [freeformObjId]="objid"
      matInput
      formControlName="field"
      [placeholder]="field.body.name"
      [type]="type"
      [readonly]="
        field['clientGeneration'] ||
        disabled ||
        field.body.status.disabled ||
        (false && !field.options.access.write)
      "
      aria-label="State"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      (optionSelected)="onSelect($event.option.value)"
      autoActiveFirstOption
      #auto="matAutocomplete"
    >
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option.val"
      >
        <img
          *ngIf="option.img"
          style="vertical-align:middle;"
          aria-hidden
          src="{{ option.img }}"
          height="25"
        />
        <span class="option-val">{{ option.val }}</span>
        <small class="option-extra" *ngIf="option.exta">{{
          option.value
        }}</small>
      </mat-option>
    </mat-autocomplete>

    <span
      *ngIf="
        field.body.view.prefix?.value &&
        field.body.view.prefix?.type === 'string'
      "
      matPrefix
      >{{ field.body.view.prefix.value }}</span
    >

    <span
      *ngIf="
        field.body.view.postfix?.value &&
        field.body.view.postfix?.type === 'string'
      "
      matSuffix
    >
      {{ field.body.view.postfix.value }}
    </span>

    <!--If this field is required and have error message -> show this message -->
    <mat-error
      *ngIf="
        field.body?.status?.untouched === true &&
        field.body?.status?.value !== true &&
        field.body?.status?.required.length > 0 &&
        (field.body?.helper?.error || field.body.gen.errorOfValidator)
      "
    >
      <!--ADD DICTIONARY-->
      {{
        field.body.gen.errorOfValidator || field.body?.helper?.error
          | connectDictionary
      }}
    </mat-error>

    <!--If this field has hint -> show this hint -->
    <mat-hint align="start" *ngIf="field.body?.view?.hint?.start"
      ><strong>{{ field.body.view.hint.start }}</strong>
    </mat-hint>
    <mat-hint align="end" *ngIf="field.body?.view?.hint?.end"
      ><strong>{{ field.body.view.hint.end }}</strong>
    </mat-hint>
  </mat-form-field>
</form>
