import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactFirestoreType } from './@res/@type/common.type';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContactFirestoreService {
  private storage: { [phone: string]: ContactFirestoreType } = {
    '79281234567': {
      name: 'Красавчик',
      phone: '79281234567',
      device: 1,
      status: 1
    }
  };

  constructor(private firestore: AngularFirestore) {}

  /**
   * get user from firebase
   * @param {string} userId - id of user
   * */
  private getContacts(userId: string): Observable<ContactFirestoreType[]> {
    return this.firestore
      .collection<ContactFirestoreType>(`/users/${userId}/contact`)
      .valueChanges();
  }

  /**
   * TODO sync with firestore
   * contact sync with users
   * */
  public sync(uid: string) {
    // this.getContacts().subscribe(
    //     () => {
    //
    //     }
    // )
  }

  /**
   * get user data by uid
   * @param {string} phone - id of user
   * */
  public getContactByPhone(phone: string): ContactFirestoreType {
    return this.storage[phone];
  }

  /**
   * get contact name by phone
   * */
  public getContactNameByPhone(phone: string): string {
    return this.storage[phone] && this.storage[phone].name;
  }
}
