import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FrfInitialValueService } from '../../../../../../../../../../../../../../@service/frf-initial-value/frf-initial-value.service';
import { FreeformCommonService } from '../../../../../../../../../../../../../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfValueSetterService } from '../../../../../../../../../../../../../../@service/@group:value-services/frf-value-setter/frf-value-setter.service';
import { takeUntil } from 'rxjs/operators';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Component({
  selector: 'frf-field-text',
  templateUrl: './freeform-field-text.component.html',
  styleUrls: ['./freeform-field-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeformFieldTextComponent extends AbstractUnsubscribeViewControl
  implements OnInit, AfterViewInit {
  // reqired
  @Input('fieldid') fieldid;
  @Input('objid') objid;
  @Input() private frf;
  @Input('disabled') disabled;

  /**
   *
   * */
  public type = 'text';

  // this field from freeform object
  public field;
  // this field we add mask
  public mask;

  // create angular reactive form
  public form = new FormGroup({
    field: new FormControl('')
  });

  constructor(
    private common: FreeformCommonService,
    private frfInitialValueService: FrfInitialValueService,
    private frfValueSetterService: FrfValueSetterService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.field = this.frf.fields[this.fieldid].objects[this.objid];
    this.type = this.getType() || this.type;
    this.mask = this.common.getMaskByField(this.field);
  }

  ngAfterViewInit(): void {
    /*
     * set if need inital value
     * */
    this.frfInitialValueService.setIfNeedInitialValueIfItExist(
      this.field,
      this.frf,
      {}
    );

    this.watchFieldChanges();
  }

  /**
   *
   * */
  private getType(): string {
    return (
      this.field &&
      this.field.body &&
      this.field.body.payload &&
      this.field.body.payload.type
    );
  }

  /**
   * watch field changes
   * */
  private watchFieldChanges() {
    this.frfValueSetterService
      .watchFieldChanges(this.fieldid, this.objid)
      .pipe(takeUntil(this.viewDestroy$))
      .subscribe(() => {
        this.cdRef.markForCheck();
      });
  }

  /**
   *
   * */
  public onKeyPress(iNevent) {
    // console.log(
    //   'onKeyPress',
    //   iNevent
    // );
    const event = iNevent,
      symbol = event.key,
      keyCode = event.keyCode;

    /*
     * dont block this symbols (enter or backspace or delete () and so on...)
     * */
    if (
      keyCode === 8 || // enter
      keyCode === 9 || // tab
      keyCode === 13 || //backspase
      keyCode === 110 || // delete
      keyCode === 39 || // >
      keyCode === 37 // <
    )
      return true;
    //**LATER add key up work only for need fields
    if (
      this.field.body.rules.resolvedSymbols &&
      Array.isArray(this.field.body.rules.resolvedSymbols)
    ) {
      const result = this.common.checkSymbolForResolved(
        symbol,
        this.field.body.rules.resolvedSymbols
      );

      if (!result) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }
}
