import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexyViewCategoryTypeEnum } from '@cnt-multi-shared/@shared/@library/@group:flexy-view/@res/@enum/common.enum';
import { CntFlexyViewCategoryService } from '@cnt-nx-workspace/feature/cnt-flexy-view';

@Component({
  selector: 'cnt-flexy-view-category',
  templateUrl: './cnt-flexy-view-category.component.html',
  styleUrls: ['./cnt-flexy-view-category.component.scss']
})
export class CntFlexyViewCategoryComponent implements OnInit {
  /**
   *
   * */
  @Input() type!: FlexyViewCategoryTypeEnum;

  /**
   *
   * */
  @Input() name!: string;

  /**
   *
   * */
  @Input() value!: string;

  /**
   *
   * */
  @Output() clickToMenu: EventEmitter<void> = new EventEmitter<void>();

  /**
   *
   * */
  public flexyViewCategoryTypeEnum = FlexyViewCategoryTypeEnum;

  constructor(private cntFlexyViewCategory: CntFlexyViewCategoryService) {}

  ngOnInit() {}

  /**
   *
   * */
  public open() {
    this.clickToMenu.emit();
  }
}
