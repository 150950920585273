import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AppsIdEnum } from '@cnt-nx-workspace/feature/app-controller';

// declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class ConnectUrlService {
  private readonly noneUser = 'none';
  private readonly baseApp = 'chat';
  private readonly basePage = 'main';

  public readonly url$: BehaviorSubject<string> = new BehaviorSubject('');

  /*
   * getter for user
   * */
  public get user(): string | null {
    return this.url.split('/')[1] || this.noneUser;
  }

  /*
   * get url
   * */
  public url: string | null = '';

  /*
   * get app name
   * */
  public get app(): AppsIdEnum {
    return <AppsIdEnum>(this.url.split('/')[2] || this.baseApp);
  }

  /*
   * get page name
   * */
  public get page() {
    return this.url.split('/')[3] || this.basePage;
  }

  /*
   * all apps
   * */
  private apps = ['chat', 'sharepay', 'onepay', 'market'];

  constructor(private router: Router) {
    this.spyForUrlChange();
  }

  /*
   * get app
   * */
  // public getApp (): AppsIdEnum {
  //   return <AppsIdEnum>this.url.split('/')[2];
  // }

  /*
   * get url
   * */
  // public getUrl(): string {
  //   return this.router.url;
  // }

  /*
   * TODO later add after active get sub domain
   * get sub domain
   * */
  public getSubDomain() {}

  /*
   * spy for url changes
   * */
  private spyForUrlChange() {
    this.url = this.router.url;
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: any) => {
        this.url = event.url;
        this.url$.next(this.url);
      });
  }

  /*
   * TODO we add thi after
   * we opened on client version site
   * */
  private isOpenedOnSubDomain(): boolean {
    return window.location.host.split('.').length > 2;
  }

  /*
   * change app
   * */
}
