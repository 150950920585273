import { Subject } from 'rxjs';
import { MzCameraEndImageInterface } from '../../../@res/@abstract/@interface/common.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MzCameraService {
  public close$: Subject<MzCameraEndImageInterface> = new Subject();
}
