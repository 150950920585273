export enum FunctionScrollVisibilityState {
  Visible = 'visible',
  Hidden = 'hidden',
  none = 'none'
}

export enum FunctionScrollDirection {
  Up = 'Up',
  Down = 'Down',
  None = 'None'
}
