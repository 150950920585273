import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[mzExtraRowView]'
})
export class MzExtraRowViewDirective {
  /**
   *
   * */
  @Input() mzExtraRowView: string;

  constructor(
    /** @docs-private */
    public template: TemplateRef<any>
  ) {}
}
