import { Component, Input, OnInit } from '@angular/core';
import { CIconFontTypeEnum } from './@res/@abstract/@enum/common.enum';

@Component({
  selector: '[c-icon]',
  templateUrl: './c-icon.component.html',
  styleUrls: ['./c-icon.component.css']
})
export class CIconComponent implements OnInit {
  @Input() type: CIconFontTypeEnum;

  public cIconFontTypeEnum = CIconFontTypeEnum;

  constructor() {}

  ngOnInit() {}
}
