import {
  APP_ID,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Inject,
  Injectable,
  LOCALE_ID,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import { AppMenuBlockComponent } from './@res/@module/shared/@sub/@view/app-menu-left-footer-controller/@sub/@view/app-menu-block/app-menu-block.component';
import { AppMenuLeftFooterControllerComponent } from './@res/@module/shared/@sub/@view/app-menu-left-footer-controller/app-menu-left-footer-controller.component';
import { SharedModule } from './@res/@module/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainRoutingModule } from './@res/@module/main/main.routing.module';
import { ApolloBoost, ApolloBoostModule } from 'apollo-angular-boost';
import { isPlatformBrowser } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { ConnectAppControllerModule } from '@cnt-nx-workspace/feature/app-controller';
import { MzOnesignalWebService } from 'mz-onesignal';
import * as Sentry from '@sentry/browser';
import { AppComponent } from './app.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { CameraModule } from './@res/@module/camera/camera.module';
import { AppZoneFooterComponent } from './@route-module/app-zone/@sub/@view/app-zone-footer/app-zone-footer.component';
import { LkComponent } from './@route-module/app-zone/@sub/@view/lk/lk.component';
import { AppZoneComponent } from './@route-module/app-zone/app-zone.component';
import { MainInfoModule } from './@res/@module/main/@sub/@module/main-info/main-info.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { OverlayContainer } from '@angular/cdk/overlay';

// Sentry.init({
//   dsn: environment.sentry.web
// });

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LkComponent,

    AppZoneFooterComponent,
    AppZoneComponent,

    AppMenuBlockComponent,
    AppMenuLeftFooterControllerComponent,
  ],
  imports: [
    SharedModule,
    MainInfoModule,
    CameraModule,
    BrowserAnimationsModule,
    MainRoutingModule,
    ApolloBoostModule,
    // @ts-ignore
    StoreModule.forRoot(reducers),
    // @ts-ignore
    ConnectAppControllerModule,
  ],
  providers: [
    MzOnesignalWebService,
    { provide: OverlayContainer, useClass: OverlayContainer },
    { provide: MAT_DATE_LOCALE, useValue: 'ru' },
    { provide: LOCALE_ID, useValue: 'ru' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  // need for server rendering
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    public boost: ApolloBoost,
    public firestore: AngularFirestore
  ) {
    const platform = isPlatformBrowser(platformId) ? 'browser' : 'server';
    console.warn(
      `Running ${platform} with appId=${appId}, platformId=${platformId}`
    );

    /*
     * enable cache offline
     * */
    this.firestore.firestore.enablePersistence({ synchronizeTabs: true });
  }
}
