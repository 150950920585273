import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserFirestoreType } from './@res/@type/common.type';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ContactFirestoreService } from '../../../../../../@route-module/chat-app/@sub/@service/contact-service/contact-firestore.service';
import { UserTypeFirestoreEnum } from './@res/@enum/common.enum';
import { environment } from '../../../../../../../environments/environment';
import { MzCacheObservable } from './@sub';
import { CacheGroupEnum } from '../../../@res/@abstract/common.enum';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';

/**
 * Service for work with user data from firestore db
 * */
@Injectable({
  providedIn: 'root'
})
export class UserFirestoreService {
  private readonly storage: {
    [id$: string]: BehaviorSubject<UserFirestoreType | null>;
  } = {};
  private readonly clear$: Subject<boolean> = new Subject();

  constructor(
    private firestore: AngularFirestore,
    private contactFirestore: ContactFirestoreService,
    private connectAuth: ConnectAuthService
  ) {
    /* TODO delete later */
    window['firestore'] = firestore;

    window['firestoreUpdate'] = (path: string, data: any) => {
      this.firestore
        .doc(path)
        .set(data)
        .then(() => {
          console.log('firestoreUpdate - SUCCESS');
        })
        .catch(err => {
          console.log('firestoreUpdate - ERROR', err);
        });
    };

    window['firestoreGet'] = (path: string) => {
      this.firestore
        .doc(path)
        .get()
        .subscribe(
          result => {
            const data = `${path}\n` + JSON.stringify(result.data()) + '\n\n';

            // writeBuffer(data);

            window['result-data'] = result.data();
            console.log('firestoreGet data > ', data);
            console.log('firestoreGet object > ', window['result-data']);
          },
          err => {
            console.log('firestoreGet - err', err);
          }
        );
    };

    window['copyThis'] = text => copy(text);

    function writeBuffer(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log('Текст записан в буффер');
        })
        .catch(err => {
          console.error('К сожалению текст не был записан в буффер: ', err);
        });
    }

    function copy(text) {
      text.split('///').forEach((row, idx) => {
        if (row) {
          const result = row.split('\n');
          if (result[0] && result[1]) {
            try {
              const path = (<string>result[0]).trim(),
                jsonData = JSON.parse(result[1]);
              if (jsonData) {
                console.log(
                  `\n\n\n(${idx + 1}) Path - ${path}\n Data - ${JSON.stringify(
                    jsonData
                  )}`
                );
                window['firestoreUpdate'](path, jsonData);
              }
            } catch (e) {
              console.log(`\n\n\n ERROR (${idx + 1}) Text - ${e}`);
            }
          }
        }
      });
    }
  }

  /**
   * get user data by uid
   * @param {string} userId - id of user
   * */
  @MzCacheObservable({
    group: CacheGroupEnum.usersFirestore
  })
  public getUserById(userId: string): Observable<UserFirestoreType> {
    return this.firestore
      .doc<UserFirestoreType>(`/users/${userId}`)
      .valueChanges()
      .pipe(
        map(user => {
          return {
            ...user,
            id: userId
          };
        })
      );
  }

  /**
   * get user data by login
   * @param {string} login - id of user
   * */
  @MzCacheObservable({
    group: CacheGroupEnum.userLoginFirestore
  })
  public getUserByLogin(login: string): Observable<UserFirestoreType> {
    return this.firestore
      .collection('users', ref => {
        return ref
          .where('active', '==', true)
          .where('login', 'array-contains', login);
      })
      .snapshotChanges()
      .pipe(
        map(doc =>
          doc.map(item => {
            if (!item) {
              return null;
            }

            const data = item.payload.doc.data() as UserFirestoreType;
            const id = item.payload.doc.id;

            return {
              ...data,
              id: id
            };
          })
        ),
        map(users => users && users[0]),
        catchError(error => {
          console.warn('getUserByLogin - error', error);
          return of(null);
        })
      );
  }

  /**
   * get my user
   * */
  public getMyUser() {
    return this.getUserById(this.connectAuth.uid);
  }

  /**
   * get user name or phone
   * @param {string} uid - user id
   * */
  public getUserNameAndImage(
    uid: string
  ): Observable<{ name: string; image: string }> {
    return this.getUserById(uid).pipe(
      map(user => {
        let data;
        /*
         * if this user is a simple user return its name from contacts or phone
         * */
        if (user.type === UserTypeFirestoreEnum.simple) {
          data = {
            name:
              this.contactFirestore.getContactNameByPhone(
                user.phones && user.phones[0]
              ) || user.phones,
            image: user.image
          };
        } else {
          /*
           * if this service or app return its name
           * */
          data = {
            name: user.name,
            image: user.image
          };
        }

        return { ...data };
      })
    );
  }

  /**
   * get url for chat icon
   * @param {string} userId - id of chat
   * @param {string} image - icon name
   * */
  public getUrlForUserIcon(userId: string, image: string): string {
    return `${environment.domain.cdn}/users/${userId}/icon/${image}`;
  }
}
