import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AbstractUnsubscribeViewControl implements OnDestroy {
  protected viewDestroy$: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this.viewDestroy$.next();
    this.viewDestroy$.complete();
  }
}
