import { Action } from '@ngrx/store';
// import {ConnectAppControllerActionsEnum} from "../../../../../..";
import { CurrentUserInterface } from '../../../../../@res/@abstract/@interface/auth/common.interface';
import { ConnectAppControllerActionsEnum } from '../../../../../@res/@abstract/@enums/app/apps-id.enum';
// ConnectTestEnum
/**
 * set main  user [3]
 * */
export class SetMainUserAction implements Action {
  readonly type = ConnectAppControllerActionsEnum.setMainUser;
  constructor(public payload: CurrentUserInterface) {}
}
