import { Injectable } from '@angular/core';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';

/**
 *
 *
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfElementGetterService {
  /**
   * get element by local id
   * */
  public getByLocalId(
    frf: any,
    localIdWithPrefix,
    startFrfField: any
  ): string | null {
    //
    const result = this.parseLocalIdWithPrefix(localIdWithPrefix);
    let lid, type;

    if (!result) {
      // we have not rigt local id with prefix
      return null;
    }

    // we have rigt local id with prefix && type object
    lid = result['id'];
    type = result['type'];
    const r = this.getElementByLocalIdFromParent(frf, type, lid, startFrfField);
    return r;
  }

  /**
   *
   * */
  private parseLocalIdWithPrefix(iNlocalId: string): object {
    // can by static
    //
    const lidWithPrefix = iNlocalId,
      lidWithOutPrefix = lidWithPrefix.slice(3);
    if (lidWithPrefix.indexOf('lg-') !== -1) {
      // group
      return { type: 'group', id: lidWithOutPrefix };
    } else if (lidWithPrefix.indexOf('lp-') !== -1) {
      // page
      return { type: 'page', id: lidWithOutPrefix };
    } else if (lidWithPrefix.indexOf('lr-') !== -1) {
      // row
      return { type: 'row', id: lidWithOutPrefix };
    } else if (lidWithPrefix.indexOf('lc-') !== -1) {
      // collection
      return { type: 'collection', id: lidWithOutPrefix };
    }
    return null;
  }

  /**
   *
   * */
  private getElementByLocalIdFromParent(
    iNform,
    iNtype,
    iNlid,
    iNelement,
    iNelType = null
  ) {
    // : string | object
    // g
    const elType = iNelType,
      type = iNtype,
      el = iNelement;

    if (elType && elType === type) {
      // we need get id from this object
      if (
        el &&
        el['body'] &&
        el['body']['gen'] &&
        el['body']['gen']['lid'] &&
        el['body']['gen']['lid'][iNlid]
      ) {
        return el['body']['gen']['lid'][iNlid];
      }
    } else {
      // we need get id from parent
      const parent = this.getParentBlockFromElement(el);
      if (!parent) {
        return null;
      } // we have not parent

      const folder = this.getFolderNameByElementType(parent['type']);
      if (!folder) {
        return null;
      } // we have not folder

      const parentEl = this.getElementById(
        iNform,
        folder,
        parent['mid'],
        parent['id']
      );

      return this.getElementByLocalIdFromParent(
        iNform,
        iNtype,
        iNlid,
        parentEl,
        parent['type']
      );
    }

    console.warn('getElementByLocalIdFromParent - 6 ERROR');
    return null;
  }

  /**
   *
   * */
  public getParentBlockFromElement(iNelement) {
    //@disct - get parent bloc from element
    const options = iNelement['options'],
      result = { type: options['p-type'] },
      pkeys = this.getKeysForParenIdAndModelId(result['type']);

    if (!pkeys) return null;
    result['id'] = options[pkeys['id']];
    result['mid'] = options[pkeys['mid']];

    return result;
  }

  public getKeysForParenIdAndModelId(iNtype) {
    //@private
    const type = iNtype;
    let keyObjId, keyModelId;

    switch (type) {
      case 'page':
        keyObjId = 'p-p-id';
        keyModelId = 'p-p-mid';
        break;
      case 'row':
        keyObjId = 'p-r-id';
        keyModelId = 'p-r-mid';
        break;
      case 'group':
        keyObjId = 'p-g-id';
        keyModelId = 'p-g-mid';
        break;
      case 'collection':
        keyObjId = 'p-c-id';
        keyModelId = 'p-c-mid';
        break;
    }

    if (keyObjId) {
      return {
        id: keyObjId,
        mid: keyModelId
      };
    }

    return null;
  }

  /**
   * get folder name by element type
   * */
  private getFolderNameByElementType(type) {
    let folder = null;
    switch (type) {
      case 'page':
        folder = 'pages';
        break;

      case 'row':
        folder = 'rows';
        break;

      case 'group':
        folder = 'groups';
        break;

      case 'field':
        folder = 'fields';
        break;

      case 'collections':
        folder = 'fields';
        break;
    }

    return folder;
  }

  /**
   * get element from frf by object id
   * */
  public getElementById(
    frf: any,
    folder: string,
    modelIdOfElement: string,
    objectIdOfElement
  ): any | null {
    if (
      frf &&
      frf[folder] &&
      frf[folder][modelIdOfElement] &&
      frf[folder][modelIdOfElement]['objects'] &&
      frf[folder][modelIdOfElement]['objects'][objectIdOfElement]
    ) {
      return frf[folder][modelIdOfElement]['objects'][objectIdOfElement];
    }
    return null;
  }
}
