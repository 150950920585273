import {Injectable} from "@angular/core";
import {FrfMainLazyServiceModule} from "../../../../frf-main-lazy-service.module";
import {FrfElementTypeEnum} from "@cnt-multi-shared/@shared/freeform/@res/@abstract/@enum/common.enum";

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfCommon2Service {

  /**
   *
   * */
  public getFrfObjectType (
    frfElement: any
  ): FrfElementTypeEnum
  {
    return frfElement?.options?.object
  }
}
