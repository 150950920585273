export enum RnKeyboardValueEnum {
  enter = 'Enter',
  escape = 'Escape'
}

export enum RnKeyboardControlValueEnum {
  alt = 'altKey',
  ctrl = 'ctrlKey',
  meta = 'metaKey',
  metaOrCtrl = 'metaOrControll'
}
