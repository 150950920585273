import { Injectable } from '@angular/core';
import { FrfMainLazyServiceModule } from '../../../../../../../../../../../../../../../../../frf-main-lazy-service.module';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular-boost';
import { ConnectAuthService } from '@cnt-nx-workspace/feature/auth';
import { Observable, of } from 'rxjs';
import { ValueForSetFieldSourceActionType } from '../../../../../../../../../../../../../../../../../@doc/@field/@trigger/frf-actions.interface';
import { MzCacheObservable } from 'mz-cache-decorator';
import {
  MzCacheFreeformGroupEnum,
  MzCacheFreeformKeyEnum
} from '../../../../../../../../../../../../../../@res/@abstract/@enum/common.enum';

/**
 *
 * */
@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfViewImageService {
  constructor(private apollo: Apollo, private connect: ConnectAuthService) {}

  /**
   * получаем по пути приватную ссылку
   * */
  // APPOLLO и так кэширует ответы graphql
  // @MzCacheObservable(
  //     {
  //         group: MzCacheFreeformGroupEnum.freeform,
  //         key: MzCacheFreeformKeyEnum.getPrivateFile
  //     }
  // )
  private getPrivateUrlByPath(path: string): Observable<{ url: string }> {
    const uid = this.connect.uid,
      token = this.connect.token;
    return this.apollo
      .watchQuery({
        query: gql`
                  {
                      freeformGetDownloadUrlToField (
                        path: "${path}",
                        uid: "${uid}",
                        token: "${token}"
                      ) {
                        url
                        error {
                          code,
                          text
                        }
                      }
                  }
              `
      })
      .valueChanges.pipe(
        map(
          (result: any) =>
            result.data && result.data.freeformGetDownloadUrlToField
        )
      );
  }

  /**
   * Получаем значения для src по значение value
   * */
  public getUrlFromValue(
    value: ValueForSetFieldSourceActionType
  ): Observable<string | null> {
    if (typeof value === 'string') {
      /*
       * if we get string url -> get this
       * */
      return of(value);
    } else if (typeof value === 'object' && typeof value.path === 'string') {
      /*
       * if we get url from frf-upload-field component -> get with request for private
       * */
      if (value.public) {
        /*
         * for public -> simple get url
         * */
        return of(value.path);
      } else {
        /*
         * for private -> get after request
         * */
        return value.path && value.path.indexOf('blob:http') === -1
          ? this.getPrivateUrlByPath(value.path).pipe(
              map(result => {
                return result && result.url;
              })
            )
          : /* if is blob */
            of(value.path);
      }
    } else {
      return of(null);
    }
  }
}
