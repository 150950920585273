<div class="host">
  <div
    class="content"
    [ngClass]="{
      'visibility-hidden': hideContent,
      'visibility-visible': !hideContent
    }"
  >
    <div
      class="template-table"
      *ngIf="loaderRowsData && loaderRowsData.row && !showMainData"
    >
      <ng-container
        [ngTemplateOutlet]="table"
        [ngTemplateOutletContext]="{ rows: loaderRows, group: 'template' }"
      ></ng-container>
    </div>
    <div *ngIf="state?.length" [ngClass]="{ 'hide-block': !showMainData }">
      <ng-container
        [ngTemplateOutlet]="table"
        [ngTemplateOutletContext]="{ rows: rows, group: 'main' }"
      ></ng-container>
    </div>
  </div>
  <div class="loading" *ngIf="hideContent">
    <span>Загрузка...</span>
  </div>
</div>

<ng-template #table let-rows="rows" let-group="group">
  <ng-container [ngSwitch]="type">
    <div
      class="flex-table group_{{ group }}"
      (cdkObserveContent)="updateContent($event)"
      cdkDropList
      (cdkDropListDropped)="dropBodyRow($event)"
      [cdkDropListEnterPredicate]="dropListRowEnterPredicate"
      [cdkDropListDisabled]="!options.headerColumnDraggable"
    >
      <!-- ХЕДЕР ТАБЛИЦЫ -->
      <div class="flex-table-header">
        <div
          class="row-header flex-row"
          cdkDropList
          (cdkDropListDropped)="dropHeader($event, group)"
          [cdkDropListEnterPredicate]="dropListColumnEnterPredicate"
          cdkDropListOrientation="horizontal"
          [cdkDropListDisabled]="!options.bodyRowDraggable"
        >
          <div class="main">
            <ng-container
              *ngFor="
                let displayedColumn of columnsForShow;
                let idx = index;
                trackBy: trackByColumn
              "
            >
              <div
                class="cell-header flex-cell n{{ idx + 1 }} key_{{
                  displayedColumn.key
                }}"
                [cntTableFlexAutoWidth]="displayedColumn.key"
                [group]="group"
                [idx]="0"
                [ngStyle]="displayedColumn.style"
                *ngIf="displayedColumn.show"
                cdkDrag
                [cdkDragData]="displayedColumn"
                [cdkDragDisabled]="!displayedColumn.draggable"
                cdkDragLockAxis="x"
                (cdkDragStarted)="onStartDrag($event)"
              >
                <div class="value-block">
                  <!-- специальный шаблон для колонки приоритетнее -->
                  <ng-container
                    *ngIf="
                      headerColumnTemplates[displayedColumn.key];
                      else commonTemplate
                    "
                    [ngTemplateOutletContext]="{
                      column: displayedColumn,
                      key: displayedColumn.key
                    }"
                    [ngTemplateOutlet]="
                      headerColumnTemplates[displayedColumn.key]
                    "
                  >
                  </ng-container>

                  <!-- общий шаблон для колонок хедера -->
                  <ng-template #commonTemplate>
                    <ng-container
                      *ngIf="headerColumnTemplates[symbolAll]; else simpleValue"
                      [ngTemplateOutletContext]="{
                        column: displayedColumn,
                        key: displayedColumn.key
                      }"
                      [ngTemplateOutlet]="headerColumnTemplates[symbolAll]"
                    >
                    </ng-container>
                  </ng-template>

                  <!-- ИЛИ просто вывод значения -->
                  <ng-template #simpleValue>
                    <span class="value">{{ displayedColumn.value }}</span>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- ОСНОВНОЕ СОДЕРЖИМОЕ -->
      <div class="flex-table-body">
        <div
          class="row-body flex-row n{{ rowIdx + 1 }}"
          *ngFor="let element of rows; let rowIdx = index; trackBy: trackBy"
          cdkDrag
          [cdkDragData]="element"
          [cdkDragDisabled]="!element.options$.draggable"
          cdkDragLockAxis="y"
          (cdkDragStarted)="onStartDrag($event)"
        >
          <div class="main">
            <ng-container
              *ngFor="
                let displayedColumn of columnsForShow;
                let idx = index;
                trackBy: trackByColumn
              "
            >
              <cnt-table-cell
                class="cell-body flex-cell n{{ idx + 1 }} key_{{
                  displayedColumn.key
                }}"
                (onChangeExpandState)="
                  changeExpandElementState($event.element, $event.state)
                "
                [cntTableFlexAutoWidth]="displayedColumn.key"
                [ngStyle]="displayedColumn.style"
                *ngIf="displayedColumn.show"
                [idx]="rowIdx + 1"
                [rowIdx]="rowIdx"
                [displayedColumn]="displayedColumn"
                [rowTemplates]="rowTemplates"
                [element]="element"
                [lengthOfRows]="rows.length"
                [group]="group"
              >
              </cnt-table-cell>
            </ng-container>
          </div>

          <!-- Добавочные шаблоны -->
          <div
            class="extra"
            *ngIf="statesWithTemplates && statesWithTemplates.length"
          >
            <ng-container *ngFor="let state of filteredStatesWithTemplate">
              <ng-container
                *ngIf="isCurrentState(state)"
                [ngTemplateOutletContext]="{
                  element: element.data$,
                  rowIdx: rowIdx,
                  displayedColumns: columnsForShow,
                  expandedState: getExpandedStateOfElement(element)
                }"
                [ngTemplateOutlet]="extraViewTemplates[state]"
              >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
