import { Injectable } from '@angular/core';
import { CntApiCommonInterface } from './@res/@enum/cnt-api-messenger.enum';

@Injectable({
  providedIn: 'root'
})
export class CntFlexyViewApiCommonService implements CntApiCommonInterface {
  /**
   *
   * */
  public copy(text: any): Promise<boolean> {
    return new Promise(resolve => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          /* TODO show success toast */
          resolve(true);
        })
        .catch(err => {
          /* TODO show error */
          console.error('К сожалению текст не был записан в буффер: ', err);
          resolve(false);
        });
    });
  }
}
