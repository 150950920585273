import { Injectable, OnDestroy } from '@angular/core';
import { FreeformCommonService } from '../../../@res/shared/service/freeform/_sub/freeform.common.service';
import { FrfMainLazyServiceModule } from '../../../frf-main-lazy-service.module';
import { Subject } from 'rxjs';
import * as uuidV4 from 'uuid/v4';
import {
  FrfTriggerBaseInterface,
  FrfTriggerInterface,
  FrfTriggerServiceInterface
} from './@res/@abstract/@interface/common.interface';
import { FrfSharedStorageService } from '../frf-shared-storage/frf-shared-storage.service';
import { FrfTriggerAndActionPipeService } from './@sub/frf-trigger-and-action-pipe/frf-trigger-and-action-pipe.service';
import { FrfTriggerNameTempType } from './@res/@abstract/@type/common.type';
import { FrfValueGetterService } from '../@group:value-services/frf-value-getter/frf-value-getter.service';
import { FrfTriggerActionService } from './@sub/frf-trigger-action/frf-trigger-action.service';
import { AbstractUnsubscribeViewControl } from '@cnt-nx-workspace/function/shared/base';

@Injectable({
  providedIn: FrfMainLazyServiceModule
})
export class FrfTriggerService extends AbstractUnsubscribeViewControl
  implements FrfTriggerServiceInterface, OnDestroy {
  /**
   * our flows for add pipe to triggers
   * */
  private flows: { [key: string]: Subject<any> } = {};

  constructor(
    private common: FreeformCommonService,
    private frfValueGetter: FrfValueGetterService,
    private frfSharedStorageService: FrfSharedStorageService,
    private frfTriggerActionService: FrfTriggerActionService,
    private frfTriggerAndActionPipe: FrfTriggerAndActionPipeService
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyFlows();
  }

  /**
   * get flow with create if not exist
   * TODO add frfObject interface
   * */
  private safeCreateFlowIfNotExist(
    modelId: string,
    objId: string,
    triggerKey: FrfTriggerNameTempType,
    frfObject: any,
    trigger: FrfTriggerInterface,
    scope: any
  ): Subject<any> | null {
    if ((!trigger && !frfObject) || !triggerKey || !modelId || !objId) {
      return null;
    }

    const key = this.getKeyForFlow(modelId, objId, triggerKey, trigger);

    if (!this.flows[key]) {
      this.flows[key] = new Subject();

      this.flows[key]
        .pipe(this.frfTriggerAndActionPipe.add(trigger, frfObject, scope))
        .subscribe(() => {
          this.startTrigger(frfObject, trigger);
        });
    }

    return this.flows[key];
  }

  /**
   * destroy our flows from memory
   * */
  public destroyFlows() {
    const keys = Object.keys(this.flows);

    for (const key of keys) {
      if (this.flows[key]) {
        this.flows[key].complete();
      }
    }

    this.flows = {};

    this.frfTriggerActionService.destroyFlows();
  }

  /**
   * get unique key for flow
   * */
  private getKeyForFlow(
    modelId: string,
    objId: string,
    triggerKey: FrfTriggerNameTempType,
    trigger: FrfTriggerInterface
  ) {
    if (!trigger.gen) {
      trigger.gen = {
        uuid: uuidV4()
      };
    } else if (!trigger.gen.uuid) {
      trigger.gen.uuid = uuidV4();
    }

    return `${modelId}.${objId}.${triggerKey}.${trigger.gen.uuid}`;
  }

  /**
   * run trigger
   * TODO add frfObject interface
   * */
  public run(
    frfObject: any,
    triggerKeys: FrfTriggerNameTempType[],
    scope: any
  ): boolean {
    const triggersFolder = <FrfTriggerBaseInterface>(
      frfObject['body']['triggers']
    );

    if (!triggersFolder) {
      return false;
    }

    for (const triggerKey of triggerKeys) {
      let triggers: any =
        triggersFolder &&
        triggersFolder.events &&
        triggersFolder.events[triggerKey];

      if (!triggers) {
        continue;
      }

      if (!Array.isArray(triggers)) {
        triggers = triggersFolder.events[triggerKey] = [triggers];
      }

      for (const trigger of triggers) {
        if (
          !trigger ||
          (typeof trigger.active === 'boolean' && !trigger.active)
        ) {
          continue;
        }

        const flow$ = this.safeCreateFlowIfNotExist(
          frfObject['modelid'],
          frfObject['id'],
          triggerKey,
          frfObject,
          trigger,
          scope
        );

        if (flow$) {
          flow$.next();
        }
      }
    }

    return true;
  }

  /**
   * start triggers
   * TODO add field interface
   * */
  private startTrigger(
    field: any,
    trigger: FrfTriggerInterface,
    scope: any = {}
  ): void {
    const actions = trigger['actions']; // actions action

    this.frfTriggerActionService.run(field, actions, scope);
  }
}
